import { AnyAction } from 'redux';
import { IMapCountryIsoCode } from '../../models/IMapGdp';

export const SET_COUNTRY_LIST_BY_REGION = 'SET_COUNTRY_LIST_BY_REGION';

/**
 * this Action is used to set the countrylist by Regions
 * @param value
 * @returns
 */
export function setCountryListByRegion(value: Array<IMapCountryIsoCode>): AnyAction {
    return {
        type: SET_COUNTRY_LIST_BY_REGION,
        value
    };
}
