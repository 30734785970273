import { LoopReducer, Loop, Cmd, loop } from 'redux-loop';
import { AnyAction } from 'redux';
import * as FilterActions from '../actions/FilterActions';
import { getColorPaletteColor } from '../../utils/colorUtilts';
import {
    IFilterColorModel,
    IFilterModel,
    IFiltersProps,
    IScenarioFilterModel
} from '../../models/IFilterModel';
import {
    FILTER_DROPDOWN_TITLE,
    FILTER_VIEWS,
    REST_OF_THE_WORLD_COLOR_CODE,
    REST_OF_THE_WORLD_TEXT,
    appRoutes
} from '../../app.labels';
import { func } from 'prop-types';
export interface IScenarioFilter {
    value: string;
    id: string;
    isBaseLine: boolean;
}
export type IFilterState = {
    loading: boolean;
    selectAllRegions: boolean;
    selectedRegions: Array<string | number>;
    regionFilters: Array<IFilterColorModel>;
    sectorFilters: Array<IFilterColorModel>;
    scenariosColorMapping: Array<IFilterColorModel>;
    selctedSectors: Array<string | number>;
    selectAllSectors: boolean;
    dropDownState: {
        regions: boolean;
        sectors: boolean;
        scenario: boolean;
    };
    filterView: string;
    scenarioFilter: IScenarioFilter[];
    selectedScenarioRegView: Array<string>;
    updateScenario: Array<string>;
    selectedScenarioSceView: Array<string | number>;
    currentTab: string;
    currentMenu: string;
    // Sectoral Region
    selectedSectoralRegion: string;
    updatedSectoralRegion: string;
    // Scenario Region
    selectedScenarioRegion: string;
    updatedScenarioRegion: string;
    // Scenario Sector
    selectedScenarioSector: string;
    updatedScenarioSector: string;
    regionFiltersState: Array<IFilterModel>; //this are the main regions uncheck it
    sectorFiltersState: Array<IFilterModel>; //this are the main sector we have to uncheck it
    scenarioFiltersState: Array<IScenarioFilterModel>;
    resetButtonState: number;
    resetStateStatus: boolean;
    filtersLoading: boolean;
    preSelectedFilters: IFiltersProps;
    resetFiltersLoading: boolean;
};

export const initialState: IFilterState = {
    loading: false,
    selectedRegions: [],
    selctedSectors: [],
    selectedScenarioRegView: [],
    selectedScenarioSceView: [],
    updateScenario: [],
    // Sectoral Region
    selectedSectoralRegion: '',
    updatedSectoralRegion: '',
    // Scenario Region
    selectedScenarioRegion: '',
    updatedScenarioRegion: '',
    // Scenario Sector
    selectedScenarioSector: '',
    updatedScenarioSector: '',
    regionFilters: [],
    sectorFilters: [],
    scenariosColorMapping: [],
    filterView: FILTER_VIEWS.regional,
    currentTab: '',
    currentMenu: '',
    scenarioFilter: [],
    regionFiltersState: [],
    sectorFiltersState: [],
    scenarioFiltersState: [],
    selectAllRegions: true,
    selectAllSectors: true,
    dropDownState: {
        regions: false,
        sectors: false,
        scenario: false
    },
    resetButtonState: 0,
    resetStateStatus: false,
    filtersLoading: true,
    resetFiltersLoading: false,
    preSelectedFilters: {
        regionalView: {
            regions: [],
            sectors: [],
            scenarios: []
        },
        scenarioView: {
            regions: [],
            sectors: [],
            scenarios: []
        }
    }
};

/* Common function to return object as per filter type*/
function getFinalObject(type: string, state: IFilterState) {
    const regionFiltersObj = [...state.regionFiltersState];
    const sectorFiltersObj = [...state.sectorFiltersState];
    const finalObj = type === FILTER_DROPDOWN_TITLE.regions ? regionFiltersObj : sectorFiltersObj;

    return finalObj;
}

/* DropDown (Open/Close) state management */
function setFilterDDState(state: IFilterState, value: string) {
    const ddState = { ...state.dropDownState };
    for (const key in ddState) {
        let index = value?.toLowerCase();
        if (key === index) {
            ddState[key] = !ddState[key];
        } else {
            ddState[key] = false;
        }
    }
    return ddState;
}

function resetFilterDDState(state: IFilterState) {
    const ddState = { ...state.dropDownState };
    for (const key in ddState) {
        ddState[key] = false;
    }
    return ddState;
}

/* Region and Sector filters state management */
function createFiltersObject(actionObj): Array<IFilterModel> {
    let updatedData: Array<IFilterModel> = [];
    actionObj?.map((values, index) => {
        updatedData?.push({
            name: values?.name,
            id: values?.id,
            color:
                values?.name?.toLowerCase() === REST_OF_THE_WORLD_TEXT
                    ? REST_OF_THE_WORLD_COLOR_CODE
                    : getColorPaletteColor(index),
            checked: true
        });
    });

    return updatedData;
}

function createFiltersColorObject(actionObj): Array<IFilterColorModel> {
    let updatedData: Array<IFilterColorModel> = [];
    actionObj?.map((values, index) => {
        updatedData?.push({
            name: values?.name,
            id: values?.id,
            color:
                values?.name?.toLowerCase() === REST_OF_THE_WORLD_TEXT
                    ? REST_OF_THE_WORLD_COLOR_CODE
                    : getColorPaletteColor(index)
        });
    });
    return updatedData;
}

function setSelectedFilters(state: IFilterState, type: string) {
    const selectedFilterArray: Array<string | number> = [];
    const finalObj = getFinalObject(type, state);
    finalObj?.map((values) => {
        if (values?.checked) {
            // Now we are storing Id in store
            selectedFilterArray?.push(values?.id);
        }
    });
    return selectedFilterArray;
}

function updateSelectedFilter(value: string, state: IFilterState, type: string) {
    const finalObj = getFinalObject(type, state);
    for (const key in finalObj) {
        if (finalObj[key]?.name === value) {
            finalObj[key]['checked'] = !finalObj[key]?.checked;
            break;
        }
    }
    return finalObj;
}

function setSelectAllFilter(
    flag: boolean,
    state: IFilterState,
    type: string,
    selectedValue: Array<string | number> = []
) {
    let updatedData: Array<IFilterModel> = [];
    const finalObj = getFinalObject(type, state);
    finalObj?.map((values, index) => {
        updatedData.push({
            name: values?.name,
            id: values?.id,
            checked: selectedValue.length === 0 ? flag : selectedValue.includes(values?.id),
            color:
                values?.name?.toLowerCase() === REST_OF_THE_WORLD_TEXT
                    ? REST_OF_THE_WORLD_COLOR_CODE
                    : getColorPaletteColor(index)
        });
    });
    return updatedData;
}

function setFiltersBasesOnID(
    defaultList: Array<IFilterModel>,
    selectedValue: Array<string | number> = []
) {
    let updatedData: Array<IFilterModel> = [];
    defaultList?.map((values, index) => {
        updatedData.push({
            name: values?.name,
            id: values?.id,
            checked: selectedValue.length === 0 ? false : selectedValue.includes(values?.id),
            color:
                values?.name?.toLowerCase() === REST_OF_THE_WORLD_TEXT
                    ? REST_OF_THE_WORLD_COLOR_CODE
                    : getColorPaletteColor(index)
        });
    });
    return updatedData;
}

function updateSelectAll(state: IFilterState, type: string) {
    const finalObj = getFinalObject(type, state);
    const falseData: Array<string> = [];
    for (const key in finalObj) {
        if (!finalObj[key]?.checked) {
            falseData.push(finalObj[key]?.name);
            break;
        }
    }
    return falseData?.length < 1;
}

interface IFilter {
    value: string;
    id: string;
    isBaseLine: boolean;
}

/* Scenario filter state management */
function createScenariosFilter(actionObj) {
    const updatedData: IFilter[] = [];
    for (const key in actionObj) {
        // Adding baseline to array
        updatedData?.push({
            value: actionObj[key]?.name,
            id: actionObj[key]?.id,
            isBaseLine: true
        });
        actionObj[key]?.data?.map((values, index) => {
            // Adding policies to array
            updatedData?.push({
                value: values?.name,
                id: values?.id,
                isBaseLine: false
            });
        });
    }
    return updatedData;
}

// Color Object for Scenarios
function createScenariosColorMapping(actionObj) {
    const updatedData: Array<{}> = [];
    for (const key in actionObj) {
        // Adding baseline to array
        updatedData?.push({
            name: actionObj[key]?.name,
            id: actionObj[key]?.id
        });
        actionObj[key]?.data?.map((values, index) => {
            // Adding policies to array
            updatedData?.push({
                name: values?.name,
                id: values?.id
            });
        });
    }
    return createFiltersColorObject(updatedData);
}

// Data Object for Scenarios
function createScenariosFiltersObject(actionObj): Array<IScenarioFilterModel> {
    for (const key in actionObj) {
        actionObj[key]['checked'] = key === '0';
        actionObj[key].data = actionObj[key]?.data?.map((values, index) => {
            values['checked'] = key === '0';
            return values;
        });
    }
    return actionObj;
}

function setSelectedScenarios(state: IFilterState) {
    const selectedScenariosArray: Array<string | number> = [];
    const finalObj = [...state.scenarioFiltersState];
    for (const key in finalObj) {
        // Adding selected baseline to array
        if (finalObj[key]?.checked) {
            selectedScenariosArray?.push(finalObj[key]?.id);
        }
        finalObj[key]?.data?.map((values) => {
            if (values?.checked) {
                // Adding selected policies to array
                selectedScenariosArray?.push(values?.id);
            }
        });
    }

    return selectedScenariosArray;
}

function updateBaseLineScenarios(state: IFilterState, baseLineName: string) {
    const finalObj = [...state.scenarioFiltersState];
    for (const key in finalObj) {
        if (finalObj[key]?.name === baseLineName) {
            finalObj[key].checked = true;
            finalObj[key].data = finalObj[key]?.data?.map((values, index) => {
                values.checked = true;
                return values;
            });
        } else {
            finalObj[key].checked = false;
            finalObj[key].data = finalObj[key]?.data?.map((values, index) => {
                values.checked = false;
                return values;
            });
        }
    }
    return finalObj;
}

function updatePolicyScenarios(state: IFilterState, baseLineName: string, policy) {
    const finalObj = [...state.scenarioFiltersState];
    for (const key in finalObj) {
        if (finalObj[key]?.name === baseLineName) {
            finalObj[key].data = finalObj[key]?.data?.map((values, index) => {
                if (values?.name === policy) {
                    values.checked = !values?.checked;
                }
                return values;
            });
        }
    }

    return finalObj;
}

function updatePreSelectedFiltersOnReset(state: IFilterState) {
    let preSelectedFilters;
    preSelectedFilters = {
        regionalView: {
            scenarios: state.updateScenario,
            ...((state?.currentTab === appRoutes.emissions ||
                state?.currentTab === appRoutes.keyindicators) && {
                regions: state.regionFiltersState.map((obj) => obj.id),
                sectors: []
            }),
            ...(state?.currentTab === appRoutes.modellinginputs && {
                regions: [],
                sectors: []
            }),
            ...(state?.currentTab === appRoutes.sectoralimpacts && {
                regions: [],
                sectors: state.sectorFilters.map((obj) => obj.id)
            })
        },
        scenarioView: {
            scenarios:
                state?.currentTab !== appRoutes.modellinginputs
                    ? state?.selectedScenarioSceView
                    : [],
            ...((state?.currentTab === appRoutes.emissions ||
                state?.currentTab === appRoutes.keyindicators) && {
                regions: [state.regionFiltersState[0].id],
                sectors: []
            }),
            ...(state?.currentTab === appRoutes.sectoralimpacts && {
                regions: [],
                sectors: []
            })
        }
    };
    return preSelectedFilters;
}

function setFiltersFromQuery(
    state: IFilterState,
    type: string,
    selectedValueArray: Array<string | number>
) {
    let updatedData: Array<IFilterModel> = [];
    const finalObj = getFinalObject(type, state);
    finalObj?.map((values, index) => {
        updatedData.push({
            name: values?.name,
            id: values?.id,
            checked: selectedValueArray.includes(values?.id),
            color:
                values?.name?.toLowerCase() === REST_OF_THE_WORLD_TEXT
                    ? REST_OF_THE_WORLD_COLOR_CODE
                    : getColorPaletteColor(index)
        });
    });
    return updatedData;
}

function getFilterRegionIds(state: IFilterState) {
    const finalObj = [...state.regionFiltersState];
    const regionIds: Array<string | number> = [];
    finalObj?.map((values) => {
        regionIds.push(values?.id);
    });
    return regionIds;
}

function getSelectedScenariosIds(scenarioFiltersState) {
    const selectedScenariosArray: Array<string | number> = [];
    const finalObj = scenarioFiltersState;
    for (const key in finalObj) {
        // Adding selected baseline to array
        if (finalObj[key]?.checked) {
            selectedScenariosArray?.push(finalObj[key]?.id);
        }
        finalObj[key]?.data?.map((values) => {
            if (values?.checked) {
                // Adding selected policies to array
                selectedScenariosArray?.push(values?.id);
            }
        });
    }
    return selectedScenariosArray;
}

export const FilterReducer: LoopReducer<IFilterState> = (
    state: IFilterState = initialState,
    action: AnyAction
): IFilterState | Loop<IFilterState, AnyAction> => {
    switch (action.type) {
        case FilterActions.CREATE_SCENARIOS_FILTER:
            const scenariosColorMapping = createScenariosColorMapping(action.value);
            const scenarioFiltersState = createScenariosFiltersObject(action.value);
            const scenarioFilter = createScenariosFilter(action.value);
            state = {
                ...state,
                loading: true,
                scenariosColorMapping,
                scenarioFiltersState,
                scenarioFilter
                // selectedScenarioRegView: [scenarioFilter[0]?.id],
                // updateScenario: [scenarioFilter[0]?.id]
            };
            return loop(
                state,
                Cmd.list([Cmd.action(FilterActions.selectedScenarios(FILTER_VIEWS.scenario))])
            );

        case FilterActions.CREATE_FILTER_REGIONS:
            const regionFilters = createFiltersColorObject(action.value);
            const regionFiltersState = createFiltersObject(action.value);
            state = {
                ...state,
                loading: true,
                regionFiltersState,
                regionFilters
                // updatedScenarioRegion: regionFiltersState[0]?.id as string
            };
            return loop(
                state,
                Cmd.list([
                    Cmd.action(FilterActions.selectedFilterRegions()),
                    Cmd.action(FilterActions.setScenarioRegion())
                ])
            );

        case FilterActions.CREATE_FILTER_SECTORS:
            const sectorFiltersState = createFiltersObject(action.value);
            const sectorFilters = createFiltersColorObject(action.value);
            state = {
                ...state,
                loading: true,
                sectorFiltersState,
                sectorFilters
            };
            return loop(state, Cmd.list([Cmd.action(FilterActions.selectedFilterSectors())]));

        case FilterActions.SELECTED_FILTER_REGIONS:
            const selectedRegions = setSelectedFilters(state, FILTER_DROPDOWN_TITLE.regions);
            return {
                ...state,
                loading: false,
                selectedRegions
            };

        case FilterActions.UPDATE_FILTER_REGIONS:
            const updatedData = updateSelectedFilter(
                action.value,
                state,
                FILTER_DROPDOWN_TITLE.regions
            );
            state = {
                ...state,
                loading: true,
                regionFiltersState: updatedData
            };
            return loop(state, Cmd.list([Cmd.action(FilterActions.updateSelectAllRegions())]));

        case FilterActions.SELECT_ALL_REGIONS:
            const updatedRegionFilters = setSelectAllFilter(
                action.value,
                state,
                FILTER_DROPDOWN_TITLE.regions
            );
            return {
                ...state,
                loading: false,
                regionFiltersState: updatedRegionFilters,
                selectAllRegions: action.value
            };

        case FilterActions.UPDATE_SELECT_ALL_REGIONS:
            const slectAllFlag = updateSelectAll(state, FILTER_DROPDOWN_TITLE.regions);
            return {
                ...state,
                loading: false,
                selectAllRegions: slectAllFlag
            };

        case FilterActions.SELECTED_FILTER_SECTORS:
            const selctedSectors = setSelectedFilters(state, FILTER_DROPDOWN_TITLE.sectors);
            return {
                ...state,
                loading: false,
                selctedSectors
            };

        case FilterActions.SELECT_ALL_SECTORS:
            const updatedSectorsFilters = setSelectAllFilter(
                action.value,
                state,
                FILTER_DROPDOWN_TITLE.sectors
            );
            return {
                ...state,
                loading: false,
                sectorFiltersState: updatedSectorsFilters,
                selectAllSectors: action.value
            };

        case FilterActions.UPDATE_FILTER_SECTORS:
            const updatedSectorData = updateSelectedFilter(
                action.value,
                state,
                FILTER_DROPDOWN_TITLE.sectors
            );
            state = {
                ...state,
                loading: true,
                sectorFiltersState: updatedSectorData
            };
            return loop(state, Cmd.list([Cmd.action(FilterActions.updateSelectAllsectors())]));

        case FilterActions.UPDATE_SELECT_ALL_SECTORS:
            const slectAllSectorFlag = updateSelectAll(state, FILTER_DROPDOWN_TITLE.sectors);
            return {
                ...state,
                loading: false,
                selectAllSectors: slectAllSectorFlag
            };

        case FilterActions.SET_DD_STATE:
            const ddState = setFilterDDState(state, action.value);
            return {
                ...state,
                loading: false,
                dropDownState: ddState
            };

        case FilterActions.RESET_DD_STATE:
            const resetDdState = resetFilterDDState(state);
            return {
                ...state,
                loading: false,
                dropDownState: resetDdState
            };

        case FilterActions.SET_FILTER_VIEW:
            return {
                ...state,
                loading: false,
                filterView: action.value
            };

        case FilterActions.UPDATE_SCENARIO:
            return {
                ...state,
                loading: false,
                updateScenario: [action.value]
            };

        case FilterActions.UPDATE_BASELINE_SCENARIO:
            const updatedBaseLineScenario = updateBaseLineScenarios(state, action.value);
            return {
                ...state,
                loading: false,
                scenarioFiltersState: updatedBaseLineScenario
            };

        case FilterActions.UPDATE_POLICIES_SCENARIO:
            const updatedPoliciesScenario = updatePolicyScenarios(
                state,
                action.baseLine,
                action.value
            );
            return {
                ...state,
                loading: false,
                scenarioFiltersState: updatedPoliciesScenario
            };

        case FilterActions.SELECTED_SCENARIOS:
            if (action.view === FILTER_VIEWS.regional) {
                return {
                    ...state,
                    loading: false,
                    selectedScenarioRegView: state.updateScenario
                };
            } else {
                const selectedArray = setSelectedScenarios(state);
                return {
                    ...state,
                    loading: false,
                    selectedScenarioSceView: selectedArray
                };
            }

        case FilterActions.CURRENT_TAB:
            const currentTab = action.value;
            return {
                ...state,
                loading: false,
                currentTab
            };

        case FilterActions.CURRENT_MENU:
            const currentMenu = action.value;
            return {
                ...state,
                loading: false,
                currentMenu
            };

        case FilterActions.UPDATED_SECTORAL_REGIONS:
            const updatedSectoralRegion = action.value;
            return {
                ...state,
                loading: false,
                updatedSectoralRegion
            };

        case FilterActions.UPDATED_SCENARIO_REGIONS:
            const updatedScenarioRegion = action.value;
            return {
                ...state,
                loading: false,
                updatedScenarioRegion
            };

        case FilterActions.UPDATED_SCENARIO_SECTOR:
            const updatedScenarioSector = action.value;
            return {
                ...state,
                loading: false,
                updatedScenarioSector
            };

        case FilterActions.SELECTED_SECTORAL_REGIONS:
            const selectedSectoralRegion = state.updatedSectoralRegion;
            return {
                ...state,
                loading: false,
                selectedSectoralRegion
            };

        case FilterActions.SELECTED_SCENARIO_REGIONS:
            const selectedScenarioRegion = state.updatedScenarioRegion;
            return {
                ...state,
                loading: false,
                selectedScenarioRegion
            };

        case FilterActions.SELECTED_SCENARIO_SECTOR:
            const selectedScenarioSector = state.updatedScenarioSector;

            return {
                ...state,
                loading: false,
                selectedScenarioSector
            };

        case FilterActions.RESET_FILTER_STATE:
            const scenarioFilterObj = [...state.scenarioFilter];
            const scenarioFiltersStateObj = [...state.scenarioFiltersState];
            const regionFiltersStateObj = [...state.regionFiltersState];
            state = {
                ...state,
                loading: true,
                selectedScenarioRegView: [scenarioFilterObj[0]?.id],
                updateScenario: [scenarioFilterObj[0]?.id],
                scenarioFiltersState: createScenariosFiltersObject(scenarioFiltersStateObj),
                resetStateStatus: true
            };

            return loop(
                state,
                Cmd.list([
                    Cmd.action(FilterActions.resetFilterLoading(false)),
                    Cmd.action(FilterActions.selectAllRegions(true)),
                    Cmd.action(
                        FilterActions.selectAllsectors(
                            !(appRoutes.sectoralimpacts === state.currentTab)
                        )
                    ),
                    Cmd.action(FilterActions.selectedFilterRegions()),
                    Cmd.action(FilterActions.selectedFilterSectors()),
                    Cmd.action(FilterActions.setUpdateSectoralRegion('')),
                    Cmd.action(FilterActions.setSectoralRegion()),
                    Cmd.action(FilterActions.resetDDState()),
                    Cmd.action(
                        FilterActions.setUpdateScenarioRegion(
                            (appRoutes.sectoralimpacts === state.currentTab
                                ? ''
                                : regionFiltersStateObj[0]?.id) as string
                        )
                    ),
                    Cmd.action(FilterActions.setUpdateScenarioSector('')),
                    Cmd.action(FilterActions.setScenarioSector()),
                    Cmd.action(FilterActions.setScenarioRegion()),
                    Cmd.list([Cmd.action(FilterActions.updateSelectAllRegions())]),
                    Cmd.action(FilterActions.selectedScenarios(FILTER_VIEWS.scenario)),
                    Cmd.action(FilterActions.resetPreSelectedFilters()),
                    Cmd.action(FilterActions.resetFilterLoading(true))
                ])
            );

        case FilterActions.SELECTED_REGIONS_ON_IDS:
            let updateScenarioRegion: string = '';
            let updateScenarioSector: string = '';
            let updatedSectoralRegion1: string = '';
            let selectedRegions1: Array<number | string> = [];
            let selectedSectors1: Array<number | string> = [];
            let selectedScenarioSecView1: Array<any> = [];
            let sectorialScenarioRegion: string = '';
            let formatted0bj: any;
            if (action.value) {
                let selectedScenarioRegView: Array<any> = [];
                let updatedSelectedSectors: IFilterModel[] = [];
                let updatedSelectedRegions: IFilterModel[] = [];

                let scenarioFiltersStateVal: Array<IScenarioFilterModel> = [];
                if (action.value?.pageName === appRoutes.sectoralimpacts) {
                    /* If selected Region is present for Sectoral Impacts Sector View */
                    if (
                        action.value?.view === 'regionalView' &&
                        !Array.isArray(action.value?.selectedRegions)
                    ) {
                        updateScenarioRegion = action.value?.selectedRegions;
                        updatedSectoralRegion1 = action.value?.selectedRegions;
                    }

                    if (
                        action.value?.view === 'scenarioView' &&
                        !Array.isArray(action.value?.selectedRegions)
                    ) {
                        sectorialScenarioRegion = action.value?.selectedRegions;
                    }

                    if (
                        action.value?.view === 'regionalView' &&
                        Array.isArray(action.value?.selectedSectors)
                    ) {
                        updatedSelectedSectors = setFiltersBasesOnID(
                            [...state.sectorFiltersState],
                            action.value?.selectedSectors
                        );
                        selectedSectors1 = action.value?.selectedSectors;
                        if (action.value?.selectedScenario?.length > 0) {
                            selectedScenarioRegView = action.value?.selectedScenario;
                        }
                    }

                    if (
                        action.value?.view === 'scenarioView' &&
                        action.value?.selectedScenario?.length > 0
                    ) {
                          /* First we fetch baseline parent scenario. */
                          let parentScenario = action.value?.selectedScenario[0];
                          scenarioFiltersStateVal = state.scenarioFiltersState.map(
                              (objectSer: IScenarioFilterModel) => {
                                  let returnOBj = {
                                      ...objectSer,
                                      checked: objectSer.id === parentScenario,
                                      data: objectSer.data.map((objData) => {
                                          return {
                                              ...objData,
                                              checked: action.value?.selectedScenario.includes(
                                                  objData.id
                                              )
                                          };
                                      })
                                  };
                                  return returnOBj;
                              }
                          );
                      }

                    if (
                        action.value?.view === 'scenarioView' &&
                        !Array.isArray(action.value?.selectedSectors)
                    ) {
                        updateScenarioSector = action.value?.selectedSectors;
                    }

                    if (Object.keys(action.value).length > 1) {
                        formatted0bj = {
                            regionFiltersState: updatedSelectedRegions,
                            selectedScenarioRegView: selectedScenarioRegView,
                            scenarioFiltersState: scenarioFiltersStateVal,
                            sectorFiltersState: updatedSelectedSectors
                        };
                    }
                } else {
                    if (Array.isArray(action.value?.selectedRegions)) {
                        updatedSelectedRegions = setFiltersBasesOnID(
                            [...state.regionFiltersState],
                            action.value?.selectedRegions
                        );
                        selectedRegions1 = action.value?.selectedRegions;
                    } else if (action.value?.selectedRegions === undefined) {
                        /* This will be for modelling Input tab. */
                        let selectedRegions = getFilterRegionIds(state);
                        updatedSelectedRegions = setFiltersBasesOnID(
                            [...state.regionFiltersState],
                            selectedRegions
                        );
                        selectedRegions1 = selectedRegions;
                    }

                    if (
                        state.filterView === FILTER_VIEWS.scenario &&
                        action.value?.selectedScenario?.length > 0
                    ) {
                        scenarioFiltersStateVal = state.scenarioFiltersState.map(
                            (objectSer: IScenarioFilterModel) => {
                                /* First we fetch baseline parent scenario. */
                                let parentScenario = action.value?.selectedScenario[0];
                                 let returnOBj = {
                                    ...objectSer,
                                    checked: objectSer.id === parentScenario,
                                    data: objectSer.data.map((objData) => {
                                        return {
                                            ...objData,
                                            checked: action.value?.selectedScenario.includes(
                                                objData.id
                                            )
                                        };
                                    })
                                };
                                return returnOBj;
                            }
                        );
                        if (action.value?.selectedScenario?.length > 0) {
                            selectedScenarioSecView1 = action.value?.selectedScenario;
                        }
                    } else {
                        if (action.value?.selectedScenario?.length > 0) {
                            selectedScenarioRegView = action.value?.selectedScenario;
                        }
                    }
                    if (
                        state.filterView === FILTER_VIEWS.scenario &&
                        !Array.isArray(action.value?.selectedRegions)
                    ) {
                        updateScenarioRegion = action.value?.selectedRegions;
                    }
                    if (Object.keys(action.value).length > 1) {
                        formatted0bj = {
                            regionFiltersState: updatedSelectedRegions,
                            selectedScenarioRegView: selectedScenarioRegView,
                            scenarioFiltersState: scenarioFiltersStateVal,
                            sectorFiltersState: updatedSelectedSectors
                        };
                    }
                }
            }
            let formattedObject: any;

            if (formatted0bj) {
                formattedObject = {};
                if (updateScenarioSector !== '') {
                    formattedObject['updatedScenarioSector'] = updateScenarioSector;
                    formattedObject['selectedScenarioSector'] = updateScenarioSector;
                }

                if (updateScenarioRegion !== undefined && updateScenarioRegion.length > 0) {
                    formattedObject['updatedScenarioRegion'] = updateScenarioRegion;
                    formattedObject['selectedScenarioRegion'] = updateScenarioRegion;
                }

                if (
                    action.value?.pageName === appRoutes.sectoralimpacts &&
                    action.value?.view === 'scenarioView'
                ) {
                    formattedObject['updatedScenarioRegion'] = sectorialScenarioRegion;
                    formattedObject['selectedScenarioRegion'] = sectorialScenarioRegion;
                }

                if (updatedSectoralRegion1 !== '') {
                    formattedObject['updatedSectoralRegion'] = updatedSectoralRegion1;
                    formattedObject['selectedSectoralRegion'] = updatedSectoralRegion1;
                    // formattedObject['selectedScenarioRegion'] = updatedSectoralRegion1;
                }

                if (formatted0bj.regionFiltersState && formatted0bj.regionFiltersState.length > 0) {
                    formattedObject['regionFiltersState'] = formatted0bj.regionFiltersState;
                    formattedObject['selectAllRegions'] =
                        selectedRegions1.length === state.regionFiltersState.length;
                    formattedObject['selectedRegions'] = selectedRegions1;
                    formattedObject['updatedSectoralRegion'] = '';
                    formattedObject['selectedSectoralRegion'] = '';
                } else {
                    if (updatedSectoralRegion1 !== '') {
                        formattedObject['updatedSectoralRegion'] = updatedSectoralRegion1;
                        formattedObject['selectedSectoralRegion'] = updatedSectoralRegion1;
                    }
                    formattedObject['selectAllRegions'] = true;
                    formattedObject['selectedRegions'] = [];
                }

                if (formatted0bj.selectedScenarioRegView.length > 0) {
                    formattedObject['selectedScenarioRegView'] =
                        formatted0bj.selectedScenarioRegView;
                    formattedObject['updateScenario'] = formatted0bj.selectedScenarioRegView;
                }

                if (selectedScenarioSecView1 && selectedScenarioSecView1.length > 0) {
                    formattedObject['selectedScenarioSecView'] =
                        formatted0bj.selectedScenarioSecView1;
                }


                if (formatted0bj.scenarioFiltersState?.length > 0) {
                    formattedObject['scenarioFiltersState'] = formatted0bj.scenarioFiltersState;
                    formattedObject['selectedScenarioSceView'] = getSelectedScenariosIds(
                        formatted0bj.scenarioFiltersState
                    );
                }

                if (formatted0bj.sectorFiltersState?.length > 0) {
                    formattedObject['sectorFiltersState'] = formatted0bj.sectorFiltersState;
                    formattedObject['selectAllSectors'] =
                        selectedSectors1.length === state.sectorFiltersState.length;
                    formattedObject['selctedSectors'] = selectedSectors1;
                }
            }

            if (formattedObject) {
                return {
                    ...state,
                    loading: false,
                    filtersLoading: false,
                    ...formattedObject
                };
            } else {
                return {
                    ...state,
                    filtersLoading: false,
                    loading: false
                };
            }

        case FilterActions.FILTERS_LOADING:
            return {
                ...state,
                filtersLoading: action.value
            };

        case FilterActions.SET_FILTER_STATE:
            state.loading = true;
            if (action.value?.selectedRegionsArray?.length > 0) {
                const regionFilters = setFiltersFromQuery(
                    state,
                    FILTER_DROPDOWN_TITLE.regions,
                    action.value?.selectedRegionsArray
                );
                state = {
                    ...state,
                    regionFiltersState: regionFilters
                };
            }

            return loop(
                state,
                Cmd.list([
                    Cmd.action(FilterActions.selectedFilterRegions()),
                    Cmd.list([Cmd.action(FilterActions.updateSelectAllRegions())])
                ])
            );

        case FilterActions.RESET_BUTTON_STATE:
            return {
                ...state,
                loading: false,
                resetButtonState: state.resetButtonState + 1
            };

        case FilterActions.SET_DEFAULT_FILTER_STATE:
            let scenarioFilterData = [...state.scenarioFilter];
            let regionFiltersStateData = [...state.regionFiltersState];
            return {
                ...state,
                loading: false,
                //selectedScenarioRegView: [scenarioFilterData[0]?.id],
                //updateScenario: [scenarioFilterData[0]?.id],
                //updatedScenarioRegion: regionFiltersStateData[0]?.id as string,
                //selectedScenarioRegion: regionFiltersStateData[0]?.id as string,
                filtersLoading: false
            };

        case FilterActions.UPDATE_PRE_SELECTED_FILTERS:
            return {
                ...state,
                preSelectedFilters: action.value
            };

        case FilterActions.RESET_FILTERS_LOADING:
            return {
                ...state,
                resetFiltersLoading: action.value
            };

        case FilterActions.RESET_PRE_SELECTED_FILTERS:
            let preSelectedFilters = updatePreSelectedFiltersOnReset(state);
            return {
                ...state,
                preSelectedFilters
            };

        default:
            return state;
    }
};