import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    MotifBreadcrumb,
    MotifBreadcrumbItem,
    MotifButton,
    MotifFormField,
    MotifIcon,
    MotifLabel,
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifOption,
    MotifSelect,
    MotifToast
} from '@ey-xd/motif-react';
import { getChartDownload } from '../../ChartDownload.service';
import { IStore } from '../../../../../../store';
import {
    DOWNLOAD_CHART,
    GLOBAL_ERROR_BOUNDARY_MESSAGE,
    WIDGET_OPTIONS
} from '../../../../../../app.labels';
import { customCIMIcFileDownload24px } from '../../../../../../assets/icons';
import { StyledIcon } from '../ChartLibraryTable/ChartLibraryTable.style';
import { HeaderWrapper, StyledMotifLabel, customizeStyles } from './ActionCellRenderer.style';
import LoadIndicator from '../../../../../loadIndicator';
import { ErrorMessages } from '../../../../../../common/enum/error';

const ActionCellRenderer = ({ value }) => {
    const scenariosData = useSelector((store: IStore) => store?.filter?.scenarioFilter);

    const [showSmallModal, setShowSmallModal] = useState(false);
    const [policy, setPolicy] = useState(scenariosData[0]?.value || '');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    const [isLoading, setIsLoading] = useState(false);

    const handleSelectClick = () => {
        setIsOpen(!isOpen);
        customizeStyles();
    };

    const downloadChart = async () => {
        let scenarioId;
        scenariosData.forEach((scenario) => {
            if (scenario.value === policy) {
                scenarioId = scenario.id;
            }
        });
        setIsLoading(true);
        const response = await getChartDownload(value, scenarioId, policy);
        if (response.error) {
            setError(true);
            setErrorMessage(response.error);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setShowSmallModal(false);
            setErrorMessage(null);
            setError(false);
            document.body.style.overflow = 'auto';
        }
    };

    const handleClose = () => {
        setError(false);
        setIsLoading(false);
        setShowSmallModal(false);
        document.body.style.overflow = 'auto';
    };

    return value ? (
        <StyledIcon>
            <MotifIcon
                data-testId={'motif-tbl-icon'}
                src={customCIMIcFileDownload24px}
                onClick={() => {
                    setShowSmallModal(true);
                }}
            />
            {showSmallModal && (
                <MotifModal
                    size="sm"
                    variant="alt"
                    data-testId="motif-modal"
                    style={{ overflowY: 'initial' }}
                    show={showSmallModal}
                    onClose={handleClose}>
                    <MotifModalHeader>
                        <HeaderWrapper>{DOWNLOAD_CHART.DOWNLOAD_REPORT}</HeaderWrapper>
                    </MotifModalHeader>

                    <MotifModalBody style={{ overflow: 'initial' }}>
                        <MotifBreadcrumb
                            style={{ padding: '10px', fontSize: '13px' }}
                            firstItemsAmount={0}
                            lastItemsAmount={3}
                            triggerButtonProps={{
                                title: 'Custom Trigger Button title',
                                'aria-label': 'Custom Trigger Button aria-label'
                            }}>
                            <MotifBreadcrumbItem disabled>{value.parent}</MotifBreadcrumbItem>
                            <MotifBreadcrumbItem disabled>
                                <strong>{value.name}</strong>
                            </MotifBreadcrumbItem>
                        </MotifBreadcrumb>
                        <StyledMotifLabel>{DOWNLOAD_CHART.DOWNLOAD_REPORT_DESC}</StyledMotifLabel>
                        <MotifFormField>
                            <MotifLabel id="select-label-visible-options">
                                {DOWNLOAD_CHART.SELECT_SCENARIO}
                            </MotifLabel>

                            <MotifSelect
                                value={policy}
                                //@ts-ignore
                                onClick={handleSelectClick}
                                onChange={(val) => setPolicy(val)}
                                id={'select-label-visible-options'}
                                triggerButtonProps={{ type: 'button' }}
                                ariaLabelledBy="select-label-visible-options">
                                {scenariosData.map((item, index) => {
                                    return (
                                        <MotifOption id={item.id} key={index} value={item.value}>
                                            {item.value}
                                        </MotifOption>
                                    );
                                })}
                            </MotifSelect>
                        </MotifFormField>
                        {error && (
                            <MotifToast variant="error" onClose={() => setError(false)}>
                                {errorMessage && errorMessage === ErrorMessages.Error404 ? (
                                    <>
                                        <p>{WIDGET_OPTIONS.noDataAvailable}</p>
                                    </>
                                ) : (
                                    <>
                                        <h3>{GLOBAL_ERROR_BOUNDARY_MESSAGE.title}</h3>
                                        <p>{GLOBAL_ERROR_BOUNDARY_MESSAGE.description}</p>
                                    </>
                                )}
                            </MotifToast>
                        )}
                        {isLoading && <LoadIndicator />}
                    </MotifModalBody>

                    <MotifModalFooter>
                        <MotifButton
                            style={{ width: '128px', height: '44px' }}
                            type="button"
                            onClick={downloadChart}>
                            {DOWNLOAD_CHART.DOWNLOAD}
                        </MotifButton>
                        <MotifButton
                            style={{ width: '128px', height: '44px' }}
                            variant="secondary"
                            type="button"
                            data-testId="motif-modal-close-button"
                            onClick={handleClose}>
                            {DOWNLOAD_CHART.CANCEL}
                        </MotifButton>
                    </MotifModalFooter>
                </MotifModal>
            )}
        </StyledIcon>
    ) : null;
};

export default ActionCellRenderer;
