import React, { FC, useEffect, useState } from 'react';
import { MotifDropdown, MotifDropdownItem, MotifAvatar } from '@ey-xd/motif-react';
import { communicationIcEmail24px, actionIcHelp24px } from '@ey-xd/motif-react/assets/icons';
import { PROFILE_CARD_MENU } from '../../app.labels';
import { logoutIcon } from '../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import { HEADER } from '../../app.constant';
import { userLogout } from '../../store/actions/userProfileActions';
import ModalComponent, { ModalSize } from '../modal';

import StyledDropdown from './HeaderProfileCard.style';

interface IDropdownItem {
    name: string;
    icon: string;
}

const HeaderProfileCard: React.FC = () => {
    const dispatch = useDispatch();

    const [showDropdown, setDropdown] = useState<boolean>(false);
    const [showModal, setModal] = useState<boolean>(false);
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);
    const [children, setChildren] = useState<IDropdownItem[]>([
        {
            name: PROFILE_CARD_MENU.contactUs,
            icon: communicationIcEmail24px
        },
        {
            name: PROFILE_CARD_MENU.logOut,
            icon: logoutIcon
        }
    ]);

    const { displayName: userName, email: userEmail, profileImage, isExternal } = useSelector(
        (state: IStore) => state.userProfile?.userData
    );

    const triggerId = 'dropdown-trigger';

    useEffect(() => {
        if (!isExternal) {
            const updateChildren = [...children];
            updateChildren.unshift({ name: PROFILE_CARD_MENU.help, icon: actionIcHelp24px });
            setChildren(updateChildren);
        }
    }, []);

    useEffect(() => {
        if (confirmLogout) {
            dispatch(userLogout());
            setModal(false);
        }
    }, [confirmLogout]);

    const handleMenu = (): void => {
        setDropdown(!showDropdown);
    };

    const handleClickOutside = (): void => {
        setDropdown(false);
    };

    const handleHelp = (): void => {
        window.open(HEADER.HELP.LINK, '_blank');
    };

    const handleContactUs = (): boolean => {
        window.open(
            `mailto:${HEADER.CONTACTUS.EMAIL}?subject=${HEADER.CONTACTUS.SUBJECT}`,
            '_blank'
        );
        return false;
    };

    const handleLogout = (): void => {
        setModal(true);
    };

    const handleClickDropdownItem = (child = 'username'): void => {
        switch (child) {
            case PROFILE_CARD_MENU.help:
                handleHelp();
                break;
            case PROFILE_CARD_MENU.contactUs:
                handleContactUs();
                break;
            case PROFILE_CARD_MENU.logOut:
                handleLogout();
                break;
            default:
                break;
        }
    };

    const ModalBody = (): JSX.Element => {
        return (
            <StyledDropdown.ModalBody>
                <StyledDropdown.ModalBodyIcon src={logoutIcon} />
                {HEADER.LOGOUT.CONFIRMATION_TEXT}
            </StyledDropdown.ModalBody>
        );
    };

    const DropdownChildrens: FC = () => {
        return (
            <>
                {children.length > 0 &&
                    children.map((child, i) => (
                        <StyledDropdown.Item
                            onClick={() => handleClickDropdownItem(child.name)}
                            key={i}>
                            <StyledDropdown.Icon src={child.icon} />
                            {child.name}
                        </StyledDropdown.Item>
                    ))}
            </>
        );
    };

    return (
        <>
            <MotifDropdown
                open={showDropdown}
                aria-labelledby={triggerId}
                handleClickOutside={handleClickOutside}
                trigger={
                    <MotifAvatar
                        userName={userName}
                        email={userEmail}
                        src={profileImage}
                        onClick={handleMenu}
                    />
                }>
                <MotifDropdownItem onClick={() => handleClickDropdownItem()} key={'username'}>
                    <MotifAvatar userName={userName} email={userEmail} />
                    {userName}
                </MotifDropdownItem>
                <DropdownChildrens />
            </MotifDropdown>
            {showModal && (
                <ModalComponent
                    isModalVisible={showModal}
                    modalHeader={PROFILE_CARD_MENU.modalHeaderText}
                    modalBody={<ModalBody />}
                    size={ModalSize.SMALL}
                    actionButtonText={PROFILE_CARD_MENU.modalActionButtonText}
                    handleActionButton={() => setConfirmLogout(true)}
                    cancelButtonText={PROFILE_CARD_MENU.modalCancelButtonText}
                    handleCancelButton={() => setModal(false)}
                    onClose={() => setModal(false)}
                />
            )}
        </>
    );
};

export default React.memo(HeaderProfileCard);
