import styled from 'styled-components';
import sassVars from './variables.module.scss';
import { props } from '../components/charts/packedBubble/PackedBubble.test';
const { fileUploadErrorColor } = sassVars;

const DEFAULT_SETTINGS = {
    GRID: {
        column: 2,
        columnGap: 10,
        paddingTop: 0,
        marginBottom: 10
    }
};

const columnProps = (column: number | string, columnGap) => {
    if (column === '2:1') {
        return `calc(66% - ${columnGap}px) 34%`;
    } else if (typeof column === 'string') {
        return column;
    }
    return `repeat(${column}, calc(${100 / Number(column)}% - ${columnGap / column}px))`;
};

const StyledCIM = {
    Container: styled.div<{
        paddingTop?: number;
        marginBottom?: number;
    }>`
        margin-bottom: ${(props) => props.marginBottom ?? 15}px;
        padding-top: ${(props) => props.paddingTop ?? 0}px;
    `,
    ChartGridContainer: styled.div<{
        paddingTop?: number;
        marginBottom?: number;
    }>`
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        margin-bottom: ${(props) => props.marginBottom ?? 15}px;
        padding-top: ${(props) => props.paddingTop ?? 0}px;
    `,
    Grid: styled.div<{
        column?: number | string; // number of columns | 'auto' | '2fr 1fr'
        columnGap?: number;
        padding?: string;
        marginBottom?: number;
    }>`
        display: grid;
        gap: ${(props) => props.columnGap ?? DEFAULT_SETTINGS.GRID.columnGap}px;
        grid-template-columns: ${(props) =>
            columnProps(
                props.column ?? DEFAULT_SETTINGS.GRID.column,
                props.columnGap ?? DEFAULT_SETTINGS.GRID.columnGap
            )};

       ${(props) => props.padding && `padding: ${props.padding};`}
     
        margin-bottom: ${(props) => props.marginBottom ?? DEFAULT_SETTINGS.GRID.marginBottom}px;
        max-width: 100%;
    `,
    ErrorMessage: styled.div`
        color: ${fileUploadErrorColor};
    `
};

export default StyledCIM;
