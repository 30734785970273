import React, { useRef, useCallback, useEffect, useState, Suspense } from 'react';
import styled from 'styled-components';
import {
    withRouter,
    generatePath,
    useHistory,
    matchPath,
    match,
    RouteComponentProps,
    Switch,
    Route,
    Redirect,
    useLocation
} from 'react-router-dom';
import { sanitizeQueryString } from '../../utils/uriAnalyzer';
import { IGenericObject } from '../../models/IGenericObject';
import { IRouteManagerContext, RouteManagerContext } from './Context';
import { removeHashFromURL } from '../../common/commonUtil';
import PageLayout from '../pageLayout/PageLayout';
import Footer from '../footer/Footer';
import LoadIndicator from '../loadIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfiguration } from '../../services/common.service';
import {
    createFilterRegions,
    createFilterSectors,
    createScenariosFilter,
    setCurrentTab
} from '../../store/actions/FilterActions';
import { appRoutes } from '../../app.labels';
import ErrorPage from '../layout/pages/errorPages';
import { setClimateScenariosData } from '../../store/actions/RichTextEditorActions';
import LandingPage from '../layout/pages/landingPage';
import { IStore, store } from '../../store';
import { getUserRole } from '../../services/userProfile.service';
import { updateUserRole } from '../../store/actions/userProfileActions';
import { setCountryListByRegion } from '../../store/actions/CommonAction';
import Policies from '../layout/pages/landingPage/policyLandingPage/Policies';
import ChartDownloadsLandingPage from '../layout/pages/chartDownloads';

const TheWorldTodayLandingPage = React.lazy(() => import('../layout/pages/theworldtoday'));
const EmissionsLandingPage = React.lazy(() =>
    import('../layout/pages/emissions/EmissionsLandingPage')
);
const KeyindicatorsLandingPage = React.lazy(() => import('../layout/pages/keyindicators'));
const SectoralImpactsLandingPage = React.lazy(() =>
    import('../layout/pages/sectorImpactDetails/SectoralImpactsLandingPage')
);
const ModellingInputsLandingPage = React.lazy(() => import('../layout/pages/modellinginputs'));
const OverviewLandingPage = React.lazy(() => import('../layout/pages/overview'));

interface IOwnProps extends RouteComponentProps<IGenericObject<string>> {}

const RouteManager = ({ ...routeProps }: IOwnProps) => {
    const history = useHistory();
    const tenantId = useSelector((store: IStore) => store?.userProfile?.userTenant?.tenantId);
    const tenantName = useSelector((store: IStore) => store?.userProfile?.userTenant?.tenantName);
    const showPolicyModal = useSelector((store: IStore) => store?.userProfile?.showPolicyModal);
    const location = useLocation();
    const errorCondition = location?.pathname.includes(`/${appRoutes.error}`);
    const errorPageCondition = location?.pathname.includes(`/${appRoutes.errorPage}`);
    const unauthorizedCondition = location?.pathname.includes(`/${appRoutes.unauthorized}`);

    const computePath = useCallback(
        (
            paramsForReplace: {
                [key: string]: string | boolean | number | undefined;
            },
            queryString: string = removeHashFromURL()
        ): string => {
            const maximalPath = '/:main?/:subpage?/:expanded?';
            let routeParams = {};
            if (window.location.pathname !== '/') {
                const currentPathParams = matchPath(window.location.pathname, {
                    path: maximalPath,
                    exact: true,
                    strict: false
                }) as match;
                routeParams = {
                    ...currentPathParams.params
                };
            }
            routeParams = {
                ...routeParams,
                ...paramsForReplace
            } as {
                [key: string]: string | boolean | number | undefined;
            };
            const nextPath = generatePath(maximalPath, routeParams);
            let path = `${nextPath}${sanitizeQueryString(queryString)}`;
            if (!path.startsWith('/')) {
                path = `/${path}`;
            }
            return path.replaceAll('&', '%26');
        },
        []
    );

    const routeTo = useCallback(
        (
            paramsForReplace: {
                [key: string]: string | boolean | number | undefined;
            },
            queryString = removeHashFromURL()
        ): void => {
            const path = computePath(paramsForReplace, queryString ?? '');
            history.push(path);
        },
        [computePath, history]
    );

    const context = useRef<IRouteManagerContext>({
        routeTo
    });

    const [isLoding, setIsLoading] = useState<boolean>(true);

    const dispatch = useDispatch();
    const getConfiguration = useCallback(async () => {
        if (!errorCondition && !unauthorizedCondition && !errorPageCondition) {
            const configData = await fetchConfiguration();
            // Creating initial filters
            dispatch(createScenariosFilter(configData?.scenarios));
            dispatch(createFilterRegions(configData?.gemRegions));
            dispatch(createFilterSectors(configData?.gemSectors));
            // Set Current Tab
            dispatch(setCurrentTab(appRoutes.overview));
            // Set climateScenariosData
            dispatch(setClimateScenariosData(configData?.climateScenariosData));
            dispatch(setCountryListByRegion(configData?.countryByRegion ?? []));
        }
        setIsLoading(false);
    }, []);

    const getUserRoles = useCallback(async () => {
        if (!errorCondition && !unauthorizedCondition && !errorPageCondition) {
            const userRole = await getUserRole();
            let roles: Array<string> = [];

            if (userRole && userRole.length > 0 && userRole[0]?.roles) {
                roles = userRole[0]?.roles;
            }
            dispatch(updateUserRole(roles));
        }
    }, []);

    useEffect(() => {
        if (tenantId || errorCondition || unauthorizedCondition || errorPageCondition) {
            getConfiguration();
            getUserRoles();
        }
    }, [tenantId]);

    // To show the policy modal if user has not accepted the policy
    if (showPolicyModal) {
        return (
            <RouteManagerContext.Provider value={context.current}>
                <Policies />
            </RouteManagerContext.Provider>
        );
    }

    // To show the tenant selection page if user has not selected the tenant
    if (!tenantId && !unauthorizedCondition && !errorPageCondition) {
        return (
            <RouteManagerContext.Provider value={context.current}>
                <LandingPage />
            </RouteManagerContext.Provider>
        );
    }

    const routeCodes = {
        HOME: '/',
        TENANT_HOME: `/${tenantName}`,
        OVERVIEW: `/${tenantName}/${appRoutes.overview}`,
        DOWNLOAD: `/${tenantName}/${appRoutes.download}`,
        THE_WORLD_TODAY: `/${tenantName}/${appRoutes.theworldtoday}`,
        MODELLING_INPUT: `/${tenantName}/${appRoutes.modellinginputs}`,
        EMISSION: `/${tenantName}/${appRoutes.emissions}`,
        KEY_INDICATORS: `/${tenantName}/${appRoutes.keyindicators}`,
        SECTORAL_IMPACTS: `/${tenantName}/${appRoutes.sectoralimpacts}`,
        ERROR: `/${tenantName}/${appRoutes.error}`
    };

    return (
        <RouteManagerContext.Provider value={context.current}>
            {isLoding ? (
                <StyledContent>
                    <div className="center-wrapper">
                        <LoadIndicator />
                    </div>
                </StyledContent>
            ) : (
                <>
                    <Switch>
                        <Route path={`/${appRoutes.unauthorized}`}>
                            <ErrorPage isUnauthorized={true} />
                        </Route>
                        <Route path={`/${appRoutes.unauthorizedaccess}`}>
                            <ErrorPage isUnauthorized={true} isTenantError={true} />
                        </Route>
                        <Route path={`/${appRoutes.errorPage}`}>
                            <ErrorPage isBlockedError={true} />
                        </Route>
                        <Route path={routeCodes.ERROR}>
                            <ErrorPage />
                        </Route>
                        <PageLayout>
                            <Route
                                path={routeCodes.TENANT_HOME}
                                exact
                                render={() => <Redirect to={routeCodes.OVERVIEW} />}></Route>
                            <Route path={routeCodes.OVERVIEW}>
                                <Suspense fallback={<div></div>}>
                                    <OverviewLandingPage />
                                </Suspense>
                            </Route>
                            <Route path={routeCodes.THE_WORLD_TODAY}>
                                <Suspense fallback={<div></div>}>
                                    <TheWorldTodayLandingPage />
                                </Suspense>
                            </Route>
                            <Route path={routeCodes.MODELLING_INPUT}>
                                <Suspense fallback={<div></div>}>
                                    <ModellingInputsLandingPage />
                                </Suspense>
                            </Route>
                            <Route path={routeCodes.EMISSION}>
                                <Suspense fallback={<div></div>}>
                                    <EmissionsLandingPage />
                                </Suspense>
                            </Route>

                            <Route path={routeCodes.KEY_INDICATORS}>
                                <Suspense fallback={<div></div>}>
                                    <KeyindicatorsLandingPage />
                                </Suspense>
                            </Route>
                            <Route path={routeCodes.SECTORAL_IMPACTS}>
                                <Suspense fallback={<div></div>}>
                                    <SectoralImpactsLandingPage />
                                </Suspense>
                            </Route>
                            <Route path={routeCodes.DOWNLOAD}>
                                <Suspense fallback={<div></div>}>
                                    <ChartDownloadsLandingPage />
                                </Suspense>
                            </Route>
                        </PageLayout>
                    </Switch>
                    {!errorCondition && !unauthorizedCondition && !errorPageCondition && <Footer />}
                </>
            )}
        </RouteManagerContext.Provider>
    );
};

// The min-height is used to push the footer bellow on pages with less content
const StyledContent = styled.div`
    .center-wrapper {
        position: absolute;
        text-align: center;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-transform: uppercase;
    }
`;

export default withRouter(RouteManager);
