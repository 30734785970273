export const chartLibraryData = [
    {
        name: 'Emissions',
        ChartType: 'Emissions'
    },
    {
        name: 'Emissions ', // added extra space to differenciate from 'Emissions' above
        description:
            'This is the total amount of greenhouse gas emissions for each country or region modelled. This includes emissions from households, industry sectors and government. Greenhouse gas emissions are modelled in million tonnes of carbon dioxide equivalent and include all Kyoto gas emissions (not just CO2). The emissions pathways in EYGEM represent Market Emissions, (i.e., the amount every country is allowed to emit), but do not show net emissions for each region.',
        type: 'Emissions',
        value: {
            name: 'Emissions',
            parent: 'Emissions',
            identifier: 'ES_Emissions'
        }
    },
    {
        name: 'Emissions intensity',
        description: 'Emissions intensity is the level of greenhouse gas emissions (t CO2 eq) required to produce a billion dollars of GDP. This calculation only considers scope 1 emissions.',
        type: 'Emissions',
        value: {
            name: 'Emissions intensity',
            parent: 'Emissions',
            identifier: 'ES_Emissions_Intensity'
        }
    },
    {
        name: 'Emissions per capita',
        description: 'Emissions per capita',
        type: 'Emissions',
        value: {
            name: 'Emissions per capita',
            parent: 'Emissions',
            identifier: 'ES_Emissions_per_Capita'
        }
    },
    {
        name: 'Emissions by sector',
        description: 'Emissions by sector is the total emissions for each industry sector. Emissions are modelled in million tonnes of carbon dioxide equivalent and include all Kyoto gas emissions (not just CO2).',
        type: 'Emissions',
        value: {
            name: 'Emissions by sector',
            parent: 'Emissions',
            identifier: 'ES_Emissions_by_Sector'
        }
    },
    {
        name: 'Emissions by country',
        description: 'This is the total amount of greenhouse gas emissions for each country or region modelled. This includes emissions from households, industry sectors and government. Greenhouse gas emissions are modelled in million tonnes of carbon dioxide equivalent and include all Kyoto gas emissions (not just CO2). The emissions pathways in EYGEM represent Market Emissions, (i.e., the amount every country is allowed to emit), but do not show net emissions for each region.',
        type: 'Emissions',
        value: {
            name: 'Emissions by country',
            parent: 'Emissions',
            identifier: 'ES_Emissions_by_Country'
        }
    },
    {
        name: 'Emissions intensity by sector',
        description: 'Emissions intensity is the level of greenhouse gas emissions (t CO2 eq) required to produce a billion dollars of output for each industry sector. This calculation only considers scope 1 emissions.',
        type: 'Emissions',
        value: {
            name: 'Emissions intensity by sector',
            parent: 'Emissions',
            identifier: 'ES_Emissions_Intensity_by_Sector'
        }
    },
    {
        ChartType: 'Key Indicators',
        name: 'Key Indicators'
    },
    {
        name: 'Carbon prices',
        description: 'A carbon price is a tax applied to each ton of carbon dioxide equivalent emitted. Carbon prices act as the main climate policy mechanism in the model and represent the cost of all policy action (tariffs, subsidies and taxes). Under each scenario, the model determines the carbon price that best fits the individual region’s economy and emissions pathway. The carbon price listed is for one tonne of CO2 equivalent and applies to all greenhouse gases and sectors equally within a region (unless otherwise specified). The global carbon price is an emissions-weighted average of all regions in the model.',
        type: 'Key Indicators',
        value: {
            name: 'Carbon prices',
            parent: 'Key Indicators',
            identifier: 'KI_Carbon_Prices'
        }
    },
    {
        name: 'GDP',
        description: 'Gross Domestic Product (GDP) is the market value of all the final goods and services produced in a specific time period.',
        type: 'Key Indicators',
        value: {
            name: 'GDP',
            parent: 'Key Indicators',
            identifier: 'KI_GDP'
        }
    },
    {
        name: 'GDP deviation',
        description: 'Gross Domestic Product (GDP) is the market value of all the final goods and services produced in a specific time period. GDP deviation is the percentage difference in GDP under a given policy scenario from the baseline at each point in time.',
        type: 'Key Indicators',
        value: {
            name: 'GDP deviation',
            parent: 'Key Indicators',
            identifier: 'KI_GDP_Deviation'
        }
    },
    {
        name: 'GNI',
        description: 'Gross National Income (GNI), sometimes referred to as Gross National Product (GNP) is the total domestic and foreign output claimed by residents of a country. GNI is equal to GDP, plus factor income earned by foreign residents, minus income earned in the domestic economy by non-residents. ',
        type: 'Key Indicators',
        value: {
            name: 'GNI',
            parent: 'Key Indicators',
            identifier: 'KI_GNI'
        }
    },
    {
        name: 'GNI deviation',
        description: 'Gross National Income (GNI), sometimes referred to as Gross National Product (GNP) is the total domestic and foreign output claimed by residents of a country. GNI is equal to GDP, plus factor income earned by foreign residents, minus income earned in the domestic economy by non-residents. GNI deviation is the percentage difference in GNI under a given policy scenario from the baseline at each point in time.',
        type: 'Key Indicators',
        value: {
            name: 'GNI deviation',
            parent: 'Key Indicators',
            identifier: 'KI_GNI_Deviation'
        }
    },
    {
        name: 'Fossil fuel as % of total fuel',
        description: 'Fossil fuel as a percentage of total fuel, based on the fuel types in the model.',
        type: 'Key Indicators',
        value: {
            name: 'Fossil fuel as % of total fuel',
            parent: 'Key Indicators',
            identifier: 'KI_Fossil_Fuel_as_a_%_of_Total_Fuel'
        }
    },
    {
        name: 'GDP per capita ',
        description: 'GDP per capita',
        type: 'Key Indicators',
        value: {
            name: 'GDP per capita',
            parent: 'Key Indicators',
            identifier: 'KI_GDP_per_Capita'
        }
    },
    {
        name: 'GNI per capita ',
        description: 'GNI per capita',
        type: 'Key Indicators',
        value: {
            name: 'GNI per capita',
            parent: 'Key Indicators',
            identifier: 'KI_GNI_per_Capita'
        }
    },
    {
        name: 'Total value of carbon tax / GDP',
        description: 'The total value of carbon tax is the carbon price multiplied by the emissions for each region at each point in time.',
        type: 'Key Indicators',
        value: {
            name: 'Total value of carbon tax / GDP',
            parent: 'Key Indicators',
            identifier: 'KI_Total_value_of_Carbon_Tax_/GDP'
        }
    },
    {
        name: 'Wages',
        description: 'Wages are defined as a national average wage across all industry sectors. ',
        type: 'Key Indicators',
        value: {
            name: 'Wages',
            parent: 'Key Indicators',
            identifier: 'KI_Wages_(Index)'
        }
    },
    {
        name: 'Employment (People)',
        description: 'Employment (People)',
        type: 'Key Indicators',
        value: {
            name: 'Employment (People)',
            parent: 'Key Indicators',
            identifier: 'KI_Employment_(People)'
        }
    },
    {
        name: 'Unemployment rate',
        description: 'The unemployment rate is the additional change in unemployment as a result of the policy scenario.',
        type: 'Key Indicators',
        value: {
            name: 'Unemployment rate',
            parent: 'Key Indicators',
            identifier: 'KI_Unemployment_Rate'
        }
    },
    {
        name: 'Investment',
        description: 'Investment',
        type: 'Key Indicators',
        value: {
            name: 'Investment',
            parent: 'Key Indicators',
            identifier: 'KI_Investment'
        }
    },
    {
        name: 'Return on capital',
        description: 'Return on capital',
        type: 'Key Indicators',
        value: {
            name: 'Return on capital',
            parent: 'Key Indicators',
            identifier: 'KI_Return_on_Capital'
        }
    },
    {
        name: 'Exchange rates',
        description: 'Change in the real effective exchange rate for each region. The real effective exchange rate is calculated for each regional currency relative to Special Drawing Rights (SDRs), which are composed of a basket of currencies.',
        type: 'Key Indicators',
        value: {
            name: 'Exchange rates',
            parent: 'Key Indicators',
            identifier: 'KI_Exchange_Rates'
        }
    },

    {
        ChartType: 'Sectoral Impacts',
        name: 'Sectoral Impacts'
    },
    {
        name: 'Output',
        description: 'Output is the total value of goods and services produced by each industry sector in a given time period. Output is made up of GVA, plus any intermediate inputs.',
        type: 'Sectoral Impacts',
        value: {
            name: 'Output',
            parent: 'Sectoral Impacts',
            identifier: 'SI_Output'
        }
    },
    {
        name: 'Output deviation',
        description: 'Output is the total value of goods and services produced by each industry sector in a given time period. Output is made up of GVA, plus any intermediate inputs. Output deviation is the percentage difference in output under a given policy scenario from the baseline at each point in time. ',
        type: 'Sectoral Impacts',
        value: {
            name: 'Output deviation',
            parent: 'Sectoral Impacts',
            identifier: 'SI_Output_Deviation'
        }
    },
    {
        name: 'GVA',
        description: 'Gross Value Added (GVA) is the additional value of goods and services produced by each industry sector in a given time period. GVA is equal to output, minus any intermediate inputs. ',
        type: 'Sectoral Impacts',
        value: {
            name: 'GVA',
            parent: 'Sectoral Impacts',
            identifier: 'SI_GVA'
        }
    },
    {
        name: 'GVA deviation',
        description: 'Gross Value Added (GVA) is the additional value of goods and services produced by each industry sector in a given time period. GVA is equal to output, minus any intermediate inputs. GVA deviation is the percentage difference in GVA under a given policy scenario from the baseline at each point in time.',
        type: 'Sectoral Impacts',
        value: {
            name: 'GVA deviation',
            parent: 'Sectoral Impacts',
            identifier: 'SI_GVA_Deviation'
        }
    },
    {
        name: 'Prices',
        description: 'Prices',
        type: 'Sectoral Impacts',
        value: {
            name: 'Prices',
            parent: 'Sectoral Impacts',
            identifier: 'SI_Prices'
        }
    },
    {
        name: 'Prices deviation',
        description: 'Prices deviation is the percentage difference in price under a given policy scenario from the baseline at each point in time.',
        type: 'Sectoral Impacts',
        value: {
            name: 'Prices deviation',
            parent: 'Sectoral Impacts',
            identifier: 'SI_Prices_Deviation'
        }
    },
    {
        name: 'Share of employment',
        description: 'Share of employment is each industries share of total employment.',
        type: 'Sectoral Impacts',
        value: {
            name: 'Share of employment',
            parent: 'Sectoral Impacts',
            identifier: 'SI_Share_of_Employment'
        }
    },
    {
        name: 'Share of employment deviation',
        description: 'Share of employment deviation is the percentage difference in employment share under a given policy scenario from the baseline at each point in time.',
        type: 'Sectoral Impacts',
        value: {
            name: 'Share of employment deviation',
            parent: 'Sectoral Impacts',
            identifier: 'SI_Share_of_Employment_Deviation'
        }
    }
];
