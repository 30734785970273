import styled from 'styled-components';
import sassVars from '../../../../../../styles/variables.module.scss';
const { darkblack, gray, white, yellow } = sassVars;

export const StyleProgressSteps = styled.div`
    .progressBar {
        li {
            list-style-type: none;
            float: left;
            width: 50%;
            position: relative;
            text-align: center;
            color: ${gray};
        }

        .icons {
            content: ' ';
            line-height: 30px;
            border-radius: 50%;
            width: 37px;
            height: 37px;
            border: 1px solid ${gray};
            display: block;
            text-align: center;
            margin: 0 auto 10px;
            background-color: ${gray};
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: ${darkblack};
            svg {
                fill: ${darkblack};
                position: relative;
                top: 3px;
                left: -1px;
            }
        }

        li:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: ${gray};
            top: 19px;
            left: -50%;
            z-index: -1;
        }

        li:first-child:after {
            content: none;
        }

        li.active {
            color: ${white};
        }

        li.active .icons {
            border-color: ${yellow};
            background-color: ${yellow};
        }

        .active:after {
            background-color: ${yellow};
        }

        .single-active {
            width: 100% !important;
            .icons {
                svg {
                    left: 0px !important;
                }
            }
        }
    }
`;
