import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MotifButton, MotifToast } from '@ey-xd/motif-react';
import { IStore } from '../../store';
import { fetchPageData, savePageDataToDB } from '../../store/actions/RichTextEditorActions';
import ReadMore from './ReadMore';
import RichTextEditor from '../rte';
import Icon from '../icon';
import LoadIndicator from '../loadIndicator';
import ErrorBoundary from '../../ErrorBoundary';
import {
    contentIcCreate24px,
    navigationIcClose24px,
    contentIcSave24px
} from '@ey-xd/motif-react/assets/icons';
import { EDIT_TEXT_AREA, GLOBAL_ERROR_BOUNDARY_MESSAGE } from '../../app.labels';
import { RICH_TEXT_EDITOR } from '../../app.constant';
import { UserRoles } from '../../common/enum/userProfile';
import { characterCountFromHTML } from '../../common/htmlUtil';

import StyledEditableArea from './EditableTextArea.style';

export enum TabNavPageNames {
    overview = 'Overview',
    theWorldToday = 'TheWorldToday',
    modellingInputs = 'ModellingInputs',
    emissions = 'Emissions',
    keyIndicators = 'KeyIndicators',
    sectoralImpacts = 'SectoralImpacts'
}

interface IEditableTextAreaProps {
    pageName: TabNavPageNames;
}

const EditableTextArea: FC<IEditableTextAreaProps> = ({ pageName }) => {
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [disableEdit, setDisableEdit] = useState<boolean>(true);
    const [content, setContent] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [validationToast, setValidationToast] = useState<boolean>(false);

    const dataLoading = useSelector((state: IStore) => state?.richTextEditor?.pageData?.loading);
    const dataError = useSelector((state: IStore) => state?.richTextEditor?.pageData?.error);
    const data = useSelector((state: IStore) => state?.richTextEditor?.pageData[pageName]) || '';
    const userRoles = useSelector((state: IStore) => state?.userProfile?.userTenant.roles) || [];

    const hasErrorContent = !!dataError;
    const characterCount = characterCountFromHTML(content);

    // Inital fetching from API
    useEffect(() => {
        if (data.length === 0) {
            dispatch(fetchPageData(pageName));
        }
        setDisableEdit(!userRoles.includes(UserRoles.engagementOwner));
    }, []);

    // Update Content when API call is successful
    useEffect(() => {
        setContent(data);
    }, [data]);

    // Update error message when API call fails
    useEffect(() => {
        if (hasErrorContent) {
            setError(dataError);
        }
    }, [dataError]);

    const saveData = async () => {
        const payload = {
            pageName: pageName,
            content: content
        };
        dispatch(savePageDataToDB(payload));
    };

    const handleEdit = () => {
        setIsEditable(true);
    };

    const handleDone = async () => {
        if (characterCount === 0) {
            setValidationToast(true);
        } else {
            setValidationToast(false);
            await saveData();

            if (!hasErrorContent) {
                setIsEditable(false);
            }
        }
    };

    const handleCancel = () => {
        setIsEditable(false);
        setContent(data);
    };

    const handleChange = (text) => {
        setContent(text);
    };

    const ValidationToaster = () => {
        return (
            <MotifToast
                variant={'warning'}
                onClose={() => setValidationToast(false)}
                style={{ marginBottom: '10px' }}>
                <p>{EDIT_TEXT_AREA.save_warning_message}</p>
            </MotifToast>
        );
    };

    if (dataLoading) {
        return (
            <StyledEditableArea.Loader
                className="loadIndicator"
                data-testid={`editable-TextArea--loader`}>
                <LoadIndicator></LoadIndicator>
            </StyledEditableArea.Loader>
        );
    }
    return (
        <ErrorBoundary>
            <StyledEditableArea.Container>
                {error && (
                    <StyledEditableArea.Error>
                        <MotifToast variant="error" onClose={() => setError(null)}>
                            <h3>{GLOBAL_ERROR_BOUNDARY_MESSAGE.title}</h3>
                            <p>{GLOBAL_ERROR_BOUNDARY_MESSAGE.description}</p>
                        </MotifToast>
                    </StyledEditableArea.Error>
                )}
                {validationToast && <ValidationToaster />}
                {isEditable && (
                    <StyledEditableArea.Grid data-testid={`editable-TextArea--editor`}>
                        <RichTextEditor
                            value={data}
                            onChange={handleChange}
                            maxCharacterLimit={RICH_TEXT_EDITOR.EDITOR_MAX_CHARACTER_LIMIT}
                        />
                        <StyledEditableArea.ActionBar>
                            <MotifButton variant="primary" onClick={handleDone}>
                                <Icon
                                    src={contentIcSave24px}
                                    testId={`editable-TextArea--save-button`}
                                />
                                {EDIT_TEXT_AREA.save}
                            </MotifButton>

                            <MotifButton variant="secondary" onClick={handleCancel}>
                                <Icon
                                    src={navigationIcClose24px}
                                    testId={`editable-TextArea--cancel-button`}
                                />
                                {EDIT_TEXT_AREA.cancel}
                            </MotifButton>
                        </StyledEditableArea.ActionBar>
                    </StyledEditableArea.Grid>
                )}

                {!isEditable && !error && disableEdit && (
                    <div>{data && data?.length > 0 && <ReadMore text={data} />}</div>
                )}

                {!isEditable && !error && !disableEdit && (
                    <StyledEditableArea.Grid data-testid={`editable-TextArea--readmore`}>
                        <div>{data && data?.length > 0 && <ReadMore text={data} />}</div>
                        <StyledEditableArea.ActionBar>
                            <MotifButton variant="secondary" onClick={handleEdit}>
                                <Icon
                                    src={contentIcCreate24px}
                                    testId={`editable-TextArea--edit-button`}
                                />
                                {EDIT_TEXT_AREA.edit}
                            </MotifButton>
                        </StyledEditableArea.ActionBar>
                    </StyledEditableArea.Grid>
                )}
            </StyledEditableArea.Container>
        </ErrorBoundary>
    );
};

export default EditableTextArea;
