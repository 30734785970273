export enum ChartUnit {
    billion = 'Billion',
    fuel_percentage = 'Fuel %',
    gdp = 'GDP $',
    gni_dollar = 'GNI $',
    gva_dollar = 'GVA $',
    index = 'Index',
    intermediate_inputs_million = 'Intermediate Inputs ($)',
    million = 'Million',
    million_dollar = 'Million $',
    dollor_million = '$ Millions',
    mt = 'Mt',
    mtCo2eq = 'Mt CO2 eq',
    output_dollar = 'Output $',
    percentage = '%',
    regions = 'Regions',
    regions_ranking_tCo2eq_Mgdp = 'Regions as per ranking (t CO2 eq / m GDP)',
    tCo2eq_million_gdp = 't CO2 eq / m GDP',
    tCo2eq_pop = 't CO2 eq / Pop',
    tCo2eq_gdp = 't CO2 eq / GDP',
    tCo2eq_dollar = '$ / t CO2 eq',
    unemployment_index = 'Unemployment rate index',
    wages_index = 'Wages index',
    return_on_capital_index = 'Return on capital index',
    exchange_rate_index = 'Exchange rate index',
    emissions_percentage_mtCo2eq = 'Percentage of Total Emissions (Mt CO2 eq)',
    m = 'M',
    gdp_pop = 'GDP / Pop',
    dollarSymbol = '$',
    tCo2eq_Mgdp = 't CO2 eq / m GDP',
    people = 'People',
    tCo2eq_million_output = 't CO2 eq / m Output',
    start_year_100 = '(Start year = 100)'
}
