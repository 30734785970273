import React, { useEffect, useMemo, useState } from 'react';
import { StyledDiv, StyledNoResultFound } from './ChartLibraryTable.style';
import SearchBar from '../../../../../SearchBar';
import StyledCIM from '../../../../../../styles';
import { MotifInlineMessage, MotifTable } from '@ey-xd/motif-react';
import { chartLibraryColumnDefs } from './chartLibraryColumnDef';
import { chartLibraryData } from '../../data';
import { DOWNLOAD_CHART } from '../../../../../../app.labels';
import { IStore } from '../../../../../../store';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../../../../common/enum/userProfile';
import LoadIndicator from '../../../../../loadIndicator';

interface IChartLibraryTableProps {
    headerName: string;
    field: string;
    cellRendererFramework: (params: any) => JSX.Element | null;
    flex: number;
    suppressMovable: boolean;
    cellStyle?: undefined;
    width?: undefined;
}

export const ChartLibraryTable = () => {
    const [filterText, setFilterText] = useState('');
    const [tableColumnDefs, setTableColumnDefs] = useState<IChartLibraryTableProps[]>([]);

    const userRoles = useSelector((state: IStore) => state?.userProfile?.userTenant.roles);

    useEffect(() => {
        /* First we will find index for action element from  chartLibraryColumnDefs*/
        let newColumnDefs = [...chartLibraryColumnDefs];
        if (userRoles && userRoles.includes(UserRoles.generalUser)) {
            const actionIndex = chartLibraryColumnDefs.findIndex(
                (column) => column.headerName === 'Action'
            );
            if (actionIndex > -1) {
                newColumnDefs.splice(actionIndex, 1);
                setTableColumnDefs(newColumnDefs as IChartLibraryTableProps[]);
            }
        } else {
            setTableColumnDefs(newColumnDefs as IChartLibraryTableProps[]);
        }
    }, [userRoles]);

    const filteredData = chartLibraryData
        .filter(
            (row) =>
                row?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
                row?.description?.toLowerCase().includes(filterText.toLowerCase()) ||
                row.ChartType
        )
        .map((row, index, array) => {
            if (row.ChartType) {
                let f = row.ChartType !== array[index + 1]?.type;
                if (f && array[index + 1]?.type === undefined) {
                    return null;
                }
            }
            return row;
        })
        .filter((row) => row !== null);

    const getRowHeight = (params) => {
        if (params.data.description) {
            return 50 + Math.floor(params.data.description.length / 100) * 20;
        }
        return 50;
    };

    const defaultColDef = {
        cellClass: 'cell-wrap-text'
    };
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    if (tableColumnDefs.length === 0) {
        return <LoadIndicator />;
    }

    return (
        <StyledDiv>
            <StyledCIM.Grid column={3} padding="5px 15px 3px" marginBottom={0}>
                <SearchBar
                    data-test-id="filterTree-searchBar"
                    className="filter-tree__search"
                    errorClassName="filter-tree__search-error"
                    value={filterText}
                    errorMessage={''}
                    onChange={(e) => setFilterText(e.target.value)}
                    onEnter={(e) => {}}
                    onSearchButtonClick={() => {}}
                />
            </StyledCIM.Grid>
            <div style={gridStyle} className="ag-theme-alpine">
                {filteredData.length > 0 ? (
                    <MotifTable
                        defaultColDef={defaultColDef}
                        columnDefs={tableColumnDefs}
                        getRowHeight={getRowHeight}
                        rowData={filteredData as Array<object>}
                        children={null}
                    />
                ) : (
                    <StyledNoResultFound>
                        <MotifInlineMessage variant="warning">
                            {DOWNLOAD_CHART.NO_RESULT_FOUND}
                        </MotifInlineMessage>
                    </StyledNoResultFound>
                )}
            </div>
        </StyledDiv>
    );
};

export default ChartLibraryTable;
