import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from './store';
import RouterManager from './components/routerManager';
import { fetchUserProfile } from './store/actions/userProfileActions';

interface IApp {
    logout: () => void;
}

const App: React.FC<IApp> = ({ logout }) => {
    const dispatch = useDispatch();
    const logoutState = useSelector((state: IStore) => state.userProfile?.logout);

    useEffect(() => {
        dispatch(fetchUserProfile());
    }, []);

    useEffect(() => {
        if (logoutState) {
            logout();
        }
    }, [logoutState]);

    return (
        <div className="App">
            <BrowserRouter>
                <RouterManager />
            </BrowserRouter>
        </div>
    );
};

export default App;
