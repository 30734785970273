import { ErrorMessages } from '../../common/enum/error';
import { sanitizeHtml } from '../../common/htmlUtil';
import { apiRoutes } from '../../services/api';
import { getDataFromApi } from '../../services/common.service';
import { IGetPageDetails, ISavePageDetails } from './IEditableTextArea';

const getPageDetails: IGetPageDetails = async (pageName) => {
    const reqBody = { PageName: pageName };
    const url = apiRoutes.cmsGetPageDetails;
    const cacheKey = JSON.stringify({ url, reqBody });
    const response = await getDataFromApi(url, cacheKey);

    if (response.errorMessage) {
        if (response?.errorMessage === ErrorMessages.Error404) {
            return {
                pageName,
                data: '',
                error: null
            };
        }
        return {
            pageName,
            data: '',
            error: ErrorMessages.Error50x
        };
    }

    return {
        pageName,
        data: sanitizeHtml(response.panelResponse[0].description ?? ''),
        error: null
    };
};

const savePageDetails: ISavePageDetails = async ({ pageName, content }) => {
    const reqBody = {
        PageName: pageName,
        panelDetails: [
            {
                orderid: '1',
                title: pageName,
                description: sanitizeHtml(content),
                level: 1,
                isActive: true
            }
        ]
    };
    const url = apiRoutes.cmsSetPageDetails;
    const cacheKey = JSON.stringify({ url, reqBody });
    const response = await getDataFromApi(url, cacheKey);

    if (response.errorMessage) {
        return {
            pageName,
            data: null,
            error: 'Error saving page details'
        };
    }
    return {
        pageName,
        data: content,
        error: null
    };
};

export { getPageDetails, savePageDetails };
