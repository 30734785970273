import Quill from 'quill';
const fontSizes = ['14px', '16px', '24px'];
const sizes = Quill.import('attributors/style/size');
sizes.whitelist = fontSizes;
Quill.register(sizes, true);

const richTextEditorSpec = {
    modules: {
        toolbar: [
            [{ header: [] }],
            [{ size: fontSizes }],
            [
                'bold',
                'italic',
                'underline',
                'link',
                { color: [] },
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
            ],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        }
    },
    formats: [
        'header',
        'size',
        'bold',
        'italic',
        'underline',
        'link',
        'color',
        'list',
        'bullet',
        'indent',
        'align'
    ],
    theme: 'snow'
};

export default richTextEditorSpec;
