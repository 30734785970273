import styled from 'styled-components';

const StyledEditableArea = {
    Container: styled.div`
        padding-bottom: 20px;
        padding-top: 20px;
    `,
    Grid: styled.div`
        display: grid;
        align-items: flex-start;
        gap: 10px;
        grid-template-columns: calc(100% - 110px) 100px;
    `,
    ActionBar: styled.div`
        --btn-secondary-border-color: var(--branding);
        --btn-secondary-color: var(--branding);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    `,
    Loader: styled.div`
        height: 200px;
    `,
    Error: styled.div`
        margin-bottom: 10px;
    `
};

export default StyledEditableArea;
