import React from 'react';
import {
    MotifButton,
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader
} from '@ey-xd/motif-react';
import { MODAL } from '../../app.labels';

export enum ModalSize {
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    EXTRA_LARGE = 'xl'
}

interface IOwnProps {
    isModalVisible: boolean;
    modalBody: JSX.Element;
    size?: ModalSize;
    variant?: 'alt' | 'default';
    modalHeader?: string | null;
    modalFooter?: JSX.Element | null;
    actionButtonText?: string;
    handleActionButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
    onClose?: () => void;
}

const ModalComponent: React.FC<IOwnProps> = ({
    isModalVisible,
    size = ModalSize.MEDIUM,
    variant = 'alt',
    modalBody,
    modalHeader = null,
    modalFooter = null,
    actionButtonText = MODAL.DEFAULT_ACTION_BUTTON_TEXT,
    handleActionButton = () => {},
    cancelButtonText = MODAL.DEFAULT_CANCEL_BUTTON_TEXT,
    handleCancelButton = () => {},
    onClose
}) => {
    const GetModalFooter: React.FC = () => {
        if (modalFooter) {
            return modalFooter;
        }
        return (
            <>
                <MotifButton type="button" onClick={handleActionButton}>
                    {actionButtonText}
                </MotifButton>
                <MotifButton variant="secondary" type="button" onClick={handleCancelButton}>
                    {cancelButtonText}
                </MotifButton>
            </>
        );
    };

    return (
        <MotifModal
            show={isModalVisible}
            onClose={() => onClose && onClose()}
            size={size}
            variant={variant}>
            {modalHeader && <MotifModalHeader>{modalHeader}</MotifModalHeader>}
            <MotifModalBody>{modalBody}</MotifModalBody>
            <MotifModalFooter>
                <GetModalFooter />
            </MotifModalFooter>
        </MotifModal>
    );
};

export default ModalComponent;
