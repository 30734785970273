import axios, {
    AxiosResponse,
    AxiosRequestConfig,
    AxiosInstance,
    CancelToken,
    AxiosError
} from 'axios';
import qs from 'query-string';
import { Guid as guid } from 'guid-typescript';
import { IdTokenResponse } from 'react-aad-msal';
import { IAppConfig } from '../models/IAppConfig';
import { getCurrentSessionId } from './session.service';
import { createBrowserHistory } from 'history';
import { authProvider } from '../msalConfig';
import { store } from '../store';
import { appRoutes } from '../app.labels';
import { showPolicyModal } from '../store/actions/userProfileActions';
import { REACT_APP_URL } from '../app.constant';

export enum apiRoutes {
    configuration = 'configuration',
    logout = 'user/sign-out',
    emissionByCountryRegView = 'emissions/regional/emissions',
    emissionByCountrySceView = 'emissions/scenario/emissions',
    emissionIntensityRegView = 'emissions/regional/emissions-intensity',
    emissionIntensitySceView = 'emissions/scenario/emissions-intensity',
    emissionBySectorRegView = 'emissions/regional/emissions-sector',
    emissionBySectorSceView = 'emissions/scenario/emissions-sector',
    emissionPerCapitaRegView = 'emissions/regional/emissions-per-capita',
    emissionPerCapitaSceView = 'emissions/scenario/emissions-per-capita',
    emissionIntensityBySectorRegView = 'emissions/regional/emissions-intensity-sector',
    emissionIntensityBySectorSceView = 'emissions/scenario/emissions-intensity-sector',
    carbonTaxRegView = 'keyindicators/scenario/carbontax-percentalltaxes',
    carbonTaxSceView = 'keyindicators/regional/carbontax-percentalltaxes',
    fossilFuelRegView = 'keyindicators/regional/fossilfuel-percenttotalfuel',
    fossilFuelSceView = 'keyindicators/scenario/fossilfuel-percenttotalfuel',
    gdpData = 'keyindicators/regional/gdp',
    gdpByScenario = 'keyindicators/scenario/gdp',
    gdpPerCapitaByRegion = 'keyindicators/regional/gdp-per-capita',
    gdpPerCapitaByScenario = 'keyindicators/scenario/gdp-per-capita',
    gniRegionView = 'keyindicators/regional/gni',
    gniScenarioView = 'keyindicators/scenario/gni',
    wagesRegionView = 'keyindicators/regional/wages',
    wagesScenarioView = 'keyindicators/scenario/wages',
    gniDeviationRegion = 'keyindicators/regional/gnideviation',
    gniDeviationScenario = 'keyindicators/scenario/gnideviation',
    gniPerCapitaRegView = 'keyindicators/regional/gni-per-capita',
    gniPerCapitaSceView = 'keyindicators/scenario/gni-per-capita',
    carbonPricesRegionalView = 'keyindicators/regional/carbon-prices',
    carbonPricesScenarioView = 'keyindicators/scenario/carbon-prices',
    gdpDeviationRegion = 'keyindicators/regional/gdp-deviation',
    gdpDeviationScenario = 'keyindicators/scenario/gdp-deviation',
    returnOnCapitalRegionalView = 'keyindicators/regional/return-on-capital',
    returnOnCapitalScenarioView = 'keyindicators/scenario/return-on-capital',
    totalCarbonTaxByGDPRegionalView = 'keyindicators/regional/carbontax-gdp',
    totalCarbonTaxByGDPScenarioView = 'keyindicators/scenario/carbontax-gdp',
    exchangeRatesRegionalView = 'keyindicators/regional/exchange-rates',
    exchangeRatesScenarioView = 'keyindicators/scenario/exchange-rates',
    policyTotalEmissions = 'modellinginputs/policy-total-emissions',
    populationPathway = 'modellinginputs/baseline-population-pathway',
    changeInEmissions = 'modellinginputs/change-emissions-startdate',
    cmsGetOverviewDetails = 'page/overview',
    cmsGetPageDetails = 'page/page-details',
    cmsSetPageDetails = 'page/save-page-details',
    cmsGetAttachmentStore = 'page/attachment-store',
    cmsSetAttachmentStore = 'page/save-attachment-store',
    tradesRegionalView = 'keyindicators/regional/trades',
    theworldtodayGDP = 'theworldtoday/gdp',
    startingEmission = '/theworldtoday/starting-emissions-intensity',
    employementRegionalView = 'keyindicators/regional/employment',
    employmentScenarioView = 'keyindicators/scenario/employment',
    startingFuelShares = 'theworldtoday/starting-fuel-shares',
    emissionsBySector = 'theworldtoday/emissions-by-sector',
    tenantDetails = 'authorization/tenant-details',
    userRoles = 'authorization/roles',
    investmentRegionalView = 'keyindicators/regional/investment',
    totalEmissions = '/theworldtoday/total-emissions',
    gvaBySector = 'theworldtoday/gva-by-sector',
    outputBySector = 'theworldtoday/output-by-sector',
    unemploymentRateRegionalView = 'keyindicators/regional/unemployment',
    unemploymentRateScenarioView = 'keyindicators/scenario/unemployment',
    modelingInputBaselineShareGDP = 'modellinginputs/baseline-share-gdp',
    modellingInputBaselineGdpPathway = 'modellinginputs/baseline-gdp-pathway',
    shareOfPopulation = 'modellinginputs/baseline-share-population',
    sectorIndexInputs = '/sectoral-impacts/sector/index-inputs',
    scenarioIndexInputs = 'sectoral-impacts/scenario/index-inputs',
    baselineTotalEmissions = 'modellinginputs/baseline-total-emissions',
    outputSectorView = 'sectoral-impacts/sector/output',
    outputScenarioView = 'sectoral-impacts/scenario/output',
    pricesSectorView = 'sectoral-impacts/sector/prices',
    pricesScenarioView = 'sectoral-impacts/scenario/prices',
    gvaDeviationSectorView = 'sectoral-impacts/sector/gva-deviation',
    gvaDeviationScenarioView = 'sectoral-impacts/scenario/gva-deviation',
    acceptPrivacy = 'paans/acceptpolicy',
    getPrivacyPolicy = 'paans/policytype',
    outputDeviationSector = 'sectoral-impacts/sector/output-deviation',
    outputDeviationScenario = 'sectoral-impacts/scenario/output-deviation',
    gvaSectorView = 'sectoral-impacts/sector/gva',
    gvaScenarioView = 'sectoral-impacts/scenario/gva',
    shareOfEmploymentDeviationSectorView = 'sectoral-impacts/sector/share-employment-deviation',
    shareOfEmploymentDeviationScenarioView = 'sectoral-impacts/scenario/share-employment-deviation',
    tradesSectorView = 'sectoral-impacts/sector/trades',
    shareOfEmploymentSectorView = 'sectoral-impacts/sector/share-employment',
    shareOfEmploymnetScenarioView = 'sectoral-impacts/scenario/share-employment',
    pricesDeviationSectorView = 'sectoral-impacts/sector/prices-deviation',
    pricesDeviationScenarioView = 'sectoral-impacts/scenario/prices-deviation',
    cmsGetPageFilters = 'configuration/filter-selection',
    cmsSetPageFilters = 'configuration/add-filter-selection'
}

export enum errorStatus {
    unauthorized = 403,
    sessionTimeOut = 401,
    notFound = 404,
    internalServerError = 500,
    badRequest = 400,
    serviceUnavailable = 503,
    gatewayTimeout = 504,
    unavailable = 451,
    blocked = 429
}

let api: AxiosInstance;
let authTokenRequest: Promise<IdTokenResponse> | null;

export const getAuthToken = (): Promise<IdTokenResponse> => {
    if (!authTokenRequest) {
        authTokenRequest = authProvider.getIdToken();
    }
    return authTokenRequest;
};

export const browserHistory = createBrowserHistory({
    forceRefresh: true
});

export const initApi = (appConfig: IAppConfig): void => {
    api = axios.create({
        baseURL: `${appConfig.baseApiUrl}/api/v1`
    });
    const sessionId = getCurrentSessionId();

    api.interceptors.request.use(
        (config: AxiosRequestConfig): Promise<any> => {
            // Check and acquire a token before the request is sent
            return getAuthToken()
                .then((res: IdTokenResponse) => {
                    if (!!res.idToken.rawIdToken) {
                        config.headers.Authorization = `Bearer ${res.idToken.rawIdToken}`;
                        return config;
                    }
                })
                .then((config) => {
                    const tenantId = store?.getState()?.userProfile?.userTenant?.tenantId;
                    config!.headers['Request-Id'] = guid.create();
                    config!.headers['X-Correlation-Id'] = guid.create();
                    config!.headers['X-Window-Id'] = sessionId;
                    if (!config?.url?.includes(apiRoutes.tenantDetails)) {
                        config!.headers['X-Tenant-Id'] = tenantId;
                    }
                    return config;
                })
                .catch(() => {
                    localStorage.clear();
                    authProvider.loginRedirect();
                })
                .finally(() => {
                    authTokenRequest = null;
                });
        }
    );

    // intercept responses and look for errors
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === errorStatus.unavailable) {
                if (error.response?.data?.showPolicy) {
                    store.dispatch(
                        showPolicyModal(
                            error.response?.data?.showPolicy,
                            error.response?.data?.policyTypeList
                        )
                    );
                }
            }
            if (error.response?.status === errorStatus.sessionTimeOut) {
                if (error?.response?.data?.Errors[0].ErrorCode === 5) {
                    // Session idle time-out
                    localStorage.clear();
                    var location_href = window.location.href;
                    var url = new URL(location_href);
                    var loc = url.searchParams.get('location');
                    const currentUrl =
                        window.location.pathname === 'error' ? REACT_APP_URL : REACT_APP_URL + loc;
                    window.location.href =
                        'https://login.microsoftonline.com/common/oauth2/logout?client_id=' +
                        authProvider.getCurrentConfiguration().auth.clientId +
                        '&post_logout_redirect_uri=' +
                        encodeURIComponent(currentUrl);
                }
            } else if (error.response?.status === errorStatus.unauthorized) {
                // Unauthorized user
                browserHistory.push(`/${appRoutes.unauthorized}`);
            }
            return Promise.reject(error);
        }
    );
};

const unwrapData = (r: AxiosResponse): any => {
    return r.data || {};
};

const getRequestConfig = (params = {}, config: AxiosRequestConfig = {}): AxiosRequestConfig => {
    const requestConfig = {
        params,
        ...config,
        paramsSerializer: (params: any): string => {
            return qs.stringify(params);
        }
    };

    return requestConfig;
};

export const get = <T = any>(
    path: string,
    queryParams = {},
    config: AxiosRequestConfig = {},
    hasCustomCancellationHandler?: boolean
): Promise<T> => {
    const requestConfig = getRequestConfig(queryParams, config);
    return api
        .get(path, requestConfig)
        .then(unwrapData)
        .catch((error: AxiosError) => {
            // handle cancellation token
            if (axios.isCancel(error) && !hasCustomCancellationHandler) {
                return null;
            }
            return Promise.reject(error);
        });
};

export const post = <T = any>(
    path: apiRoutes | any,
    data: any,
    cancelToken?: CancelToken,
    hasCustomCancellationHandler = false
): Promise<T> => {
    return api
        .post(path, data, {
            cancelToken: cancelToken
        })
        .then(unwrapData)
        .catch((error: AxiosError) => {
            // handle cancellation token
            if (axios.isCancel(error) && !hasCustomCancellationHandler) {
                return null;
            }
            return Promise.reject(error);
        });
};

export const put = <T = any>(
    path: apiRoutes | any,
    data: any,
    cancelToken?: CancelToken
): Promise<T> => {
    return api
        .put(path, data, {
            cancelToken: cancelToken
        })
        .then(unwrapData);
};

export const delete_ = (path: apiRoutes | any, data: unknown): Promise<void> => {
    return api.delete(`${path}`, {
        data
    });
};

export const formatBodyRequest = (query: any, timelineKey = true) => {};
