export const totalEmissions = {
	totalEmissions: [
		{
			scenario: "SSP2-Baseline (7.0)",
			groupByRegion: [
				{
					name: "Australia",
					data: [
						{
							year: "2020",
							value: 527.7370476376862
						},
						{
							year: "2021",
							value: 540.1045141371892
						},
						{
							year: "2022",
							value: 547.6047089969212
						},
						{
							year: "2023",
							value: 554.199369613262
						},
						{
							year: "2024",
							value: 560.7901997688267
						},
						{
							year: "2025",
							value: 567.3771994636127
						},
						{
							year: "2026",
							value: 573.9603686976212
						},
						{
							year: "2027",
							value: 580.5397074708524
						},
						{
							year: "2028",
							value: 587.115215783306
						},
						{
							year: "2029",
							value: 593.6889024655512
						},
						{
							year: "2030",
							value: 600.2629060340109
						},
						{
							year: "2031",
							value: 606.8395555646162
						},
						{
							year: "2032",
							value: 613.4171743405767
						},
						{
							year: "2033",
							value: 619.9940129030399
						},
						{
							year: "2034",
							value: 626.5681921072862
						},
						{
							year: "2035",
							value: 633.1397119533162
						},
						{
							year: "2036",
							value: 639.7085724411298
						},
						{
							year: "2037",
							value: 646.2747735707269
						},
						{
							year: "2038",
							value: 652.838315342108
						},
						{
							year: "2039",
							value: 659.4504517143848
						},
						{
							year: "2040",
							value: 666.1625180240163
						},
						{
							year: "2041",
							value: 673.0274841350843
						},
						{
							year: "2042",
							value: 679.9927895362239
						},
						{
							year: "2043",
							value: 687.0073430230175
						},
						{
							year: "2044",
							value: 694.0199720137007
						},
						{
							year: "2045",
							value: 701.0306765082735
						},
						{
							year: "2046",
							value: 708.0394565067244
						},
						{
							year: "2047",
							value: 715.0463120090749
						},
						{
							year: "2048",
							value: 722.0512430153149
						},
						{
							year: "2049",
							value: 729.022141649111
						},
						{
							year: "2050",
							value: 735.9269963256014
						},
						{
							year: "2051",
							value: 742.7329187854469
						},
						{
							year: "2052",
							value: 749.4732816632684
						},
						{
							year: "2053",
							value: 756.1803854183419
						},
						{
							year: "2054",
							value: 762.8864342184722
						},
						{
							year: "2055",
							value: 769.5914280636597
						},
						{
							year: "2056",
							value: 776.2953669539043
						},
						{
							year: "2057",
							value: 782.9982508892056
						},
						{
							year: "2058",
							value: 789.7000798695644
						},
						{
							year: "2059",
							value: 796.3870034722498
						},
						{
							year: "2060",
							value: 803.0452725580085
						},
						{
							year: "2061",
							value: 809.6608195612862
						},
						{
							year: "2062",
							value: 816.248221534222
						},
						{
							year: "2063",
							value: 822.8215314665019
						},
						{
							year: "2064",
							value: 829.3947010643342
						},
						{
							year: "2065",
							value: 835.9677303277194
						},
						{
							year: "2066",
							value: 842.5406192566568
						},
						{
							year: "2067",
							value: 849.1133678511469
						},
						{
							year: "2068",
							value: 855.6859761111898
						},
						{
							year: "2069",
							value: 862.6102204752582
						},
						{
							year: "2070",
							value: 870.2379918838407
						},
						{
							year: "2071",
							value: 878.9319556715146
						},
						{
							year: "2072",
							value: 888.3300224835302
						},
						{
							year: "2073",
							value: 898.0806448854418
						},
						{
							year: "2074",
							value: 907.8321609407904
						},
						{
							year: "2075",
							value: 917.5845706495759
						},
						{
							year: "2076",
							value: 927.3378740117897
						},
						{
							year: "2077",
							value: 937.0920710274487
						},
						{
							year: "2078",
							value: 946.8471616965447
						},
						{
							year: "2079",
							value: 956.4776857864147
						},
						{
							year: "2080",
							value: 965.8580841027755
						},
						{
							year: "2081",
							value: 974.8588966644879
						},
						{
							year: "2082",
							value: 983.6090856457421
						},
						{
							year: "2083",
							value: 992.2339133559558
						},
						{
							year: "2084",
							value: 1000.85874106617
						},
						{
							year: "2085",
							value: 1009.483568776384
						},
						{
							year: "2086",
							value: 1018.108396486598
						},
						{
							year: "2087",
							value: 1026.733224196812
						},
						{
							year: "2088",
							value: 1035.358051907026
						},
						{
							year: "2089",
							value: 1043.719642462438
						},
						{
							year: "2090",
							value: 1051.554758708244
						},
						{
							year: "2091",
							value: 1058.592186606165
						},
						{
							year: "2092",
							value: 1065.103140194479
						},
						{
							year: "2093",
							value: 1071.350856627992
						},
						{
							year: "2094",
							value: 1077.598573061505
						},
						{
							year: "2095",
							value: 1083.846289495017
						},
						{
							year: "2096",
							value: 1090.09400592853
						},
						{
							year: "2097",
							value: 1096.341722362042
						},
						{
							year: "2098",
							value: 1102.58943879557
						},
						{
							year: "2099",
							value: 1108.837155229083
						},
						{
							year: "2100",
							value: 1115.084871662596
						}
					]
				},
				{
					name: "Canada",
					data: [
						{
							year: "2020",
							value: 672.3540219599809
						},
						{
							year: "2021",
							value: 688.1105732190413
						},
						{
							year: "2022",
							value: 697.6660634049163
						},
						{
							year: "2023",
							value: 706.0678737548089
						},
						{
							year: "2024",
							value: 714.4648039741
						},
						{
							year: "2025",
							value: 722.8568540628057
						},
						{
							year: "2026",
							value: 731.2440240209173
						},
						{
							year: "2027",
							value: 739.6263138484383
						},
						{
							year: "2028",
							value: 748.0037235453692
						},
						{
							year: "2029",
							value: 756.3788124266539
						},
						{
							year: "2030",
							value: 764.7543050311981
						},
						{
							year: "2031",
							value: 773.1331686768662
						},
						{
							year: "2032",
							value: 781.513267172418
						},
						{
							year: "2033",
							value: 789.8923716507709
						},
						{
							year: "2034",
							value: 798.2680880141918
						},
						{
							year: "2035",
							value: 806.640416276172
						},
						{
							year: "2036",
							value: 815.0093564300195
						},
						{
							year: "2037",
							value: 823.3749084756981
						},
						{
							year: "2038",
							value: 831.7370724132259
						},
						{
							year: "2039",
							value: 840.1611474398158
						},
						{
							year: "2040",
							value: 848.7125364300449
						},
						{
							year: "2041",
							value: 857.4587246994076
						},
						{
							year: "2042",
							value: 866.3327484609765
						},
						{
							year: "2043",
							value: 875.2695158722679
						},
						{
							year: "2044",
							value: 884.2038314134358
						},
						{
							year: "2045",
							value: 893.1356950845218
						},
						{
							year: "2046",
							value: 902.0651068854406
						},
						{
							year: "2047",
							value: 910.9920668162373
						},
						{
							year: "2048",
							value: 919.9165748769108
						},
						{
							year: "2049",
							value: 928.7977245973061
						},
						{
							year: "2050",
							value: 937.5947321857069
						},
						{
							year: "2051",
							value: 946.2656969388363
						},
						{
							year: "2052",
							value: 954.8531366697049
						},
						{
							year: "2053",
							value: 963.3982032053499
						},
						{
							year: "2054",
							value: 971.9419256943679
						},
						{
							year: "2055",
							value: 980.484304136759
						},
						{
							year: "2056",
							value: 989.0253385325229
						},
						{
							year: "2057",
							value: 997.5650288816594
						},
						{
							year: "2058",
							value: 1006.103375184169
						},
						{
							year: "2059",
							value: 1014.622731554718
						},
						{
							year: "2060",
							value: 1023.105581146393
						},
						{
							year: "2061",
							value: 1031.534001426896
						},
						{
							year: "2062",
							value: 1039.926564030866
						},
						{
							year: "2063",
							value: 1048.301172920472
						},
						{
							year: "2064",
							value: 1056.675603019465
						},
						{
							year: "2065",
							value: 1065.049854327846
						},
						{
							year: "2066",
							value: 1073.423926845614
						},
						{
							year: "2067",
							value: 1081.797820572769
						},
						{
							year: "2068",
							value: 1090.171535509312
						},
						{
							year: "2069",
							value: 1098.993246185142
						},
						{
							year: "2070",
							value: 1108.711273009422
						},
						{
							year: "2071",
							value: 1119.787663316932
						},
						{
							year: "2072",
							value: 1131.761103589771
						},
						{
							year: "2073",
							value: 1144.183711056558
						},
						{
							year: "2074",
							value: 1156.607457066649
						},
						{
							year: "2075",
							value: 1169.032341620048
						},
						{
							year: "2076",
							value: 1181.45836471675
						},
						{
							year: "2077",
							value: 1193.885526356757
						},
						{
							year: "2078",
							value: 1206.31382654007
						},
						{
							year: "2079",
							value: 1218.583424883293
						},
						{
							year: "2080",
							value: 1230.534354922735
						},
						{
							year: "2081",
							value: 1242.001680466421
						},
						{
							year: "2082",
							value: 1253.149703484214
						},
						{
							year: "2083",
							value: 1264.138012198912
						},
						{
							year: "2084",
							value: 1275.126320913612
						},
						{
							year: "2085",
							value: 1286.114629628309
						},
						{
							year: "2086",
							value: 1297.102938343009
						},
						{
							year: "2087",
							value: 1308.091247057707
						},
						{
							year: "2088",
							value: 1319.079555772405
						},
						{
							year: "2089",
							value: 1329.732491860619
						},
						{
							year: "2090",
							value: 1339.714682695866
						},
						{
							year: "2091",
							value: 1348.680592844799
						},
						{
							year: "2092",
							value: 1356.97575774076
						},
						{
							year: "2093",
							value: 1364.935550010237
						},
						{
							year: "2094",
							value: 1372.895342279713
						},
						{
							year: "2095",
							value: 1380.855134549189
						},
						{
							year: "2096",
							value: 1388.814926818666
						},
						{
							year: "2097",
							value: 1396.774719088143
						},
						{
							year: "2098",
							value: 1404.734511357618
						},
						{
							year: "2099",
							value: 1412.694303627094
						},
						{
							year: "2100",
							value: 1420.654095896571
						}
					]
				},
				{
					name: "China",
					data: [
						{
							year: "2020",
							value: 13106.41681037749
						},
						{
							year: "2021",
							value: 13185.08980927839
						},
						{
							year: "2022",
							value: 13368.1853773105
						},
						{
							year: "2023",
							value: 13529.17494546304
						},
						{
							year: "2024",
							value: 13690.0710039928
						},
						{
							year: "2025",
							value: 13850.87355289978
						},
						{
							year: "2026",
							value: 14011.58259218405
						},
						{
							year: "2027",
							value: 14172.19812184555
						},
						{
							year: "2028",
							value: 14332.72014188433
						},
						{
							year: "2029",
							value: 14493.19769208943
						},
						{
							year: "2030",
							value: 14653.68297815508
						},
						{
							year: "2031",
							value: 14814.23285773479
						},
						{
							year: "2032",
							value: 14974.80639863702
						},
						{
							year: "2033",
							value: 15135.36089288287
						},
						{
							year: "2034",
							value: 15295.85046658821
						},
						{
							year: "2035",
							value: 15456.2751197533
						},
						{
							year: "2036",
							value: 15616.63485237801
						},
						{
							year: "2037",
							value: 15776.92966446234
						},
						{
							year: "2038",
							value: 15937.15955600631
						},
						{
							year: "2039",
							value: 16098.57574420265
						},
						{
							year: "2040",
							value: 16262.43143283672
						},
						{
							year: "2041",
							value: 16430.01972795898
						},
						{
							year: "2042",
							value: 16600.05751668176
						},
						{
							year: "2043",
							value: 16771.2975549975
						},
						{
							year: "2044",
							value: 16942.49061230607
						},
						{
							year: "2045",
							value: 17113.63668860747
						},
						{
							year: "2046",
							value: 17284.73578390167
						},
						{
							year: "2047",
							value: 17455.7878981887
						},
						{
							year: "2048",
							value: 17626.79303146854
						},
						{
							year: "2049",
							value: 17796.96736279177
						},
						{
							year: "2050",
							value: 17965.52942188691
						},
						{
							year: "2051",
							value: 18131.67633701023
						},
						{
							year: "2052",
							value: 18296.22280452713
						},
						{
							year: "2053",
							value: 18459.95734674276
						},
						{
							year: "2054",
							value: 18623.66613528412
						},
						{
							year: "2055",
							value: 18787.34917015133
						},
						{
							year: "2056",
							value: 18951.00645134438
						},
						{
							year: "2057",
							value: 19114.63797886328
						},
						{
							year: "2058",
							value: 19278.24375270802
						},
						{
							year: "2059",
							value: 19441.48565486106
						},
						{
							year: "2060",
							value: 19604.02803984799
						},
						{
							year: "2061",
							value: 19765.52748873718
						},
						{
							year: "2062",
							value: 19926.3398581019
						},
						{
							year: "2063",
							value: 20086.80821104611
						},
						{
							year: "2064",
							value: 20247.27313813063
						},
						{
							year: "2065",
							value: 20407.73463935543
						},
						{
							year: "2066",
							value: 20568.19271472052
						},
						{
							year: "2067",
							value: 20728.64736422591
						},
						{
							year: "2068",
							value: 20889.0985878716
						},
						{
							year: "2069",
							value: 21058.13399011682
						},
						{
							year: "2070",
							value: 21244.34397065637
						},
						{
							year: "2071",
							value: 21456.58195485892
						},
						{
							year: "2072",
							value: 21686.00857823751
						},
						{
							year: "2073",
							value: 21924.04182680413
						},
						{
							year: "2074",
							value: 22162.09689133509
						},
						{
							year: "2075",
							value: 22400.17377183041
						},
						{
							year: "2076",
							value: 22638.27246829006
						},
						{
							year: "2077",
							value: 22876.39298071406
						},
						{
							year: "2078",
							value: 23114.5353091024
						},
						{
							year: "2079",
							value: 23349.63670468731
						},
						{
							year: "2080",
							value: 23578.63200283921
						},
						{
							year: "2081",
							value: 23798.36081249791
						},
						{
							year: "2082",
							value: 24011.97137220654
						},
						{
							year: "2083",
							value: 24222.52159900919
						},
						{
							year: "2084",
							value: 24433.07182581186
						},
						{
							year: "2085",
							value: 24643.62205261452
						},
						{
							year: "2086",
							value: 24854.17227941718
						},
						{
							year: "2087",
							value: 25064.72250621986
						},
						{
							year: "2088",
							value: 25275.27273302252
						},
						{
							year: "2089",
							value: 25479.3967859341
						},
						{
							year: "2090",
							value: 25670.66849106347
						},
						{
							year: "2091",
							value: 25842.46694197753
						},
						{
							year: "2092",
							value: 26001.41304510925
						},
						{
							year: "2093",
							value: 26153.93297434994
						},
						{
							year: "2094",
							value: 26306.45290359061
						},
						{
							year: "2095",
							value: 26458.97283283126
						},
						{
							year: "2096",
							value: 26611.49276207194
						},
						{
							year: "2097",
							value: 26764.01269131262
						},
						{
							year: "2098",
							value: 26916.53262055327
						},
						{
							year: "2099",
							value: 27069.05254979395
						},
						{
							year: "2100",
							value: 27221.57247903462
						}
					]
				},
				{
					name: "France",
					data: [
						{
							year: "2020",
							value: 399.412668092019
						},
						{
							year: "2021",
							value: 408.7728652095359
						},
						{
							year: "2022",
							value: 414.4493149747082
						},
						{
							year: "2023",
							value: 419.4404199269274
						},
						{
							year: "2024",
							value: 424.4286258320436
						},
						{
							year: "2025",
							value: 429.4139326900565
						},
						{
							year: "2026",
							value: 434.3963405009667
						},
						{
							year: "2027",
							value: 439.3758492647734
						},
						{
							year: "2028",
							value: 444.3524589814775
						},
						{
							year: "2029",
							value: 449.3276900150198
						},
						{
							year: "2030",
							value: 454.3031608808205
						},
						{
							year: "2031",
							value: 459.2806343174189
						},
						{
							year: "2032",
							value: 464.25884131797
						},
						{
							year: "2033",
							value: 469.2364578214966
						},
						{
							year: "2034",
							value: 474.2120616155395
						},
						{
							year: "2035",
							value: 479.1856527000991
						},
						{
							year: "2036",
							value: 484.1572310751749
						},
						{
							year: "2037",
							value: 489.1267967407678
						},
						{
							year: "2038",
							value: 494.0943496968764
						},
						{
							year: "2039",
							value: 499.098681005548
						},
						{
							year: "2040",
							value: 504.1786433184857
						},
						{
							year: "2041",
							value: 509.3743263637771
						},
						{
							year: "2042",
							value: 514.6459502279687
						},
						{
							year: "2043",
							value: 519.9548470283228
						},
						{
							year: "2044",
							value: 525.2622872924583
						},
						{
							year: "2045",
							value: 530.5682710203682
						},
						{
							year: "2046",
							value: 535.8727982120568
						},
						{
							year: "2047",
							value: 541.1758688675218
						},
						{
							year: "2048",
							value: 546.4774829867635
						},
						{
							year: "2049",
							value: 551.7533400348648
						},
						{
							year: "2050",
							value: 556.979212354176
						},
						{
							year: "2051",
							value: 562.1302087844675
						},
						{
							year: "2052",
							value: 567.2315870807046
						},
						{
							year: "2053",
							value: 572.3077935323375
						},
						{
							year: "2054",
							value: 577.3832015515493
						},
						{
							year: "2055",
							value: 582.45781113834
						},
						{
							year: "2056",
							value: 587.53162229271
						},
						{
							year: "2057",
							value: 592.6046350146593
						},
						{
							year: "2058",
							value: 597.6768493041874
						},
						{
							year: "2059",
							value: 602.7377826031318
						},
						{
							year: "2060",
							value: 607.7770290087458
						},
						{
							year: "2061",
							value: 612.7839416204204
						},
						{
							year: "2062",
							value: 617.7695529387511
						},
						{
							year: "2063",
							value: 622.7444988327342
						},
						{
							year: "2064",
							value: 627.7193385159505
						},
						{
							year: "2065",
							value: 632.6940719883866
						},
						{
							year: "2066",
							value: 637.6686992500711
						},
						{
							year: "2067",
							value: 642.6432203009897
						},
						{
							year: "2068",
							value: 647.6176351411418
						},
						{
							year: "2069",
							value: 652.8581823575137
						},
						{
							year: "2070",
							value: 658.6311871968286
						},
						{
							year: "2071",
							value: 665.2111294015188
						},
						{
							year: "2072",
							value: 672.3239651539012
						},
						{
							year: "2073",
							value: 679.7036291864688
						},
						{
							year: "2074",
							value: 687.0839695719762
						},
						{
							year: "2075",
							value: 694.4649863104241
						},
						{
							year: "2076",
							value: 701.8466794018152
						},
						{
							year: "2077",
							value: 709.2290488461417
						},
						{
							year: "2078",
							value: 716.612094643408
						},
						{
							year: "2079",
							value: 723.9008634328607
						},
						{
							year: "2080",
							value: 731.0003269556032
						},
						{
							year: "2081",
							value: 737.8125046800253
						},
						{
							year: "2082",
							value: 744.4350003773764
						},
						{
							year: "2083",
							value: 750.9626176121162
						},
						{
							year: "2084",
							value: 757.4902348468559
						},
						{
							year: "2085",
							value: 764.0178520815961
						},
						{
							year: "2086",
							value: 770.5454693163357
						},
						{
							year: "2087",
							value: 777.0730865510759
						},
						{
							year: "2088",
							value: 783.6007037858158
						},
						{
							year: "2089",
							value: 789.9290925320302
						},
						{
							year: "2090",
							value: 795.859024301205
						},
						{
							year: "2091",
							value: 801.1852333778127
						},
						{
							year: "2092",
							value: 806.1129854774101
						},
						{
							year: "2093",
							value: 810.8415090884811
						},
						{
							year: "2094",
							value: 815.570032699552
						},
						{
							year: "2095",
							value: 820.2985563106224
						},
						{
							year: "2096",
							value: 825.0270799216933
						},
						{
							year: "2097",
							value: 829.755603532764
						},
						{
							year: "2098",
							value: 834.4841271438343
						},
						{
							year: "2099",
							value: 839.2126507549049
						},
						{
							year: "2100",
							value: 843.9411743659754
						}
					]
				},
				{
					name: "Global",
					data: [
						{
							year: "2020",
							value: 49017.00912423762
						},
						{
							year: "2021",
							value: 49500.908514963616
						},
						{
							year: "2022",
							value: 50188.305952326264
						},
						{
							year: "2023",
							value: 50792.71254687185
						},
						{
							year: "2024",
							value: 51396.76617800529
						},
						{
							year: "2025",
							value: 52000.468744979364
						},
						{
							year: "2026",
							value: 52603.82024785897
						},
						{
							year: "2027",
							value: 53206.82068665595
						},
						{
							year: "2028",
							value: 53809.47006137067
						},
						{
							year: "2029",
							value: 54411.95248255807
						},
						{
							year: "2030",
							value: 55014.46394656165
						},
						{
							year: "2031",
							value: 55617.21791462069
						},
						{
							year: "2032",
							value: 56220.060714612126
						},
						{
							year: "2033",
							value: 56822.83200755701
						},
						{
							year: "2034",
							value: 57425.359568681175
						},
						{
							year: "2035",
							value: 58027.643397998865
						},
						{
							year: "2036",
							value: 58629.68349550286
						},
						{
							year: "2037",
							value: 59231.47986119317
						},
						{
							year: "2038",
							value: 59833.03249507005
						},
						{
							year: "2039",
							value: 60439.03885395999
						},
						{
							year: "2040",
							value: 61054.20385297323
						},
						{
							year: "2041",
							value: 61683.38221268066
						},
						{
							year: "2042",
							value: 62321.75672993931
						},
						{
							year: "2043",
							value: 62964.64486449024
						},
						{
							year: "2044",
							value: 63607.35661779097
						},
						{
							year: "2045",
							value: 64249.89198984143
						},
						{
							year: "2046",
							value: 64892.2509806417
						},
						{
							year: "2047",
							value: 65534.4335901914
						},
						{
							year: "2048",
							value: 66176.43981849065
						},
						{
							year: "2049",
							value: 66815.32695896099
						},
						{
							year: "2050",
							value: 67448.1611301841
						},
						{
							year: "2051",
							value: 68071.9281029996
						},
						{
							year: "2052",
							value: 68689.68649986384
						},
						{
							year: "2053",
							value: 69304.3966777047
						},
						{
							year: "2054",
							value: 69919.010168277
						},
						{
							year: "2055",
							value: 70533.52697158096
						},
						{
							year: "2056",
							value: 71147.94708761657
						},
						{
							year: "2057",
							value: 71762.27051638383
						},
						{
							year: "2058",
							value: 72376.49725788276
						},
						{
							year: "2059",
							value: 72989.35791236488
						},
						{
							year: "2060",
							value: 73599.5923627743
						},
						{
							year: "2061",
							value: 74205.91130808986
						},
						{
							year: "2062",
							value: 74809.65074409129
						},
						{
							year: "2063",
							value: 75412.098635913
						},
						{
							year: "2064",
							value: 76014.53366599644
						},
						{
							year: "2065",
							value: 76616.95583434154
						},
						{
							year: "2066",
							value: 77219.3651409484
						},
						{
							year: "2067",
							value: 77821.76158581698
						},
						{
							year: "2068",
							value: 78424.14516894732
						},
						{
							year: "2069",
							value: 79058.7564169438
						},
						{
							year: "2070",
							value: 79757.84635059077
						},
						{
							year: "2071",
							value: 80554.65347050743
						},
						{
							year: "2072",
							value: 81415.99206497945
						},
						{
							year: "2073",
							value: 82309.64259576256
						},
						{
							year: "2074",
							value: 83203.37503043158
						},
						{
							year: "2075",
							value: 84097.18936898686
						},
						{
							year: "2076",
							value: 84991.08561142835
						},
						{
							year: "2077",
							value: 85885.06375775615
						},
						{
							year: "2078",
							value: 86779.1238079702
						},
						{
							year: "2079",
							value: 87661.76725470455
						},
						{
							year: "2080",
							value: 88521.4865207
						},
						{
							year: "2081",
							value: 89346.41651918623
						},
						{
							year: "2082",
							value: 90148.37671262109
						},
						{
							year: "2083",
							value: 90938.84746858342
						},
						{
							year: "2084",
							value: 91729.31822454575
						},
						{
							year: "2085",
							value: 92519.7889805081
						},
						{
							year: "2086",
							value: 93310.25973647041
						},
						{
							year: "2087",
							value: 94100.73049243278
						},
						{
							year: "2088",
							value: 94891.20124839514
						},
						{
							year: "2089",
							value: 95657.54615747162
						},
						{
							year: "2090",
							value: 96375.63937277615
						},
						{
							year: "2091",
							value: 97020.62396115368
						},
						{
							year: "2092",
							value: 97617.35685575931
						},
						{
							year: "2093",
							value: 98189.96390347909
						},
						{
							year: "2094",
							value: 98762.57095119881
						},
						{
							year: "2095",
							value: 99335.17799891837
						},
						{
							year: "2096",
							value: 99907.78504663814
						},
						{
							year: "2097",
							value: 100480.3920943579
						},
						{
							year: "2098",
							value: 101052.9991420776
						},
						{
							year: "2099",
							value: 101625.60618979734
						},
						{
							year: "2100",
							value: 102198.2132375171
						}
					]
				},
				{
					name: "India",
					data: [
						{
							year: "2020",
							value: 3502.106733367986
						},
						{
							year: "2021",
							value: 3523.128591834056
						},
						{
							year: "2022",
							value: 3572.05273570436
						},
						{
							year: "2023",
							value: 3615.071956993751
						},
						{
							year: "2024",
							value: 3658.064292559401
						},
						{
							year: "2025",
							value: 3701.03164182571
						},
						{
							year: "2026",
							value: 3743.974004792699
						},
						{
							year: "2027",
							value: 3786.891381460356
						},
						{
							year: "2028",
							value: 3829.783771828678
						},
						{
							year: "2029",
							value: 3872.664279606287
						},
						{
							year: "2030",
							value: 3915.546854449524
						},
						{
							year: "2031",
							value: 3958.446689044518
						},
						{
							year: "2032",
							value: 4001.352846078544
						},
						{
							year: "2033",
							value: 4044.253913738412
						},
						{
							year: "2034",
							value: 4087.137634263225
						},
						{
							year: "2035",
							value: 4130.004007652985
						},
						{
							year: "2036",
							value: 4172.853033907685
						},
						{
							year: "2037",
							value: 4215.684713027333
						},
						{
							year: "2038",
							value: 4258.499045011933
						},
						{
							year: "2039",
							value: 4301.630362162143
						},
						{
							year: "2040",
							value: 4345.413527607394
						},
						{
							year: "2041",
							value: 4390.194066587712
						},
						{
							year: "2042",
							value: 4435.629124092608
						},
						{
							year: "2043",
							value: 4481.385429478834
						},
						{
							year: "2044",
							value: 4527.129181274709
						},
						{
							year: "2045",
							value: 4572.860379480124
						},
						{
							year: "2046",
							value: 4618.579024095041
						},
						{
							year: "2047",
							value: 4664.285115119525
						},
						{
							year: "2048",
							value: 4709.978652553428
						},
						{
							year: "2049",
							value: 4755.45019501236
						},
						{
							year: "2050",
							value: 4800.490929225964
						},
						{
							year: "2051",
							value: 4844.886323329769
						},
						{
							year: "2052",
							value: 4888.85406879396
						},
						{
							year: "2053",
							value: 4932.604863231949
						},
						{
							year: "2054",
							value: 4976.348776142742
						},
						{
							year: "2055",
							value: 5020.085807526351
						},
						{
							year: "2056",
							value: 5063.815957382773
						},
						{
							year: "2057",
							value: 5107.539225712003
						},
						{
							year: "2058",
							value: 5151.255612514045
						},
						{
							year: "2059",
							value: 5194.8747707449479
						},
						{
							year: "2060",
							value: 5238.307014038259
						},
						{
							year: "2061",
							value: 5281.460578915842
						},
						{
							year: "2062",
							value: 5324.430552263922
						},
						{
							year: "2063",
							value: 5367.308602481473
						},
						{
							year: "2064",
							value: 5410.185737289959
						},
						{
							year: "2065",
							value: 5453.061956689386
						},
						{
							year: "2066",
							value: 5495.93726067975
						},
						{
							year: "2067",
							value: 5538.811649261048
						},
						{
							year: "2068",
							value: 5581.685122433286
						},
						{
							year: "2069",
							value: 5626.852336609991
						},
						{
							year: "2070",
							value: 5676.608695107415
						},
						{
							year: "2071",
							value: 5733.319883187402
						},
						{
							year: "2072",
							value: 5794.623972735137
						},
						{
							year: "2073",
							value: 5858.22780114256
						},
						{
							year: "2074",
							value: 5921.837458898874
						},
						{
							year: "2075",
							value: 5985.452946004071
						},
						{
							year: "2076",
							value: 6049.074262458153
						},
						{
							year: "2077",
							value: 6112.701408261129
						},
						{
							year: "2078",
							value: 6176.334383412992
						},
						{
							year: "2079",
							value: 6239.154804144858
						},
						{
							year: "2080",
							value: 6300.343641155934
						},
						{
							year: "2081",
							value: 6359.056420105338
						},
						{
							year: "2082",
							value: 6416.134368112751
						},
						{
							year: "2083",
							value: 6472.394577883204
						},
						{
							year: "2084",
							value: 6528.654787653661
						},
						{
							year: "2085",
							value: 6584.914997424118
						},
						{
							year: "2086",
							value: 6641.175207194573
						},
						{
							year: "2087",
							value: 6697.43541696503
						},
						{
							year: "2088",
							value: 6753.695626735485
						},
						{
							year: "2089",
							value: 6808.23872654779
						},
						{
							year: "2090",
							value: 6859.34760644378
						},
						{
							year: "2091",
							value: 6905.253122830223
						},
						{
							year: "2092",
							value: 6947.724419300355
						},
						{
							year: "2093",
							value: 6988.478605812333
						},
						{
							year: "2094",
							value: 7029.232792324312
						},
						{
							year: "2095",
							value: 7069.986978836288
						},
						{
							year: "2096",
							value: 7110.741165348268
						},
						{
							year: "2097",
							value: 7151.495351860247
						},
						{
							year: "2098",
							value: 7192.249538372205
						},
						{
							year: "2099",
							value: 7233.003724884183
						},
						{
							year: "2100",
							value: 7273.757911396165
						}
					]
				},
				{
					name: "Netherlands",
					data: [
						{
							year: "2020",
							value: 163.9151815480303
						},
						{
							year: "2021",
							value: 167.7565182540843
						},
						{
							year: "2022",
							value: 170.0860783167517
						},
						{
							year: "2023",
							value: 172.1343805703442
						},
						{
							year: "2024",
							value: 174.18149317718852
						},
						{
							year: "2025",
							value: 176.2274159534216
						},
						{
							year: "2026",
							value: 178.2721489881474
						},
						{
							year: "2027",
							value: 180.3156922813653
						},
						{
							year: "2028",
							value: 182.3580458330756
						},
						{
							year: "2029",
							value: 184.3998335862576
						},
						{
							year: "2030",
							value: 186.4417197643299
						},
						{
							year: "2031",
							value: 188.4844277785145
						},
						{
							year: "2032",
							value: 190.5274368404062
						},
						{
							year: "2033",
							value: 192.5702035679048
						},
						{
							year: "2034",
							value: 194.6121442984705
						},
						{
							year: "2035",
							value: 196.6532590321033
						},
						{
							year: "2036",
							value: 198.6935477688038
						},
						{
							year: "2037",
							value: 200.7330105085707
						},
						{
							year: "2038",
							value: 202.7716472514047
						},
						{
							year: "2039",
							value: 204.8253774822275
						},
						{
							year: "2040",
							value: 206.9101459617727
						},
						{
							year: "2041",
							value: 209.0424051352205
						},
						{
							year: "2042",
							value: 211.2058297023929
						},
						{
							year: "2043",
							value: 213.3845507299802
						},
						{
							year: "2044",
							value: 215.5626740088792
						},
						{
							year: "2045",
							value: 217.7401995390903
						},
						{
							year: "2046",
							value: 219.9171273206137
						},
						{
							year: "2047",
							value: 222.0934573534485
						},
						{
							year: "2048",
							value: 224.2691896375952
						},
						{
							year: "2049",
							value: 226.4343514633939
						},
						{
							year: "2050",
							value: 228.5790000293254
						},
						{
							year: "2051",
							value: 230.6929202386882
						},
						{
							year: "2052",
							value: 232.786477635196
						},
						{
							year: "2053",
							value: 234.8697047448854
						},
						{
							year: "2054",
							value: 236.9526041853676
						},
						{
							year: "2055",
							value: 239.0351759567369
						},
						{
							year: "2056",
							value: 241.1174200589935
						},
						{
							year: "2057",
							value: 243.1993364921372
						},
						{
							year: "2058",
							value: 245.2809252561684
						},
						{
							year: "2059",
							value: 247.3578844083758
						},
						{
							year: "2060",
							value: 249.4259434647066
						},
						{
							year: "2061",
							value: 251.4807330378667
						},
						{
							year: "2062",
							value: 253.5267807617354
						},
						{
							year: "2063",
							value: 255.5684514963407
						},
						{
							year: "2064",
							value: 257.6100786430522
						},
						{
							year: "2065",
							value: 259.6516622018703
						},
						{
							year: "2066",
							value: 261.6932021727945
						},
						{
							year: "2067",
							value: 263.7346985558253
						},
						{
							year: "2068",
							value: 265.7761513509623
						},
						{
							year: "2069",
							value: 267.9268223558319
						},
						{
							year: "2070",
							value: 270.2960089323973
						},
						{
							year: "2071",
							value: 272.996354970524
						},
						{
							year: "2072",
							value: 275.9153954797401
						},
						{
							year: "2073",
							value: 278.9439397910936
						},
						{
							year: "2074",
							value: 281.9727616712954
						},
						{
							year: "2075",
							value: 285.0018611203397
						},
						{
							year: "2076",
							value: 288.0312381382386
						},
						{
							year: "2077",
							value: 291.0608927249862
						},
						{
							year: "2078",
							value: 294.0908248805824
						},
						{
							year: "2079",
							value: 297.082066643981
						},
						{
							year: "2080",
							value: 299.995619316647
						},
						{
							year: "2081",
							value: 302.7912726152496
						},
						{
							year: "2082",
							value: 305.5090822042301
						},
						{
							year: "2083",
							value: 308.1879545696548
						},
						{
							year: "2084",
							value: 310.8668269350796
						},
						{
							year: "2085",
							value: 313.5456993005043
						},
						{
							year: "2086",
							value: 316.224571665929
						},
						{
							year: "2087",
							value: 318.9034440313539
						},
						{
							year: "2088",
							value: 321.5823163967787
						},
						{
							year: "2089",
							value: 324.1794272751052
						},
						{
							year: "2090",
							value: 326.6130151792466
						},
						{
							year: "2091",
							value: 328.7988410012608
						},
						{
							year: "2092",
							value: 330.8211438490783
						},
						{
							year: "2093",
							value: 332.7616852097975
						},
						{
							year: "2094",
							value: 334.7022265705169
						},
						{
							year: "2095",
							value: 336.6427679312359
						},
						{
							year: "2096",
							value: 338.5833092919551
						},
						{
							year: "2097",
							value: 340.5238506526744
						},
						{
							year: "2098",
							value: 342.4643920133934
						},
						{
							year: "2099",
							value: 344.4049333741131
						},
						{
							year: "2100",
							value: 346.3454747348322
						}
					]
				},
				{
					name: "Remaining Advanced Economies",
					data: [
						{
							year: "2020",
							value: 1474.438235613005
						},
						{
							year: "2021",
							value: 1483.288745967444
						},
						{
							year: "2022",
							value: 1503.88652720572
						},
						{
							year: "2023",
							value: 1521.997440222842
						},
						{
							year: "2024",
							value: 1540.097833647524
						},
						{
							year: "2025",
							value: 1558.187707479767
						},
						{
							year: "2026",
							value: 1576.267061719573
						},
						{
							year: "2027",
							value: 1594.33589636694
						},
						{
							year: "2028",
							value: 1612.394211421866
						},
						{
							year: "2029",
							value: 1630.447523734703
						},
						{
							year: "2030",
							value: 1648.501706311983
						},
						{
							year: "2031",
							value: 1666.56315549373
						},
						{
							year: "2032",
							value: 1684.627266513499
						},
						{
							year: "2033",
							value: 1702.689234833337
						},
						{
							year: "2034",
							value: 1720.743899759087
						},
						{
							year: "2035",
							value: 1738.791261290753
						},
						{
							year: "2036",
							value: 1756.831319428332
						},
						{
							year: "2037",
							value: 1774.864074171842
						},
						{
							year: "2038",
							value: 1792.889525521254
						},
						{
							year: "2039",
							value: 1811.048432197803
						},
						{
							year: "2040",
							value: 1829.481776409278
						},
						{
							year: "2041",
							value: 1848.335029265835
						},
						{
							year: "2042",
							value: 1867.463843862471
						},
						{
							year: "2043",
							value: 1886.727908451098
						},
						{
							year: "2044",
							value: 1905.986687797059
						},
						{
							year: "2045",
							value: 1925.240181900362
						},
						{
							year: "2046",
							value: 1944.488390760989
						},
						{
							year: "2047",
							value: 1963.731314378949
						},
						{
							year: "2048",
							value: 1982.968952754242
						},
						{
							year: "2049",
							value: 2002.113128042811
						},
						{
							year: "2050",
							value: 2021.075926845818
						},
						{
							year: "2051",
							value: 2039.767028153757
						},
						{
							year: "2052",
							value: 2058.278083215723
						},
						{
							year: "2053",
							value: 2076.697798766207
						},
						{
							year: "2054",
							value: 2095.114617094475
						},
						{
							year: "2055",
							value: 2113.528538200533
						},
						{
							year: "2056",
							value: 2131.939562084375
						},
						{
							year: "2057",
							value: 2150.347688746011
						},
						{
							year: "2058",
							value: 2168.752918185436
						},
						{
							year: "2059",
							value: 2187.117212993918
						},
						{
							year: "2060",
							value: 2205.402813917253
						},
						{
							year: "2061",
							value: 2223.571087207943
						},
						{
							year: "2062",
							value: 2241.662065816458
						},
						{
							year: "2063",
							value: 2259.714343461058
						},
						{
							year: "2064",
							value: 2277.766235705237
						},
						{
							year: "2065",
							value: 2295.817742548997
						},
						{
							year: "2066",
							value: 2313.868863992336
						},
						{
							year: "2067",
							value: 2331.919600035255
						},
						{
							year: "2068",
							value: 2349.969950677757
						},
						{
							year: "2069",
							value: 2368.985999369663
						},
						{
							year: "2070",
							value: 2389.934144017622
						},
						{
							year: "2071",
							value: 2413.810372241164
						},
						{
							year: "2072",
							value: 2439.62027823386
						},
						{
							year: "2073",
							value: 2466.39840745991
						},
						{
							year: "2074",
							value: 2493.178990925551
						},
						{
							year: "2075",
							value: 2519.962028631161
						},
						{
							year: "2076",
							value: 2546.747520576732
						},
						{
							year: "2077",
							value: 2573.535466762277
						},
						{
							year: "2078",
							value: 2600.325867187792
						},
						{
							year: "2079",
							value: 2626.774170481658
						},
						{
							year: "2080",
							value: 2652.535553493942
						},
						{
							year: "2081",
							value: 2677.254480345867
						},
						{
							year: "2082",
							value: 2701.285119789267
						},
						{
							year: "2083",
							value: 2724.97147963928
						},
						{
							year: "2084",
							value: 2748.657839489292
						},
						{
							year: "2085",
							value: 2772.344199339306
						},
						{
							year: "2086",
							value: 2796.030559189318
						},
						{
							year: "2087",
							value: 2819.716919039332
						},
						{
							year: "2088",
							value: 2843.403278889345
						},
						{
							year: "2089",
							value: 2866.36671067818
						},
						{
							year: "2090",
							value: 2887.884286344655
						},
						{
							year: "2091",
							value: 2907.211170916652
						},
						{
							year: "2092",
							value: 2925.092199366291
						},
						{
							year: "2093",
							value: 2942.25029975475
						},
						{
							year: "2094",
							value: 2959.408400143209
						},
						{
							year: "2095",
							value: 2976.566500531667
						},
						{
							year: "2096",
							value: 2993.724600920124
						},
						{
							year: "2097",
							value: 3010.882701308584
						},
						{
							year: "2098",
							value: 3028.040801697043
						},
						{
							year: "2099",
							value: 3045.198902085502
						},
						{
							year: "2100",
							value: 3062.357002473962
						}
					]
				},
				{
					name: "Rest of Asia",
					data: [
						{
							year: "2020",
							value: 4437.550619530948
						},
						{
							year: "2021",
							value: 4464.187603541214
						},
						{
							year: "2022",
							value: 4526.179821788912
						},
						{
							year: "2023",
							value: 4580.687424303695
						},
						{
							year: "2024",
							value: 4635.163366472989
						},
						{
							year: "2025",
							value: 4689.60764830902
						},
						{
							year: "2026",
							value: 4744.020269787478
						},
						{
							year: "2027",
							value: 4798.401230920359
						},
						{
							year: "2028",
							value: 4852.750531707856
						},
						{
							year: "2029",
							value: 4907.084775966856
						},
						{
							year: "2030",
							value: 4961.421639421724
						},
						{
							year: "2031",
							value: 5015.780372850086
						},
						{
							year: "2032",
							value: 5070.147117492952
						},
						{
							year: "2033",
							value: 5124.507413347947
						},
						{
							year: "2034",
							value: 5178.845728505366
						},
						{
							year: "2035",
							value: 5233.162062965212
						},
						{
							year: "2036",
							value: 5287.456416727481
						},
						{
							year: "2037",
							value: 5341.728789792045
						},
						{
							year: "2038",
							value: 5395.979182159174
						},
						{
							year: "2039",
							value: 5450.63122903805
						},
						{
							year: "2040",
							value: 5506.109238255483
						},
						{
							year: "2041",
							value: 5562.851027686363
						},
						{
							year: "2042",
							value: 5620.422162925622
						},
						{
							year: "2043",
							value: 5678.400353998928
						},
						{
							year: "2044",
							value: 5736.362638314461
						},
						{
							year: "2045",
							value: 5794.309015872219
						},
						{
							year: "2046",
							value: 5852.239486672492
						},
						{
							year: "2047",
							value: 5910.154050714695
						},
						{
							year: "2048",
							value: 5968.052707999121
						},
						{
							year: "2049",
							value: 6025.670073623863
						},
						{
							year: "2050",
							value: 6082.74155857593
						},
						{
							year: "2051",
							value: 6138.995327764504
						},
						{
							year: "2052",
							value: 6194.707219842809
						},
						{
							year: "2053",
							value: 6250.144211490571
						},
						{
							year: "2054",
							value: 6305.572483498606
						},
						{
							year: "2055",
							value: 6360.992035866913
						},
						{
							year: "2056",
							value: 6416.402868595493
						},
						{
							year: "2057",
							value: 6471.804981684342
						},
						{
							year: "2058",
							value: 6527.198375133465
						},
						{
							year: "2059",
							value: 6582.468569460025
						},
						{
							year: "2060",
							value: 6637.501922331083
						},
						{
							year: "2061",
							value: 6692.182159488393
						},
						{
							year: "2062",
							value: 6746.629766307933
						},
						{
							year: "2063",
							value: 6800.960896572366
						},
						{
							year: "2064",
							value: 6855.29086691444
						},
						{
							year: "2065",
							value: 6909.619677334162
						},
						{
							year: "2066",
							value: 6963.947327831529
						},
						{
							year: "2067",
							value: 7018.273818406538
						},
						{
							year: "2068",
							value: 7072.599149059193
						},
						{
							year: "2069",
							value: 7129.830897813282
						},
						{
							year: "2070",
							value: 7192.877589099177
						},
						{
							year: "2071",
							value: 7264.736802178842
						},
						{
							year: "2072",
							value: 7342.415718502261
						},
						{
							year: "2073",
							value: 7423.00865285882
						},
						{
							year: "2074",
							value: 7503.608973631325
						},
						{
							year: "2075",
							value: 7584.216680819774
						},
						{
							year: "2076",
							value: 7664.831774424165
						},
						{
							year: "2077",
							value: 7745.454254444499
						},
						{
							year: "2078",
							value: 7826.084120880779
						},
						{
							year: "2079",
							value: 7905.684392925157
						},
						{
							year: "2080",
							value: 7983.217271811007
						},
						{
							year: "2081",
							value: 8057.612717151967
						},
						{
							year: "2082",
							value: 8129.936654753682
						},
						{
							year: "2083",
							value: 8201.224429506361
						},
						{
							year: "2084",
							value: 8272.51220425904
						},
						{
							year: "2085",
							value: 8343.799979011721
						},
						{
							year: "2086",
							value: 8415.087753764356
						},
						{
							year: "2087",
							value: 8486.375528517037
						},
						{
							year: "2088",
							value: 8557.663303269721
						},
						{
							year: "2089",
							value: 8626.775313864719
						},
						{
							year: "2090",
							value: 8691.535796144337
						},
						{
							year: "2091",
							value: 8749.703053703706
						},
						{
							year: "2092",
							value: 8803.518782947698
						},
						{
							year: "2093",
							value: 8855.158748034008
						},
						{
							year: "2094",
							value: 8906.798713120315
						},
						{
							year: "2095",
							value: 8958.438678206623
						},
						{
							year: "2096",
							value: 9010.078643292929
						},
						{
							year: "2097",
							value: 9061.718608379239
						},
						{
							year: "2098",
							value: 9113.35857346554
						},
						{
							year: "2099",
							value: 9164.998538551856
						},
						{
							year: "2100",
							value: 9216.638503638163
						}
					]
				},
				{
					name: "Rest of the European Union",
					data: [
						{
							year: "2020",
							value: 2729.770648172649
						},
						{
							year: "2021",
							value: 2793.742558414159
						},
						{
							year: "2022",
							value: 2832.538037858547
						},
						{
							year: "2023",
							value: 2866.649554314951
						},
						{
							year: "2024",
							value: 2900.74125734449
						},
						{
							year: "2025",
							value: 2934.813146947155
						},
						{
							year: "2026",
							value: 2968.865223122955
						},
						{
							year: "2027",
							value: 3002.897485871885
						},
						{
							year: "2028",
							value: 3036.909935193946
						},
						{
							year: "2029",
							value: 3070.912961958516
						},
						{
							year: "2030",
							value: 3104.917627847511
						},
						{
							year: "2031",
							value: 3138.935980230247
						},
						{
							year: "2032",
							value: 3172.959346127848
						},
						{
							year: "2033",
							value: 3206.978676296057
						},
						{
							year: "2034",
							value: 3240.98425067786
						},
						{
							year: "2035",
							value: 3274.976069273647
						},
						{
							year: "2036",
							value: 3308.954132083233
						},
						{
							year: "2037",
							value: 3342.918439106599
						},
						{
							year: "2038",
							value: 3376.868990343758
						},
						{
							year: "2039",
							value: 3411.070901828939
						},
						{
							year: "2040",
							value: 3445.789710528528
						},
						{
							year: "2041",
							value: 3481.299408160292
						},
						{
							year: "2042",
							value: 3517.328120422771
						},
						{
							year: "2043",
							value: 3553.61157314605
						},
						{
							year: "2044",
							value: 3589.885071228059
						},
						{
							year: "2045",
							value: 3626.148614668798
						},
						{
							year: "2046",
							value: 3662.402203468259
						},
						{
							year: "2047",
							value: 3698.645837626449
						},
						{
							year: "2048",
							value: 3734.879517143365
						},
						{
							year: "2049",
							value: 3770.937160943909
						},
						{
							year: "2050",
							value: 3806.65318601771
						},
						{
							year: "2051",
							value: 3841.857474683454
						},
						{
							year: "2052",
							value: 3876.722650104227
						},
						{
							year: "2053",
							value: 3911.415789512998
						},
						{
							year: "2054",
							value: 3946.103472065837
						},
						{
							year: "2055",
							value: 3980.785697762745
						},
						{
							year: "2056",
							value: 4015.462466603723
						},
						{
							year: "2057",
							value: 4050.133778588767
						},
						{
							year: "2058",
							value: 4084.799633717881
						},
						{
							year: "2059",
							value: 4119.3883893470569
						},
						{
							year: "2060",
							value: 4153.828926730813
						},
						{
							year: "2061",
							value: 4188.048480032992
						},
						{
							year: "2062",
							value: 4222.122450458124
						},
						{
							year: "2063",
							value: 4256.123528447286
						},
						{
							year: "2064",
							value: 4290.123880543014
						},
						{
							year: "2065",
							value: 4324.123506745284
						},
						{
							year: "2066",
							value: 4358.122407054153
						},
						{
							year: "2067",
							value: 4392.120581469593
						},
						{
							year: "2068",
							value: 4426.118029991607
						},
						{
							year: "2069",
							value: 4461.934350089729
						},
						{
							year: "2070",
							value: 4501.389731506187
						},
						{
							year: "2071",
							value: 4546.360095573145
						},
						{
							year: "2072",
							value: 4594.97250026952
						},
						{
							year: "2073",
							value: 4645.408532671122
						},
						{
							year: "2074",
							value: 4695.849187581098
						},
						{
							year: "2075",
							value: 4746.29446499945
						},
						{
							year: "2076",
							value: 4796.74436492617
						},
						{
							year: "2077",
							value: 4847.198887361266
						},
						{
							year: "2078",
							value: 4897.658032304736
						},
						{
							year: "2079",
							value: 4947.472844633626
						},
						{
							year: "2080",
							value: 4995.993857336485
						},
						{
							year: "2081",
							value: 5042.551426206555
						},
						{
							year: "2082",
							value: 5087.812620496282
						},
						{
							year: "2083",
							value: 5132.425371551568
						},
						{
							year: "2084",
							value: 5177.038122606857
						},
						{
							year: "2085",
							value: 5221.650873662144
						},
						{
							year: "2086",
							value: 5266.263624717434
						},
						{
							year: "2087",
							value: 5310.876375772721
						},
						{
							year: "2088",
							value: 5355.48912682801
						},
						{
							year: "2089",
							value: 5398.740258376189
						},
						{
							year: "2090",
							value: 5439.268150910113
						},
						{
							year: "2091",
							value: 5475.669923725547
						},
						{
							year: "2092",
							value: 5509.348457526759
						},
						{
							year: "2093",
							value: 5541.665371820855
						},
						{
							year: "2094",
							value: 5573.982286114952
						},
						{
							year: "2095",
							value: 5606.299200409048
						},
						{
							year: "2096",
							value: 5638.616114703144
						},
						{
							year: "2097",
							value: 5670.933028997239
						},
						{
							year: "2098",
							value: 5703.249943291333
						},
						{
							year: "2099",
							value: 5735.566857585427
						},
						{
							year: "2100",
							value: 5767.883771879523
						}
					]
				},
				{
					name: "Rest of the World",
					data: [
						{
							year: "2020",
							value: 15616.19790208732
						},
						{
							year: "2021",
							value: 15709.93619365566
						},
						{
							year: "2022",
							value: 15928.09319772107
						},
						{
							year: "2023",
							value: 16119.91106776245
						},
						{
							year: "2024",
							value: 16311.61752178556
						},
						{
							year: "2025",
							value: 16503.21255979043
						},
						{
							year: "2026",
							value: 16694.69618177706
						},
						{
							year: "2027",
							value: 16886.06838774541
						},
						{
							year: "2028",
							value: 17077.32917769553
						},
						{
							year: "2029",
							value: 17268.53698217043
						},
						{
							year: "2030",
							value: 17459.75400386548
						},
						{
							year: "2031",
							value: 17651.04798825087
						},
						{
							year: "2032",
							value: 17842.37016492564
						},
						{
							year: "2033",
							value: 18033.66964765135
						},
						{
							year: "2034",
							value: 18224.89177803727
						},
						{
							year: "2035",
							value: 18416.03655608342
						},
						{
							year: "2036",
							value: 18607.10398178974
						},
						{
							year: "2037",
							value: 18798.09405515631
						},
						{
							year: "2038",
							value: 18989.00677618311
						},
						{
							year: "2039",
							value: 19181.33296082572
						},
						{
							year: "2040",
							value: 19376.56579204977
						},
						{
							year: "2041",
							value: 19576.24599607247
						},
						{
							year: "2042",
							value: 19778.84475345562
						},
						{
							year: "2043",
							value: 19982.87598226428
						},
						{
							year: "2044",
							value: 20186.8512335532
						},
						{
							year: "2045",
							value: 20390.77050732238
						},
						{
							year: "2046",
							value: 20594.6338035718
						},
						{
							year: "2047",
							value: 20798.44112230148
						},
						{
							year: "2048",
							value: 21002.19246351143
						},
						{
							year: "2049",
							value: 21204.95391038495
						},
						{
							year: "2050",
							value: 21405.79434692049
						},
						{
							year: "2051",
							value: 21603.75715742199
						},
						{
							year: "2052",
							value: 21799.81304653416
						},
						{
							year: "2053",
							value: 21994.90153270384
						},
						{
							year: "2054",
							value: 22189.95933356279
						},
						{
							year: "2055",
							value: 22384.98644911101
						},
						{
							year: "2056",
							value: 22579.98287934851
						},
						{
							year: "2057",
							value: 22774.94862427527
						},
						{
							year: "2058",
							value: 22969.88368389132
						},
						{
							year: "2059",
							value: 23164.38519309989
						},
						{
							year: "2060",
							value: 23358.05323282099
						},
						{
							year: "2061",
							value: 23550.47862195821
						},
						{
							year: "2062",
							value: 23742.0853609652
						},
						{
							year: "2063",
							value: 23933.28220697232
						},
						{
							year: "2064",
							value: 24124.47497109314
						},
						{
							year: "2065",
							value: 24315.66365332768
						},
						{
							year: "2066",
							value: 24506.8482536759
						},
						{
							year: "2067",
							value: 24698.02877213784
						},
						{
							year: "2068",
							value: 24889.20520871349
						},
						{
							year: "2069",
							value: 25090.6096300829
						},
						{
							year: "2070",
							value: 25312.47743342845
						},
						{
							year: "2071",
							value: 25565.35740906148
						},
						{
							year: "2072",
							value: 25838.71752010659
						},
						{
							year: "2073",
							value: 26122.33236088823
						},
						{
							year: "2074",
							value: 26405.97319522872
						},
						{
							year: "2075",
							value: 26689.64002312806
						},
						{
							year: "2076",
							value: 26973.33284458624
						},
						{
							year: "2077",
							value: 27257.05165960328
						},
						{
							year: "2078",
							value: 27540.79646817916
						},
						{
							year: "2079",
							value: 27820.91802799433
						},
						{
							year: "2080",
							value: 28093.76421824795
						},
						{
							year: "2081",
							value: 28355.56945655705
						},
						{
							year: "2082",
							value: 28610.0848456712
						},
						{
							year: "2083",
							value: 28860.95387094715
						},
						{
							year: "2084",
							value: 29111.82289622308
						},
						{
							year: "2085",
							value: 29362.69192149904
						},
						{
							year: "2086",
							value: 29613.560946775
						},
						{
							year: "2087",
							value: 29864.42997205095
						},
						{
							year: "2088",
							value: 30115.29899732691
						},
						{
							year: "2089",
							value: 30358.51128432815
						},
						{
							year: "2090",
							value: 30586.4100947799
						},
						{
							year: "2091",
							value: 30791.10666803551
						},
						{
							year: "2092",
							value: 30980.48976474169
						},
						{
							year: "2093",
							value: 31162.21612317314
						},
						{
							year: "2094",
							value: 31343.9424816046
						},
						{
							year: "2095",
							value: 31525.66884003592
						},
						{
							year: "2096",
							value: 31707.39519846737
						},
						{
							year: "2097",
							value: 31889.12155689882
						},
						{
							year: "2098",
							value: 32070.84791533027
						},
						{
							year: "2099",
							value: 32252.57427376171
						},
						{
							year: "2100",
							value: 32434.30063219316
						}
					]
				},
				{
					name: "United Kingdom",
					data: [
						{
							year: "2020",
							value: 405.7548833709021
						},
						{
							year: "2021",
							value: 415.263709688027
						},
						{
							year: "2022",
							value: 421.0302949679344
						},
						{
							year: "2023",
							value: 426.1006529449455
						},
						{
							year: "2024",
							value: 431.1680658413091
						},
						{
							year: "2025",
							value: 436.2325336570252
						},
						{
							year: "2026",
							value: 441.2940563920938
						},
						{
							year: "2027",
							value: 446.3526340465145
						},
						{
							year: "2028",
							value: 451.4082666202878
						},
						{
							year: "2029",
							value: 456.4624986189905
						},
						{
							year: "2030",
							value: 461.5169742582192
						},
						{
							year: "2031",
							value: 466.5734842667679
						},
						{
							year: "2032",
							value: 471.6307394874247
						},
						{
							year: "2033",
							value: 476.6873948346412
						},
						{
							year: "2034",
							value: 481.7420055128547
						},
						{
							year: "2035",
							value: 486.7945715221163
						},
						{
							year: "2036",
							value: 491.845092862325
						},
						{
							year: "2037",
							value: 496.8935695335308
						},
						{
							year: "2038",
							value: 501.9400015357339
						},
						{
							year: "2039",
							value: 507.0237958885759
						},
						{
							year: "2040",
							value: 512.1844221793206
						},
						{
							year: "2041",
							value: 517.4626067149917
						},
						{
							year: "2042",
							value: 522.8179379227086
						},
						{
							year: "2043",
							value: 528.2111339180855
						},
						{
							year: "2044",
							value: 533.6028502491168
						},
						{
							year: "2045",
							value: 538.9930869157962
						},
						{
							year: "2046",
							value: 544.3818439181299
						},
						{
							year: "2047",
							value: 549.7691212561143
						},
						{
							year: "2048",
							value: 555.1549189297504
						},
						{
							year: "2049",
							value: 560.5145505394845
						},
						{
							year: "2050",
							value: 565.8234037202378
						},
						{
							year: "2051",
							value: 571.0561920686926
						},
						{
							year: "2052",
							value: 576.238574404006
						},
						{
							year: "2053",
							value: 581.3953851946802
						},
						{
							year: "2054",
							value: 586.5513848747422
						},
						{
							year: "2055",
							value: 591.7065734441919
						},
						{
							year: "2056",
							value: 596.860950903042
						},
						{
							year: "2057",
							value: 602.0145172512669
						},
						{
							year: "2058",
							value: 607.1672724888791
						},
						{
							year: "2059",
							value: 612.3085676067099
						},
						{
							year: "2060",
							value: 617.4278314682065
						},
						{
							year: "2061",
							value: 622.5142481121879
						},
						{
							year: "2062",
							value: 627.579025222703
						},
						{
							year: "2063",
							value: 632.6329675541582
						},
						{
							year: "2064",
							value: 637.6868019883418
						},
						{
							year: "2065",
							value: 642.7405285252545
						},
						{
							year: "2066",
							value: 647.7941471648966
						},
						{
							year: "2067",
							value: 652.847657907266
						},
						{
							year: "2068",
							value: 657.9010607523657
						},
						{
							year: "2069",
							value: 663.2248218507216
						},
						{
							year: "2070",
							value: 669.0894953886584
						},
						{
							year: "2071",
							value: 675.7739195322533
						},
						{
							year: "2072",
							value: 682.9996989621634
						},
						{
							year: "2073",
							value: 690.4965435994689
						},
						{
							year: "2074",
							value: 697.9940753294081
						},
						{
							year: "2075",
							value: 705.4922941519985
						},
						{
							year: "2076",
							value: 712.9912000672358
						},
						{
							year: "2077",
							value: 720.490793075122
						},
						{
							year: "2078",
							value: 727.9910731756572
						},
						{
							year: "2079",
							value: 735.3955792575753
						},
						{
							year: "2080",
							value: 742.6077741221689
						},
						{
							year: "2081",
							value: 749.5281214193115
						},
						{
							year: "2082",
							value: 756.2557747562443
						},
						{
							year: "2083",
							value: 762.8870430693512
						},
						{
							year: "2084",
							value: 769.5183113824584
						},
						{
							year: "2085",
							value: 776.1495796955657
						},
						{
							year: "2086",
							value: 782.7808480086729
						},
						{
							year: "2087",
							value: 789.4121163217802
						},
						{
							year: "2088",
							value: 796.0433846348874
						},
						{
							year: "2089",
							value: 802.4722609394673
						},
						{
							year: "2090",
							value: 808.4963532269915
						},
						{
							year: "2091",
							value: 813.9071363977663
						},
						{
							year: "2092",
							value: 818.9131355514854
						},
						{
							year: "2093",
							value: 823.7167426966774
						},
						{
							year: "2094",
							value: 828.5203498418694
						},
						{
							year: "2095",
							value: 833.3239569870667
						},
						{
							year: "2096",
							value: 838.1275641322584
						},
						{
							year: "2097",
							value: 842.9311712774503
						},
						{
							year: "2098",
							value: 847.7347784226417
						},
						{
							year: "2099",
							value: 852.5383855678354
						},
						{
							year: "2100",
							value: 857.3419927130269
						}
					]
				},
				{
					name: "United States of America",
					data: [
						{
							year: "2020",
							value: 5981.354372479605
						},
						{
							year: "2021",
							value: 6121.526831764819
						},
						{
							year: "2022",
							value: 6206.533794075921
						},
						{
							year: "2023",
							value: 6281.277461000836
						},
						{
							year: "2024",
							value: 6355.977713609052
						},
						{
							year: "2025",
							value: 6430.634551900579
						},
						{
							year: "2026",
							value: 6505.247975875401
						},
						{
							year: "2027",
							value: 6579.817985533514
						},
						{
							year: "2028",
							value: 6654.344580874938
						},
						{
							year: "2029",
							value: 6728.850529919378
						},
						{
							year: "2030",
							value: 6803.360070541773
						},
						{
							year: "2031",
							value: 6877.899600412262
						},
						{
							year: "2032",
							value: 6952.45011567783
						},
						{
							year: "2033",
							value: 7026.991788029183
						},
						{
							year: "2034",
							value: 7101.503319301816
						},
						{
							year: "2035",
							value: 7175.984709495735
						},
						{
							year: "2036",
							value: 7250.435958610926
						},
						{
							year: "2037",
							value: 7324.857066647404
						},
						{
							year: "2038",
							value: 7399.248033605169
						},
						{
							year: "2039",
							value: 7474.189770174137
						},
						{
							year: "2040",
							value: 7550.264109372414
						},
						{
							year: "2041",
							value: 7628.071409900526
						},
						{
							year: "2042",
							value: 7707.015952648195
						},
						{
							year: "2043",
							value: 7786.518671581885
						},
						{
							year: "2044",
							value: 7865.999578339826
						},
						{
							year: "2045",
							value: 7945.458672922028
						},
						{
							year: "2046",
							value: 8024.895955328479
						},
						{
							year: "2047",
							value: 8104.311425559201
						},
						{
							year: "2048",
							value: 8183.705083614181
						},
						{
							year: "2049",
							value: 8262.713019877157
						},
						{
							year: "2050",
							value: 8340.972416096236
						},
						{
							year: "2051",
							value: 8418.110517819774
						},
						{
							year: "2052",
							value: 8494.505569392944
						},
						{
							year: "2053",
							value: 8570.52366316078
						},
						{
							year: "2054",
							value: 8646.52980010393
						},
						{
							year: "2055",
							value: 8722.523980222388
						},
						{
							year: "2056",
							value: 8798.506203516152
						},
						{
							year: "2057",
							value: 8874.47646998523
						},
						{
							year: "2058",
							value: 8950.43477962962
						},
						{
							year: "2059",
							value: 9026.224152212799
						},
						{
							year: "2060",
							value: 9101.688755441852
						},
						{
							year: "2061",
							value: 9176.669147990617
						},
						{
							year: "2062",
							value: 9251.330545689478
						},
						{
							year: "2063",
							value: 9325.832224662185
						},
						{
							year: "2064",
							value: 9400.332313088871
						},
						{
							year: "2065",
							value: 9474.830810969532
						},
						{
							year: "2066",
							value: 9549.327718304176
						},
						{
							year: "2067",
							value: 9623.823035092802
						},
						{
							year: "2068",
							value: 9698.31676133541
						},
						{
							year: "2069",
							value: 9776.795919636945
						},
						{
							year: "2070",
							value: 9863.248830364413
						},
						{
							year: "2071",
							value: 9961.785930513732
						},
						{
							year: "2072",
							value: 10068.30331122546
						},
						{
							year: "2073",
							value: 10178.81654541877
						},
						{
							year: "2074",
							value: 10289.3399082508
						},
						{
							year: "2075",
							value: 10399.87339972154
						},
						{
							year: "2076",
							value: 10510.417019831
						},
						{
							year: "2077",
							value: 10620.97076857918
						},
						{
							year: "2078",
							value: 10731.53464596608
						},
						{
							year: "2079",
							value: 10840.6866898335
						},
						{
							year: "2080",
							value: 10947.00381639553
						},
						{
							year: "2081",
							value: 11049.01873047605
						},
						{
							year: "2082",
							value: 11148.19308512356
						},
						{
							year: "2083",
							value: 11245.94659924067
						},
						{
							year: "2084",
							value: 11343.70011335778
						},
						{
							year: "2085",
							value: 11441.45362747489
						},
						{
							year: "2086",
							value: 11539.20714159201
						},
						{
							year: "2087",
							value: 11636.96065570912
						},
						{
							year: "2088",
							value: 11734.71416982623
						},
						{
							year: "2089",
							value: 11829.48416267284
						},
						{
							year: "2090",
							value: 11918.28711297834
						},
						{
							year: "2091",
							value: 11998.0490897367
						},
						{
							year: "2092",
							value: 12071.84402395405
						},
						{
							year: "2093",
							value: 12142.65543690086
						},
						{
							year: "2094",
							value: 12213.46684984767
						},
						{
							year: "2095",
							value: 12284.27826279445
						},
						{
							year: "2096",
							value: 12355.08967574126
						},
						{
							year: "2097",
							value: 12425.90108868808
						},
						{
							year: "2098",
							value: 12496.71250163489
						},
						{
							year: "2099",
							value: 12567.5239145817
						},
						{
							year: "2100",
							value: 12638.33532752851
						}
					]
				}
			]
		},
		{
			scenario: "SSP2-6.0",
			groupByRegion: [
				{
					name: "Australia",
					data: [
						{
							year: "2020",
							value: 527.7370476376862
						},
						{
							year: "2021",
							value: 537.6937031600044
						},
						{
							year: "2022",
							value: 543.7104656987984
						},
						{
							year: "2023",
							value: 549.3184778282807
						},
						{
							year: "2024",
							value: 554.9233438910032
						},
						{
							year: "2025",
							value: 560.5250638869652
						},
						{
							year: "2026",
							value: 566.1236378161669
						},
						{
							year: "2027",
							value: 571.7190656786086
						},
						{
							year: "2028",
							value: 577.3113474742906
						},
						{
							year: "2029",
							value: 582.8697160051223
						},
						{
							year: "2030",
							value: 588.3635921412788
						},
						{
							year: "2031",
							value: 593.7616524818673
						},
						{
							year: "2032",
							value: 599.0961664681536
						},
						{
							year: "2033",
							value: 604.3982774347626
						},
						{
							year: "2034",
							value: 609.6989406480511
						},
						{
							year: "2035",
							value: 614.9981561080196
						},
						{
							year: "2036",
							value: 620.2959238146676
						},
						{
							year: "2037",
							value: 625.5922437679964
						},
						{
							year: "2038",
							value: 630.8871159680043
						},
						{
							year: "2039",
							value: 636.0501307879212
						},
						{
							year: "2040",
							value: 640.9511388309379
						},
						{
							year: "2041",
							value: 645.4562991233343
						},
						{
							year: "2042",
							value: 649.7007616743931
						},
						{
							year: "2043",
							value: 653.8154565708094
						},
						{
							year: "2044",
							value: 657.9310536693151
						},
						{
							year: "2045",
							value: 662.0475529699093
						},
						{
							year: "2046",
							value: 666.1649544725927
						},
						{
							year: "2047",
							value: 670.2832581773646
						},
						{
							year: "2048",
							value: 674.4024640842258
						},
						{
							year: "2049",
							value: 678.3676909727371
						},
						{
							year: "2050",
							value: 682.0241975248798
						},
						{
							year: "2051",
							value: 685.2126889547408
						},
						{
							year: "2052",
							value: 688.0931637998048
						},
						{
							year: "2053",
							value: 690.8207830853522
						},
						{
							year: "2054",
							value: 693.5505679342444
						},
						{
							year: "2055",
							value: 696.2825183464793
						},
						{
							year: "2056",
							value: 699.0166343220592
						},
						{
							year: "2057",
							value: 701.7529158609825
						},
						{
							year: "2058",
							value: 704.4913629632498
						},
						{
							year: "2059",
							value: 706.9661113485645
						},
						{
							year: "2060",
							value: 708.9112215708829
						},
						{
							year: "2061",
							value: 710.0526225250674
						},
						{
							year: "2062",
							value: 710.664007209763
						},
						{
							year: "2063",
							value: 711.0110895737684
						},
						{
							year: "2064",
							value: 711.3596587316305
						},
						{
							year: "2065",
							value: 711.7097146833498
						},
						{
							year: "2066",
							value: 712.0612574289257
						},
						{
							year: "2067",
							value: 712.4142869683597
						},
						{
							year: "2068",
							value: 712.7688033016502
						},
						{
							year: "2069",
							value: 713.0777960595335
						},
						{
							year: "2070",
							value: 713.294446630629
						},
						{
							year: "2071",
							value: 713.3707036047971
						},
						{
							year: "2072",
							value: 713.3555829924424
						},
						{
							year: "2073",
							value: 713.296478678596
						},
						{
							year: "2074",
							value: 713.2405927904072
						},
						{
							year: "2075",
							value: 713.1879253278753
						},
						{
							year: "2076",
							value: 713.1384762910002
						},
						{
							year: "2077",
							value: 713.092245679782
						},
						{
							year: "2078",
							value: 713.049233494221
						},
						{
							year: "2079",
							value: 713.432620642538
						},
						{
							year: "2080",
							value: 714.6652316301143
						},
						{
							year: "2081",
							value: 717.1823582233736
						},
						{
							year: "2082",
							value: 720.5469158415992
						},
						{
							year: "2083",
							value: 724.3350107708867
						},
						{
							year: "2084",
							value: 728.1231057001744
						},
						{
							year: "2085",
							value: 731.9112006294619
						},
						{
							year: "2086",
							value: 735.6992955587498
						},
						{
							year: "2087",
							value: 739.4873904880383
						},
						{
							year: "2088",
							value: 743.2754854173263
						},
						{
							year: "2089",
							value: 746.865970955641
						},
						{
							year: "2090",
							value: 750.0612377120074
						},
						{
							year: "2091",
							value: 752.6576881320896
						},
						{
							year: "2092",
							value: 754.858919770224
						},
						{
							year: "2093",
							value: 756.8625420173848
						},
						{
							year: "2094",
							value: 758.8661642645457
						},
						{
							year: "2095",
							value: 760.8697865117059
						},
						{
							year: "2096",
							value: 762.8734087588669
						},
						{
							year: "2097",
							value: 764.8770310060278
						},
						{
							year: "2098",
							value: 766.8806532531887
						},
						{
							year: "2099",
							value: 768.8842755003493
						},
						{
							year: "2100",
							value: 770.8878977475101
						}
					]
				},
				{
					name: "Canada",
					data: [
						{
							year: "2020",
							value: 672.3540219599809
						},
						{
							year: "2021",
							value: 685.0391222683245
						},
						{
							year: "2022",
							value: 692.7046718260918
						},
						{
							year: "2023",
							value: 699.8494601772909
						},
						{
							year: "2024",
							value: 706.9902403379549
						},
						{
							year: "2025",
							value: 714.1270123080838
						},
						{
							year: "2026",
							value: 721.2597760876764
						},
						{
							year: "2027",
							value: 728.3885316767337
						},
						{
							year: "2028",
							value: 735.5132790752559
						},
						{
							year: "2029",
							value: 742.5948198804141
						},
						{
							year: "2030",
							value: 749.5941952944194
						},
						{
							year: "2031",
							value: 756.4714982941305
						},
						{
							year: "2032",
							value: 763.2678411886395
						},
						{
							year: "2033",
							value: 770.0229015908519
						},
						{
							year: "2034",
							value: 776.7761175086339
						},
						{
							year: "2035",
							value: 783.5274889419854
						},
						{
							year: "2036",
							value: 790.2770158909069
						},
						{
							year: "2037",
							value: 797.0246983553989
						},
						{
							year: "2038",
							value: 803.7705363354594
						},
						{
							year: "2039",
							value: 810.348383759947
						},
						{
							year: "2040",
							value: 816.5924260990835
						},
						{
							year: "2041",
							value: 822.332145635027
						},
						{
							year: "2042",
							value: 827.7397278391524
						},
						{
							year: "2043",
							value: 832.9819818653339
						},
						{
							year: "2044",
							value: 838.2253853260788
						},
						{
							year: "2045",
							value: 843.4699382213878
						},
						{
							year: "2046",
							value: 848.7156405512601
						},
						{
							year: "2047",
							value: 853.9624923156971
						},
						{
							year: "2048",
							value: 859.2104935146975
						},
						{
							year: "2049",
							value: 864.2623204774025
						},
						{
							year: "2050",
							value: 868.9208277731498
						},
						{
							year: "2051",
							value: 872.9830687062968
						},
						{
							year: "2052",
							value: 876.6528865746889
						},
						{
							year: "2053",
							value: 880.1279615295805
						},
						{
							year: "2054",
							value: 883.6057954820267
						},
						{
							year: "2055",
							value: 887.0863884320265
						},
						{
							year: "2056",
							value: 890.5697403795826
						},
						{
							year: "2057",
							value: 894.0558513246931
						},
						{
							year: "2058",
							value: 897.5447212673586
						},
						{
							year: "2059",
							value: 900.6976305384692
						},
						{
							year: "2060",
							value: 903.175763705328
						},
						{
							year: "2061",
							value: 904.6299453392514
						},
						{
							year: "2062",
							value: 905.4088691490541
						},
						{
							year: "2063",
							value: 905.8510632766693
						},
						{
							year: "2064",
							value: 906.2951516276177
						},
						{
							year: "2065",
							value: 906.7411342019001
						},
						{
							year: "2066",
							value: 907.1890109995154
						},
						{
							year: "2067",
							value: 907.6387820204653
						},
						{
							year: "2068",
							value: 908.0904472647483
						},
						{
							year: "2069",
							value: 908.4841140055036
						},
						{
							year: "2070",
							value: 908.7601338216001
						},
						{
							year: "2071",
							value: 908.8572876665194
						},
						{
							year: "2072",
							value: 908.8380235186319
						},
						{
							year: "2073",
							value: 908.7627227162577
						},
						{
							year: "2074",
							value: 908.6915222919876
						},
						{
							year: "2075",
							value: 908.6244222458223
						},
						{
							year: "2076",
							value: 908.5614225777603
						},
						{
							year: "2077",
							value: 908.502523287802
						},
						{
							year: "2078",
							value: 908.447724375949
						},
						{
							year: "2079",
							value: 908.9361719698646
						},
						{
							year: "2080",
							value: 910.5065581284991
						},
						{
							year: "2081",
							value: 913.7134586035288
						},
						{
							year: "2082",
							value: 918.000013540038
						},
						{
							year: "2083",
							value: 922.8261686729279
						},
						{
							year: "2084",
							value: 927.652323805818
						},
						{
							year: "2085",
							value: 932.4784789387077
						},
						{
							year: "2086",
							value: 937.3046340715985
						},
						{
							year: "2087",
							value: 942.1307892044893
						},
						{
							year: "2088",
							value: 946.9569443373792
						},
						{
							year: "2089",
							value: 951.5313387318622
						},
						{
							year: "2090",
							value: 955.6022116495295
						},
						{
							year: "2091",
							value: 958.9101732386891
						},
						{
							year: "2092",
							value: 961.7146133510325
						},
						{
							year: "2093",
							value: 964.2672927249699
						},
						{
							year: "2094",
							value: 966.8199720989074
						},
						{
							year: "2095",
							value: 969.3726514728448
						},
						{
							year: "2096",
							value: 971.9253308467819
						},
						{
							year: "2097",
							value: 974.4780102207195
						},
						{
							year: "2098",
							value: 977.0306895946566
						},
						{
							year: "2099",
							value: 979.5833689685935
						},
						{
							year: "2100",
							value: 982.136048342531
						}
					]
				},
				{
					name: "China",
					data: [
						{
							year: "2020",
							value: 13106.41681037749
						},
						{
							year: "2021",
							value: 13070.35755616084
						},
						{
							year: "2022",
							value: 13216.6141279793
						},
						{
							year: "2023",
							value: 13352.93471957419
						},
						{
							year: "2024",
							value: 13489.17883599892
						},
						{
							year: "2025",
							value: 13625.34647725346
						},
						{
							year: "2026",
							value: 13761.43764333783
						},
						{
							year: "2027",
							value: 13897.45233425203
						},
						{
							year: "2028",
							value: 14033.39054999605
						},
						{
							year: "2029",
							value: 14168.50439585275
						},
						{
							year: "2030",
							value: 14302.050548703
						},
						{
							year: "2031",
							value: 14433.26759354949
						},
						{
							year: "2032",
							value: 14562.93994191313
						},
						{
							year: "2033",
							value: 14691.82463170724
						},
						{
							year: "2034",
							value: 14820.67412924702
						},
						{
							year: "2035",
							value: 14949.48843453249
						},
						{
							year: "2036",
							value: 15078.26754756363
						},
						{
							year: "2037",
							value: 15207.01146834046
						},
						{
							year: "2038",
							value: 15335.72019686296
						},
						{
							year: "2039",
							value: 15461.22371190783
						},
						{
							year: "2040",
							value: 15580.35831796957
						},
						{
							year: "2041",
							value: 15689.87058401132
						},
						{
							year: "2042",
							value: 15793.0457613475
						},
						{
							year: "2043",
							value: 15893.06652263707
						},
						{
							year: "2044",
							value: 15993.10921482116
						},
						{
							year: "2045",
							value: 16093.17383789978
						},
						{
							year: "2046",
							value: 16193.26039187293
						},
						{
							year: "2047",
							value: 16293.3688767406
						},
						{
							year: "2048",
							value: 16393.49929250279
						},
						{
							year: "2049",
							value: 16489.88675793071
						},
						{
							year: "2050",
							value: 16578.76979256936
						},
						{
							year: "2051",
							value: 16656.27622942759
						},
						{
							year: "2052",
							value: 16726.29534241933
						},
						{
							year: "2053",
							value: 16792.59881432102
						},
						{
							year: "2054",
							value: 16858.95492713528
						},
						{
							year: "2055",
							value: 16925.36368086209
						},
						{
							year: "2056",
							value: 16991.8250755015
						},
						{
							year: "2057",
							value: 17058.33911105346
						},
						{
							year: "2058",
							value: 17124.905787518
						},
						{
							year: "2059",
							value: 17185.06242700904
						},
						{
							year: "2060",
							value: 17232.34452449768
						},
						{
							year: "2061",
							value: 17260.08990908834
						},
						{
							year: "2062",
							value: 17274.95156059435
						},
						{
							year: "2063",
							value: 17283.38850261603
						},
						{
							year: "2064",
							value: 17291.8615858966
						},
						{
							year: "2065",
							value: 17300.37081043604
						},
						{
							year: "2066",
							value: 17308.91617623436
						},
						{
							year: "2067",
							value: 17317.49768329157
						},
						{
							year: "2068",
							value: 17326.11533160765
						},
						{
							year: "2069",
							value: 17333.62638446928
						},
						{
							year: "2070",
							value: 17338.89276644908
						},
						{
							year: "2071",
							value: 17340.74643502037
						},
						{
							year: "2072",
							value: 17340.37888039069
						},
						{
							year: "2073",
							value: 17338.94216184528
						},
						{
							year: "2074",
							value: 17337.58367738344
						},
						{
							year: "2075",
							value: 17336.30342700516
						},
						{
							year: "2076",
							value: 17335.10141071045
						},
						{
							year: "2077",
							value: 17333.97762849929
						},
						{
							year: "2078",
							value: 17332.93208037171
						},
						{
							year: "2079",
							value: 17342.25152577621
						},
						{
							year: "2080",
							value: 17372.21406065543
						},
						{
							year: "2081",
							value: 17433.40083742938
						},
						{
							year: "2082",
							value: 17515.1871236181
						},
						{
							year: "2083",
							value: 17607.26883276123
						},
						{
							year: "2084",
							value: 17699.35054190435
						},
						{
							year: "2085",
							value: 17791.43225104747
						},
						{
							year: "2086",
							value: 17883.51396019059
						},
						{
							year: "2087",
							value: 17975.59566933374
						},
						{
							year: "2088",
							value: 18067.67737847687
						},
						{
							year: "2089",
							value: 18154.95556215317
						},
						{
							year: "2090",
							value: 18232.6266948958
						},
						{
							year: "2091",
							value: 18295.74168986017
						},
						{
							year: "2092",
							value: 18349.24963389089
						},
						{
							year: "2093",
							value: 18397.95405245478
						},
						{
							year: "2094",
							value: 18446.65847101866
						},
						{
							year: "2095",
							value: 18495.36288958255
						},
						{
							year: "2096",
							value: 18544.06730814644
						},
						{
							year: "2097",
							value: 18592.77172671034
						},
						{
							year: "2098",
							value: 18641.47614527423
						},
						{
							year: "2099",
							value: 18690.18056383811
						},
						{
							year: "2100",
							value: 18738.884982402
						}
					]
				},
				{
					name: "France",
					data: [
						{
							year: "2020",
							value: 399.412668092019
						},
						{
							year: "2021",
							value: 406.9482662942747
						},
						{
							year: "2022",
							value: 411.5019946892268
						},
						{
							year: "2023",
							value: 415.7463642997278
						},
						{
							year: "2024",
							value: 419.9883528401024
						},
						{
							year: "2025",
							value: 424.2279603103508
						},
						{
							year: "2026",
							value: 428.4651867104729
						},
						{
							year: "2027",
							value: 432.7000320404688
						},
						{
							year: "2028",
							value: 436.9324963003387
						},
						{
							year: "2029",
							value: 441.1392936344955
						},
						{
							year: "2030",
							value: 445.2972805249964
						},
						{
							year: "2031",
							value: 449.3827501595563
						},
						{
							year: "2032",
							value: 453.4201253519449
						},
						{
							year: "2033",
							value: 457.4329766330382
						},
						{
							year: "2034",
							value: 461.444732196067
						},
						{
							year: "2035",
							value: 465.4553920410314
						},
						{
							year: "2036",
							value: 469.4649561679311
						},
						{
							year: "2037",
							value: 473.473424576767
						},
						{
							year: "2038",
							value: 477.4807972675381
						},
						{
							year: "2039",
							value: 481.388374978545
						},
						{
							year: "2040",
							value: 485.0976554006116
						},
						{
							year: "2041",
							value: 488.5073422903665
						},
						{
							year: "2042",
							value: 491.7197226220559
						},
						{
							year: "2043",
							value: 494.8338895624269
						},
						{
							year: "2044",
							value: 497.9487393257023
						},
						{
							year: "2045",
							value: 501.0642719118816
						},
						{
							year: "2046",
							value: 504.1804873209652
						},
						{
							year: "2047",
							value: 507.2973855529531
						},
						{
							year: "2048",
							value: 510.4149666078449
						},
						{
							year: "2049",
							value: 513.4160101355258
						},
						{
							year: "2050",
							value: 516.183401669671
						},
						{
							year: "2051",
							value: 518.5965804959252
						},
						{
							year: "2052",
							value: 520.776639956198
						},
						{
							year: "2053",
							value: 522.8410121681859
						},
						{
							year: "2054",
							value: 524.907023365796
						},
						{
							year: "2055",
							value: 526.9746735490271
						},
						{
							year: "2056",
							value: 529.0439627178806
						},
						{
							year: "2057",
							value: 531.1148908723551
						},
						{
							year: "2058",
							value: 533.1874580124521
						},
						{
							year: "2059",
							value: 535.0604473351832
						},
						{
							year: "2060",
							value: 536.5325851490942
						},
						{
							year: "2061",
							value: 537.3964433953831
						},
						{
							year: "2062",
							value: 537.8591639666186
						},
						{
							year: "2063",
							value: 538.1218498888512
						},
						{
							year: "2064",
							value: 538.3856610765998
						},
						{
							year: "2065",
							value: 538.6505975298649
						},
						{
							year: "2066",
							value: 538.9166592486462
						},
						{
							year: "2067",
							value: 539.1838462329441
						},
						{
							year: "2068",
							value: 539.4521584827579
						},
						{
							year: "2069",
							value: 539.686016655876
						},
						{
							year: "2070",
							value: 539.8499865401819
						},
						{
							year: "2071",
							value: 539.9077008917693
						},
						{
							year: "2072",
							value: 539.8962570029028
						},
						{
							year: "2073",
							value: 539.8515244759849
						},
						{
							year: "2074",
							value: 539.809227783323
						},
						{
							year: "2075",
							value: 539.769366924917
						},
						{
							year: "2076",
							value: 539.7319419007666
						},
						{
							year: "2077",
							value: 539.6969527108719
						},
						{
							year: "2078",
							value: 539.6643993552335
						},
						{
							year: "2079",
							value: 539.9545622014974
						},
						{
							year: "2080",
							value: 540.8874518772765
						},
						{
							year: "2081",
							value: 542.7925147358332
						},
						{
							year: "2082",
							value: 545.3389475497889
						},
						{
							year: "2083",
							value: 548.2059304714264
						},
						{
							year: "2084",
							value: 551.0729133930639
						},
						{
							year: "2085",
							value: 553.9398963147019
						},
						{
							year: "2086",
							value: 556.8068792363395
						},
						{
							year: "2087",
							value: 559.6738621579775
						},
						{
							year: "2088",
							value: 562.5408450796151
						},
						{
							year: "2089",
							value: 565.258269249518
						},
						{
							year: "2090",
							value: 567.6765759159513
						},
						{
							year: "2091",
							value: 569.6416742437943
						},
						{
							year: "2092",
							value: 571.3076550681674
						},
						{
							year: "2093",
							value: 572.8240771408064
						},
						{
							year: "2094",
							value: 574.3404992134449
						},
						{
							year: "2095",
							value: 575.8569212860834
						},
						{
							year: "2096",
							value: 577.3733433587225
						},
						{
							year: "2097",
							value: 578.8897654313618
						},
						{
							year: "2098",
							value: 580.4061875040006
						},
						{
							year: "2099",
							value: 581.9226095766393
						},
						{
							year: "2100",
							value: 583.439031649278
						}
					]
				},
				{
					name: "Global",
					data: [
						{
							year: "2020",
							value: 49017.00912423762
						},
						{
							year: "2021",
							value: 49117.36015823385
						},
						{
							year: "2022",
							value: 49666.98067799903
						},
						{
							year: "2023",
							value: 50179.262577371715
						},
						{
							year: "2024",
							value: 50691.257089160674
						},
						{
							year: "2025",
							value: 51202.96421336658
						},
						{
							year: "2026",
							value: 51714.38394998805
						},
						{
							year: "2027",
							value: 52225.51629902581
						},
						{
							year: "2028",
							value: 52736.361260479855
						},
						{
							year: "2029",
							value: 53244.10830571503
						},
						{
							year: "2030",
							value: 53745.96408579662
						},
						{
							year: "2031",
							value: 54239.06726395616
						},
						{
							year: "2032",
							value: 54726.36559606186
						},
						{
							year: "2033",
							value: 55210.70397014985
						},
						{
							year: "2034",
							value: 55694.910094555846
						},
						{
							year: "2035",
							value: 56178.98396927986
						},
						{
							year: "2036",
							value: 56662.925594321874
						},
						{
							year: "2037",
							value: 57146.73496968193
						},
						{
							year: "2038",
							value: 57630.41209535996
						},
						{
							year: "2039",
							value: 58102.04428469344
						},
						{
							year: "2040",
							value: 58549.74262256225
						},
						{
							year: "2041",
							value: 58961.28097488392
						},
						{
							year: "2042",
							value: 59349.00505380342
						},
						{
							year: "2043",
							value: 59724.875089068446
						},
						{
							year: "2044",
							value: 60100.82753888413
						},
						{
							year: "2045",
							value: 60476.862403250474
						},
						{
							year: "2046",
							value: 60852.97968216747
						},
						{
							year: "2047",
							value: 61229.17937563515
						},
						{
							year: "2048",
							value: 61605.46148365349
						},
						{
							year: "2049",
							value: 61967.67788315291
						},
						{
							year: "2050",
							value: 62301.69323090026
						},
						{
							year: "2051",
							value: 62592.95623249562
						},
						{
							year: "2052",
							value: 62856.08246879012
						},
						{
							year: "2053",
							value: 63105.24562252511
						},
						{
							year: "2054",
							value: 63354.60659660668
						},
						{
							year: "2055",
							value: 63604.16539103478
						},
						{
							year: "2056",
							value: 63853.92200580952
						},
						{
							year: "2057",
							value: 64103.87644093079
						},
						{
							year: "2058",
							value: 64354.02869639867
						},
						{
							year: "2059",
							value: 64580.092544697734
						},
						{
							year: "2060",
							value: 64757.7748920557
						},
						{
							year: "2061",
							value: 64862.03983215467
						},
						{
							year: "2062",
							value: 64917.88873195945
						},
						{
							year: "2063",
							value: 64949.5940864712
						},
						{
							year: "2064",
							value: 64981.43525694829
						},
						{
							year: "2065",
							value: 65013.41224339073
						},
						{
							year: "2066",
							value: 65045.525045798495
						},
						{
							year: "2067",
							value: 65077.773664158114
						},
						{
							year: "2068",
							value: 65110.1580984966
						},
						{
							year: "2069",
							value: 65138.38403546761
						},
						{
							year: "2070",
							value: 65158.17467847953
						},
						{
							year: "2071",
							value: 65165.140616973455
						},
						{
							year: "2072",
							value: 65163.75937602069
						},
						{
							year: "2073",
							value: 65158.3603024347
						},
						{
							year: "2074",
							value: 65153.25522628853
						},
						{
							year: "2075",
							value: 65148.4441475822
						},
						{
							year: "2076",
							value: 65143.92706631569
						},
						{
							year: "2077",
							value: 65139.70398246369
						},
						{
							year: "2078",
							value: 65135.77489609024
						},
						{
							year: "2079",
							value: 65170.79662785546
						},
						{
							year: "2080",
							value: 65283.39344172168
						},
						{
							year: "2081",
							value: 65513.32846368365
						},
						{
							year: "2082",
							value: 65820.67479736069
						},
						{
							year: "2083",
							value: 66166.71050851344
						},
						{
							year: "2084",
							value: 66512.7462196662
						},
						{
							year: "2085",
							value: 66858.78193081898
						},
						{
							year: "2086",
							value: 67204.81764197175
						},
						{
							year: "2087",
							value: 67550.85335312458
						},
						{
							year: "2088",
							value: 67896.88906427736
						},
						{
							year: "2089",
							value: 68224.87351023946
						},
						{
							year: "2090",
							value: 68516.75542582013
						},
						{
							year: "2091",
							value: 68753.93653779268
						},
						{
							year: "2092",
							value: 68955.01511938384
						},
						{
							year: "2093",
							value: 69138.04243591539
						},
						{
							year: "2094",
							value: 69321.06975231628
						},
						{
							year: "2095",
							value: 69504.0970687171
						},
						{
							year: "2096",
							value: 69687.12438511795
						},
						{
							year: "2097",
							value: 69870.15170151883
						},
						{
							year: "2098",
							value: 70053.17901791967
						},
						{
							year: "2099",
							value: 70236.20633432045
						},
						{
							year: "2100",
							value: 70419.2336507213
						}
					]
				},
				{
					name: "India",
					data: [
						{
							year: "2020",
							value: 3502.106733367986
						},
						{
							year: "2021",
							value: 3492.471502105357
						},
						{
							year: "2022",
							value: 3531.552063358314
						},
						{
							year: "2023",
							value: 3567.977675989769
						},
						{
							year: "2024",
							value: 3604.382854034693
						},
						{
							year: "2025",
							value: 3640.76759749309
						},
						{
							year: "2026",
							value: 3677.131906364955
						},
						{
							year: "2027",
							value: 3713.475780650293
						},
						{
							year: "2028",
							value: 3749.799220349101
						},
						{
							year: "2029",
							value: 3785.90238387518
						},
						{
							year: "2030",
							value: 3821.586651198493
						},
						{
							year: "2031",
							value: 3856.648568039539
						},
						{
							year: "2032",
							value: 3891.297733475612
						},
						{
							year: "2033",
							value: 3925.736432205123
						},
						{
							year: "2034",
							value: 3960.165727370517
						},
						{
							year: "2035",
							value: 3994.585618971793
						},
						{
							year: "2036",
							value: 4028.996107008953
						},
						{
							year: "2037",
							value: 4063.397191481999
						},
						{
							year: "2038",
							value: 4097.788872390925
						},
						{
							year: "2039",
							value: 4131.324102611284
						},
						{
							year: "2040",
							value: 4163.157525285258
						},
						{
							year: "2041",
							value: 4192.419805726969
						},
						{
							year: "2042",
							value: 4219.988781175651
						},
						{
							year: "2043",
							value: 4246.714879289013
						},
						{
							year: "2044",
							value: 4273.446837458141
						},
						{
							year: "2045",
							value: 4300.184655683035
						},
						{
							year: "2046",
							value: 4326.928333963691
						},
						{
							year: "2047",
							value: 4353.677872300115
						},
						{
							year: "2048",
							value: 4380.433270692305
						},
						{
							year: "2049",
							value: 4406.188532147147
						},
						{
							year: "2050",
							value: 4429.938568377012
						},
						{
							year: "2051",
							value: 4450.648715042395
						},
						{
							year: "2052",
							value: 4469.358207546761
						},
						{
							year: "2053",
							value: 4487.074860294198
						},
						{
							year: "2054",
							value: 4504.805578983302
						},
						{
							year: "2055",
							value: 4522.550363614072
						},
						{
							year: "2056",
							value: 4540.309214186518
						},
						{
							year: "2057",
							value: 4558.082130700631
						},
						{
							year: "2058",
							value: 4575.869113156414
						},
						{
							year: "2059",
							value: 4591.943298440338
						},
						{
							year: "2060",
							value: 4604.577335216251
						},
						{
							year: "2061",
							value: 4611.991054740101
						},
						{
							year: "2062",
							value: 4615.962169848154
						},
						{
							year: "2063",
							value: 4618.216567208674
						},
						{
							year: "2064",
							value: 4620.480621712552
						},
						{
							year: "2065",
							value: 4622.754333359791
						},
						{
							year: "2066",
							value: 4625.03770215039
						},
						{
							year: "2067",
							value: 4627.33072808435
						},
						{
							year: "2068",
							value: 4629.633411161676
						},
						{
							year: "2069",
							value: 4631.640405840843
						},
						{
							year: "2070",
							value: 4633.047612101623
						},
						{
							year: "2071",
							value: 4633.542922549845
						},
						{
							year: "2072",
							value: 4633.444709929103
						},
						{
							year: "2073",
							value: 4633.060810823447
						},
						{
							year: "2074",
							value: 4632.697816295665
						},
						{
							year: "2075",
							value: 4632.355726345757
						},
						{
							year: "2076",
							value: 4632.034540973712
						},
						{
							year: "2077",
							value: 4631.734260179543
						},
						{
							year: "2078",
							value: 4631.454883963249
						},
						{
							year: "2079",
							value: 4633.945091086565
						},
						{
							year: "2080",
							value: 4641.951245374764
						},
						{
							year: "2081",
							value: 4658.300689012444
						},
						{
							year: "2082",
							value: 4680.154434983034
						},
						{
							year: "2083",
							value: 4704.759174651845
						},
						{
							year: "2084",
							value: 4729.363914320644
						},
						{
							year: "2085",
							value: 4753.968653989447
						},
						{
							year: "2086",
							value: 4778.573393658253
						},
						{
							year: "2087",
							value: 4803.178133327062
						},
						{
							year: "2088",
							value: 4827.782872995865
						},
						{
							year: "2089",
							value: 4851.104084212567
						},
						{
							year: "2090",
							value: 4871.85823852505
						},
						{
							year: "2091",
							value: 4888.722912679638
						},
						{
							year: "2092",
							value: 4903.020529930012
						},
						{
							year: "2093",
							value: 4916.034618728281
						},
						{
							year: "2094",
							value: 4929.048707526551
						},
						{
							year: "2095",
							value: 4942.062796324819
						},
						{
							year: "2096",
							value: 4955.07688512309
						},
						{
							year: "2097",
							value: 4968.090973921359
						},
						{
							year: "2098",
							value: 4981.105062719627
						},
						{
							year: "2099",
							value: 4994.119151517893
						},
						{
							year: "2100",
							value: 5007.133240316162
						}
					]
				},
				{
					name: "Netherlands",
					data: [
						{
							year: "2020",
							value: 163.9151815480303
						},
						{
							year: "2021",
							value: 167.0077201138813
						},
						{
							year: "2022",
							value: 168.8765271854623
						},
						{
							year: "2023",
							value: 170.618376336046
						},
						{
							year: "2024",
							value: 172.3592483179669
						},
						{
							year: "2025",
							value: 174.0991431319171
						},
						{
							year: "2026",
							value: 175.8380607765191
						},
						{
							year: "2027",
							value: 177.5760012524583
						},
						{
							year: "2028",
							value: 179.3129645597348
						},
						{
							year: "2029",
							value: 181.0393944034219
						},
						{
							year: "2030",
							value: 182.7457929026166
						},
						{
							year: "2031",
							value: 184.422431005744
						},
						{
							year: "2032",
							value: 186.0793316046171
						},
						{
							year: "2033",
							value: 187.7261678222089
						},
						{
							year: "2034",
							value: 189.3725543674691
						},
						{
							year: "2035",
							value: 191.0184912403972
						},
						{
							year: "2036",
							value: 192.6639784409936
						},
						{
							year: "2037",
							value: 194.3090159692581
						},
						{
							year: "2038",
							value: 195.9536038251907
						},
						{
							year: "2039",
							value: 197.5572367651554
						},
						{
							year: "2040",
							value: 199.0794903729689
						},
						{
							year: "2041",
							value: 200.4787936282759
						},
						{
							year: "2042",
							value: 201.7971241380137
						},
						{
							year: "2043",
							value: 203.075148800725
						},
						{
							year: "2044",
							value: 204.3534536874991
						},
						{
							year: "2045",
							value: 205.6320387983356
						},
						{
							year: "2046",
							value: 206.9109041332349
						},
						{
							year: "2047",
							value: 208.1900496921967
						},
						{
							year: "2048",
							value: 209.4694754752213
						},
						{
							year: "2049",
							value: 210.7010753591342
						},
						{
							year: "2050",
							value: 211.8367866744674
						},
						{
							year: "2051",
							value: 212.8271324441512
						},
						{
							year: "2052",
							value: 213.7218082305668
						},
						{
							year: "2053",
							value: 214.5690070643012
						},
						{
							year: "2054",
							value: 215.4168785222356
						},
						{
							year: "2055",
							value: 216.2654226043695
						},
						{
							year: "2056",
							value: 217.1146393107035
						},
						{
							year: "2057",
							value: 217.9645286412371
						},
						{
							year: "2058",
							value: 218.8150905959706
						},
						{
							year: "2059",
							value: 219.5837477017968
						},
						{
							year: "2060",
							value: 220.1878991391196
						},
						{
							year: "2061",
							value: 220.5424183941831
						},
						{
							year: "2062",
							value: 220.7323145408272
						},
						{
							year: "2063",
							value: 220.8401183591801
						},
						{
							year: "2064",
							value: 220.9483839758595
						},
						{
							year: "2065",
							value: 221.0571113908657
						},
						{
							year: "2066",
							value: 221.1663006041983
						},
						{
							year: "2067",
							value: 221.2759516158577
						},
						{
							year: "2068",
							value: 221.3860644258438
						},
						{
							year: "2069",
							value: 221.4820376085743
						},
						{
							year: "2070",
							value: 221.5493292984858
						},
						{
							year: "2071",
							value: 221.5730147225924
						},
						{
							year: "2072",
							value: 221.5683182588226
						},
						{
							year: "2073",
							value: 221.5499604527957
						},
						{
							year: "2074",
							value: 221.5326022901125
						},
						{
							year: "2075",
							value: 221.5162437707733
						},
						{
							year: "2076",
							value: 221.5008848947778
						},
						{
							year: "2077",
							value: 221.4865256621257
						},
						{
							year: "2078",
							value: 221.4731660728177
						},
						{
							year: "2079",
							value: 221.5922461609532
						},
						{
							year: "2080",
							value: 221.9750952618727
						},
						{
							year: "2081",
							value: 222.7569150434932
						},
						{
							year: "2082",
							value: 223.8019469895921
						},
						{
							year: "2083",
							value: 224.9785296685503
						},
						{
							year: "2084",
							value: 226.1551123475083
						},
						{
							year: "2085",
							value: 227.3316950264665
						},
						{
							year: "2086",
							value: 228.5082777054247
						},
						{
							year: "2087",
							value: 229.684860384383
						},
						{
							year: "2088",
							value: 230.8614430633412
						},
						{
							year: "2089",
							value: 231.9766482449138
						},
						{
							year: "2090",
							value: 232.9690984317148
						},
						{
							year: "2091",
							value: 233.7755562021955
						},
						{
							year: "2092",
							value: 234.4592589779045
						},
						{
							year: "2093",
							value: 235.081584256228
						},
						{
							year: "2094",
							value: 235.7039095345515
						},
						{
							year: "2095",
							value: 236.326234812875
						},
						{
							year: "2096",
							value: 236.9485600911986
						},
						{
							year: "2097",
							value: 237.5708853695222
						},
						{
							year: "2098",
							value: 238.1932106478457
						},
						{
							year: "2099",
							value: 238.8155359261692
						},
						{
							year: "2100",
							value: 239.4378612044927
						}
					]
				},
				{
					name: "Remaining Advanced Economies",
					data: [
						{
							year: "2020",
							value: 1474.438235613005
						},
						{
							year: "2021",
							value: 1470.381662109055
						},
						{
							year: "2022",
							value: 1486.835150870992
						},
						{
							year: "2023",
							value: 1502.17086737207
						},
						{
							year: "2024",
							value: 1517.497980612881
						},
						{
							year: "2025",
							value: 1532.816490593424
						},
						{
							year: "2026",
							value: 1548.126397313702
						},
						{
							year: "2027",
							value: 1563.427700773716
						},
						{
							year: "2028",
							value: 1578.720400973466
						},
						{
							year: "2029",
							value: 1593.920361677759
						},
						{
							year: "2030",
							value: 1608.943960944457
						},
						{
							year: "2031",
							value: 1623.705541541576
						},
						{
							year: "2032",
							value: 1638.293347750951
						},
						{
							year: "2033",
							value: 1652.792544393857
						},
						{
							year: "2034",
							value: 1667.287781998521
						},
						{
							year: "2035",
							value: 1681.779060564943
						},
						{
							year: "2036",
							value: 1696.266380093122
						},
						{
							year: "2037",
							value: 1710.749740583062
						},
						{
							year: "2038",
							value: 1725.229142034758
						},
						{
							year: "2039",
							value: 1739.347965200786
						},
						{
							year: "2040",
							value: 1752.750302460734
						},
						{
							year: "2041",
							value: 1765.070151177338
						},
						{
							year: "2042",
							value: 1776.677093687372
						},
						{
							year: "2043",
							value: 1787.92917249229
						},
						{
							year: "2044",
							value: 1799.183718466526
						},
						{
							year: "2045",
							value: 1810.44073161008
						},
						{
							year: "2046",
							value: 1821.700211922951
						},
						{
							year: "2047",
							value: 1832.962159405143
						},
						{
							year: "2048",
							value: 1844.226574056652
						},
						{
							year: "2049",
							value: 1855.06991640696
						},
						{
							year: "2050",
							value: 1865.069029563866
						},
						{
							year: "2051",
							value: 1873.78830468408
						},
						{
							year: "2052",
							value: 1881.665275095781
						},
						{
							year: "2053",
							value: 1889.124245426127
						},
						{
							year: "2054",
							value: 1896.589137723963
						},
						{
							year: "2055",
							value: 1904.059951989285
						},
						{
							year: "2056",
							value: 1911.536688222097
						},
						{
							year: "2057",
							value: 1919.019346422397
						},
						{
							year: "2058",
							value: 1926.507926590188
						},
						{
							year: "2059",
							value: 1933.275394058591
						},
						{
							year: "2060",
							value: 1938.594508611857
						},
						{
							year: "2061",
							value: 1941.715793131807
						},
						{
							year: "2062",
							value: 1943.387690763466
						},
						{
							year: "2063",
							value: 1944.336825075949
						},
						{
							year: "2064",
							value: 1945.290025187249
						},
						{
							year: "2065",
							value: 1946.247291097367
						},
						{
							year: "2066",
							value: 1947.208622806302
						},
						{
							year: "2067",
							value: 1948.174020314058
						},
						{
							year: "2068",
							value: 1949.14348362063
						},
						{
							year: "2069",
							value: 1949.988457780169
						},
						{
							year: "2070",
							value: 1950.580912229518
						},
						{
							year: "2071",
							value: 1950.789445183811
						},
						{
							year: "2072",
							value: 1950.748096231804
						},
						{
							year: "2073",
							value: 1950.58646908475
						},
						{
							year: "2074",
							value: 1950.433643071202
						},
						{
							year: "2075",
							value: 1950.28961819116
						},
						{
							year: "2076",
							value: 1950.154394444625
						},
						{
							year: "2077",
							value: 1950.027971831594
						},
						{
							year: "2078",
							value: 1949.910350352071
						},
						{
							year: "2079",
							value: 1950.958764028968
						},
						{
							year: "2080",
							value: 1954.329472263032
						},
						{
							year: "2081",
							value: 1961.212827530508
						},
						{
							year: "2082",
							value: 1970.413574710313
						},
						{
							year: "2083",
							value: 1980.772530535197
						},
						{
							year: "2084",
							value: 1991.131486360081
						},
						{
							year: "2085",
							value: 2001.490442184965
						},
						{
							year: "2086",
							value: 2011.84939800985
						},
						{
							year: "2087",
							value: 2022.208353834736
						},
						{
							year: "2088",
							value: 2032.56730965962
						},
						{
							year: "2089",
							value: 2042.38588120431
						},
						{
							year: "2090",
							value: 2051.123684188603
						},
						{
							year: "2091",
							value: 2058.22395905109
						},
						{
							year: "2092",
							value: 2064.243465353181
						},
						{
							year: "2093",
							value: 2069.722587375078
						},
						{
							year: "2094",
							value: 2075.201709396975
						},
						{
							year: "2095",
							value: 2080.680831418872
						},
						{
							year: "2096",
							value: 2086.15995344077
						},
						{
							year: "2097",
							value: 2091.639075462668
						},
						{
							year: "2098",
							value: 2097.118197484564
						},
						{
							year: "2099",
							value: 2102.59731950646
						},
						{
							year: "2100",
							value: 2108.076441528357
						}
					]
				},
				{
					name: "Rest of Asia",
					data: [
						{
							year: "2020",
							value: 4437.550619530948
						},
						{
							year: "2021",
							value: 4425.341732219956
						},
						{
							year: "2022",
							value: 4474.861059300229
						},
						{
							year: "2023",
							value: 4521.016277346383
						},
						{
							year: "2024",
							value: 4567.145602546027
						},
						{
							year: "2025",
							value: 4613.249034899161
						},
						{
							year: "2026",
							value: 4659.32657440578
						},
						{
							year: "2027",
							value: 4705.378221065888
						},
						{
							year: "2028",
							value: 4751.403974879489
						},
						{
							year: "2029",
							value: 4797.150614793599
						},
						{
							year: "2030",
							value: 4842.366467599943
						},
						{
							year: "2031",
							value: 4886.793734569699
						},
						{
							year: "2032",
							value: 4930.698000559589
						},
						{
							year: "2033",
							value: 4974.335582311989
						},
						{
							year: "2034",
							value: 5017.961248724579
						},
						{
							year: "2035",
							value: 5061.574999797362
						},
						{
							year: "2036",
							value: 5105.176835530337
						},
						{
							year: "2037",
							value: 5148.76675592347
						},
						{
							year: "2038",
							value: 5192.344760976828
						},
						{
							year: "2039",
							value: 5234.837549738108
						},
						{
							year: "2040",
							value: 5275.173963006931
						},
						{
							year: "2041",
							value: 5312.252459063542
						},
						{
							year: "2042",
							value: 5347.185353288745
						},
						{
							year: "2043",
							value: 5381.050230138615
						},
						{
							year: "2044",
							value: 5414.922532317315
						},
						{
							year: "2045",
							value: 5448.802259824836
						},
						{
							year: "2046",
							value: 5482.689412661186
						},
						{
							year: "2047",
							value: 5516.583990826373
						},
						{
							year: "2048",
							value: 5550.485994320386
						},
						{
							year: "2049",
							value: 5583.120715397316
						},
						{
							year: "2050",
							value: 5613.214597740063
						},
						{
							year: "2051",
							value: 5639.456608951736
						},
						{
							year: "2052",
							value: 5663.16357346622
						},
						{
							year: "2053",
							value: 5685.612501885877
						},
						{
							year: "2054",
							value: 5708.07925338545
						},
						{
							year: "2055",
							value: 5730.563827964927
						},
						{
							year: "2056",
							value: 5753.066225624324
						},
						{
							year: "2057",
							value: 5775.586446363622
						},
						{
							year: "2058",
							value: 5798.124490182863
						},
						{
							year: "2059",
							value: 5818.492233457359
						},
						{
							year: "2060",
							value: 5834.500933931259
						},
						{
							year: "2061",
							value: 5843.894923940734
						},
						{
							year: "2062",
							value: 5848.926759247198
						},
						{
							year: "2063",
							value: 5851.783326212605
						},
						{
							year: "2064",
							value: 5854.652129830332
						},
						{
							year: "2065",
							value: 5857.533170100382
						},
						{
							year: "2066",
							value: 5860.426447022752
						},
						{
							year: "2067",
							value: 5863.331960583901
						},
						{
							year: "2068",
							value: 5866.249710810918
						},
						{
							year: "2069",
							value: 5868.792791635968
						},
						{
							year: "2070",
							value: 5870.575875216713
						},
						{
							year: "2071",
							value: 5871.203487495182
						},
						{
							year: "2072",
							value: 5871.079041409097
						},
						{
							year: "2073",
							value: 5870.592599434642
						},
						{
							year: "2074",
							value: 5870.132645836911
						},
						{
							year: "2075",
							value: 5869.699180615909
						},
						{
							year: "2076",
							value: 5869.292203771631
						},
						{
							year: "2077",
							value: 5868.911715278788
						},
						{
							year: "2078",
							value: 5868.55771520137
						},
						{
							year: "2079",
							value: 5871.713078816492
						},
						{
							year: "2080",
							value: 5881.857748190209
						},
						{
							year: "2081",
							value: 5902.574273775153
						},
						{
							year: "2082",
							value: 5930.265349848424
						},
						{
							year: "2083",
							value: 5961.442234527547
						},
						{
							year: "2084",
							value: 5992.619119206671
						},
						{
							year: "2085",
							value: 6023.796003885828
						},
						{
							year: "2086",
							value: 6054.972888564956
						},
						{
							year: "2087",
							value: 6086.149773244078
						},
						{
							year: "2088",
							value: 6117.326657923206
						},
						{
							year: "2089",
							value: 6146.877172273994
						},
						{
							year: "2090",
							value: 6173.174945968118
						},
						{
							year: "2091",
							value: 6194.544324727903
						},
						{
							year: "2092",
							value: 6212.660962831011
						},
						{
							year: "2093",
							value: 6229.151230736863
						},
						{
							year: "2094",
							value: 6245.641498512073
						},
						{
							year: "2095",
							value: 6262.1317662872
						},
						{
							year: "2096",
							value: 6278.622034062329
						},
						{
							year: "2097",
							value: 6295.112301837464
						},
						{
							year: "2098",
							value: 6311.602569612598
						},
						{
							year: "2099",
							value: 6328.09283738773
						},
						{
							year: "2100",
							value: 6344.583105162861
						}
					]
				},
				{
					name: "Rest of the European Union",
					data: [
						{
							year: "2020",
							value: 2729.770648172649
						},
						{
							year: "2021",
							value: 2781.272406710261
						},
						{
							year: "2022",
							value: 2812.394689765707
						},
						{
							year: "2023",
							value: 2841.402672006452
						},
						{
							year: "2024",
							value: 2870.394380914222
						},
						{
							year: "2025",
							value: 2899.369816489019
						},
						{
							year: "2026",
							value: 2928.32897873084
						},
						{
							year: "2027",
							value: 2957.271867639688
						},
						{
							year: "2028",
							value: 2986.198483215563
						},
						{
							year: "2029",
							value: 3014.949679166479
						},
						{
							year: "2030",
							value: 3043.367282001655
						},
						{
							year: "2031",
							value: 3071.2892684166
						},
						{
							year: "2032",
							value: 3098.882555200644
						},
						{
							year: "2033",
							value: 3126.308234248176
						},
						{
							year: "2034",
							value: 3153.726424652384
						},
						{
							year: "2035",
							value: 3181.137126413265
						},
						{
							year: "2036",
							value: 3208.540339530821
						},
						{
							year: "2037",
							value: 3235.936064005055
						},
						{
							year: "2038",
							value: 3263.32429983596
						},
						{
							year: "2039",
							value: 3290.030490683425
						},
						{
							year: "2040",
							value: 3315.381426271842
						},
						{
							year: "2041",
							value: 3338.684801288902
						},
						{
							year: "2042",
							value: 3360.639692159295
						},
						{
							year: "2043",
							value: 3381.923347352164
						},
						{
							year: "2044",
							value: 3403.211669272132
						},
						{
							year: "2045",
							value: 3424.504657919199
						},
						{
							year: "2046",
							value: 3445.802313293361
						},
						{
							year: "2047",
							value: 3467.104635394626
						},
						{
							year: "2048",
							value: 3488.41162422299
						},
						{
							year: "2049",
							value: 3508.922141765875
						},
						{
							year: "2050",
							value: 3527.835773669434
						},
						{
							year: "2051",
							value: 3544.328552329082
						},
						{
							year: "2052",
							value: 3559.228085572109
						},
						{
							year: "2053",
							value: 3573.336958728559
						},
						{
							year: "2054",
							value: 3587.457033469736
						},
						{
							year: "2055",
							value: 3601.588309795635
						},
						{
							year: "2056",
							value: 3615.730787706267
						},
						{
							year: "2057",
							value: 3629.884467201623
						},
						{
							year: "2058",
							value: 3644.049348281708
						},
						{
							year: "2059",
							value: 3656.850222380059
						},
						{
							year: "2060",
							value: 3666.911492128151
						},
						{
							year: "2061",
							value: 3672.815498368529
						},
						{
							year: "2062",
							value: 3675.97794446644
						},
						{
							year: "2063",
							value: 3677.773261384214
						},
						{
							year: "2064",
							value: 3679.576268886246
						},
						{
							year: "2065",
							value: 3681.386966972535
						},
						{
							year: "2066",
							value: 3683.20535564308
						},
						{
							year: "2067",
							value: 3685.031434897887
						},
						{
							year: "2068",
							value: 3686.865204736951
						},
						{
							year: "2069",
							value: 3688.463499502769
						},
						{
							year: "2070",
							value: 3689.584145423941
						},
						{
							year: "2071",
							value: 3689.9785919588864
						},
						{
							year: "2072",
							value: 3689.90037913685
						},
						{
							year: "2073",
							value: 3689.594656387537
						},
						{
							year: "2074",
							value: 3689.305581254555
						},
						{
							year: "2075",
							value: 3689.033153737903
						},
						{
							year: "2076",
							value: 3688.77737383758
						},
						{
							year: "2077",
							value: 3688.538241553585
						},
						{
							year: "2078",
							value: 3688.315756885921
						},
						{
							year: "2079",
							value: 3690.298863793133
						},
						{
							year: "2080",
							value: 3696.674662705797
						},
						{
							year: "2081",
							value: 3709.694742161597
						},
						{
							year: "2082",
							value: 3727.09824011845
						},
						{
							year: "2083",
							value: 3746.692525561832
						},
						{
							year: "2084",
							value: 3766.286811005213
						},
						{
							year: "2085",
							value: 3785.881096448598
						},
						{
							year: "2086",
							value: 3805.475381891983
						},
						{
							year: "2087",
							value: 3825.06966733537
						},
						{
							year: "2088",
							value: 3844.663952778753
						},
						{
							year: "2089",
							value: 3863.236084636943
						},
						{
							year: "2090",
							value: 3879.763909324738
						},
						{
							year: "2091",
							value: 3893.194298905881
						},
						{
							year: "2092",
							value: 3904.580381316631
						},
						{
							year: "2093",
							value: 3914.944310142187
						},
						{
							year: "2094",
							value: 3925.308238967744
						},
						{
							year: "2095",
							value: 3935.672167793296
						},
						{
							year: "2096",
							value: 3946.036096618852
						},
						{
							year: "2097",
							value: 3956.400025444409
						},
						{
							year: "2098",
							value: 3966.763954269964
						},
						{
							year: "2099",
							value: 3977.127883095519
						},
						{
							year: "2100",
							value: 3987.491811921074
						}
					]
				},
				{
					name: "Rest of the World",
					data: [
						{
							year: "2020",
							value: 15616.19790208732
						},
						{
							year: "2021",
							value: 15573.23356956258
						},
						{
							year: "2022",
							value: 15747.49718432795
						},
						{
							year: "2023",
							value: 15909.92215274418
						},
						{
							year: "2024",
							value: 16072.25600156538
						},
						{
							year: "2025",
							value: 16234.49873079155
						},
						{
							year: "2026",
							value: 16396.65034042269
						},
						{
							year: "2027",
							value: 16558.71083045881
						},
						{
							year: "2028",
							value: 16720.68020089991
						},
						{
							year: "2029",
							value: 16881.66734076714
						},
						{
							year: "2030",
							value: 17040.78658610661
						},
						{
							year: "2031",
							value: 17197.13071662651
						},
						{
							year: "2032",
							value: 17351.63435280507
						},
						{
							year: "2033",
							value: 17505.19949967116
						},
						{
							year: "2034",
							value: 17658.72271522864
						},
						{
							year: "2035",
							value: 17812.20399947751
						},
						{
							year: "2036",
							value: 17965.64335241776
						},
						{
							year: "2037",
							value: 18119.04077404942
						},
						{
							year: "2038",
							value: 18272.39626437244
						},
						{
							year: "2039",
							value: 18421.93276673642
						},
						{
							year: "2040",
							value: 18563.88076153651
						},
						{
							year: "2041",
							value: 18694.36380995141
						},
						{
							year: "2042",
							value: 18817.29626442585
						},
						{
							year: "2043",
							value: 18936.47025570153
						},
						{
							year: "2044",
							value: 19055.6703774745
						},
						{
							year: "2045",
							value: 19174.89662974478
						},
						{
							year: "2046",
							value: 19294.14901251235
						},
						{
							year: "2047",
							value: 19413.42752577723
						},
						{
							year: "2048",
							value: 19532.73216953941
						},
						{
							year: "2049",
							value: 19647.57711588746
						},
						{
							year: "2050",
							value: 19753.48058890658
						},
						{
							year: "2051",
							value: 19845.82893049948
						},
						{
							year: "2052",
							value: 19929.25618153441
						},
						{
							year: "2053",
							value: 20008.25627391607
						},
						{
							year: "2054",
							value: 20087.31908755254
						},
						{
							year: "2055",
							value: 20166.4446224438
						},
						{
							year: "2056",
							value: 20245.63287858989
						},
						{
							year: "2057",
							value: 20324.88385599078
						},
						{
							year: "2058",
							value: 20404.19755464648
						},
						{
							year: "2059",
							value: 20475.87374204446
						},
						{
							year: "2060",
							value: 20532.21000864509
						},
						{
							year: "2061",
							value: 20565.26842750249
						},
						{
							year: "2062",
							value: 20582.97597445659
						},
						{
							year: "2063",
							value: 20593.0285279657
						},
						{
							year: "2064",
							value: 20603.12414351528
						},
						{
							year: "2065",
							value: 20613.26282110535
						},
						{
							year: "2066",
							value: 20623.44456073586
						},
						{
							year: "2067",
							value: 20633.66936240686
						},
						{
							year: "2068",
							value: 20643.93722611832
						},
						{
							year: "2069",
							value: 20652.8865895971
						},
						{
							year: "2070",
							value: 20659.16144445746
						},
						{
							year: "2071",
							value: 20661.37007673834
						},
						{
							year: "2072",
							value: 20660.93213813773
						},
						{
							year: "2073",
							value: 20659.22029870371
						},
						{
							year: "2074",
							value: 20657.6016745969
						},
						{
							year: "2075",
							value: 20656.07626581732
						},
						{
							year: "2076",
							value: 20654.64407236495
						},
						{
							year: "2077",
							value: 20653.3050942398
						},
						{
							year: "2078",
							value: 20652.05933144187
						},
						{
							year: "2079",
							value: 20663.16338115125
						},
						{
							year: "2080",
							value: 20698.8635180454
						},
						{
							year: "2081",
							value: 20771.76710633479
						},
						{
							year: "2082",
							value: 20869.21485649244
						},
						{
							year: "2083",
							value: 20978.92952633285
						},
						{
							year: "2084",
							value: 21088.64419617328
						},
						{
							year: "2085",
							value: 21198.35886601369
						},
						{
							year: "2086",
							value: 21308.07353585412
						},
						{
							year: "2087",
							value: 21417.78820569456
						},
						{
							year: "2088",
							value: 21527.50287553498
						},
						{
							year: "2089",
							value: 21631.49418059899
						},
						{
							year: "2090",
							value: 21724.03875611012
						},
						{
							year: "2091",
							value: 21799.23980199571
						},
						{
							year: "2092",
							value: 21862.99411832844
						},
						{
							year: "2093",
							value: 21921.02506988474
						},
						{
							year: "2094",
							value: 21979.05602144104
						},
						{
							year: "2095",
							value: 22037.08697299733
						},
						{
							year: "2096",
							value: 22095.11792455364
						},
						{
							year: "2097",
							value: 22153.14887610996
						},
						{
							year: "2098",
							value: 22211.17982766627
						},
						{
							year: "2099",
							value: 22269.21077922255
						},
						{
							year: "2100",
							value: 22327.24173077886
						}
					]
				},
				{
					name: "United Kingdom",
					data: [
						{
							year: "2020",
							value: 405.7548833709021
						},
						{
							year: "2021",
							value: 413.4101382337287
						},
						{
							year: "2022",
							value: 418.0361746151559
						},
						{
							year: "2023",
							value: 422.3479399492866
						},
						{
							year: "2024",
							value: 426.6572864046273
						},
						{
							year: "2025",
							value: 430.9642139811776
						},
						{
							year: "2026",
							value: 435.2687226789377
						},
						{
							year: "2027",
							value: 439.5708124979076
						},
						{
							year: "2028",
							value: 443.8704834380877
						},
						{
							year: "2029",
							value: 448.1440798911991
						},
						{
							year: "2030",
							value: 452.3680908467663
						},
						{
							year: "2031",
							value: 456.5184330555026
						},
						{
							year: "2032",
							value: 460.6199171374616
						},
						{
							year: "2033",
							value: 464.696487896528
						},
						{
							year: "2034",
							value: 468.7719455387836
						},
						{
							year: "2035",
							value: 472.846290064228
						},
						{
							year: "2036",
							value: 476.9195214728612
						},
						{
							year: "2037",
							value: 480.9916397646837
						},
						{
							year: "2038",
							value: 485.0626449396947
						},
						{
							year: "2039",
							value: 489.0322705050688
						},
						{
							year: "2040",
							value: 492.8004500478841
						},
						{
							year: "2041",
							value: 496.2642788565515
						},
						{
							year: "2042",
							value: 499.5276681052052
						},
						{
							year: "2043",
							value: 502.6912844464792
						},
						{
							year: "2044",
							value: 505.8555944531026
						},
						{
							year: "2045",
							value: 509.0205981250742
						},
						{
							year: "2046",
							value: 512.1862954623956
						},
						{
							year: "2047",
							value: 515.3526864650662
						},
						{
							year: "2048",
							value: 518.5197711330857
						},
						{
							year: "2049",
							value: 521.5684677915616
						},
						{
							year: "2050",
							value: 524.379802330706
						},
						{
							year: "2051",
							value: 526.8312996702305
						},
						{
							year: "2052",
							value: 529.0459759754913
						},
						{
							year: "2053",
							value: 531.1431280515902
						},
						{
							year: "2054",
							value: 533.2419451385238
						},
						{
							year: "2055",
							value: 535.3424272362909
						},
						{
							year: "2056",
							value: 537.4445743448933
						},
						{
							year: "2057",
							value: 539.5483864643297
						},
						{
							year: "2058",
							value: 541.6538635946007
						},
						{
							year: "2059",
							value: 543.5565938405642
						},
						{
							year: "2060",
							value: 545.0521075152876
						},
						{
							year: "2061",
							value: 545.9296828401924
						},
						{
							year: "2062",
							value: 546.3997508836318
						},
						{
							year: "2063",
							value: 546.6666079571639
						},
						{
							year: "2064",
							value: 546.9346081641384
						},
						{
							year: "2065",
							value: 547.2037515045557
						},
						{
							year: "2066",
							value: 547.4740379784155
						},
						{
							year: "2067",
							value: 547.7454675857184
						},
						{
							year: "2068",
							value: 548.0180403264639
						},
						{
							year: "2069",
							value: 548.2556118992737
						},
						{
							year: "2070",
							value: 548.4221854373659
						},
						{
							year: "2071",
							value: 548.4808162266925
						},
						{
							year: "2072",
							value: 548.4691906219903
						},
						{
							year: "2073",
							value: 548.4237477938267
						},
						{
							year: "2074",
							value: 548.3807794781747
						},
						{
							year: "2075",
							value: 548.3402856750339
						},
						{
							year: "2076",
							value: 548.3022663844047
						},
						{
							year: "2077",
							value: 548.2667216062865
						},
						{
							year: "2078",
							value: 548.2336513406799
						},
						{
							year: "2079",
							value: 548.5284216402972
						},
						{
							year: "2080",
							value: 549.4761245346542
						},
						{
							year: "2081",
							value: 551.411437607423
						},
						{
							year: "2082",
							value: 553.99830485521
						},
						{
							year: "2083",
							value: 556.9108121789074
						},
						{
							year: "2084",
							value: 559.8233195026041
						},
						{
							year: "2085",
							value: 562.7358268263009
						},
						{
							year: "2086",
							value: 565.6483341499982
						},
						{
							year: "2087",
							value: 568.5608414736955
						},
						{
							year: "2088",
							value: 571.4733487973923
						},
						{
							year: "2089",
							value: 574.2339225478329
						},
						{
							year: "2090",
							value: 576.6906291517597
						},
						{
							year: "2091",
							value: 578.6869309882419
						},
						{
							year: "2092",
							value: 580.3793656782099
						},
						{
							year: "2093",
							value: 581.9198667949219
						},
						{
							year: "2094",
							value: 583.4603679116341
						},
						{
							year: "2095",
							value: 585.0008690283456
						},
						{
							year: "2096",
							value: 586.5413701450577
						},
						{
							year: "2097",
							value: 588.0818712617698
						},
						{
							year: "2098",
							value: 589.6223723784819
						},
						{
							year: "2099",
							value: 591.1628734951939
						},
						{
							year: "2100",
							value: 592.7033746119057
						}
					]
				},
				{
					name: "United States of America",
					data: [
						{
							year: "2020",
							value: 5981.354372479605
						},
						{
							year: "2021",
							value: 6094.20277929559
						},
						{
							year: "2022",
							value: 6162.39656838181
						},
						{
							year: "2023",
							value: 6225.957593748038
						},
						{
							year: "2024",
							value: 6289.482961696894
						},
						{
							year: "2025",
							value: 6352.972672228377
						},
						{
							year: "2026",
							value: 6416.42672534248
						},
						{
							year: "2027",
							value: 6479.845121039208
						},
						{
							year: "2028",
							value: 6543.227859318567
						},
						{
							year: "2029",
							value: 6606.226225767466
						},
						{
							year: "2030",
							value: 6668.493637532388
						},
						{
							year: "2031",
							value: 6729.675076215947
						},
						{
							year: "2032",
							value: 6790.136282606053
						},
						{
							year: "2033",
							value: 6850.230234234918
						},
						{
							year: "2034",
							value: 6910.307777075176
						},
						{
							year: "2035",
							value: 6970.368911126834
						},
						{
							year: "2036",
							value: 7030.41363638989
						},
						{
							year: "2037",
							value: 7090.441952864353
						},
						{
							year: "2038",
							value: 7150.453860550208
						},
						{
							year: "2039",
							value: 7208.971301018947
						},
						{
							year: "2040",
							value: 7264.519165279904
						},
						{
							year: "2041",
							value: 7315.580504130889
						},
						{
							year: "2042",
							value: 7363.687103340183
						},
						{
							year: "2043",
							value: 7410.322920211991
						},
						{
							year: "2044",
							value: 7456.968962612651
						},
						{
							year: "2045",
							value: 7503.625230542173
						},
						{
							year: "2046",
							value: 7550.291724000548
						},
						{
							year: "2047",
							value: 7596.968442987786
						},
						{
							year: "2048",
							value: 7643.655387503879
						},
						{
							year: "2049",
							value: 7688.597138881079
						},
						{
							year: "2050",
							value: 7730.039864101072
						},
						{
							year: "2051",
							value: 7766.178121289918
						},
						{
							year: "2052",
							value: 7798.825328618759
						},
						{
							year: "2053",
							value: 7829.74007605425
						},
						{
							year: "2054",
							value: 7860.679367913591
						},
						{
							year: "2055",
							value: 7891.643204196774
						},
						{
							year: "2056",
							value: 7922.631584903804
						},
						{
							year: "2057",
							value: 7953.644510034674
						},
						{
							year: "2058",
							value: 7984.68197958939
						},
						{
							year: "2059",
							value: 8012.730696543314
						},
						{
							year: "2060",
							value: 8034.776511945693
						},
						{
							year: "2061",
							value: 8047.713112888589
						},
						{
							year: "2062",
							value: 8054.64252683336
						},
						{
							year: "2063",
							value: 8058.576346952388
						},
						{
							year: "2064",
							value: 8062.527018344178
						},
						{
							year: "2065",
							value: 8066.494541008736
						},
						{
							year: "2066",
							value: 8070.478914946053
						},
						{
							year: "2067",
							value: 8074.480140156144
						},
						{
							year: "2068",
							value: 8078.498216638996
						},
						{
							year: "2069",
							value: 8082.000330412709
						},
						{
							year: "2070",
							value: 8084.455840872934
						},
						{
							year: "2071",
							value: 8085.32013491466
						},
						{
							year: "2072",
							value: 8085.148758390633
						},
						{
							year: "2073",
							value: 8084.478872037878
						},
						{
							year: "2074",
							value: 8083.845463215855
						},
						{
							year: "2075",
							value: 8083.248531924579
						},
						{
							year: "2076",
							value: 8082.688078164032
						},
						{
							year: "2077",
							value: 8082.164101934219
						},
						{
							year: "2078",
							value: 8081.676603235147
						},
						{
							year: "2079",
							value: 8086.021900587689
						},
						{
							year: "2080",
							value: 8099.992273054627
						},
						{
							year: "2081",
							value: 8128.521303226127
						},
						{
							year: "2082",
							value: 8166.655088813698
						},
						{
							year: "2083",
							value: 8209.589232380244
						},
						{
							year: "2084",
							value: 8252.523375946785
						},
						{
							year: "2085",
							value: 8295.45751951334
						},
						{
							year: "2086",
							value: 8338.391663079892
						},
						{
							year: "2087",
							value: 8381.325806646448
						},
						{
							year: "2088",
							value: 8424.259950213003
						},
						{
							year: "2089",
							value: 8464.954395429713
						},
						{
							year: "2090",
							value: 8501.169443946736
						},
						{
							year: "2091",
							value: 8530.597527767277
						},
						{
							year: "2092",
							value: 8555.546214888132
						},
						{
							year: "2093",
							value: 8578.25520365915
						},
						{
							year: "2094",
							value: 8600.964192430163
						},
						{
							year: "2095",
							value: 8623.673181201179
						},
						{
							year: "2096",
							value: 8646.382169972198
						},
						{
							year: "2097",
							value: 8669.091158743217
						},
						{
							year: "2098",
							value: 8691.80014751423
						},
						{
							year: "2099",
							value: 8714.509136285245
						},
						{
							year: "2100",
							value: 8737.218125056263
						}
					]
				}
			]
		},
		{
			scenario: "SSP2-4.5",
			groupByRegion: [
				{
					name: "Australia",
					data: [
						{
							year: "2020",
							value: 527.7370476376862
						},
						{
							year: "2021",
							value: 535.6879607761312
						},
						{
							year: "2022",
							value: 539.4560462025534
						},
						{
							year: "2023",
							value: 542.5688607703222
						},
						{
							year: "2024",
							value: 545.67962447804
						},
						{
							year: "2025",
							value: 548.7883373257066
						},
						{
							year: "2026",
							value: 551.894999313322
						},
						{
							year: "2027",
							value: 554.999610440886
						},
						{
							year: "2028",
							value: 558.1021707083997
						},
						{
							year: "2029",
							value: 560.9034381611494
						},
						{
							year: "2030",
							value: 563.1045078870002
						},
						{
							year: "2031",
							value: 564.3977440783704
						},
						{
							year: "2032",
							value: 565.0924779691355
						},
						{
							year: "2033",
							value: 565.4886255991606
						},
						{
							year: "2034",
							value: 565.8857659657342
						},
						{
							year: "2035",
							value: 566.2838990688557
						},
						{
							year: "2036",
							value: 566.6830249085266
						},
						{
							year: "2037",
							value: 567.0831434847455
						},
						{
							year: "2038",
							value: 567.4842547975129
						},
						{
							year: "2039",
							value: 567.7272937290267
						},
						{
							year: "2040",
							value: 567.6533113967068
						},
						{
							year: "2041",
							value: 567.0986549976553
						},
						{
							year: "2042",
							value: 566.2275620324749
						},
						{
							year: "2043",
							value: 565.1993300891838
						},
						{
							year: "2044",
							value: 564.1731405206939
						},
						{
							year: "2045",
							value: 563.148993327005
						},
						{
							year: "2046",
							value: 562.1268885081165
						},
						{
							year: "2047",
							value: 561.1068260640282
						},
						{
							year: "2048",
							value: 560.0888059947407
						},
						{
							year: "2049",
							value: 559.067359049867
						},
						{
							year: "2050",
							value: 558.0370436186845
						},
						{
							year: "2051",
							value: 556.9922799952758
						},
						{
							year: "2052",
							value: 555.9387869214485
						},
						{
							year: "2053",
							value: 554.8820889269194
						},
						{
							year: "2054",
							value: 553.8276829017414
						},
						{
							year: "2055",
							value: 552.7755688459134
						},
						{
							year: "2056",
							value: 551.7257467594368
						},
						{
							year: "2057",
							value: 550.6782166423108
						},
						{
							year: "2058",
							value: 549.6329784945356
						},
						{
							year: "2059",
							value: 548.6535934379346
						},
						{
							year: "2060",
							value: 547.8034665692161
						},
						{
							year: "2061",
							value: 547.1477730545722
						},
						{
							year: "2062",
							value: 546.6205528741946
						},
						{
							year: "2063",
							value: 546.1579328560279
						},
						{
							year: "2064",
							value: 545.6961958531315
						},
						{
							year: "2065",
							value: 545.2353418655054
						},
						{
							year: "2066",
							value: 544.7753708931493
						},
						{
							year: "2067",
							value: 544.3162829360639
						},
						{
							year: "2068",
							value: 543.8580779942492
						},
						{
							year: "2069",
							value: 543.3678878665268
						},
						{
							year: "2070",
							value: 542.8128815463847
						},
						{
							year: "2071",
							value: 542.1592692158813
						},
						{
							year: "2072",
							value: 541.4410277933994
						},
						{
							year: "2073",
							value: 540.6910998694498
						},
						{
							year: "2074",
							value: 539.9423908398761
						},
						{
							year: "2075",
							value: 539.1949007046792
						},
						{
							year: "2076",
							value: 538.4486294638583
						},
						{
							year: "2077",
							value: 537.7035771174146
						},
						{
							year: "2078",
							value: 536.9597436653467
						},
						{
							year: "2079",
							value: 536.2220904872274
						},
						{
							year: "2080",
							value: 535.4954439843941
						},
						{
							year: "2081",
							value: 534.7846459247813
						},
						{
							year: "2082",
							value: 534.0841755590233
						},
						{
							year: "2083",
							value: 533.3888015510738
						},
						{
							year: "2084",
							value: 532.6934275431247
						},
						{
							year: "2085",
							value: 531.9980535351751
						},
						{
							year: "2086",
							value: 531.302679527226
						},
						{
							year: "2087",
							value: 530.6073055192765
						},
						{
							year: "2088",
							value: 529.9119315113269
						},
						{
							year: "2089",
							value: 529.4950346796985
						},
						{
							year: "2090",
							value: 529.6350922007125
						},
						{
							year: "2091",
							value: 530.6190199530032
						},
						{
							year: "2092",
							value: 532.1599020579357
						},
						{
							year: "2093",
							value: 533.9792613391899
						},
						{
							year: "2094",
							value: 535.7986206204435
						},
						{
							year: "2095",
							value: 537.6179799016968
						},
						{
							year: "2096",
							value: 539.4373391829508
						},
						{
							year: "2097",
							value: 541.2566984642041
						},
						{
							year: "2098",
							value: 543.0760577454579
						},
						{
							year: "2099",
							value: 544.895417026711
						},
						{
							year: "2100",
							value: 546.7147763079643
						}
					]
				},
				{
					name: "Canada",
					data: [
						{
							year: "2020",
							value: 672.3540219599809
						},
						{
							year: "2021",
							value: 682.4837417718268
						},
						{
							year: "2022",
							value: 687.2844041526175
						},
						{
							year: "2023",
							value: 691.2502303223439
						},
						{
							year: "2024",
							value: 695.2134436302091
						},
						{
							year: "2025",
							value: 699.1740440762147
						},
						{
							year: "2026",
							value: 703.1320316603601
						},
						{
							year: "2027",
							value: 707.0874063826452
						},
						{
							year: "2028",
							value: 711.0401682430705
						},
						{
							year: "2029",
							value: 714.6090733386534
						},
						{
							year: "2030",
							value: 717.4133071695253
						},
						{
							year: "2031",
							value: 719.0609317933872
						},
						{
							year: "2032",
							value: 719.9460451803263
						},
						{
							year: "2033",
							value: 720.4507500395542
						},
						{
							year: "2034",
							value: 720.956719677168
						},
						{
							year: "2035",
							value: 721.4639540931667
						},
						{
							year: "2036",
							value: 721.9724532875519
						},
						{
							year: "2037",
							value: 722.4822172603214
						},
						{
							year: "2038",
							value: 722.9932460114769
						},
						{
							year: "2039",
							value: 723.3028854499579
						},
						{
							year: "2040",
							value: 723.2086295719809
						},
						{
							year: "2041",
							value: 722.5019794279798
						},
						{
							year: "2042",
							value: 721.3921788914026
						},
						{
							year: "2043",
							value: 720.082178227864
						},
						{
							year: "2044",
							value: 718.7747796157028
						},
						{
							year: "2045",
							value: 717.4699830549191
						},
						{
							year: "2046",
							value: 716.1677885455122
						},
						{
							year: "2047",
							value: 714.8681960874823
						},
						{
							year: "2048",
							value: 713.57120568083
						},
						{
							year: "2049",
							value: 712.2698493241393
						},
						{
							year: "2050",
							value: 710.9571942298196
						},
						{
							year: "2051",
							value: 709.6261316725467
						},
						{
							year: "2052",
							value: 708.2839475138533
						},
						{
							year: "2053",
							value: 706.9376801828053
						},
						{
							year: "2054",
							value: 705.5943328946423
						},
						{
							year: "2055",
							value: 704.2539056493638
						},
						{
							year: "2056",
							value: 702.9163984469709
						},
						{
							year: "2057",
							value: 701.5818112874631
						},
						{
							year: "2058",
							value: 700.2501441708405
						},
						{
							year: "2059",
							value: 699.0023760166039
						},
						{
							year: "2060",
							value: 697.9192869632245
						},
						{
							year: "2061",
							value: 697.0839122747932
						},
						{
							year: "2062",
							value: 696.4122167583953
						},
						{
							year: "2063",
							value: 695.8228239324682
						},
						{
							year: "2064",
							value: 695.2345560964795
						},
						{
							year: "2065",
							value: 694.6474132504286
						},
						{
							year: "2066",
							value: 694.0613953943152
						},
						{
							year: "2067",
							value: 693.4765025281398
						},
						{
							year: "2068",
							value: 692.8927346519026
						},
						{
							year: "2069",
							value: 692.2682166170697
						},
						{
							year: "2070",
							value: 691.5611206623114
						},
						{
							year: "2071",
							value: 690.7283974696082
						},
						{
							year: "2072",
							value: 689.8133347289767
						},
						{
							year: "2073",
							value: 688.8579023633608
						},
						{
							year: "2074",
							value: 687.9040229084977
						},
						{
							year: "2075",
							value: 686.951696364389
						},
						{
							year: "2076",
							value: 686.0009227310333
						},
						{
							year: "2077",
							value: 685.0517020084308
						},
						{
							year: "2078",
							value: 684.1040341965818
						},
						{
							year: "2079",
							value: 683.1642402531019
						},
						{
							year: "2080",
							value: 682.2384691689795
						},
						{
							year: "2081",
							value: 681.3328895127454
						},
						{
							year: "2082",
							value: 680.4404676716235
						},
						{
							year: "2083",
							value: 679.5545387547435
						},
						{
							year: "2084",
							value: 678.6686098378634
						},
						{
							year: "2085",
							value: 677.7826809209835
						},
						{
							year: "2086",
							value: 676.8967520041039
						},
						{
							year: "2087",
							value: 676.0108230872236
						},
						{
							year: "2088",
							value: 675.1248941703432
						},
						{
							year: "2089",
							value: 674.5937541589639
						},
						{
							year: "2090",
							value: 674.7721919585855
						},
						{
							year: "2091",
							value: 676.0257476536648
						},
						{
							year: "2092",
							value: 677.9888811597451
						},
						{
							year: "2093",
							value: 680.3068035713258
						},
						{
							year: "2094",
							value: 682.624725982907
						},
						{
							year: "2095",
							value: 684.9426483944876
						},
						{
							year: "2096",
							value: 687.2605708060688
						},
						{
							year: "2097",
							value: 689.5784932176498
						},
						{
							year: "2098",
							value: 691.8964156292299
						},
						{
							year: "2099",
							value: 694.2143380408109
						},
						{
							year: "2100",
							value: 696.5322604523915
						}
					]
				},
				{
					name: "China",
					data: [
						{
							year: "2020",
							value: 13106.41681037749
						},
						{
							year: "2021",
							value: 12995.84942874508
						},
						{
							year: "2022",
							value: 13087.26359972155
						},
						{
							year: "2023",
							value: 13162.7808268844
						},
						{
							year: "2024",
							value: 13238.2482999546
						},
						{
							year: "2025",
							value: 13313.66601893216
						},
						{
							year: "2026",
							value: 13389.0339838171
						},
						{
							year: "2027",
							value: 13464.35219460939
						},
						{
							year: "2028",
							value: 13539.62065130904
						},
						{
							year: "2029",
							value: 13607.57971085714
						},
						{
							year: "2030",
							value: 13660.97790688506
						},
						{
							year: "2031",
							value: 13692.35196053096
						},
						{
							year: "2032",
							value: 13709.2062818869
						},
						{
							year: "2033",
							value: 13718.8168673925
						},
						{
							year: "2034",
							value: 13728.45153679704
						},
						{
							year: "2035",
							value: 13738.11029010049
						},
						{
							year: "2036",
							value: 13747.79312730288
						},
						{
							year: "2037",
							value: 13757.5000484042
						},
						{
							year: "2038",
							value: 13767.23105340447
						},
						{
							year: "2039",
							value: 13773.12720487798
						},
						{
							year: "2040",
							value: 13771.33238527572
						},
						{
							year: "2041",
							value: 13757.87635942754
						},
						{
							year: "2042",
							value: 13736.7435473376
						},
						{
							year: "2043",
							value: 13711.79852618486
						},
						{
							year: "2044",
							value: 13686.90305327164
						},
						{
							year: "2045",
							value: 13662.05712859793
						},
						{
							year: "2046",
							value: 13637.26075216374
						},
						{
							year: "2047",
							value: 13612.51392396907
						},
						{
							year: "2048",
							value: 13587.8166440139
						},
						{
							year: "2049",
							value: 13563.03622767639
						},
						{
							year: "2050",
							value: 13538.04066087597
						},
						{
							year: "2051",
							value: 13512.69457932736
						},
						{
							year: "2052",
							value: 13487.13672034198
						},
						{
							year: "2053",
							value: 13461.50110962438
						},
						{
							year: "2054",
							value: 13435.92110233781
						},
						{
							year: "2055",
							value: 13410.39669848227
						},
						{
							year: "2056",
							value: 13384.92789805777
						},
						{
							year: "2057",
							value: 13359.51470106428
						},
						{
							year: "2058",
							value: 13334.15710750181
						},
						{
							year: "2059",
							value: 13310.39711724572
						},
						{
							year: "2060",
							value: 13289.77294498471
						},
						{
							year: "2061",
							value: 13273.86574748979
						},
						{
							year: "2062",
							value: 13261.07532735406
						},
						{
							year: "2063",
							value: 13249.85211432887
						},
						{
							year: "2064",
							value: 13238.65032335223
						},
						{
							year: "2065",
							value: 13227.46995442412
						},
						{
							year: "2066",
							value: 13216.31100754455
						},
						{
							year: "2067",
							value: 13205.17348271352
						},
						{
							year: "2068",
							value: 13194.05737993102
						},
						{
							year: "2069",
							value: 13182.16531298572
						},
						{
							year: "2070",
							value: 13168.70079801302
						},
						{
							year: "2071",
							value: 13152.84409027652
						},
						{
							year: "2072",
							value: 13135.41947359008
						},
						{
							year: "2073",
							value: 13117.22613885854
						},
						{
							year: "2074",
							value: 13099.06237463994
						},
						{
							year: "2075",
							value: 13080.9281809343
						},
						{
							year: "2076",
							value: 13062.82355774163
						},
						{
							year: "2077",
							value: 13044.74850506189
						},
						{
							year: "2078",
							value: 13026.70302289512
						},
						{
							year: "2079",
							value: 13008.80747486081
						},
						{
							year: "2080",
							value: 12991.17894999147
						},
						{
							year: "2081",
							value: 12973.93491011501
						},
						{
							year: "2082",
							value: 12956.941421238569
						},
						{
							year: "2083",
							value: 12940.07157056864
						},
						{
							year: "2084",
							value: 12923.20171989871
						},
						{
							year: "2085",
							value: 12906.33186922878
						},
						{
							year: "2086",
							value: 12889.46201855886
						},
						{
							year: "2087",
							value: 12872.59216788893
						},
						{
							year: "2088",
							value: 12855.722317219
						},
						{
							year: "2089",
							value: 12845.60835377784
						},
						{
							year: "2090",
							value: 12849.0061647942
						},
						{
							year: "2091",
							value: 12872.87636135229
						},
						{
							year: "2092",
							value: 12910.25833236791
						},
						{
							year: "2093",
							value: 12954.39619061229
						},
						{
							year: "2094",
							value: 12998.53404885667
						},
						{
							year: "2095",
							value: 13042.67190710105
						},
						{
							year: "2096",
							value: 13086.80976534542
						},
						{
							year: "2097",
							value: 13130.9476235898
						},
						{
							year: "2098",
							value: 13175.08548183417
						},
						{
							year: "2099",
							value: 13219.22334007855
						},
						{
							year: "2100",
							value: 13263.36119832292
						}
					]
				},
				{
					name: "France",
					data: [
						{
							year: "2020",
							value: 399.412668092019
						},
						{
							year: "2021",
							value: 405.43023961672
						},
						{
							year: "2022",
							value: 408.2820785401058
						},
						{
							year: "2023",
							value: 410.6379820669734
						},
						{
							year: "2024",
							value: 412.9923334202942
						},
						{
							year: "2025",
							value: 415.3451326000683
						},
						{
							year: "2026",
							value: 417.6963796062961
						},
						{
							year: "2027",
							value: 420.046074438977
						},
						{
							year: "2028",
							value: 422.3942170981117
						},
						{
							year: "2029",
							value: 424.5143292114275
						},
						{
							year: "2030",
							value: 426.1801874940587
						},
						{
							year: "2031",
							value: 427.1589607675677
						},
						{
							year: "2032",
							value: 427.6847633773459
						},
						{
							year: "2033",
							value: 427.9845838704776
						},
						{
							year: "2034",
							value: 428.2851557066416
						},
						{
							year: "2035",
							value: 428.5864788858374
						},
						{
							year: "2036",
							value: 428.8885534080658
						},
						{
							year: "2037",
							value: 429.1913792733257
						},
						{
							year: "2038",
							value: 429.4949564816181
						},
						{
							year: "2039",
							value: 429.678898140671
						},
						{
							year: "2040",
							value: 429.6229053296271
						},
						{
							year: "2041",
							value: 429.2031190114008
						},
						{
							year: "2042",
							value: 428.5438407459536
						},
						{
							year: "2043",
							value: 427.7656333683863
						},
						{
							year: "2044",
							value: 426.9889717423866
						},
						{
							year: "2045",
							value: 426.213855867954
						},
						{
							year: "2046",
							value: 425.4402857450882
						},
						{
							year: "2047",
							value: 424.6682613737892
						},
						{
							year: "2048",
							value: 423.8977827540576
						},
						{
							year: "2049",
							value: 423.1247105367619
						},
						{
							year: "2050",
							value: 422.3449262915832
						},
						{
							year: "2051",
							value: 421.5542070721934
						},
						{
							year: "2052",
							value: 420.7568810528866
						},
						{
							year: "2053",
							value: 419.9571294204183
						},
						{
							year: "2054",
							value: 419.1591124427325
						},
						{
							year: "2055",
							value: 418.362830119829
						},
						{
							year: "2056",
							value: 417.5682824517081
						},
						{
							year: "2057",
							value: 416.7754694383697
						},
						{
							year: "2058",
							value: 415.9843910798136
						},
						{
							year: "2059",
							value: 415.243152994867
						},
						{
							year: "2060",
							value: 414.5997427162606
						},
						{
							year: "2061",
							value: 414.1034874366532
						},
						{
							year: "2062",
							value: 413.704465954536
						},
						{
							year: "2063",
							value: 413.3543364788893
						},
						{
							year: "2064",
							value: 413.0048753047893
						},
						{
							year: "2065",
							value: 412.6560824322358
						},
						{
							year: "2066",
							value: 412.3079578612287
						},
						{
							year: "2067",
							value: 411.960501591768
						},
						{
							year: "2068",
							value: 411.6137136238539
						},
						{
							year: "2069",
							value: 411.2427179780825
						},
						{
							year: "2070",
							value: 410.8226668254713
						},
						{
							year: "2071",
							value: 410.3279866699036
						},
						{
							year: "2072",
							value: 409.7843926126513
						},
						{
							year: "2073",
							value: 409.2168169339639
						},
						{
							year: "2074",
							value: 408.6501637636681
						},
						{
							year: "2075",
							value: 408.0844331017639
						},
						{
							year: "2076",
							value: 407.519624948251
						},
						{
							year: "2077",
							value: 406.9557393031296
						},
						{
							year: "2078",
							value: 406.3927761663999
						},
						{
							year: "2079",
							value: 405.8344905100907
						},
						{
							year: "2080",
							value: 405.284535149262
						},
						{
							year: "2081",
							value: 404.7465745290339
						},
						{
							year: "2082",
							value: 404.2164303237728
						},
						{
							year: "2083",
							value: 403.6901432475097
						},
						{
							year: "2084",
							value: 403.163856171247
						},
						{
							year: "2085",
							value: 402.6375690949842
						},
						{
							year: "2086",
							value: 402.1112820187215
						},
						{
							year: "2087",
							value: 401.5849949424585
						},
						{
							year: "2088",
							value: 401.0587078661955
						},
						{
							year: "2089",
							value: 400.7431835409939
						},
						{
							year: "2090",
							value: 400.8491847179154
						},
						{
							year: "2091",
							value: 401.5938608980532
						},
						{
							year: "2092",
							value: 402.7600625803138
						},
						{
							year: "2093",
							value: 404.1370270136356
						},
						{
							year: "2094",
							value: 405.5139914469574
						},
						{
							year: "2095",
							value: 406.8909558802791
						},
						{
							year: "2096",
							value: 408.2679203136007
						},
						{
							year: "2097",
							value: 409.6448847469225
						},
						{
							year: "2098",
							value: 411.0218491802441
						},
						{
							year: "2099",
							value: 412.3988136135625
						},
						{
							year: "2100",
							value: 413.7757780468841
						}
					]
				},
				{
					name: "Global",
					data: [
						{
							year: "2020",
							value: 49017.00912423762
						},
						{
							year: "2021",
							value: 48859.20617119482
						},
						{
							year: "2022",
							value: 49202.88696336668
						},
						{
							year: "2023",
							value: 49486.801592545475
						},
						{
							year: "2024",
							value: 49770.52916619677
						},
						{
							year: "2025",
							value: 50054.06968432064
						},
						{
							year: "2026",
							value: 50337.42314691701
						},
						{
							year: "2027",
							value: 50620.589553985876
						},
						{
							year: "2028",
							value: 50903.56890552727
						},
						{
							year: "2029",
							value: 51159.06784154264
						},
						{
							year: "2030",
							value: 51359.82374312512
						},
						{
							year: "2031",
							value: 51477.77766094447
						},
						{
							year: "2032",
							value: 51541.143181337764
						},
						{
							year: "2033",
							value: 51577.275146486994
						},
						{
							year: "2034",
							value: 51613.497657482396
						},
						{
							year: "2035",
							value: 51649.8107143182
						},
						{
							year: "2036",
							value: 51686.214317005964
						},
						{
							year: "2037",
							value: 51722.708465539865
						},
						{
							year: "2038",
							value: 51759.293159919995
						},
						{
							year: "2039",
							value: 51781.4603358357
						},
						{
							year: "2040",
							value: 51774.71253058708
						},
						{
							year: "2041",
							value: 51724.12324477195
						},
						{
							year: "2042",
							value: 51644.67230710605
						},
						{
							year: "2043",
							value: 51550.888985120895
						},
						{
							year: "2044",
							value: 51457.291944737706
						},
						{
							year: "2045",
							value: 51363.881185956445
						},
						{
							year: "2046",
							value: 51270.65670877708
						},
						{
							year: "2047",
							value: 51177.618513199646
						},
						{
							year: "2048",
							value: 51084.76659922411
						},
						{
							year: "2049",
							value: 50991.60212563731
						},
						{
							year: "2050",
							value: 50897.628772193726
						},
						{
							year: "2051",
							value: 50802.33762321531
						},
						{
							year: "2052",
							value: 50706.25027561194
						},
						{
							year: "2053",
							value: 50609.870612532395
						},
						{
							year: "2054",
							value: 50513.69999615776
						},
						{
							year: "2055",
							value: 50417.738426487995
						},
						{
							year: "2056",
							value: 50321.98590354019
						},
						{
							year: "2057",
							value: 50226.44242728017
						},
						{
							year: "2058",
							value: 50131.107997725034
						},
						{
							year: "2059",
							value: 50041.779918870845
						},
						{
							year: "2060",
							value: 49964.2412639178
						},
						{
							year: "2061",
							value: 49904.4365511692
						},
						{
							year: "2062",
							value: 49856.34967713648
						},
						{
							year: "2063",
							value: 49814.154876241984
						},
						{
							year: "2064",
							value: 49772.04061369877
						},
						{
							year: "2065",
							value: 49730.00688950678
						},
						{
							year: "2066",
							value: 49688.05370366605
						},
						{
							year: "2067",
							value: 49646.18105617655
						},
						{
							year: "2068",
							value: 49604.388947038315
						},
						{
							year: "2069",
							value: 49559.67952239726
						},
						{
							year: "2070",
							value: 49509.0583208671
						},
						{
							year: "2071",
							value: 49449.44342945563
						},
						{
							year: "2072",
							value: 49383.93382627003
						},
						{
							year: "2073",
							value: 49315.53415009155
						},
						{
							year: "2074",
							value: 49247.24564723783
						},
						{
							year: "2075",
							value: 49179.0683177089
						},
						{
							year: "2076",
							value: 49111.002161504715
						},
						{
							year: "2077",
							value: 49043.047178625246
						},
						{
							year: "2078",
							value: 48975.20336907057
						},
						{
							year: "2079",
							value: 48907.92325200328
						},
						{
							year: "2080",
							value: 48841.64703544603
						},
						{
							year: "2081",
							value: 48776.8163289833
						},
						{
							year: "2082",
							value: 48712.92759426581
						},
						{
							year: "2083",
							value: 48649.503689850884
						},
						{
							year: "2084",
							value: 48586.07978543607
						},
						{
							year: "2085",
							value: 48522.65588102114
						},
						{
							year: "2086",
							value: 48459.23197660624
						},
						{
							year: "2087",
							value: 48395.80807219145
						},
						{
							year: "2088",
							value: 48332.384167776516
						},
						{
							year: "2089",
							value: 48294.35970252789
						},
						{
							year: "2090",
							value: 48307.13411561182
						},
						{
							year: "2091",
							value: 48396.87652616934
						},
						{
							year: "2092",
							value: 48537.417815059474
						},
						{
							year: "2093",
							value: 48703.358543115864
						},
						{
							year: "2094",
							value: 48869.299271172255
						},
						{
							year: "2095",
							value: 49035.23999922876
						},
						{
							year: "2096",
							value: 49201.18072728515
						},
						{
							year: "2097",
							value: 49367.12145534155
						},
						{
							year: "2098",
							value: 49533.06218339792
						},
						{
							year: "2099",
							value: 49699.00291145429
						},
						{
							year: "2100",
							value: 49864.943639510646
						}
					]
				},
				{
					name: "India",
					data: [
						{
							year: "2020",
							value: 3502.106733367986
						},
						{
							year: "2021",
							value: 3472.562520231168
						},
						{
							year: "2022",
							value: 3496.988890026495
						},
						{
							year: "2023",
							value: 3517.167508909029
						},
						{
							year: "2024",
							value: 3537.332833224075
						},
						{
							year: "2025",
							value: 3557.484862971629
						},
						{
							year: "2026",
							value: 3577.623598151696
						},
						{
							year: "2027",
							value: 3597.749038764275
						},
						{
							year: "2028",
							value: 3617.861184809361
						},
						{
							year: "2029",
							value: 3636.02021969129
						},
						{
							year: "2030",
							value: 3650.288511671065
						},
						{
							year: "2031",
							value: 3658.671831545293
						},
						{
							year: "2032",
							value: 3663.175399008541
						},
						{
							year: "2033",
							value: 3665.743400369839
						},
						{
							year: "2034",
							value: 3668.317837081532
						},
						{
							year: "2035",
							value: 3670.898709137864
						},
						{
							year: "2036",
							value: 3673.486016550343
						},
						{
							year: "2037",
							value: 3676.07975931322
						},
						{
							year: "2038",
							value: 3678.679937426493
						},
						{
							year: "2039",
							value: 3680.255421563393
						},
						{
							year: "2040",
							value: 3679.775835883705
						},
						{
							year: "2041",
							value: 3676.180311690555
						},
						{
							year: "2042",
							value: 3670.533507946572
						},
						{
							year: "2043",
							value: 3663.868060951653
						},
						{
							year: "2044",
							value: 3657.215853519137
						},
						{
							year: "2045",
							value: 3650.576885649026
						},
						{
							year: "2046",
							value: 3643.951157341314
						},
						{
							year: "2047",
							value: 3637.338668596005
						},
						{
							year: "2048",
							value: 3630.7394194131
						},
						{
							year: "2049",
							value: 3624.117955731096
						},
						{
							year: "2050",
							value: 3617.439002660823
						},
						{
							year: "2051",
							value: 3610.666390119956
						},
						{
							year: "2052",
							value: 3603.837189481978
						},
						{
							year: "2053",
							value: 3596.987213153066
						},
						{
							year: "2054",
							value: 3590.152094367068
						},
						{
							year: "2055",
							value: 3583.331833123982
						},
						{
							year: "2056",
							value: 3576.526429423809
						},
						{
							year: "2057",
							value: 3569.735883266549
						},
						{
							year: "2058",
							value: 3562.960194652203
						},
						{
							year: "2059",
							value: 3556.61139443744
						},
						{
							year: "2060",
							value: 3551.100502056227
						},
						{
							year: "2061",
							value: 3546.850011303347
						},
						{
							year: "2062",
							value: 3543.432340621271
						},
						{
							year: "2063",
							value: 3540.433436307902
						},
						{
							year: "2064",
							value: 3537.440256083849
						},
						{
							year: "2065",
							value: 3534.452799949111
						},
						{
							year: "2066",
							value: 3531.471067903687
						},
						{
							year: "2067",
							value: 3528.495059947579
						},
						{
							year: "2068",
							value: 3525.524776080786
						},
						{
							year: "2069",
							value: 3522.347150317372
						},
						{
							year: "2070",
							value: 3518.749357783411
						},
						{
							year: "2071",
							value: 3514.512358171967
						},
						{
							year: "2072",
							value: 3509.856404656472
						},
						{
							year: "2073",
							value: 3504.995045446894
						},
						{
							year: "2074",
							value: 3500.14158764119
						},
						{
							year: "2075",
							value: 3495.296031239358
						},
						{
							year: "2076",
							value: 3490.458376241397
						},
						{
							year: "2077",
							value: 3485.628622647305
						},
						{
							year: "2078",
							value: 3480.806770457088
						},
						{
							year: "2079",
							value: 3476.024981492576
						},
						{
							year: "2080",
							value: 3471.314542587917
						},
						{
							year: "2081",
							value: 3466.706840190269
						},
						{
							year: "2082",
							value: 3462.166086399318
						},
						{
							year: "2083",
							value: 3457.658369417878
						},
						{
							year: "2084",
							value: 3453.150652436439
						},
						{
							year: "2085",
							value: 3448.642935454999
						},
						{
							year: "2086",
							value: 3444.135218473561
						},
						{
							year: "2087",
							value: 3439.627501492118
						},
						{
							year: "2088",
							value: 3435.119784510677
						},
						{
							year: "2089",
							value: 3432.41727779349
						},
						{
							year: "2090",
							value: 3433.325191604813
						},
						{
							year: "2091",
							value: 3439.703439550247
						},
						{
							year: "2092",
							value: 3449.69210802419
						},
						{
							year: "2093",
							value: 3461.485986762385
						},
						{
							year: "2094",
							value: 3473.279865500577
						},
						{
							year: "2095",
							value: 3485.073744238772
						},
						{
							year: "2096",
							value: 3496.867622976967
						},
						{
							year: "2097",
							value: 3508.661501715162
						},
						{
							year: "2098",
							value: 3520.455380453356
						},
						{
							year: "2099",
							value: 3532.249259191551
						},
						{
							year: "2100",
							value: 3544.043137929742
						}
					]
				},
				{
					name: "Netherlands",
					data: [
						{
							year: "2020",
							value: 163.9151815480303
						},
						{
							year: "2021",
							value: 166.3847363208819
						},
						{
							year: "2022",
							value: 167.55510403914
						},
						{
							year: "2023",
							value: 168.5219445675364
						},
						{
							year: "2024",
							value: 169.4881480985875
						},
						{
							year: "2025",
							value: 170.4537146323447
						},
						{
							year: "2026",
							value: 171.418644168706
						},
						{
							year: "2027",
							value: 172.382936707722
						},
						{
							year: "2028",
							value: 173.346592249393
						},
						{
							year: "2029",
							value: 174.2166662114723
						},
						{
							year: "2030",
							value: 174.9003186971726
						},
						{
							year: "2031",
							value: 175.3019979926734
						},
						{
							year: "2032",
							value: 175.5177824113784
						},
						{
							year: "2033",
							value: 175.6408259064527
						},
						{
							year: "2034",
							value: 175.7641777456022
						},
						{
							year: "2035",
							value: 175.8878379288268
						},
						{
							year: "2036",
							value: 176.0118064561266
						},
						{
							year: "2037",
							value: 176.1360833275016
						},
						{
							year: "2038",
							value: 176.2606685429516
						},
						{
							year: "2039",
							value: 176.3361564603492
						},
						{
							year: "2040",
							value: 176.3131775402031
						},
						{
							year: "2041",
							value: 176.1409012049886
						},
						{
							year: "2042",
							value: 175.8703396394338
						},
						{
							year: "2043",
							value: 175.5509706909397
						},
						{
							year: "2044",
							value: 175.2322361042712
						},
						{
							year: "2045",
							value: 174.9141358794282
						},
						{
							year: "2046",
							value: 174.5966700164105
						},
						{
							year: "2047",
							value: 174.2798385152181
						},
						{
							year: "2048",
							value: 173.9636413758512
						},
						{
							year: "2049",
							value: 173.646379848571
						},
						{
							year: "2050",
							value: 173.3263637685211
						},
						{
							year: "2051",
							value: 173.0018600784637
						},
						{
							year: "2052",
							value: 172.6746450201984
						},
						{
							year: "2053",
							value: 172.34643451323
						},
						{
							year: "2054",
							value: 172.0189358921805
						},
						{
							year: "2055",
							value: 171.6921491570493
						},
						{
							year: "2056",
							value: 171.3660743078369
						},
						{
							year: "2057",
							value: 171.0407113445432
						},
						{
							year: "2058",
							value: 170.7160602671681
						},
						{
							year: "2059",
							value: 170.4118631667586
						},
						{
							year: "2060",
							value: 170.1478136729447
						},
						{
							year: "2061",
							value: 169.944155199122
						},
						{
							year: "2062",
							value: 169.7804005562803
						},
						{
							year: "2063",
							value: 169.6367107305379
						},
						{
							year: "2064",
							value: 169.4932951694304
						},
						{
							year: "2065",
							value: 169.3501538729575
						},
						{
							year: "2066",
							value: 169.2072868411194
						},
						{
							year: "2067",
							value: 169.0646940739161
						},
						{
							year: "2068",
							value: 168.9223755713477
						},
						{
							year: "2069",
							value: 168.7701224667108
						},
						{
							year: "2070",
							value: 168.5977374459692
						},
						{
							year: "2071",
							value: 168.3947253881571
						},
						{
							year: "2072",
							value: 168.1716395276567
						},
						{
							year: "2073",
							value: 167.9387118365059
						},
						{
							year: "2074",
							value: 167.7061627340751
						},
						{
							year: "2075",
							value: 167.4739922203645
						},
						{
							year: "2076",
							value: 167.2422002953738
						},
						{
							year: "2077",
							value: 167.0107869591031
						},
						{
							year: "2078",
							value: 166.7797522115523
						},
						{
							year: "2079",
							value: 166.5506370577328
						},
						{
							year: "2080",
							value: 166.3249405784014
						},
						{
							year: "2081",
							value: 166.1041666271822
						},
						{
							year: "2082",
							value: 165.886600458748
						},
						{
							year: "2083",
							value: 165.670617219579
						},
						{
							year: "2084",
							value: 165.4546339804102
						},
						{
							year: "2085",
							value: 165.2386507412413
						},
						{
							year: "2086",
							value: 165.0226675020724
						},
						{
							year: "2087",
							value: 164.8066842629035
						},
						{
							year: "2088",
							value: 164.5907010237345
						},
						{
							year: "2089",
							value: 164.4612128244849
						},
						{
							year: "2090",
							value: 164.5047147050741
						},
						{
							year: "2091",
							value: 164.8103227672371
						},
						{
							year: "2092",
							value: 165.2889209092386
						},
						{
							year: "2093",
							value: 165.8540140911595
						},
						{
							year: "2094",
							value: 166.4191072730804
						},
						{
							year: "2095",
							value: 166.9842004550012
						},
						{
							year: "2096",
							value: 167.5492936369222
						},
						{
							year: "2097",
							value: 168.114386818843
						},
						{
							year: "2098",
							value: 168.6794800007638
						},
						{
							year: "2099",
							value: 169.2445731826851
						},
						{
							year: "2100",
							value: 169.8096663646059
						}
					]
				},
				{
					name: "Remaining Advanced Economies",
					data: [
						{
							year: "2020",
							value: 1474.438235613005
						},
						{
							year: "2021",
							value: 1461.999689101852
						},
						{
							year: "2022",
							value: 1472.283548597134
						},
						{
							year: "2023",
							value: 1480.779042734647
						},
						{
							year: "2024",
							value: 1489.268939664481
						},
						{
							year: "2025",
							value: 1497.753239386675
						},
						{
							year: "2026",
							value: 1506.23194190121
						},
						{
							year: "2027",
							value: 1514.705047208085
						},
						{
							year: "2028",
							value: 1523.172555307301
						},
						{
							year: "2029",
							value: 1530.817774996527
						},
						{
							year: "2030",
							value: 1536.824934930125
						},
						{
							year: "2031",
							value: 1540.354435400845
						},
						{
							year: "2032",
							value: 1542.250503273708
						},
						{
							year: "2033",
							value: 1543.331669464369
						},
						{
							year: "2034",
							value: 1544.415545031815
						},
						{
							year: "2035",
							value: 1545.502129976046
						},
						{
							year: "2036",
							value: 1546.591424297064
						},
						{
							year: "2037",
							value: 1547.683427994866
						},
						{
							year: "2038",
							value: 1548.778141069454
						},
						{
							year: "2039",
							value: 1549.441442969684
						},
						{
							year: "2040",
							value: 1549.239530373289
						},
						{
							year: "2041",
							value: 1547.725762018674
						},
						{
							year: "2042",
							value: 1545.348374924838
						},
						{
							year: "2043",
							value: 1542.542124100688
						},
						{
							year: "2044",
							value: 1539.741447326251
						},
						{
							year: "2045",
							value: 1536.946344601527
						},
						{
							year: "2046",
							value: 1534.156815926512
						},
						{
							year: "2047",
							value: 1531.372861301212
						},
						{
							year: "2048",
							value: 1528.594480725623
						},
						{
							year: "2049",
							value: 1525.806747520503
						},
						{
							year: "2050",
							value: 1522.994810440784
						},
						{
							year: "2051",
							value: 1520.143441351968
						},
						{
							year: "2052",
							value: 1517.268247845333
						},
						{
							year: "2053",
							value: 1514.38430746848
						},
						{
							year: "2054",
							value: 1511.506622334837
						},
						{
							year: "2055",
							value: 1508.635192444401
						},
						{
							year: "2056",
							value: 1505.770017797173
						},
						{
							year: "2057",
							value: 1502.911098393152
						},
						{
							year: "2058",
							value: 1500.05843423234
						},
						{
							year: "2059",
							value: 1497.385496341059
						},
						{
							year: "2060",
							value: 1495.065329921857
						},
						{
							year: "2061",
							value: 1493.27581105127
						},
						{
							year: "2062",
							value: 1491.836921630116
						},
						{
							year: "2063",
							value: 1490.574338984518
						},
						{
							year: "2064",
							value: 1489.314166264372
						},
						{
							year: "2065",
							value: 1488.056403469677
						},
						{
							year: "2066",
							value: 1486.801050600432
						},
						{
							year: "2067",
							value: 1485.548107656639
						},
						{
							year: "2068",
							value: 1484.297574638298
						},
						{
							year: "2069",
							value: 1482.959747644217
						},
						{
							year: "2070",
							value: 1481.445024284892
						},
						{
							year: "2071",
							value: 1479.661185382475
						},
						{
							year: "2072",
							value: 1477.700960749374
						},
						{
							year: "2073",
							value: 1475.654257310166
						},
						{
							year: "2074",
							value: 1473.610880477731
						},
						{
							year: "2075",
							value: 1471.570830252072
						},
						{
							year: "2076",
							value: 1469.534106633186
						},
						{
							year: "2077",
							value: 1467.500709621074
						},
						{
							year: "2078",
							value: 1465.470639215737
						},
						{
							year: "2079",
							value: 1463.457436015291
						},
						{
							year: "2080",
							value: 1461.474272235224
						},
						{
							year: "2081",
							value: 1459.534362029548
						},
						{
							year: "2082",
							value: 1457.622638167993
						},
						{
							year: "2083",
							value: 1455.724823287184
						},
						{
							year: "2084",
							value: 1453.827008406375
						},
						{
							year: "2085",
							value: 1451.929193525565
						},
						{
							year: "2086",
							value: 1450.031378644756
						},
						{
							year: "2087",
							value: 1448.133563763947
						},
						{
							year: "2088",
							value: 1446.235748883138
						},
						{
							year: "2089",
							value: 1445.097953967334
						},
						{
							year: "2090",
							value: 1445.480198981544
						},
						{
							year: "2091",
							value: 1448.165534798804
						},
						{
							year: "2092",
							value: 1452.370910546076
						},
						{
							year: "2093",
							value: 1457.336306258354
						},
						{
							year: "2094",
							value: 1462.301701970633
						},
						{
							year: "2095",
							value: 1467.26709768291
						},
						{
							year: "2096",
							value: 1472.232493395189
						},
						{
							year: "2097",
							value: 1477.197889107467
						},
						{
							year: "2098",
							value: 1482.163284819745
						},
						{
							year: "2099",
							value: 1487.128680532022
						},
						{
							year: "2100",
							value: 1492.0940762443
						}
					]
				},
				{
					name: "Rest of Asia",
					data: [
						{
							year: "2020",
							value: 4437.550619530948
						},
						{
							year: "2021",
							value: 4400.114884046463
						},
						{
							year: "2022",
							value: 4431.065754671102
						},
						{
							year: "2023",
							value: 4456.634262298326
						},
						{
							year: "2024",
							value: 4482.185924260728
						},
						{
							year: "2025",
							value: 4507.720740558309
						},
						{
							year: "2026",
							value: 4533.238711191069
						},
						{
							year: "2027",
							value: 4558.739836159005
						},
						{
							year: "2028",
							value: 4584.224115462124
						},
						{
							year: "2029",
							value: 4607.233590222731
						},
						{
							year: "2030",
							value: 4625.313070014488
						},
						{
							year: "2031",
							value: 4635.935649260046
						},
						{
							year: "2032",
							value: 4641.642159685963
						},
						{
							year: "2033",
							value: 4644.896097072622
						},
						{
							year: "2034",
							value: 4648.15818874906
						},
						{
							year: "2035",
							value: 4651.428434715273
						},
						{
							year: "2036",
							value: 4654.706834971266
						},
						{
							year: "2037",
							value: 4657.993389517035
						},
						{
							year: "2038",
							value: 4661.288098352587
						},
						{
							year: "2039",
							value: 4663.284408328282
						},
						{
							year: "2040",
							value: 4662.67672104401
						},
						{
							year: "2041",
							value: 4658.120800329529
						},
						{
							year: "2042",
							value: 4650.965685034564
						},
						{
							year: "2043",
							value: 4642.519837807813
						},
						{
							year: "2044",
							value: 4634.09076654845
						},
						{
							year: "2045",
							value: 4625.678471256475
						},
						{
							year: "2046",
							value: 4617.282951931879
						},
						{
							year: "2047",
							value: 4608.904208574675
						},
						{
							year: "2048",
							value: 4600.542241184855
						},
						{
							year: "2049",
							value: 4592.152125605466
						},
						{
							year: "2050",
							value: 4583.689164710395
						},
						{
							year: "2051",
							value: 4575.107527066328
						},
						{
							year: "2052",
							value: 4566.454186140628
						},
						{
							year: "2053",
							value: 4557.774520151994
						},
						{
							year: "2054",
							value: 4549.113680288258
						},
						{
							year: "2055",
							value: 4540.471666549422
						},
						{
							year: "2056",
							value: 4531.848478952535
						},
						{
							year: "2057",
							value: 4523.244117463448
						},
						{
							year: "2058",
							value: 4514.658582099255
						},
						{
							year: "2059",
							value: 4506.613960826573
						},
						{
							year: "2060",
							value: 4499.631060024211
						},
						{
							year: "2061",
							value: 4494.245225351007
						},
						{
							year: "2062",
							value: 4489.91466440374
						},
						{
							year: "2063",
							value: 4486.114726050357
						},
						{
							year: "2064",
							value: 4482.322040741506
						},
						{
							year: "2065",
							value: 4478.536608477187
						},
						{
							year: "2066",
							value: 4474.758429257396
						},
						{
							year: "2067",
							value: 4470.987503082133
						},
						{
							year: "2068",
							value: 4467.223829951404
						},
						{
							year: "2069",
							value: 4463.19743205764
						},
						{
							year: "2070",
							value: 4458.6386371127859
						},
						{
							year: "2071",
							value: 4453.269897184998
						},
						{
							year: "2072",
							value: 4447.370297035617
						},
						{
							year: "2073",
							value: 4441.210425502632
						},
						{
							year: "2074",
							value: 4435.0605659088419
						},
						{
							year: "2075",
							value: 4428.920718254245
						},
						{
							year: "2076",
							value: 4422.790882538829
						},
						{
							year: "2077",
							value: 4416.671058762601
						},
						{
							year: "2078",
							value: 4410.561246925564
						},
						{
							year: "2079",
							value: 4404.502199558482
						},
						{
							year: "2080",
							value: 4398.533560487454
						},
						{
							year: "2081",
							value: 4392.695099759061
						},
						{
							year: "2082",
							value: 4386.941470206243
						},
						{
							year: "2083",
							value: 4381.229701888853
						},
						{
							year: "2084",
							value: 4375.517933571556
						},
						{
							year: "2085",
							value: 4369.806165254136
						},
						{
							year: "2086",
							value: 4364.094396936749
						},
						{
							year: "2087",
							value: 4358.382628619476
						},
						{
							year: "2088",
							value: 4352.670860302087
						},
						{
							year: "2089",
							value: 4349.24648998152
						},
						{
							year: "2090",
							value: 4350.396915654604
						},
						{
							year: "2091",
							value: 4358.478850408936
						},
						{
							year: "2092",
							value: 4371.135581156951
						},
						{
							year: "2093",
							value: 4386.079709901791
						},
						{
							year: "2094",
							value: 4401.023838646632
						},
						{
							year: "2095",
							value: 4415.967967391593
						},
						{
							year: "2096",
							value: 4430.912096136432
						},
						{
							year: "2097",
							value: 4445.856224881274
						},
						{
							year: "2098",
							value: 4460.800353626113
						},
						{
							year: "2099",
							value: 4475.744482370952
						},
						{
							year: "2100",
							value: 4490.688611115788
						}
					]
				},
				{
					name: "Rest of the European Union",
					data: [
						{
							year: "2020",
							value: 2729.770648172649
						},
						{
							year: "2021",
							value: 2770.897511273603
						},
						{
							year: "2022",
							value: 2790.388295638462
						},
						{
							year: "2023",
							value: 2806.489628448687
						},
						{
							year: "2024",
							value: 2822.580352987999
						},
						{
							year: "2025",
							value: 2838.660469256401
						},
						{
							year: "2026",
							value: 2854.729977253893
						},
						{
							year: "2027",
							value: 2870.788876980471
						},
						{
							year: "2028",
							value: 2886.83716843614
						},
						{
							year: "2029",
							value: 2901.326993822528
						},
						{
							year: "2030",
							value: 2912.712238726414
						},
						{
							year: "2031",
							value: 2919.401627337957
						},
						{
							year: "2032",
							value: 2922.995205222607
						},
						{
							year: "2033",
							value: 2925.044316949037
						},
						{
							year: "2034",
							value: 2927.098563700767
						},
						{
							year: "2035",
							value: 2929.157945477798
						},
						{
							year: "2036",
							value: 2931.222462280127
						},
						{
							year: "2037",
							value: 2933.292114107755
						},
						{
							year: "2038",
							value: 2935.366900960684
						},
						{
							year: "2039",
							value: 2936.624043214727
						},
						{
							year: "2040",
							value: 2936.241362482977
						},
						{
							year: "2041",
							value: 2933.372348899926
						},
						{
							year: "2042",
							value: 2928.866536736799
						},
						{
							year: "2043",
							value: 2923.547908092338
						},
						{
							year: "2044",
							value: 2918.239843828017
						},
						{
							year: "2045",
							value: 2912.942343943834
						},
						{
							year: "2046",
							value: 2907.655408439784
						},
						{
							year: "2047",
							value: 2902.379037315871
						},
						{
							year: "2048",
							value: 2897.113230572096
						},
						{
							year: "2049",
							value: 2891.829697984678
						},
						{
							year: "2050",
							value: 2886.500292298664
						},
						{
							year: "2051",
							value: 2881.096151948418
						},
						{
							year: "2052",
							value: 2875.646857676091
						},
						{
							year: "2053",
							value: 2870.180985643115
						},
						{
							year: "2054",
							value: 2864.726969042099
						},
						{
							year: "2055",
							value: 2859.284807873039
						},
						{
							year: "2056",
							value: 2853.854502135939
						},
						{
							year: "2057",
							value: 2848.436051830798
						},
						{
							year: "2058",
							value: 2843.029456957615
						},
						{
							year: "2059",
							value: 2837.963493533719
						},
						{
							year: "2060",
							value: 2833.566130521516
						},
						{
							year: "2061",
							value: 2830.174492738107
						},
						{
							year: "2062",
							value: 2827.447395635564
						},
						{
							year: "2063",
							value: 2825.054449086706
						},
						{
							year: "2064",
							value: 2822.666070019285
						},
						{
							year: "2065",
							value: 2820.282258433296
						},
						{
							year: "2066",
							value: 2817.90301432874
						},
						{
							year: "2067",
							value: 2815.528337705617
						},
						{
							year: "2068",
							value: 2813.15822856393
						},
						{
							year: "2069",
							value: 2810.622672971102
						},
						{
							year: "2070",
							value: 2807.751849387548
						},
						{
							year: "2071",
							value: 2804.37097672932
						},
						{
							year: "2072",
							value: 2800.655803875396
						},
						{
							year: "2073",
							value: 2796.776729544333
						},
						{
							year: "2074",
							value: 2792.903960061692
						},
						{
							year: "2075",
							value: 2789.037495427472
						},
						{
							year: "2076",
							value: 2785.177335641671
						},
						{
							year: "2077",
							value: 2781.323480704292
						},
						{
							year: "2078",
							value: 2777.475930615334
						},
						{
							year: "2079",
							value: 2773.660348587932
						},
						{
							year: "2080",
							value: 2769.901699647307
						},
						{
							year: "2081",
							value: 2766.225028303892
						},
						{
							year: "2082",
							value: 2762.60177795048
						},
						{
							year: "2083",
							value: 2759.004888998115
						},
						{
							year: "2084",
							value: 2755.40800004575
						},
						{
							year: "2085",
							value: 2751.811111093385
						},
						{
							year: "2086",
							value: 2748.214222141019
						},
						{
							year: "2087",
							value: 2744.617333188653
						},
						{
							year: "2088",
							value: 2741.020444236287
						},
						{
							year: "2089",
							value: 2738.864005268453
						},
						{
							year: "2090",
							value: 2739.588466269687
						},
						{
							year: "2091",
							value: 2744.677927224053
						},
						{
							year: "2092",
							value: 2752.648288147486
						},
						{
							year: "2093",
							value: 2762.05909905545
						},
						{
							year: "2094",
							value: 2771.469909963414
						},
						{
							year: "2095",
							value: 2780.880720871377
						},
						{
							year: "2096",
							value: 2790.291531779341
						},
						{
							year: "2097",
							value: 2799.702342687304
						},
						{
							year: "2098",
							value: 2809.113153595267
						},
						{
							year: "2099",
							value: 2818.523964503232
						},
						{
							year: "2100",
							value: 2827.934775411194
						}
					]
				},
				{
					name: "Rest of the World",
					data: [
						{
							year: "2020",
							value: 15616.19790208732
						},
						{
							year: "2021",
							value: 15484.4576913136
						},
						{
							year: "2022",
							value: 15593.37699440593
						},
						{
							year: "2023",
							value: 15683.35520747924
						},
						{
							year: "2024",
							value: 15773.27413892217
						},
						{
							year: "2025",
							value: 15863.1337887347
						},
						{
							year: "2026",
							value: 15952.93415691686
						},
						{
							year: "2027",
							value: 16042.67524346864
						},
						{
							year: "2028",
							value: 16132.35704839003
						},
						{
							year: "2029",
							value: 16213.32976110755
						},
						{
							year: "2030",
							value: 16276.95331351329
						},
						{
							year: "2031",
							value: 16314.33526449299
						},
						{
							year: "2032",
							value: 16334.41706271501
						},
						{
							year: "2033",
							value: 16345.86800368399
						},
						{
							year: "2034",
							value: 16357.34764043896
						},
						{
							year: "2035",
							value: 16368.85597297992
						},
						{
							year: "2036",
							value: 16380.39300130692
						},
						{
							year: "2037",
							value: 16391.95872541986
						},
						{
							year: "2038",
							value: 16403.55314531884
						},
						{
							year: "2039",
							value: 16410.57836583511
						},
						{
							year: "2040",
							value: 16408.43985166202
						},
						{
							year: "2041",
							value: 16392.40709719823
						},
						{
							year: "2042",
							value: 16367.22750916906
						},
						{
							year: "2043",
							value: 16337.50570246713
						},
						{
							year: "2044",
							value: 16307.84293212313
						},
						{
							year: "2045",
							value: 16278.23919813707
						},
						{
							year: "2046",
							value: 16248.69450050893
						},
						{
							year: "2047",
							value: 16219.20883923871
						},
						{
							year: "2048",
							value: 16189.78221432642
						},
						{
							year: "2049",
							value: 16160.25653303446
						},
						{
							year: "2050",
							value: 16130.47450157022
						},
						{
							year: "2051",
							value: 16100.27483439709
						},
						{
							year: "2052",
							value: 16069.82283598717
						},
						{
							year: "2053",
							value: 16039.27819696809
						},
						{
							year: "2054",
							value: 16008.79980902246
						},
						{
							year: "2055",
							value: 15978.38767215029
						},
						{
							year: "2056",
							value: 15948.04178635158
						},
						{
							year: "2057",
							value: 15917.76215162634
						},
						{
							year: "2058",
							value: 15887.54876797456
						},
						{
							year: "2059",
							value: 15859.23891674985
						},
						{
							year: "2060",
							value: 15834.66536928418
						},
						{
							year: "2061",
							value: 15815.71206208037
						},
						{
							year: "2062",
							value: 15800.4723718599
						},
						{
							year: "2063",
							value: 15787.09999722584
						},
						{
							year: "2064",
							value: 15773.75314680288
						},
						{
							year: "2065",
							value: 15760.43182059102
						},
						{
							year: "2066",
							value: 15747.13601859026
						},
						{
							year: "2067",
							value: 15733.86574080059
						},
						{
							year: "2068",
							value: 15720.62098722202
						},
						{
							year: "2069",
							value: 15706.45167812944
						},
						{
							year: "2070",
							value: 15690.40880893709
						},
						{
							year: "2071",
							value: 15671.51565990395
						},
						{
							year: "2072",
							value: 15650.75435904802
						},
						{
							year: "2073",
							value: 15629.07713637326
						},
						{
							year: "2074",
							value: 15607.43514674419
						},
						{
							year: "2075",
							value: 15585.82839016083
						},
						{
							year: "2076",
							value: 15564.25686662315
						},
						{
							year: "2077",
							value: 15542.72057613116
						},
						{
							year: "2078",
							value: 15521.21951868487
						},
						{
							year: "2079",
							value: 15499.89710663944
						},
						{
							year: "2080",
							value: 15478.89285070403
						},
						{
							year: "2081",
							value: 15458.34670577071
						},
						{
							year: "2082",
							value: 15438.09909048572
						},
						{
							year: "2083",
							value: 15417.99878920187
						},
						{
							year: "2084",
							value: 15397.89848791803
						},
						{
							year: "2085",
							value: 15377.79818663419
						},
						{
							year: "2086",
							value: 15357.69788535035
						},
						{
							year: "2087",
							value: 15337.59758406651
						},
						{
							year: "2088",
							value: 15317.49728278265
						},
						{
							year: "2089",
							value: 15305.44657075687
						},
						{
							year: "2090",
							value: 15309.49503724723
						},
						{
							year: "2091",
							value: 15337.93619845902
						},
						{
							year: "2092",
							value: 15382.47653818695
						},
						{
							year: "2093",
							value: 15435.06646717293
						},
						{
							year: "2094",
							value: 15487.65639615892
						},
						{
							year: "2095",
							value: 15540.2463251449
						},
						{
							year: "2096",
							value: 15592.83625413089
						},
						{
							year: "2097",
							value: 15645.42618311688
						},
						{
							year: "2098",
							value: 15698.01611210286
						},
						{
							year: "2099",
							value: 15750.60604108883
						},
						{
							year: "2100",
							value: 15803.1959700748
						}
					]
				},
				{
					name: "United Kingdom",
					data: [
						{
							year: "2020",
							value: 405.7548833709021
						},
						{
							year: "2021",
							value: 411.8680070328145
						},
						{
							year: "2022",
							value: 414.7651298889289
						},
						{
							year: "2023",
							value: 417.1584424629732
						},
						{
							year: "2024",
							value: 419.5501782167345
						},
						{
							year: "2025",
							value: 421.9403371502124
						},
						{
							year: "2026",
							value: 424.3289192634074
						},
						{
							year: "2027",
							value: 426.715924556319
						},
						{
							year: "2028",
							value: 429.101353028948
						},
						{
							year: "2029",
							value: 431.2551300921069
						},
						{
							year: "2030",
							value: 432.9474402945108
						},
						{
							year: "2031",
							value: 433.9417553655283
						},
						{
							year: "2032",
							value: 434.4759071179644
						},
						{
							year: "2033",
							value: 434.7804884168098
						},
						{
							year: "2034",
							value: 435.0858329891537
						},
						{
							year: "2035",
							value: 435.3919408349955
						},
						{
							year: "2036",
							value: 435.6988119543357
						},
						{
							year: "2037",
							value: 436.0064463471737
						},
						{
							year: "2038",
							value: 436.3148440135099
						},
						{
							year: "2039",
							value: 436.5017064552361
						},
						{
							year: "2040",
							value: 436.4448245425437
						},
						{
							year: "2041",
							value: 436.0183724988309
						},
						{
							year: "2042",
							value: 435.3486256503312
						},
						{
							year: "2043",
							value: 434.5580612317525
						},
						{
							year: "2044",
							value: 433.7690671095039
						},
						{
							year: "2045",
							value: 432.9816432835854
						},
						{
							year: "2046",
							value: 432.1957897539966
						},
						{
							year: "2047",
							value: 431.4115065207375
						},
						{
							year: "2048",
							value: 430.6287935838081
						},
						{
							year: "2049",
							value: 429.843445865959
						},
						{
							year: "2050",
							value: 429.0512795409189
						},
						{
							year: "2051",
							value: 428.2480046068133
						},
						{
							year: "2052",
							value: 427.4380179643815
						},
						{
							year: "2053",
							value: 426.625567192832
						},
						{
							year: "2054",
							value: 425.8148786203948
						},
						{
							year: "2055",
							value: 425.005952247069
						},
						{
							year: "2056",
							value: 424.1987880728552
						},
						{
							year: "2057",
							value: 423.3933860977531
						},
						{
							year: "2058",
							value: 422.589746321763
						},
						{
							year: "2059",
							value: 421.8367382257898
						},
						{
							year: "2060",
							value: 421.1831113295706
						},
						{
							year: "2061",
							value: 420.6789760850347
						},
						{
							year: "2062",
							value: 420.2736185992235
						},
						{
							year: "2063",
							value: 419.9179294688952
						},
						{
							year: "2064",
							value: 419.5629192519761
						},
						{
							year: "2065",
							value: 419.2085879484657
						},
						{
							year: "2066",
							value: 418.8549355583642
						},
						{
							year: "2067",
							value: 418.5019620816719
						},
						{
							year: "2068",
							value: 418.1496675183882
						},
						{
							year: "2069",
							value: 417.7727808870748
						},
						{
							year: "2070",
							value: 417.3460598037108
						},
						{
							year: "2071",
							value: 416.8435246943788
						},
						{
							year: "2072",
							value: 416.2912989866787
						},
						{
							year: "2073",
							value: 415.7147108568877
						},
						{
							year: "2074",
							value: 415.1390598838639
						},
						{
							year: "2075",
							value: 414.5643460676076
						},
						{
							year: "2076",
							value: 413.9905694081184
						},
						{
							year: "2077",
							value: 413.4177299053963
						},
						{
							year: "2078",
							value: 412.8458275594415
						},
						{
							year: "2079",
							value: 412.2786769669341
						},
						{
							year: "2080",
							value: 411.7199889454492
						},
						{
							year: "2081",
							value: 411.1734861272951
						},
						{
							year: "2082",
							value: 410.6349238398165
						},
						{
							year: "2083",
							value: 410.100279928123
						},
						{
							year: "2084",
							value: 409.5656360164295
						},
						{
							year: "2085",
							value: 409.0309921047358
						},
						{
							year: "2086",
							value: 408.4963481930426
						},
						{
							year: "2087",
							value: 407.9617042813488
						},
						{
							year: "2088",
							value: 407.4270603696551
						},
						{
							year: "2089",
							value: 407.1065258798893
						},
						{
							year: "2090",
							value: 407.2142102339794
						},
						{
							year: "2091",
							value: 407.9707110181538
						},
						{
							year: "2092",
							value: 409.1554306461841
						},
						{
							year: "2093",
							value: 410.5542596961422
						},
						{
							year: "2094",
							value: 411.9530887461005
						},
						{
							year: "2095",
							value: 413.3519177960583
						},
						{
							year: "2096",
							value: 414.7507468460162
						},
						{
							year: "2097",
							value: 416.1495758959743
						},
						{
							year: "2098",
							value: 417.548404945932
						},
						{
							year: "2099",
							value: 418.9472339958912
						},
						{
							year: "2100",
							value: 420.3460630458491
						}
					]
				},
				{
					name: "United States of America",
					data: [
						{
							year: "2020",
							value: 5981.354372479605
						},
						{
							year: "2021",
							value: 6071.469760964686
						},
						{
							year: "2022",
							value: 6114.17711748266
						},
						{
							year: "2023",
							value: 6149.457655601003
						},
						{
							year: "2024",
							value: 6184.714949338853
						},
						{
							year: "2025",
							value: 6219.948998696213
						},
						{
							year: "2026",
							value: 6255.159803673084
						},
						{
							year: "2027",
							value: 6290.347364269459
						},
						{
							year: "2028",
							value: 6325.511680485353
						},
						{
							year: "2029",
							value: 6357.261153830064
						},
						{
							year: "2030",
							value: 6382.208005842411
						},
						{
							year: "2031",
							value: 6396.865502378851
						},
						{
							year: "2032",
							value: 6404.739593488883
						},
						{
							year: "2033",
							value: 6409.229517722181
						},
						{
							year: "2034",
							value: 6413.730693598927
						},
						{
							year: "2035",
							value: 6418.243121119122
						},
						{
							year: "2036",
							value: 6422.766800282766
						},
						{
							year: "2037",
							value: 6427.301731089857
						},
						{
							year: "2038",
							value: 6431.847913540404
						},
						{
							year: "2039",
							value: 6434.602508811285
						},
						{
							year: "2040",
							value: 6433.763995484293
						},
						{
							year: "2041",
							value: 6427.477538066646
						},
						{
							year: "2042",
							value: 6417.60459899701
						},
						{
							year: "2043",
							value: 6405.950651908291
						},
						{
							year: "2044",
							value: 6394.319853028519
						},
						{
							year: "2045",
							value: 6382.71220235769
						},
						{
							year: "2046",
							value: 6371.127699895794
						},
						{
							year: "2047",
							value: 6359.566345642842
						},
						{
							year: "2048",
							value: 6348.028139598826
						},
						{
							year: "2049",
							value: 6336.451093459421
						},
						{
							year: "2050",
							value: 6324.773532187342
						},
						{
							year: "2051",
							value: 6312.9322155789
						},
						{
							year: "2052",
							value: 6300.991959665995
						},
						{
							year: "2053",
							value: 6289.015379287071
						},
						{
							year: "2054",
							value: 6277.064776013534
						},
						{
							year: "2055",
							value: 6265.140149845367
						},
						{
							year: "2056",
							value: 6253.241500782579
						},
						{
							year: "2057",
							value: 6241.368828825169
						},
						{
							year: "2058",
							value: 6229.522133973136
						},
						{
							year: "2059",
							value: 6218.421815894535
						},
						{
							year: "2060",
							value: 6208.786505873884
						},
						{
							year: "2061",
							value: 6201.354897105127
						},
						{
							year: "2062",
							value: 6195.379400889206
						},
						{
							year: "2063",
							value: 6190.136080790979
						},
						{
							year: "2064",
							value: 6184.902768758839
						},
						{
							year: "2065",
							value: 6179.679464792782
						},
						{
							year: "2066",
							value: 6174.466168892808
						},
						{
							year: "2067",
							value: 6169.262881058921
						},
						{
							year: "2068",
							value: 6164.069601291118
						},
						{
							year: "2069",
							value: 6158.513802476306
						},
						{
							year: "2070",
							value: 6152.223379064504
						},
						{
							year: "2071",
							value: 6144.815358368471
						},
						{
							year: "2072",
							value: 6136.674833665709
						},
						{
							year: "2073",
							value: 6128.175175195566
						},
						{
							year: "2074",
							value: 6119.689331634268
						},
						{
							year: "2075",
							value: 6111.21730298182
						},
						{
							year: "2076",
							value: 6102.759089238216
						},
						{
							year: "2077",
							value: 6094.314690403453
						},
						{
							year: "2078",
							value: 6085.884106477537
						},
						{
							year: "2079",
							value: 6077.523569573663
						},
						{
							year: "2080",
							value: 6069.28778196614
						},
						{
							year: "2081",
							value: 6061.231620093774
						},
						{
							year: "2082",
							value: 6053.2925119645
						},
						{
							year: "2083",
							value: 6045.411165787319
						},
						{
							year: "2084",
							value: 6037.52981961014
						},
						{
							year: "2085",
							value: 6029.648473432961
						},
						{
							year: "2086",
							value: 6021.767127255785
						},
						{
							year: "2087",
							value: 6013.885781078603
						},
						{
							year: "2088",
							value: 6006.004434901424
						},
						{
							year: "2089",
							value: 6001.279339898344
						},
						{
							year: "2090",
							value: 6002.866747243472
						},
						{
							year: "2091",
							value: 6014.018552085878
						},
						{
							year: "2092",
							value: 6031.482859276494
						},
						{
							year: "2093",
							value: 6052.103417641208
						},
						{
							year: "2094",
							value: 6072.723976005924
						},
						{
							year: "2095",
							value: 6093.344534370638
						},
						{
							year: "2096",
							value: 6113.965092735354
						},
						{
							year: "2097",
							value: 6134.58565110007
						},
						{
							year: "2098",
							value: 6155.206209464783
						},
						{
							year: "2099",
							value: 6175.826767829498
						},
						{
							year: "2100",
							value: 6196.447326194208
						}
					]
				}
			]
		},
		{
			scenario: "SSP2-3.4",
			groupByRegion: [
				{
					name: "Australia",
					data: [
						{
							year: "2020",
							value: 527.7370476376862
						},
						{
							year: "2021",
							value: 533.5131816795567
						},
						{
							year: "2022",
							value: 535.197447119352
						},
						{
							year: "2023",
							value: 536.0821800951356
						},
						{
							year: "2024",
							value: 536.9653688191859
						},
						{
							year: "2025",
							value: 537.8470132915022
						},
						{
							year: "2026",
							value: 538.7271135120844
						},
						{
							year: "2027",
							value: 539.6056694809329
						},
						{
							year: "2028",
							value: 540.4826811980479
						},
						{
							year: "2029",
							value: 540.9073244435464
						},
						{
							year: "2030",
							value: 540.4292778668851
						},
						{
							year: "2031",
							value: 538.5850616468633
						},
						{
							year: "2032",
							value: 535.8406851898438
						},
						{
							year: "2033",
							value: 532.6479784543877
						},
						{
							year: "2034",
							value: 529.4582685297169
						},
						{
							year: "2035",
							value: 526.2715554158316
						},
						{
							year: "2036",
							value: 523.0878391127321
						},
						{
							year: "2037",
							value: 519.907119620418
						},
						{
							year: "2038",
							value: 516.7293969388895
						},
						{
							year: "2039",
							value: 513.5913583263975
						},
						{
							year: "2040",
							value: 510.5295944553404
						},
						{
							year: "2041",
							value: 507.5817111590657
						},
						{
							year: "2042",
							value: 504.7096167363424
						},
						{
							year: "2043",
							value: 501.8764307689171
						},
						{
							year: "2044",
							value: 499.0453694128294
						},
						{
							year: "2045",
							value: 496.2164326680057
						},
						{
							year: "2046",
							value: 493.3896205344456
						},
						{
							year: "2047",
							value: 490.5649330121495
						},
						{
							year: "2048",
							value: 487.7423701011171
						},
						{
							year: "2049",
							value: 485.0031373665602
						},
						{
							year: "2050",
							value: 482.4283667322076
						},
						{
							year: "2051",
							value: 480.1015772550086
						},
						{
							year: "2052",
							value: 477.9388794390405
						},
						{
							year: "2053",
							value: 475.858920436126
						},
						{
							year: "2054",
							value: 473.7804210395705
						},
						{
							year: "2055",
							value: 471.7033812493741
						},
						{
							year: "2056",
							value: 469.6278010655372
						},
						{
							year: "2057",
							value: 467.5536804880595
						},
						{
							year: "2058",
							value: 465.4810195169415
						},
						{
							year: "2059",
							value: 463.6462938202558
						},
						{
							year: "2060",
							value: 462.2858082718398
						},
						{
							year: "2061",
							value: 461.6428628806311
						},
						{
							year: "2062",
							value: 461.4732984909302
						},
						{
							year: "2063",
							value: 461.5402978461934
						},
						{
							year: "2064",
							value: 461.6072144841113
						},
						{
							year: "2065",
							value: 461.6740484046842
						},
						{
							year: "2066",
							value: 461.740799607912
						},
						{
							year: "2067",
							value: 461.8074680937948
						},
						{
							year: "2068",
							value: 461.8740538623324
						},
						{
							year: "2069",
							value: 461.9619521538366
						},
						{
							year: "2070",
							value: 462.092667461841
						},
						{
							year: "2071",
							value: 462.2884618737177
						},
						{
							year: "2072",
							value: 462.5276228789083
						},
						{
							year: "2073",
							value: 462.7889737435451
						},
						{
							year: "2074",
							value: 463.0512284805392
						},
						{
							year: "2075",
							value: 463.3143870898899
						},
						{
							year: "2076",
							value: 463.5784495715974
						},
						{
							year: "2077",
							value: 463.8434159256612
						},
						{
							year: "2078",
							value: 464.1092861520819
						},
						{
							year: "2079",
							value: 464.0965554889124
						},
						{
							year: "2080",
							value: 463.5256190809586
						},
						{
							year: "2081",
							value: 462.1083021385068
						},
						{
							year: "2082",
							value: 460.1322759519041
						},
						{
							year: "2083",
							value: 457.8768450966018
						},
						{
							year: "2084",
							value: 455.6214142412995
						},
						{
							year: "2085",
							value: 453.3659833859969
						},
						{
							year: "2086",
							value: 451.1105525306949
						},
						{
							year: "2087",
							value: 448.8551216753922
						},
						{
							year: "2088",
							value: 446.5996908200899
						},
						{
							year: "2089",
							value: 444.3085004384421
						},
						{
							year: "2090",
							value: 441.9457910041027
						},
						{
							year: "2091",
							value: 439.4747193687172
						},
						{
							year: "2092",
							value: 436.932128680639
						},
						{
							year: "2093",
							value: 434.3537784662155
						},
						{
							year: "2094",
							value: 431.7754282517922
						},
						{
							year: "2095",
							value: 429.1970780373684
						},
						{
							year: "2096",
							value: 426.6187278229449
						},
						{
							year: "2097",
							value: 424.0403776085213
						},
						{
							year: "2098",
							value: 421.4620273940978
						},
						{
							year: "2099",
							value: 418.8836771796744
						},
						{
							year: "2100",
							value: 416.3053269652508
						}
					]
				},
				{
					name: "Canada",
					data: [
						{
							year: "2020",
							value: 672.3540219599809
						},
						{
							year: "2021",
							value: 679.7130030507119
						},
						{
							year: "2022",
							value: 681.858811550539
						},
						{
							year: "2023",
							value: 682.985989900614
						},
						{
							year: "2024",
							value: 684.1112008241656
						},
						{
							year: "2025",
							value: 685.2344443211955
						},
						{
							year: "2026",
							value: 686.3557203917034
						},
						{
							year: "2027",
							value: 687.4750290356889
						},
						{
							year: "2028",
							value: 688.5923702531522
						},
						{
							year: "2029",
							value: 689.1333794456793
						},
						{
							year: "2030",
							value: 688.5243326866133
						},
						{
							year: "2031",
							value: 686.1747417332288
						},
						{
							year: "2032",
							value: 682.678317601345
						},
						{
							year: "2033",
							value: 678.6107062328961
						},
						{
							year: "2034",
							value: 674.5469128980561
						},
						{
							year: "2035",
							value: 670.4869375968256
						},
						{
							year: "2036",
							value: 666.4307803292044
						},
						{
							year: "2037",
							value: 662.3784410951919
						},
						{
							year: "2038",
							value: 658.3299198947889
						},
						{
							year: "2039",
							value: 654.3319574784322
						},
						{
							year: "2040",
							value: 650.4311715430649
						},
						{
							year: "2041",
							value: 646.6754731185158
						},
						{
							year: "2042",
							value: 643.0163321786012
						},
						{
							year: "2043",
							value: 639.4067618658992
						},
						{
							year: "2044",
							value: 635.7998983764811
						},
						{
							year: "2045",
							value: 632.1957417103475
						},
						{
							year: "2046",
							value: 628.5942918674975
						},
						{
							year: "2047",
							value: 624.9955488479316
						},
						{
							year: "2048",
							value: 621.3995126516495
						},
						{
							year: "2049",
							value: 617.9096417881848
						},
						{
							year: "2050",
							value: 614.6293009454469
						},
						{
							year: "2051",
							value: 611.6648960960704
						},
						{
							year: "2052",
							value: 608.9095493162181
						},
						{
							year: "2053",
							value: 606.2596144531086
						},
						{
							year: "2054",
							value: 603.6115391755833
						},
						{
							year: "2055",
							value: 600.9653234836421
						},
						{
							year: "2056",
							value: 598.3209673772856
						},
						{
							year: "2057",
							value: 595.6784708565135
						},
						{
							year: "2058",
							value: 593.0378339213262
						},
						{
							year: "2059",
							value: 590.7003342008615
						},
						{
							year: "2060",
							value: 588.9670317268934
						},
						{
							year: "2061",
							value: 588.1478985589549
						},
						{
							year: "2062",
							value: 587.931868056825
						},
						{
							year: "2063",
							value: 588.0172273966334
						},
						{
							year: "2064",
							value: 588.1024813518761
						},
						{
							year: "2065",
							value: 588.187629922553
						},
						{
							year: "2066",
							value: 588.2726731086636
						},
						{
							year: "2067",
							value: 588.3576109102089
						},
						{
							year: "2068",
							value: 588.4424433271879
						},
						{
							year: "2069",
							value: 588.5544285859061
						},
						{
							year: "2070",
							value: 588.7209641047978
						},
						{
							year: "2071",
							value: 588.9704125012836
						},
						{
							year: "2072",
							value: 589.275111336531
						},
						{
							year: "2073",
							value: 589.6080807684937
						},
						{
							year: "2074",
							value: 589.9422017629947
						},
						{
							year: "2075",
							value: 590.2774743200348
						},
						{
							year: "2076",
							value: 590.6138984396138
						},
						{
							year: "2077",
							value: 590.951474121731
						},
						{
							year: "2078",
							value: 591.2902013663872
						},
						{
							year: "2079",
							value: 591.2739820908554
						},
						{
							year: "2080",
							value: 590.5465906903828
						},
						{
							year: "2081",
							value: 588.7408831871985
						},
						{
							year: "2082",
							value: 586.2233620846395
						},
						{
							year: "2083",
							value: 583.3498704213793
						},
						{
							year: "2084",
							value: 580.4763787581192
						},
						{
							year: "2085",
							value: 577.6028870948587
						},
						{
							year: "2086",
							value: 574.7293954315985
						},
						{
							year: "2087",
							value: 571.8559037683383
						},
						{
							year: "2088",
							value: 568.9824121050779
						},
						{
							year: "2089",
							value: 566.0633616517557
						},
						{
							year: "2090",
							value: 563.053193618309
						},
						{
							year: "2091",
							value: 559.9049686452804
						},
						{
							year: "2092",
							value: 556.6656260921272
						},
						{
							year: "2093",
							value: 553.380724748912
						},
						{
							year: "2094",
							value: 550.0958234056974
						},
						{
							year: "2095",
							value: 546.810922062482
						},
						{
							year: "2096",
							value: 543.5260207192672
						},
						{
							year: "2097",
							value: 540.2411193760518
						},
						{
							year: "2098",
							value: 536.9562180328367
						},
						{
							year: "2099",
							value: 533.6713166896218
						},
						{
							year: "2100",
							value: 530.3864153464065
						}
					]
				},
				{
					name: "China",
					data: [
						{
							year: "2020",
							value: 13106.41681037749
						},
						{
							year: "2021",
							value: 12906.81836310795
						},
						{
							year: "2022",
							value: 12947.56432563181
						},
						{
							year: "2023",
							value: 12968.96789767167
						},
						{
							year: "2024",
							value: 12990.3341109783
						},
						{
							year: "2025",
							value: 13011.66296555168
						},
						{
							year: "2026",
							value: 13032.95446139182
						},
						{
							year: "2027",
							value: 13054.20859849872
						},
						{
							year: "2028",
							value: 13075.42537687237
						},
						{
							year: "2029",
							value: 13085.69839997091
						},
						{
							year: "2030",
							value: 13074.13343673109
						},
						{
							year: "2031",
							value: 13029.51792470348
						},
						{
							year: "2032",
							value: 12963.12562238166
						},
						{
							year: "2033",
							value: 12885.88725726484
						},
						{
							year: "2034",
							value: 12808.72139137353
						},
						{
							year: "2035",
							value: 12731.62802470776
						},
						{
							year: "2036",
							value: 12654.60715726749
						},
						{
							year: "2037",
							value: 12577.65878905275
						},
						{
							year: "2038",
							value: 12500.78292006353
						},
						{
							year: "2039",
							value: 12424.86709309118
						},
						{
							year: "2040",
							value: 12350.79651430987
						},
						{
							year: "2041",
							value: 12279.48094851268
						},
						{
							year: "2042",
							value: 12209.9988770141
						},
						{
							year: "2043",
							value: 12141.4580837884
						},
						{
							year: "2044",
							value: 12072.9686894271
						},
						{
							year: "2045",
							value: 12004.53069393019
						},
						{
							year: "2046",
							value: 11936.14409729768
						},
						{
							year: "2047",
							value: 11867.80889952954
						},
						{
							year: "2048",
							value: 11799.52510062579
						},
						{
							year: "2049",
							value: 11733.25723588983
						},
						{
							year: "2050",
							value: 11670.96805908097
						},
						{
							year: "2051",
							value: 11614.67807378733
						},
						{
							year: "2052",
							value: 11562.35781471429
						},
						{
							year: "2053",
							value: 11512.03918347035
						},
						{
							year: "2054",
							value: 11461.75586320803
						},
						{
							year: "2055",
							value: 11411.50785392735
						},
						{
							year: "2056",
							value: 11361.29515562832
						},
						{
							year: "2057",
							value: 11311.11776831092
						},
						{
							year: "2058",
							value: 11260.97569197515
						},
						{
							year: "2059",
							value: 11216.58977588933
						},
						{
							year: "2060",
							value: 11183.67673744601
						},
						{
							year: "2061",
							value: 11168.12252123075
						},
						{
							year: "2062",
							value: 11164.02039807087
						},
						{
							year: "2063",
							value: 11165.64125494658
						},
						{
							year: "2064",
							value: 11167.26011071381
						},
						{
							year: "2065",
							value: 11168.87696537258
						},
						{
							year: "2066",
							value: 11170.49181892286
						},
						{
							year: "2067",
							value: 11172.10467136468
						},
						{
							year: "2068",
							value: 11173.71552269802
						},
						{
							year: "2069",
							value: 11175.84196928229
						},
						{
							year: "2070",
							value: 11179.00425054466
						},
						{
							year: "2071",
							value: 11183.74093371824
						},
						{
							year: "2072",
							value: 11189.5267470017
						},
						{
							year: "2073",
							value: 11195.84938017117
						},
						{
							year: "2074",
							value: 11202.19387993501
						},
						{
							year: "2075",
							value: 11208.5602462932
						},
						{
							year: "2076",
							value: 11214.94847924577
						},
						{
							year: "2077",
							value: 11221.35857879268
						},
						{
							year: "2078",
							value: 11227.79054493397
						},
						{
							year: "2079",
							value: 11227.48256312057
						},
						{
							year: "2080",
							value: 11213.67039733494
						},
						{
							year: "2081",
							value: 11179.38248661983
						},
						{
							year: "2082",
							value: 11131.57821121214
						},
						{
							year: "2083",
							value: 11077.0145427239
						},
						{
							year: "2084",
							value: 11022.45087423566
						},
						{
							year: "2085",
							value: 10967.88720574742
						},
						{
							year: "2086",
							value: 10913.32353725918
						},
						{
							year: "2087",
							value: 10858.75986877094
						},
						{
							year: "2088",
							value: 10804.1962002827
						},
						{
							year: "2089",
							value: 10748.76743281072
						},
						{
							year: "2090",
							value: 10691.60846737126
						},
						{
							year: "2091",
							value: 10631.82798985986
						},
						{
							year: "2092",
							value: 10570.31731438097
						},
						{
							year: "2093",
							value: 10507.94153991834
						},
						{
							year: "2094",
							value: 10445.56576545572
						},
						{
							year: "2095",
							value: 10383.18999099309
						},
						{
							year: "2096",
							value: 10320.81421653047
						},
						{
							year: "2097",
							value: 10258.43844206785
						},
						{
							year: "2098",
							value: 10196.06266760521
						},
						{
							year: "2099",
							value: 10133.68689314259
						},
						{
							year: "2100",
							value: 10071.31111867995
						}
					]
				},
				{
					name: "France",
					data: [
						{
							year: "2020",
							value: 399.412668092019
						},
						{
							year: "2021",
							value: 403.7842791419685
						},
						{
							year: "2022",
							value: 405.0589991111182
						},
						{
							year: "2023",
							value: 405.7286006863508
						},
						{
							year: "2024",
							value: 406.3970335096301
						},
						{
							year: "2025",
							value: 407.0642975809565
						},
						{
							year: "2026",
							value: 407.7303929003293
						},
						{
							year: "2027",
							value: 408.395319467749
						},
						{
							year: "2028",
							value: 409.059077283216
						},
						{
							year: "2029",
							value: 409.3804644066537
						},
						{
							year: "2030",
							value: 409.0186594897706
						},
						{
							year: "2031",
							value: 407.62288232333
						},
						{
							year: "2032",
							value: 405.5458276085745
						},
						{
							year: "2033",
							value: 403.1294584691476
						},
						{
							year: "2034",
							value: 400.7153574369096
						},
						{
							year: "2035",
							value: 398.3035245118608
						},
						{
							year: "2036",
							value: 395.8939596940008
						},
						{
							year: "2037",
							value: 393.4866629833296
						},
						{
							year: "2038",
							value: 391.0816343798474
						},
						{
							year: "2039",
							value: 388.7066402792784
						},
						{
							year: "2040",
							value: 386.3893739772803
						},
						{
							year: "2041",
							value: 384.1582970753749
						},
						{
							year: "2042",
							value: 381.9845802565545
						},
						{
							year: "2043",
							value: 379.8403109249614
						},
						{
							year: "2044",
							value: 377.6976495848055
						},
						{
							year: "2045",
							value: 375.5565962360867
						},
						{
							year: "2046",
							value: 373.4171508788046
						},
						{
							year: "2047",
							value: 371.2793135129594
						},
						{
							year: "2048",
							value: 369.1430841385512
						},
						{
							year: "2049",
							value: 367.0699224003205
						},
						{
							year: "2050",
							value: 365.1212322081658
						},
						{
							year: "2051",
							value: 363.3602241506206
						},
						{
							year: "2052",
							value: 361.7234072760058
						},
						{
							year: "2053",
							value: 360.1492104698958
						},
						{
							year: "2054",
							value: 358.5761183527079
						},
						{
							year: "2055",
							value: 357.0041309244418
						},
						{
							year: "2056",
							value: 355.4332481850983
						},
						{
							year: "2057",
							value: 353.8634701346767
						},
						{
							year: "2058",
							value: 352.2947967731774
						},
						{
							year: "2059",
							value: 350.9062024173447
						},
						{
							year: "2060",
							value: 349.876532119962
						},
						{
							year: "2061",
							value: 349.3899251339946
						},
						{
							year: "2062",
							value: 349.2615919695799
						},
						{
							year: "2063",
							value: 349.3122997820508
						},
						{
							year: "2064",
							value: 349.3629449907008
						},
						{
							year: "2065",
							value: 349.4135275955302
						},
						{
							year: "2066",
							value: 349.4640475965386
						},
						{
							year: "2067",
							value: 349.5145049937267
						},
						{
							year: "2068",
							value: 349.564899787094
						},
						{
							year: "2069",
							value: 349.6314247568432
						},
						{
							year: "2070",
							value: 349.7303553704189
						},
						{
							year: "2071",
							value: 349.8785404728165
						},
						{
							year: "2072",
							value: 350.0595471609241
						},
						{
							year: "2073",
							value: 350.2573480290227
						},
						{
							year: "2074",
							value: 350.4558329841512
						},
						{
							year: "2075",
							value: 350.6550020263101
						},
						{
							year: "2076",
							value: 350.854855155499
						},
						{
							year: "2077",
							value: 351.0553923717179
						},
						{
							year: "2078",
							value: 351.256613674967
						},
						{
							year: "2079",
							value: 351.2469785959713
						},
						{
							year: "2080",
							value: 350.8148709108518
						},
						{
							year: "2081",
							value: 349.7421883238745
						},
						{
							year: "2082",
							value: 348.2466520621595
						},
						{
							year: "2083",
							value: 346.5396510857736
						},
						{
							year: "2084",
							value: 344.8326501093873
						},
						{
							year: "2085",
							value: 343.125649133001
						},
						{
							year: "2086",
							value: 341.418648156615
						},
						{
							year: "2087",
							value: 339.7116471802288
						},
						{
							year: "2088",
							value: 338.0046462038425
						},
						{
							year: "2089",
							value: 336.2705809766312
						},
						{
							year: "2090",
							value: 334.4823872477688
						},
						{
							year: "2091",
							value: 332.6121806376177
						},
						{
							year: "2092",
							value: 330.6878455258158
						},
						{
							year: "2093",
							value: 328.7364461631885
						},
						{
							year: "2094",
							value: 326.7850468005616
						},
						{
							year: "2095",
							value: 324.8336474379342
						},
						{
							year: "2096",
							value: 322.8822480753071
						},
						{
							year: "2097",
							value: 320.9308487126798
						},
						{
							year: "2098",
							value: 318.9794493500524
						},
						{
							year: "2099",
							value: 317.0280499874253
						},
						{
							year: "2100",
							value: 315.076650624798
						}
					]
				},
				{
					name: "Global",
					data: [
						{
							year: "2020",
							value: 49017.00912423762
						},
						{
							year: "2021",
							value: 48555.30870473927
						},
						{
							year: "2022",
							value: 48708.59456754125
						},
						{
							year: "2023",
							value: 48789.11457011215
						},
						{
							year: "2024",
							value: 48869.494029539586
						},
						{
							year: "2025",
							value: 48949.732945823496
						},
						{
							year: "2026",
							value: 49029.831318963894
						},
						{
							year: "2027",
							value: 49109.78914896078
						},
						{
							year: "2028",
							value: 49189.606435814174
						},
						{
							year: "2029",
							value: 49228.25343570583
						},
						{
							year: "2030",
							value: 49184.74617121428
						},
						{
							year: "2031",
							value: 49016.90310574486
						},
						{
							year: "2032",
							value: 48767.13599473721
						},
						{
							year: "2033",
							value: 48476.566114803325
						},
						{
							year: "2034",
							value: 48186.26897615834
						},
						{
							year: "2035",
							value: 47896.24457880223
						},
						{
							year: "2036",
							value: 47606.492922735066
						},
						{
							year: "2037",
							value: 47317.014007956794
						},
						{
							year: "2038",
							value: 47027.80783446742
						},
						{
							year: "2039",
							value: 46742.213328484904
						},
						{
							year: "2040",
							value: 46463.560625899016
						},
						{
							year: "2041",
							value: 46195.27225186548
						},
						{
							year: "2042",
							value: 45933.881463202735
						},
						{
							year: "2043",
							value: 45676.031753048315
						},
						{
							year: "2044",
							value: 45418.37540485644
						},
						{
							year: "2045",
							value: 45160.912418627006
						},
						{
							year: "2046",
							value: 44903.64279436
						},
						{
							year: "2047",
							value: 44646.56653205541
						},
						{
							year: "2048",
							value: 44389.683631713255
						},
						{
							year: "2049",
							value: 44140.38465353467
						},
						{
							year: "2050",
							value: 43906.05345557159
						},
						{
							year: "2051",
							value: 43694.2911500966
						},
						{
							year: "2052",
							value: 43497.462910995855
						},
						{
							year: "2053",
							value: 43308.164773769895
						},
						{
							year: "2054",
							value: 43118.999476068464
						},
						{
							year: "2055",
							value: 42929.96701789144
						},
						{
							year: "2056",
							value: 42741.067399238964
						},
						{
							year: "2057",
							value: 42552.30062011094
						},
						{
							year: "2058",
							value: 42363.666680507406
						},
						{
							year: "2059",
							value: 42196.68735244539
						},
						{
							year: "2060",
							value: 42072.868863870804
						},
						{
							year: "2061",
							value: 42014.354073568065
						},
						{
							year: "2062",
							value: 41998.92193137904
						},
						{
							year: "2063",
							value: 42005.01957711576
						},
						{
							year: "2064",
							value: 42011.10969470439
						},
						{
							year: "2065",
							value: 42017.192284130375
						},
						{
							year: "2066",
							value: 42023.267345422966
						},
						{
							year: "2067",
							value: 42029.33487856743
						},
						{
							year: "2068",
							value: 42035.39488356381
						},
						{
							year: "2069",
							value: 42043.394552222555
						},
						{
							year: "2070",
							value: 42055.29101954596
						},
						{
							year: "2071",
							value: 42073.110369540555
						},
						{
							year: "2072",
							value: 42094.87653546796
						},
						{
							year: "2073",
							value: 42118.662211901436
						},
						{
							year: "2074",
							value: 42142.53015022238
						},
						{
							year: "2075",
							value: 42166.480350430764
						},
						{
							year: "2076",
							value: 42190.51281252663
						},
						{
							year: "2077",
							value: 42214.62753650992
						},
						{
							year: "2078",
							value: 42238.82452238069
						},
						{
							year: "2079",
							value: 42237.66589818652
						},
						{
							year: "2080",
							value: 42185.70468243722
						},
						{
							year: "2081",
							value: 42056.7139395
						},
						{
							year: "2082",
							value: 41876.874781271676
						},
						{
							year: "2083",
							value: 41671.606860628825
						},
						{
							year: "2084",
							value: 41466.338939985966
						},
						{
							year: "2085",
							value: 41261.071019343115
						},
						{
							year: "2086",
							value: 41055.80309870026
						},
						{
							year: "2087",
							value: 40850.53517805741
						},
						{
							year: "2088",
							value: 40645.26725741456
						},
						{
							year: "2089",
							value: 40436.744843911125
						},
						{
							year: "2090",
							value: 40221.71344468648
						},
						{
							year: "2091",
							value: 39996.819945884345
						},
						{
							year: "2092",
							value: 39765.417461360965
						},
						{
							year: "2093",
							value: 39530.76048397699
						},
						{
							year: "2094",
							value: 39296.10350659304
						},
						{
							year: "2095",
							value: 39061.44652920905
						},
						{
							year: "2096",
							value: 38826.78955182513
						},
						{
							year: "2097",
							value: 38592.13257444117
						},
						{
							year: "2098",
							value: 38357.47559705044
						},
						{
							year: "2099",
							value: 38122.818619761994
						},
						{
							year: "2100",
							value: 37888.16164237745
						}
					]
				},
				{
					name: "India",
					data: [
						{
							year: "2020",
							value: 3502.106733367986
						},
						{
							year: "2021",
							value: 3448.772929303478
						},
						{
							year: "2022",
							value: 3459.660474829985
						},
						{
							year: "2023",
							value: 3465.379627123355
						},
						{
							year: "2024",
							value: 3471.088796957511
						},
						{
							year: "2025",
							value: 3476.787984332455
						},
						{
							year: "2026",
							value: 3482.477189248189
						},
						{
							year: "2027",
							value: 3488.156411704706
						},
						{
							year: "2028",
							value: 3493.825651702009
						},
						{
							year: "2029",
							value: 3496.570660035405
						},
						{
							year: "2030",
							value: 3493.480438183067
						},
						{
							year: "2031",
							value: 3481.558927724095
						},
						{
							year: "2032",
							value: 3463.818538999318
						},
						{
							year: "2033",
							value: 3443.180022579196
						},
						{
							year: "2034",
							value: 3422.5608783513
						},
						{
							year: "2035",
							value: 3401.961106315633
						},
						{
							year: "2036",
							value: 3381.38070647219
						},
						{
							year: "2037",
							value: 3360.819678820974
						},
						{
							year: "2038",
							value: 3340.278023361986
						},
						{
							year: "2039",
							value: 3319.99289641573
						},
						{
							year: "2040",
							value: 3300.200829945732
						},
						{
							year: "2041",
							value: 3281.144918113711
						},
						{
							year: "2042",
							value: 3262.578926053173
						},
						{
							year: "2043",
							value: 3244.264448729666
						},
						{
							year: "2044",
							value: 3225.963705465718
						},
						{
							year: "2045",
							value: 3207.676696261332
						},
						{
							year: "2046",
							value: 3189.4034211165
						},
						{
							year: "2047",
							value: 3171.143880031227
						},
						{
							year: "2048",
							value: 3152.898073005511
						},
						{
							year: "2049",
							value: 3135.190934688807
						},
						{
							year: "2050",
							value: 3118.546923692185
						},
						{
							year: "2051",
							value: 3103.505929698924
						},
						{
							year: "2052",
							value: 3089.525668408429
						},
						{
							year: "2053",
							value: 3076.080253094478
						},
						{
							year: "2054",
							value: 3062.644273069234
						},
						{
							year: "2055",
							value: 3049.217728332698
						},
						{
							year: "2056",
							value: 3035.800618884875
						},
						{
							year: "2057",
							value: 3022.392944725761
						},
						{
							year: "2058",
							value: 3008.994705855355
						},
						{
							year: "2059",
							value: 2997.134544696144
						},
						{
							year: "2060",
							value: 2988.339999610665
						},
						{
							year: "2061",
							value: 2984.183827399156
						},
						{
							year: "2062",
							value: 2983.087717505265
						},
						{
							year: "2063",
							value: 2983.52081938661
						},
						{
							year: "2064",
							value: 2983.95338656075
						},
						{
							year: "2065",
							value: 2984.385419027691
						},
						{
							year: "2066",
							value: 2984.816916787429
						},
						{
							year: "2067",
							value: 2985.247879839967
						},
						{
							year: "2068",
							value: 2985.6783081853
						},
						{
							year: "2069",
							value: 2986.246506420459
						},
						{
							year: "2070",
							value: 2987.091485384732
						},
						{
							year: "2071",
							value: 2988.35715320806
						},
						{
							year: "2072",
							value: 2989.903154373073
						},
						{
							year: "2073",
							value: 2991.592596767267
						},
						{
							year: "2074",
							value: 2993.287882035866
						},
						{
							year: "2075",
							value: 2994.989010178873
						},
						{
							year: "2076",
							value: 2996.695981196283
						},
						{
							year: "2077",
							value: 2998.408795088106
						},
						{
							year: "2078",
							value: 3000.127451854335
						},
						{
							year: "2079",
							value: 3000.045157418103
						},
						{
							year: "2080",
							value: 2996.354470672817
						},
						{
							year: "2081",
							value: 2987.19255214651
						},
						{
							year: "2082",
							value: 2974.418986555609
						},
						{
							year: "2083",
							value: 2959.839273917552
						},
						{
							year: "2084",
							value: 2945.259561279493
						},
						{
							year: "2085",
							value: 2930.679848641433
						},
						{
							year: "2086",
							value: 2916.100136003376
						},
						{
							year: "2087",
							value: 2901.520423365317
						},
						{
							year: "2088",
							value: 2886.940710727258
						},
						{
							year: "2089",
							value: 2872.129838877661
						},
						{
							year: "2090",
							value: 2856.856648604985
						},
						{
							year: "2091",
							value: 2840.882975873101
						},
						{
							year: "2092",
							value: 2824.446984718137
						},
						{
							year: "2093",
							value: 2807.779834351636
						},
						{
							year: "2094",
							value: 2791.112683985136
						},
						{
							year: "2095",
							value: 2774.445533618633
						},
						{
							year: "2096",
							value: 2757.778383252132
						},
						{
							year: "2097",
							value: 2741.111232885631
						},
						{
							year: "2098",
							value: 2724.444082519128
						},
						{
							year: "2099",
							value: 2707.776932152628
						},
						{
							year: "2100",
							value: 2691.109781786123
						}
					]
				},
				{
					name: "Netherlands",
					data: [
						{
							year: "2020",
							value: 163.9151815480303
						},
						{
							year: "2021",
							value: 165.7092497073403
						},
						{
							year: "2022",
							value: 166.2323827280801
						},
						{
							year: "2023",
							value: 166.5071808823581
						},
						{
							year: "2024",
							value: 166.7814993919098
						},
						{
							year: "2025",
							value: 167.0553382566896
						},
						{
							year: "2026",
							value: 167.3286974767202
						},
						{
							year: "2027",
							value: 167.6015770520017
						},
						{
							year: "2028",
							value: 167.8739769825342
						},
						{
							year: "2029",
							value: 168.0058712187428
						},
						{
							year: "2030",
							value: 167.8573899023217
						},
						{
							year: "2031",
							value: 167.2845761526111
						},
						{
							year: "2032",
							value: 166.4321725396852
						},
						{
							year: "2033",
							value: 165.4405174956569
						},
						{
							year: "2034",
							value: 164.4497932613702
						},
						{
							year: "2035",
							value: 163.4599998368252
						},
						{
							year: "2036",
							value: 162.4711372220218
						},
						{
							year: "2037",
							value: 161.48320541696
						},
						{
							year: "2038",
							value: 160.4962044216397
						},
						{
							year: "2039",
							value: 159.5215293022889
						},
						{
							year: "2040",
							value: 158.5705451255592
						},
						{
							year: "2041",
							value: 157.6549322635632
						},
						{
							year: "2042",
							value: 156.7628594372301
						},
						{
							year: "2043",
							value: 155.8828715811802
						},
						{
							year: "2044",
							value: 155.0035436296098
						},
						{
							year: "2045",
							value: 154.1248755825191
						},
						{
							year: "2046",
							value: 153.2468674399077
						},
						{
							year: "2047",
							value: 152.3695192017758
						},
						{
							year: "2048",
							value: 151.4928308681233
						},
						{
							year: "2049",
							value: 150.6420248959486
						},
						{
							year: "2050",
							value: 149.8423008691979
						},
						{
							year: "2051",
							value: 149.119599815644
						},
						{
							year: "2052",
							value: 148.4478656491303
						},
						{
							year: "2053",
							value: 147.801830166554
						},
						{
							year: "2054",
							value: 147.1562480378646
						},
						{
							year: "2055",
							value: 146.5111192630621
						},
						{
							year: "2056",
							value: 145.8664438421466
						},
						{
							year: "2057",
							value: 145.2222217751182
						},
						{
							year: "2058",
							value: 144.5784530619767
						},
						{
							year: "2059",
							value: 144.0085870698141
						},
						{
							year: "2060",
							value: 143.5860201170149
						},
						{
							year: "2061",
							value: 143.3863212116529
						},
						{
							year: "2062",
							value: 143.3336544945808
						},
						{
							year: "2063",
							value: 143.354464501292
						},
						{
							year: "2064",
							value: 143.3752488159872
						},
						{
							year: "2065",
							value: 143.3960074386665
						},
						{
							year: "2066",
							value: 143.4167403693297
						},
						{
							year: "2067",
							value: 143.437447607977
						},
						{
							year: "2068",
							value: 143.4581291546083
						},
						{
							year: "2069",
							value: 143.4854303730891
						},
						{
							year: "2070",
							value: 143.5260305613469
						},
						{
							year: "2071",
							value: 143.5868443260341
						},
						{
							year: "2072",
							value: 143.6611277591139
						},
						{
							year: "2073",
							value: 143.7423033648451
						},
						{
							year: "2074",
							value: 143.8237597134242
						},
						{
							year: "2075",
							value: 143.9054968048514
						},
						{
							year: "2076",
							value: 143.9875146391264
						},
						{
							year: "2077",
							value: 144.0698132162494
						},
						{
							year: "2078",
							value: 144.1523925362205
						},
						{
							year: "2079",
							value: 144.1484383909164
						},
						{
							year: "2080",
							value: 143.9711054832408
						},
						{
							year: "2081",
							value: 143.5308866935447
						},
						{
							year: "2082",
							value: 142.9171327545218
						},
						{
							year: "2083",
							value: 142.2165956963486
						},
						{
							year: "2084",
							value: 141.5160586381754
						},
						{
							year: "2085",
							value: 140.8155215800021
						},
						{
							year: "2086",
							value: 140.114984521829
						},
						{
							year: "2087",
							value: 139.4144474636558
						},
						{
							year: "2088",
							value: 138.7139104054826
						},
						{
							year: "2089",
							value: 138.0022664347082
						},
						{
							year: "2090",
							value: 137.2684086387314
						},
						{
							year: "2091",
							value: 136.5008935318421
						},
						{
							year: "2092",
							value: 135.7111645997502
						},
						{
							year: "2093",
							value: 134.9103287550573
						},
						{
							year: "2094",
							value: 134.1094929103644
						},
						{
							year: "2095",
							value: 133.3086570656715
						},
						{
							year: "2096",
							value: 132.5078212209786
						},
						{
							year: "2097",
							value: 131.7069853762857
						},
						{
							year: "2098",
							value: 130.9061495315927
						},
						{
							year: "2099",
							value: 130.1053136868998
						},
						{
							year: "2100",
							value: 129.3044778422068
						}
					]
				},
				{
					name: "Remaining Advanced Economies",
					data: [
						{
							year: "2020",
							value: 1474.438235613005
						},
						{
							year: "2021",
							value: 1451.983922837743
						},
						{
							year: "2022",
							value: 1456.567738991404
						},
						{
							year: "2023",
							value: 1458.975585884331
						},
						{
							year: "2024",
							value: 1461.379230015699
						},
						{
							year: "2025",
							value: 1463.778671385512
						},
						{
							year: "2026",
							value: 1466.173909993765
						},
						{
							year: "2027",
							value: 1468.564945840462
						},
						{
							year: "2028",
							value: 1470.951778925601
						},
						{
							year: "2029",
							value: 1472.107467644415
						},
						{
							year: "2030",
							value: 1470.806438977242
						},
						{
							year: "2031",
							value: 1465.787308440901
						},
						{
							year: "2032",
							value: 1458.318344916397
						},
						{
							year: "2033",
							value: 1449.629227178717
						},
						{
							year: "2034",
							value: 1440.94826541774
						},
						{
							year: "2035",
							value: 1432.275459633465
						},
						{
							year: "2036",
							value: 1423.610809825894
						},
						{
							year: "2037",
							value: 1414.954315995023
						},
						{
							year: "2038",
							value: 1406.305978140856
						},
						{
							year: "2039",
							value: 1397.765642548326
						},
						{
							year: "2040",
							value: 1389.432892638931
						},
						{
							year: "2041",
							value: 1381.410074615741
						},
						{
							year: "2042",
							value: 1373.59351999297
						},
						{
							year: "2043",
							value: 1365.882856758821
						},
						{
							year: "2044",
							value: 1358.177975764932
						},
						{
							year: "2045",
							value: 1350.478877011301
						},
						{
							year: "2046",
							value: 1342.785560497927
						},
						{
							year: "2047",
							value: 1335.098026224812
						},
						{
							year: "2048",
							value: 1327.416274191954
						},
						{
							year: "2049",
							value: 1319.961309576318
						},
						{
							year: "2050",
							value: 1312.953937135736
						},
						{
							year: "2051",
							value: 1306.621458335479
						},
						{
							year: "2052",
							value: 1300.735563541307
						},
						{
							year: "2053",
							value: 1295.07484673799
						},
						{
							year: "2054",
							value: 1289.418102329433
						},
						{
							year: "2055",
							value: 1283.765330315635
						},
						{
							year: "2056",
							value: 1278.116530696597
						},
						{
							year: "2057",
							value: 1272.47170347232
						},
						{
							year: "2058",
							value: 1266.830848642804
						},
						{
							year: "2059",
							value: 1261.837547060343
						},
						{
							year: "2060",
							value: 1258.134914751823
						},
						{
							year: "2061",
							value: 1256.385105368787
						},
						{
							year: "2062",
							value: 1255.923627047013
						},
						{
							year: "2063",
							value: 1256.105969283382
						},
						{
							year: "2064",
							value: 1256.288086400189
						},
						{
							year: "2065",
							value: 1256.46997839743
						},
						{
							year: "2066",
							value: 1256.651645275105
						},
						{
							year: "2067",
							value: 1256.833087033217
						},
						{
							year: "2068",
							value: 1257.014303671763
						},
						{
							year: "2069",
							value: 1257.253523452059
						},
						{
							year: "2070",
							value: 1257.609271973753
						},
						{
							year: "2071",
							value: 1258.142136667601
						},
						{
							year: "2072",
							value: 1258.793025804782
						},
						{
							year: "2073",
							value: 1259.504305800657
						},
						{
							year: "2074",
							value: 1260.218045732246
						},
						{
							year: "2075",
							value: 1260.934245599552
						},
						{
							year: "2076",
							value: 1261.652905402573
						},
						{
							year: "2077",
							value: 1262.374025141309
						},
						{
							year: "2078",
							value: 1263.09760481576
						},
						{
							year: "2079",
							value: 1263.06295765261
						},
						{
							year: "2080",
							value: 1261.509124469551
						},
						{
							year: "2081",
							value: 1257.651822560946
						},
						{
							year: "2082",
							value: 1252.273964332672
						},
						{
							year: "2083",
							value: 1246.135691740069
						},
						{
							year: "2084",
							value: 1239.997419147466
						},
						{
							year: "2085",
							value: 1233.859146554864
						},
						{
							year: "2086",
							value: 1227.720873962261
						},
						{
							year: "2087",
							value: 1221.582601369658
						},
						{
							year: "2088",
							value: 1215.444328777054
						},
						{
							year: "2089",
							value: 1209.208734770241
						},
						{
							year: "2090",
							value: 1202.778497935007
						},
						{
							year: "2091",
							value: 1196.053347723377
						},
						{
							year: "2092",
							value: 1189.133554683327
						},
						{
							year: "2093",
							value: 1182.116440229065
						},
						{
							year: "2094",
							value: 1175.099325774805
						},
						{
							year: "2095",
							value: 1168.082211320543
						},
						{
							year: "2096",
							value: 1161.065096866283
						},
						{
							year: "2097",
							value: 1154.047982412022
						},
						{
							year: "2098",
							value: 1147.03086795776
						},
						{
							year: "2099",
							value: 1140.013753503499
						},
						{
							year: "2100",
							value: 1132.996639049238
						}
					]
				},
				{
					name: "Rest of Asia",
					data: [
						{
							year: "2020",
							value: 4437.550619530948
						},
						{
							year: "2021",
							value: 4369.970881593962
						},
						{
							year: "2022",
							value: 4383.766587457519
						},
						{
							year: "2023",
							value: 4391.013376243485
						},
						{
							year: "2024",
							value: 4398.247516166515
						},
						{
							year: "2025",
							value: 4405.469007226608
						},
						{
							year: "2026",
							value: 4412.677849423765
						},
						{
							year: "2027",
							value: 4419.87404275798
						},
						{
							year: "2028",
							value: 4427.05758722926
						},
						{
							year: "2029",
							value: 4430.535811726079
						},
						{
							year: "2030",
							value: 4426.620164106127
						},
						{
							year: "2031",
							value: 4411.514312071719
						},
						{
							year: "2032",
							value: 4389.035307583858
						},
						{
							year: "2033",
							value: 4362.884059692493
						},
						{
							year: "2034",
							value: 4336.75735847836
						},
						{
							year: "2035",
							value: 4310.655203941415
						},
						{
							year: "2036",
							value: 4284.577596081751
						},
						{
							year: "2037",
							value: 4258.524534899322
						},
						{
							year: "2038",
							value: 4232.496020394125
						},
						{
							year: "2039",
							value: 4206.792555451166
						},
						{
							year: "2040",
							value: 4181.713851827166
						},
						{
							year: "2041",
							value: 4157.567936298605
						},
						{
							year: "2042",
							value: 4134.042802474022
						},
						{
							year: "2043",
							value: 4110.836365211853
						},
						{
							year: "2044",
							value: 4087.647330498826
						},
						{
							year: "2045",
							value: 4064.475698334933
						},
						{
							year: "2046",
							value: 4041.321468720174
						},
						{
							year: "2047",
							value: 4018.184641654544
						},
						{
							year: "2048",
							value: 3995.065217138049
						},
						{
							year: "2049",
							value: 3972.628344538267
						},
						{
							year: "2050",
							value: 3951.538570030307
						},
						{
							year: "2051",
							value: 3932.479992638245
						},
						{
							year: "2052",
							value: 3914.76547909708
						},
						{
							year: "2053",
							value: 3897.728673654291
						},
						{
							year: "2054",
							value: 3880.703823749892
						},
						{
							year: "2055",
							value: 3863.690929383742
						},
						{
							year: "2056",
							value: 3846.689990555938
						},
						{
							year: "2057",
							value: 3829.701007266434
						},
						{
							year: "2058",
							value: 3812.723979515253
						},
						{
							year: "2059",
							value: 3797.695863724742
						},
						{
							year: "2060",
							value: 3786.552217353049
						},
						{
							year: "2061",
							value: 3781.28589454331
						},
						{
							year: "2062",
							value: 3779.897003952955
						},
						{
							year: "2063",
							value: 3780.445791202519
						},
						{
							year: "2064",
							value: 3780.993900919827
						},
						{
							year: "2065",
							value: 3781.541333090271
						},
						{
							year: "2066",
							value: 3782.088087743164
						},
						{
							year: "2067",
							value: 3782.634164863704
						},
						{
							year: "2068",
							value: 3783.179564451984
						},
						{
							year: "2069",
							value: 3783.899533494169
						},
						{
							year: "2070",
							value: 3784.970213862267
						},
						{
							year: "2071",
							value: 3786.573952828826
						},
						{
							year: "2072",
							value: 3788.532904668369
						},
						{
							year: "2073",
							value: 3790.673612166397
						},
						{
							year: "2074",
							value: 3792.821723222601
						},
						{
							year: "2075",
							value: 3794.977237836984
						},
						{
							year: "2076",
							value: 3797.140156009559
						},
						{
							year: "2077",
							value: 3799.310477740298
						},
						{
							year: "2078",
							value: 3801.488203029216
						},
						{
							year: "2079",
							value: 3801.383927016426
						},
						{
							year: "2080",
							value: 3796.707424984965
						},
						{
							year: "2081",
							value: 3785.098276455839
						},
						{
							year: "2082",
							value: 3768.912777778303
						},
						{
							year: "2083",
							value: 3750.438694097998
						},
						{
							year: "2084",
							value: 3731.964610417693
						},
						{
							year: "2085",
							value: 3713.490526737388
						},
						{
							year: "2086",
							value: 3695.016443057086
						},
						{
							year: "2087",
							value: 3676.542359376781
						},
						{
							year: "2088",
							value: 3658.068275696484
						},
						{
							year: "2089",
							value: 3639.301288121334
						},
						{
							year: "2090",
							value: 3619.948492756484
						},
						{
							year: "2091",
							value: 3599.70810983154
						},
						{
							year: "2092",
							value: 3578.88191911685
						},
						{
							year: "2093",
							value: 3557.762824507315
						},
						{
							year: "2094",
							value: 3536.643729897782
						},
						{
							year: "2095",
							value: 3515.524635288246
						},
						{
							year: "2096",
							value: 3494.405540678752
						},
						{
							year: "2097",
							value: 3473.286446069207
						},
						{
							year: "2098",
							value: 3452.167351452933
						},
						{
							year: "2099",
							value: 3431.048256843441
						},
						{
							year: "2100",
							value: 3409.929162233946
						}
					]
				},
				{
					name: "Rest of the European Union",
					data: [
						{
							year: "2020",
							value: 2729.770648172649
						},
						{
							year: "2021",
							value: 2759.648257178853
						},
						{
							year: "2022",
							value: 2768.360282195602
						},
						{
							year: "2023",
							value: 2772.93664862577
						},
						{
							year: "2024",
							value: 2777.505027265285
						},
						{
							year: "2025",
							value: 2782.065418114149
						},
						{
							year: "2026",
							value: 2786.61782117236
						},
						{
							year: "2027",
							value: 2791.162236439915
						},
						{
							year: "2028",
							value: 2795.698663916817
						},
						{
							year: "2029",
							value: 2797.895171955613
						},
						{
							year: "2030",
							value: 2795.422430048877
						},
						{
							year: "2031",
							value: 2785.883044233849
						},
						{
							year: "2032",
							value: 2771.687492995895
						},
						{
							year: "2033",
							value: 2755.172910262535
						},
						{
							year: "2034",
							value: 2738.673828821265
						},
						{
							year: "2035",
							value: 2722.190248672079
						},
						{
							year: "2036",
							value: 2705.722169814981
						},
						{
							year: "2037",
							value: 2689.269592249968
						},
						{
							year: "2038",
							value: 2672.832515977041
						},
						{
							year: "2039",
							value: 2656.600709368887
						},
						{
							year: "2040",
							value: 2640.763441198573
						},
						{
							year: "2041",
							value: 2625.515231196295
						},
						{
							year: "2042",
							value: 2610.659046494391
						},
						{
							year: "2043",
							value: 2596.00411952094
						},
						{
							year: "2044",
							value: 2581.360182303645
						},
						{
							year: "2045",
							value: 2566.727234842504
						},
						{
							year: "2046",
							value: 2552.105277137515
						},
						{
							year: "2047",
							value: 2537.494309188681
						},
						{
							year: "2048",
							value: 2522.894330996001
						},
						{
							year: "2049",
							value: 2508.725386157644
						},
						{
							year: "2050",
							value: 2495.407137354127
						},
						{
							year: "2051",
							value: 2483.3715949422
						},
						{
							year: "2052",
							value: 2472.184832433887
						},
						{
							year: "2053",
							value: 2461.426044395706
						},
						{
							year: "2054",
							value: 2450.674806311835
						},
						{
							year: "2055",
							value: 2439.931118182273
						},
						{
							year: "2056",
							value: 2429.194980007022
						},
						{
							year: "2057",
							value: 2418.466391786083
						},
						{
							year: "2058",
							value: 2407.745353519453
						},
						{
							year: "2059",
							value: 2398.255058349604
						},
						{
							year: "2060",
							value: 2391.217815969392
						},
						{
							year: "2061",
							value: 2387.892119080921
						},
						{
							year: "2062",
							value: 2387.01503096281
						},
						{
							year: "2063",
							value: 2387.361591573361
						},
						{
							year: "2064",
							value: 2387.707724320486
						},
						{
							year: "2065",
							value: 2388.053429204188
						},
						{
							year: "2066",
							value: 2388.398706224462
						},
						{
							year: "2067",
							value: 2388.743555381315
						},
						{
							year: "2068",
							value: 2389.08797667474
						},
						{
							year: "2069",
							value: 2389.542639043511
						},
						{
							year: "2070",
							value: 2390.218776549201
						},
						{
							year: "2071",
							value: 2391.231541980388
						},
						{
							year: "2072",
							value: 2392.468625287432
						},
						{
							year: "2073",
							value: 2393.820487777167
						},
						{
							year: "2074",
							value: 2395.17702563362
						},
						{
							year: "2075",
							value: 2396.538238856793
						},
						{
							year: "2076",
							value: 2397.904127446687
						},
						{
							year: "2077",
							value: 2399.274691403296
						},
						{
							year: "2078",
							value: 2400.649930726628
						},
						{
							year: "2079",
							value: 2400.584080146672
						},
						{
							year: "2080",
							value: 2397.630856651474
						},
						{
							year: "2081",
							value: 2390.299648418335
						},
						{
							year: "2082",
							value: 2380.078462871043
						},
						{
							year: "2083",
							value: 2368.412029795694
						},
						{
							year: "2084",
							value: 2356.745596720348
						},
						{
							year: "2085",
							value: 2345.079163645001
						},
						{
							year: "2086",
							value: 2333.412730569656
						},
						{
							year: "2087",
							value: 2321.746297494309
						},
						{
							year: "2088",
							value: 2310.079864418962
						},
						{
							year: "2089",
							value: 2298.228461753466
						},
						{
							year: "2090",
							value: 2286.007119907667
						},
						{
							year: "2091",
							value: 2273.225264152325
						},
						{
							year: "2092",
							value: 2260.07346921668
						},
						{
							year: "2093",
							value: 2246.736704690885
						},
						{
							year: "2094",
							value: 2233.399940165092
						},
						{
							year: "2095",
							value: 2220.063175639296
						},
						{
							year: "2096",
							value: 2206.726411113503
						},
						{
							year: "2097",
							value: 2193.389646587708
						},
						{
							year: "2098",
							value: 2180.052882061914
						},
						{
							year: "2099",
							value: 2166.71611753612
						},
						{
							year: "2100",
							value: 2153.379353010323
						}
					]
				},
				{
					name: "Rest of the World",
					data: [
						{
							year: "2020",
							value: 15616.19790208732
						},
						{
							year: "2021",
							value: 15378.37784046359
						},
						{
							year: "2022",
							value: 15426.9263509901
						},
						{
							year: "2023",
							value: 15452.42854748071
						},
						{
							year: "2024",
							value: 15477.88623131923
						},
						{
							year: "2025",
							value: 15503.29940250566
						},
						{
							year: "2026",
							value: 15528.66806103998
						},
						{
							year: "2027",
							value: 15553.99220692221
						},
						{
							year: "2028",
							value: 15579.27184015236
						},
						{
							year: "2029",
							value: 15591.51207057388
						},
						{
							year: "2030",
							value: 15577.73250310734
						},
						{
							year: "2031",
							value: 15524.57345320022
						},
						{
							year: "2032",
							value: 15445.46752003536
						},
						{
							year: "2033",
							value: 15353.43858392347
						},
						{
							year: "2034",
							value: 15261.4960300982
						},
						{
							year: "2035",
							value: 15169.63985855955
						},
						{
							year: "2036",
							value: 15077.87006930753
						},
						{
							year: "2037",
							value: 14986.18666234212
						},
						{
							year: "2038",
							value: 14894.58963766333
						},
						{
							year: "2039",
							value: 14804.13649588913
						},
						{
							year: "2040",
							value: 14715.88195357554
						},
						{
							year: "2041",
							value: 14630.90998868988
						},
						{
							year: "2042",
							value: 14548.12261858962
						},
						{
							year: "2043",
							value: 14466.45677453292
						},
						{
							year: "2044",
							value: 14384.85217183989
						},
						{
							year: "2045",
							value: 14303.30881051053
						},
						{
							year: "2046",
							value: 14221.82669054483
						},
						{
							year: "2047",
							value: 14140.40581194279
						},
						{
							year: "2048",
							value: 14059.04617470442
						},
						{
							year: "2049",
							value: 13980.08850799522
						},
						{
							year: "2050",
							value: 13905.87141828425
						},
						{
							year: "2051",
							value: 13838.80232053092
						},
						{
							year: "2052",
							value: 13776.46312196934
						},
						{
							year: "2053",
							value: 13716.50884804106
						},
						{
							year: "2054",
							value: 13656.59664688411
						},
						{
							year: "2055",
							value: 13596.72651849846
						},
						{
							year: "2056",
							value: 13536.89846288416
						},
						{
							year: "2057",
							value: 13477.11248004117
						},
						{
							year: "2058",
							value: 13417.36856996952
						},
						{
							year: "2059",
							value: 13364.48308191509
						},
						{
							year: "2060",
							value: 13325.26744202464
						},
						{
							year: "2061",
							value: 13306.73470938354
						},
						{
							year: "2062",
							value: 13301.84706022586
						},
						{
							year: "2063",
							value: 13303.77829910742
						},
						{
							year: "2064",
							value: 13305.70715368314
						},
						{
							year: "2065",
							value: 13307.63362395305
						},
						{
							year: "2066",
							value: 13309.55770991712
						},
						{
							year: "2067",
							value: 13311.47941157538
						},
						{
							year: "2068",
							value: 13313.3987289278
						},
						{
							year: "2069",
							value: 13315.9323741772
						},
						{
							year: "2070",
							value: 13319.7002087219
						},
						{
							year: "2071",
							value: 13325.34393140432
						},
						{
							year: "2072",
							value: 13332.23768478987
						},
						{
							year: "2073",
							value: 13339.77105506683
						},
						{
							year: "2074",
							value: 13347.33047922793
						},
						{
							year: "2075",
							value: 13354.91595727317
						},
						{
							year: "2076",
							value: 13362.52748920253
						},
						{
							year: "2077",
							value: 13370.16507501604
						},
						{
							year: "2078",
							value: 13377.82871471368
						},
						{
							year: "2079",
							value: 13377.46175667947
						},
						{
							year: "2080",
							value: 13361.00466413575
						},
						{
							year: "2081",
							value: 13320.15087418514
						},
						{
							year: "2082",
							value: 13263.19243648757
						},
						{
							year: "2083",
							value: 13198.18023233567
						},
						{
							year: "2084",
							value: 13133.16802818376
						},
						{
							year: "2085",
							value: 13068.15582403186
						},
						{
							year: "2086",
							value: 13003.14361987995
						},
						{
							year: "2087",
							value: 12938.13141572805
						},
						{
							year: "2088",
							value: 12873.11921157614
						},
						{
							year: "2089",
							value: 12807.07624843569
						},
						{
							year: "2090",
							value: 12738.97176731812
						},
						{
							year: "2091",
							value: 12667.74377411405
						},
						{
							year: "2092",
							value: 12594.45426293286
						},
						{
							year: "2093",
							value: 12520.13399276314
						},
						{
							year: "2094",
							value: 12445.81372259341
						},
						{
							year: "2095",
							value: 12371.49345242367
						},
						{
							year: "2096",
							value: 12297.17318225394
						},
						{
							year: "2097",
							value: 12222.85291208421
						},
						{
							year: "2098",
							value: 12148.53264191448
						},
						{
							year: "2099",
							value: 12074.21237184021
						},
						{
							year: "2100",
							value: 11999.89210166989
						}
					]
				},
				{
					name: "United Kingdom",
					data: [
						{
							year: "2020",
							value: 405.7548833709021
						},
						{
							year: "2021",
							value: 410.1959105926685
						},
						{
							year: "2022",
							value: 411.4908716535774
						},
						{
							year: "2023",
							value: 412.1711057341872
						},
						{
							year: "2024",
							value: 412.8501525044026
						},
						{
							year: "2025",
							value: 413.5280119642238
						},
						{
							year: "2026",
							value: 414.2046841136504
						},
						{
							year: "2027",
							value: 414.8801689526827
						},
						{
							year: "2028",
							value: 415.5544664813209
						},
						{
							year: "2029",
							value: 415.8809568638377
						},
						{
							year: "2030",
							value: 415.513406899651
						},
						{
							year: "2031",
							value: 414.0954663919393
						},
						{
							year: "2032",
							value: 411.9854304294681
						},
						{
							year: "2033",
							value: 409.5306921182554
						},
						{
							year: "2034",
							value: 407.0782579291735
						},
						{
							year: "2035",
							value: 404.6281278622229
						},
						{
							year: "2036",
							value: 402.180301917403
						},
						{
							year: "2037",
							value: 399.7347800947138
						},
						{
							year: "2038",
							value: 397.2915623941557
						},
						{
							year: "2039",
							value: 394.8788561099851
						},
						{
							year: "2040",
							value: 392.5247942756471
						},
						{
							year: "2041",
							value: 390.2582904302668
						},
						{
							year: "2042",
							value: 388.0500574803328
						},
						{
							year: "2043",
							value: 385.8717396099644
						},
						{
							year: "2044",
							value: 383.6950552640938
						},
						{
							year: "2045",
							value: 381.5200044427204
						},
						{
							year: "2046",
							value: 379.3465871458447
						},
						{
							year: "2047",
							value: 377.1748033734667
						},
						{
							year: "2048",
							value: 375.004653125586
						},
						{
							year: "2049",
							value: 372.898571955644
						},
						{
							year: "2050",
							value: 370.9189387972348
						},
						{
							year: "2051",
							value: 369.1299679505717
						},
						{
							year: "2052",
							value: 367.4671603004499
						},
						{
							year: "2053",
							value: 365.8679670537329
						},
						{
							year: "2054",
							value: 364.2698960371315
						},
						{
							year: "2055",
							value: 362.6729472506456
						},
						{
							year: "2056",
							value: 361.0771206942761
						},
						{
							year: "2057",
							value: 359.482416368022
						},
						{
							year: "2058",
							value: 357.888834271884
						},
						{
							year: "2059",
							value: 356.4781906295785
						},
						{
							year: "2060",
							value: 355.4321703482967
						},
						{
							year: "2061",
							value: 354.937836601243
						},
						{
							year: "2062",
							value: 354.8074656532996
						},
						{
							year: "2063",
							value: 354.8589786477006
						},
						{
							year: "2064",
							value: 354.9104280442037
						},
						{
							year: "2065",
							value: 354.9618138428093
						},
						{
							year: "2066",
							value: 355.0131360435172
						},
						{
							year: "2067",
							value: 355.0643946463278
						},
						{
							year: "2068",
							value: 355.1155896512406
						},
						{
							year: "2069",
							value: 355.1831709612445
						},
						{
							year: "2070",
							value: 355.2836724795487
						},
						{
							year: "2071",
							value: 355.4342105914917
						},
						{
							year: "2072",
							value: 355.6180914582987
						},
						{
							year: "2073",
							value: 355.8190331774218
						},
						{
							year: "2074",
							value: 356.0206698460928
						},
						{
							year: "2075",
							value: 356.2230014643119
						},
						{
							year: "2076",
							value: 356.4260280320788
						},
						{
							year: "2077",
							value: 356.6297495493936
						},
						{
							year: "2078",
							value: 356.8341660162563
						},
						{
							year: "2079",
							value: 356.8243779432519
						},
						{
							year: "2080",
							value: 356.385408883465
						},
						{
							year: "2081",
							value: 355.2956933267398
						},
						{
							year: "2082",
							value: 353.7764096636855
						},
						{
							year: "2083",
							value: 352.0423034687165
						},
						{
							year: "2084",
							value: 350.3081972737471
						},
						{
							year: "2085",
							value: 348.5740910787777
						},
						{
							year: "2086",
							value: 346.8399848838084
						},
						{
							year: "2087",
							value: 345.105878688839
						},
						{
							year: "2088",
							value: 343.3717724938695
						},
						{
							year: "2089",
							value: 341.6101722987985
						},
						{
							year: "2090",
							value: 339.7935841035238
						},
						{
							year: "2091",
							value: 337.8936807564259
						},
						{
							year: "2092",
							value: 335.9387894091244
						},
						{
							year: "2093",
							value: 333.9564040617212
						},
						{
							year: "2094",
							value: 331.974018714318
						},
						{
							year: "2095",
							value: 329.9916333669147
						},
						{
							year: "2096",
							value: 328.0092480195115
						},
						{
							year: "2097",
							value: 326.0268626721084
						},
						{
							year: "2098",
							value: 324.044477324705
						},
						{
							year: "2099",
							value: 322.0620919773019
						},
						{
							year: "2100",
							value: 320.0797066298985
						}
					]
				},
				{
					name: "United States of America",
					data: [
						{
							year: "2020",
							value: 5981.354372479605
						},
						{
							year: "2021",
							value: 6046.820886081444
						},
						{
							year: "2022",
							value: 6065.910295282164
						},
						{
							year: "2023",
							value: 6075.937829784185
						},
						{
							year: "2024",
							value: 6085.947861787751
						},
						{
							year: "2025",
							value: 6095.940391292867
						},
						{
							year: "2026",
							value: 6105.915418299529
						},
						{
							year: "2027",
							value: 6115.872942807733
						},
						{
							year: "2028",
							value: 6125.812964817487
						},
						{
							year: "2029",
							value: 6130.625857421061
						},
						{
							year: "2030",
							value: 6125.207693215298
						},
						{
							year: "2031",
							value: 6104.305407122628
						},
						{
							year: "2032",
							value: 6073.20073445581
						},
						{
							year: "2033",
							value: 6037.014701131734
						},
						{
							year: "2034",
							value: 6000.862633562719
						},
						{
							year: "2035",
							value: 5964.744531748762
						},
						{
							year: "2036",
							value: 5928.660395689863
						},
						{
							year: "2037",
							value: 5892.61022538602
						},
						{
							year: "2038",
							value: 5856.594020837239
						},
						{
							year: "2039",
							value: 5821.027594224105
						},
						{
							year: "2040",
							value: 5786.325663026313
						},
						{
							year: "2041",
							value: 5752.914450391777
						},
						{
							year: "2042",
							value: 5720.362226495392
						},
						{
							year: "2043",
							value: 5688.250989754789
						},
						{
							year: "2044",
							value: 5656.163833288506
						},
						{
							year: "2045",
							value: 5624.100757096533
						},
						{
							year: "2046",
							value: 5592.061761178878
						},
						{
							year: "2047",
							value: 5560.046845535535
						},
						{
							year: "2048",
							value: 5528.056010166504
						},
						{
							year: "2049",
							value: 5497.009636281921
						},
						{
							year: "2050",
							value: 5467.82727044177
						},
						{
							year: "2051",
							value: 5441.455514895589
						},
						{
							year: "2052",
							value: 5416.943568850674
						},
						{
							year: "2053",
							value: 5393.369381796599
						},
						{
							year: "2054",
							value: 5369.811737873076
						},
						{
							year: "2055",
							value: 5346.270637080111
						},
						{
							year: "2056",
							value: 5322.746079417705
						},
						{
							year: "2057",
							value: 5299.238064885857
						},
						{
							year: "2058",
							value: 5275.74659348457
						},
						{
							year: "2059",
							value: 5254.951872672287
						},
						{
							year: "2060",
							value: 5239.532174131219
						},
						{
							year: "2061",
							value: 5232.245052175123
						},
						{
							year: "2062",
							value: 5230.323214949047
						},
						{
							year: "2063",
							value: 5231.082583442016
						},
						{
							year: "2064",
							value: 5231.841014419308
						},
						{
							year: "2065",
							value: 5232.598507880925
						},
						{
							year: "2066",
							value: 5233.355063826866
						},
						{
							year: "2067",
							value: 5234.110682257138
						},
						{
							year: "2068",
							value: 5234.865363171737
						},
						{
							year: "2069",
							value: 5235.861599521948
						},
						{
							year: "2070",
							value: 5237.343122531494
						},
						{
							year: "2071",
							value: 5239.562249967778
						},
						{
							year: "2072",
							value: 5242.272892948964
						},
						{
							year: "2073",
							value: 5245.235035068626
						},
						{
							year: "2074",
							value: 5248.207421647901
						},
						{
							year: "2075",
							value: 5251.190052686796
						},
						{
							year: "2076",
							value: 5254.18292818531
						},
						{
							year: "2077",
							value: 5257.186048143437
						},
						{
							year: "2078",
							value: 5260.199412561182
						},
						{
							year: "2079",
							value: 5260.055123642765
						},
						{
							year: "2080",
							value: 5253.58414913883
						},
						{
							year: "2081",
							value: 5237.520325443533
						},
						{
							year: "2082",
							value: 5215.124109517426
						},
						{
							year: "2083",
							value: 5189.561130249123
						},
						{
							year: "2084",
							value: 5163.998150980818
						},
						{
							year: "2085",
							value: 5138.43517171251
						},
						{
							year: "2086",
							value: 5112.872192444207
						},
						{
							year: "2087",
							value: 5087.309213175903
						},
						{
							year: "2088",
							value: 5061.746233907602
						},
						{
							year: "2089",
							value: 5035.77795734168
						},
						{
							year: "2090",
							value: 5008.999086180521
						},
						{
							year: "2091",
							value: 4980.992041390217
						},
						{
							year: "2092",
							value: 4952.174402004675
						},
						{
							year: "2093",
							value: 4922.951465321518
						},
						{
							year: "2094",
							value: 4893.728528638362
						},
						{
							year: "2095",
							value: 4864.505591955203
						},
						{
							year: "2096",
							value: 4835.282655272048
						},
						{
							year: "2097",
							value: 4806.059718588892
						},
						{
							year: "2098",
							value: 4776.836781905732
						},
						{
							year: "2099",
							value: 4747.613845222576
						},
						{
							year: "2100",
							value: 4718.390908539414
						}
					]
				}
			]
		},
		{
			scenario: "SSP2-2.6",
			groupByRegion: [
				{
					name: "Australia",
					data: [
						{
							year: "2020",
							value: 527.7370476376862
						},
						{
							year: "2021",
							value: 527.5262551348545
						},
						{
							year: "2022",
							value: 522.9043738895539
						},
						{
							year: "2023",
							value: 516.8797860728245
						},
						{
							year: "2024",
							value: 510.8556524487355
						},
						{
							year: "2025",
							value: 504.8319730172871
						},
						{
							year: "2026",
							value: 498.8087477784783
						},
						{
							year: "2027",
							value: 492.7859767323098
						},
						{
							year: "2028",
							value: 486.7636598787821
						},
						{
							year: "2029",
							value: 480.8436792509204
						},
						{
							year: "2030",
							value: 475.1282025106102
						},
						{
							year: "2031",
							value: 469.7227702829229
						},
						{
							year: "2032",
							value: 464.5232787424907
						},
						{
							year: "2033",
							value: 459.4284171140014
						},
						{
							year: "2034",
							value: 454.3365889932859
						},
						{
							year: "2035",
							value: 449.2477943803444
						},
						{
							year: "2036",
							value: 444.1620332751767
						},
						{
							year: "2037",
							value: 439.0793056777494
						},
						{
							year: "2038",
							value: 433.9996115881295
						},
						{
							year: "2039",
							value: 429.1549281655534
						},
						{
							year: "2040",
							value: 424.7771046606488
						},
						{
							year: "2041",
							value: 421.1048920262864
						},
						{
							year: "2042",
							value: 417.8988958903607
						},
						{
							year: "2043",
							value: 414.9268832763154
						},
						{
							year: "2044",
							value: 411.9567491162377
						},
						{
							year: "2045",
							value: 408.9884934101271
						},
						{
							year: "2046",
							value: 406.0221161579839
						},
						{
							year: "2047",
							value: 403.057617359808
						},
						{
							year: "2048",
							value: 400.0949970155989
						},
						{
							year: "2049",
							value: 397.3180363289845
						},
						{
							year: "2050",
							value: 394.9104876015094
						},
						{
							year: "2051",
							value: 393.0616433598423
						},
						{
							year: "2052",
							value: 391.5820656906069
						},
						{
							year: "2053",
							value: 390.2879155858049
						},
						{
							year: "2054",
							value: 388.9953829396088
						},
						{
							year: "2055",
							value: 387.7044677520176
						},
						{
							year: "2056",
							value: 386.4151700230324
						},
						{
							year: "2057",
							value: 385.1274897526525
						},
						{
							year: "2058",
							value: 383.8414269408783
						},
						{
							year: "2059",
							value: 382.631722316926
						},
						{
							year: "2060",
							value: 381.5729898820048
						},
						{
							year: "2061",
							value: 380.7419817798994
						},
						{
							year: "2062",
							value: 380.0613083865462
						},
						{
							year: "2063",
							value: 379.4559755167135
						},
						{
							year: "2064",
							value: 378.8511157131793
						},
						{
							year: "2065",
							value: 378.246728975941
						},
						{
							year: "2066",
							value: 377.6428153050005
						},
						{
							year: "2067",
							value: 377.0393747003574
						},
						{
							year: "2068",
							value: 376.4364071620115
						},
						{
							year: "2069",
							value: 375.4488658971357
						},
						{
							year: "2070",
							value: 373.6919236190745
						},
						{
							year: "2071",
							value: 370.7693044627116
						},
						{
							year: "2072",
							value: 367.078388475719
						},
						{
							year: "2073",
							value: 363.0046614632644
						},
						{
							year: "2074",
							value: 358.9333897243453
						},
						{
							year: "2075",
							value: 354.8645732589619
						},
						{
							year: "2076",
							value: 350.7982120671138
						},
						{
							year: "2077",
							value: 346.734306148801
						},
						{
							year: "2078",
							value: 342.672855504024
						},
						{
							year: "2079",
							value: 338.5083688086652
						},
						{
							year: "2080",
							value: 334.1350828459014
						},
						{
							year: "2081",
							value: 329.4437657994098
						},
						{
							year: "2082",
							value: 324.5422817828053
						},
						{
							year: "2083",
							value: 319.5355783347906
						},
						{
							year: "2084",
							value: 314.528874886776
						},
						{
							year: "2085",
							value: 309.5221714387613
						},
						{
							year: "2086",
							value: 304.5154679907466
						},
						{
							year: "2087",
							value: 299.5087645427321
						},
						{
							year: "2088",
							value: 294.5020610947174
						},
						{
							year: "2089",
							value: 289.5799432025602
						},
						{
							year: "2090",
							value: 284.8269964221175
						},
						{
							year: "2091",
							value: 280.3303695079088
						},
						{
							year: "2092",
							value: 276.0029137054147
						},
						{
							year: "2093",
							value: 271.7600434587781
						},
						{
							year: "2094",
							value: 267.5171732121412
						},
						{
							year: "2095",
							value: 263.2743029655043
						},
						{
							year: "2096",
							value: 259.0314327188675
						},
						{
							year: "2097",
							value: 254.7885624722306
						},
						{
							year: "2098",
							value: 250.5456922255938
						},
						{
							year: "2099",
							value: 246.3028219789572
						},
						{
							year: "2100",
							value: 242.0599517323201
						}
					]
				},
				{
					name: "Canada",
					data: [
						{
							year: "2020",
							value: 672.3540219599809
						},
						{
							year: "2021",
							value: 672.0854655118407
						},
						{
							year: "2022",
							value: 666.197039716817
						},
						{
							year: "2023",
							value: 658.5215205025395
						},
						{
							year: "2024",
							value: 650.8465799443366
						},
						{
							year: "2025",
							value: 643.1722180422084
						},
						{
							year: "2026",
							value: 635.4984347961545
						},
						{
							year: "2027",
							value: 627.8252302061753
						},
						{
							year: "2028",
							value: 620.1526042722708
						},
						{
							year: "2029",
							value: 612.6103579909357
						},
						{
							year: "2030",
							value: 605.3286562590427
						},
						{
							year: "2031",
							value: 598.4419612373721
						},
						{
							year: "2032",
							value: 591.8176412943146
						},
						{
							year: "2033",
							value: 585.3266232341309
						},
						{
							year: "2034",
							value: 578.8394699607028
						},
						{
							year: "2035",
							value: 572.3561814740308
						},
						{
							year: "2036",
							value: 565.8767577741147
						},
						{
							year: "2037",
							value: 559.4011988609545
						},
						{
							year: "2038",
							value: 552.9295047345503
						},
						{
							year: "2039",
							value: 546.757221778724
						},
						{
							year: "2040",
							value: 541.1797334175607
						},
						{
							year: "2041",
							value: 536.5012160664325
						},
						{
							year: "2042",
							value: 532.4166735731074
						},
						{
							year: "2043",
							value: 528.6302336342891
						},
						{
							year: "2044",
							value: 524.8461869064183
						},
						{
							year: "2045",
							value: 521.0645333894959
						},
						{
							year: "2046",
							value: 517.2852730835211
						},
						{
							year: "2047",
							value: 513.5084059884943
						},
						{
							year: "2048",
							value: 509.7339321044151
						},
						{
							year: "2049",
							value: 506.1959946129919
						},
						{
							year: "2050",
							value: 503.128699873635
						},
						{
							year: "2051",
							value: 500.7732126713872
						},
						{
							year: "2052",
							value: 498.8881829938881
						},
						{
							year: "2053",
							value: 497.2393940148337
						},
						{
							year: "2054",
							value: 495.5926657302178
						},
						{
							year: "2055",
							value: 493.947998140039
						},
						{
							year: "2056",
							value: 492.3053912442987
						},
						{
							year: "2057",
							value: 490.6648450429964
						},
						{
							year: "2058",
							value: 489.0263595361321
						},
						{
							year: "2059",
							value: 487.485156823647
						},
						{
							year: "2060",
							value: 486.1362975499139
						},
						{
							year: "2061",
							value: 485.0775664219154
						},
						{
							year: "2062",
							value: 484.2103665622297
						},
						{
							year: "2063",
							value: 483.4391529597768
						},
						{
							year: "2064",
							value: 482.6685420590465
						},
						{
							year: "2065",
							value: 481.8985338600386
						},
						{
							year: "2066",
							value: 481.1291283627534
						},
						{
							year: "2067",
							value: 480.3603255671906
						},
						{
							year: "2068",
							value: 479.5921254733501
						},
						{
							year: "2069",
							value: 478.3339660466755
						},
						{
							year: "2070",
							value: 476.0955649105533
						},
						{
							year: "2071",
							value: 472.3720538301119
						},
						{
							year: "2072",
							value: 467.669707804416
						},
						{
							year: "2073",
							value: 462.4796481839062
						},
						{
							year: "2074",
							value: 457.2927166610806
						},
						{
							year: "2075",
							value: 452.1089132359396
						},
						{
							year: "2076",
							value: 446.9282379084827
						},
						{
							year: "2077",
							value: 441.7506906787098
						},
						{
							year: "2078",
							value: 436.5762715466214
						},
						{
							year: "2079",
							value: 431.2705811624471
						},
						{
							year: "2080",
							value: 425.6988737763389
						},
						{
							year: "2081",
							value: 419.7219845307991
						},
						{
							year: "2082",
							value: 413.4773357859574
						},
						{
							year: "2083",
							value: 407.0986340914249
						},
						{
							year: "2084",
							value: 400.7199323968923
						},
						{
							year: "2085",
							value: 394.3412307023597
						},
						{
							year: "2086",
							value: 387.9625290078272
						},
						{
							year: "2087",
							value: 381.5838273132946
						},
						{
							year: "2088",
							value: 375.2051256187619
						},
						{
							year: "2089",
							value: 368.9341886508769
						},
						{
							year: "2090",
							value: 362.8787811362865
						},
						{
							year: "2091",
							value: 357.1499333994153
						},
						{
							year: "2092",
							value: 351.6366151158389
						},
						{
							year: "2093",
							value: 346.23106155891
						},
						{
							year: "2094",
							value: 340.8255080019809
						},
						{
							year: "2095",
							value: 335.419954445052
						},
						{
							year: "2096",
							value: 330.014400888123
						},
						{
							year: "2097",
							value: 324.6088473311939
						},
						{
							year: "2098",
							value: 319.2032937742649
						},
						{
							year: "2099",
							value: 313.7977402173361
						},
						{
							year: "2100",
							value: 308.3921866604068
						}
					]
				},
				{
					name: "China",
					data: [
						{
							year: "2020",
							value: 13106.41681037749
						},
						{
							year: "2021",
							value: 12677.37170208135
						},
						{
							year: "2022",
							value: 12566.29987212177
						},
						{
							year: "2023",
							value: 12421.51856813722
						},
						{
							year: "2024",
							value: 12276.7481791903
						},
						{
							year: "2025",
							value: 12131.98870528102
						},
						{
							year: "2026",
							value: 11987.24014640936
						},
						{
							year: "2027",
							value: 11842.50250257532
						},
						{
							year: "2028",
							value: 11697.77577377892
						},
						{
							year: "2029",
							value: 11555.50836214206
						},
						{
							year: "2030",
							value: 11418.15553394395
						},
						{
							year: "2031",
							value: 11288.25361362478
						},
						{
							year: "2032",
							value: 11163.30080553557
						},
						{
							year: "2033",
							value: 11040.86243586898
						},
						{
							year: "2034",
							value: 10918.49696666041
						},
						{
							year: "2035",
							value: 10796.20439790984
						},
						{
							year: "2036",
							value: 10673.98472961727
						},
						{
							year: "2037",
							value: 10551.83796178272
						},
						{
							year: "2038",
							value: 10429.76409440615
						},
						{
							year: "2039",
							value: 10313.33794133968
						},
						{
							year: "2040",
							value: 10208.13124256864
						},
						{
							year: "2041",
							value: 10119.88159796471
						},
						{
							year: "2042",
							value: 10042.83594517501
						},
						{
							year: "2043",
							value: 9971.413322613982
						},
						{
							year: "2044",
							value: 9900.035842562804
						},
						{
							year: "2045",
							value: 9828.703505021484
						},
						{
							year: "2046",
							value: 9757.416309990012
						},
						{
							year: "2047",
							value: 9686.174257468387
						},
						{
							year: "2048",
							value: 9614.977347456608
						},
						{
							year: "2049",
							value: 9548.242161324673
						},
						{
							year: "2050",
							value: 9490.384585873237
						},
						{
							year: "2051",
							value: 9445.9536491327
						},
						{
							year: "2052",
							value: 9410.396829178397
						},
						{
							year: "2053",
							value: 9379.29615550165
						},
						{
							year: "2054",
							value: 9348.234352163123
						},
						{
							year: "2055",
							value: 9317.211419162797
						},
						{
							year: "2056",
							value: 9286.227356500704
						},
						{
							year: "2057",
							value: 9255.282164176828
						},
						{
							year: "2058",
							value: 9224.375842191166
						},
						{
							year: "2059",
							value: 9195.30454001749
						},
						{
							year: "2060",
							value: 9169.861361635567
						},
						{
							year: "2061",
							value: 9149.890794303064
						},
						{
							year: "2062",
							value: 9133.533031004532
						},
						{
							year: "2063",
							value: 9118.985831278149
						},
						{
							year: "2064",
							value: 9104.450000156145
						},
						{
							year: "2065",
							value: 9089.925537638512
						},
						{
							year: "2066",
							value: 9075.41244372524
						},
						{
							year: "2067",
							value: 9060.910718416355
						},
						{
							year: "2068",
							value: 9046.420361711833
						},
						{
							year: "2069",
							value: 9022.688030734724
						},
						{
							year: "2070",
							value: 8980.465657722421
						},
						{
							year: "2071",
							value: 8910.23004569707
						},
						{
							year: "2072",
							value: 8821.530927060208
						},
						{
							year: "2073",
							value: 8723.632194917474
						},
						{
							year: "2074",
							value: 8625.792467260186
						},
						{
							year: "2075",
							value: 8528.011744088339
						},
						{
							year: "2076",
							value: 8430.29002540193
						},
						{
							year: "2077",
							value: 8332.627311200951
						},
						{
							year: "2078",
							value: 8235.023601485416
						},
						{
							year: "2079",
							value: 8134.943756602726
						},
						{
							year: "2080",
							value: 8029.8461028465449
						},
						{
							year: "2081",
							value: 7917.105610043034
						},
						{
							year: "2082",
							value: 7799.314440095615
						},
						{
							year: "2083",
							value: 7678.994664549356
						},
						{
							year: "2084",
							value: 7558.674889003099
						},
						{
							year: "2085",
							value: 7438.355113456843
						},
						{
							year: "2086",
							value: 7318.035337910589
						},
						{
							year: "2087",
							value: 7197.715562364336
						},
						{
							year: "2088",
							value: 7077.39578681808
						},
						{
							year: "2089",
							value: 6959.108749020502
						},
						{
							year: "2090",
							value: 6844.887186720272
						},
						{
							year: "2091",
							value: 6736.82543577966
						},
						{
							year: "2092",
							value: 6632.829160336404
						},
						{
							year: "2093",
							value: 6530.865622641825
						},
						{
							year: "2094",
							value: 6428.902084947244
						},
						{
							year: "2095",
							value: 6326.93854725266
						},
						{
							year: "2096",
							value: 6224.975009558081
						},
						{
							year: "2097",
							value: 6123.011471863502
						},
						{
							year: "2098",
							value: 6021.047934168919
						},
						{
							year: "2099",
							value: 5919.084396474342
						},
						{
							year: "2100",
							value: 5817.120858779755
						}
					]
				},
				{
					name: "France",
					data: [
						{
							year: "2020",
							value: 399.412668092019
						},
						{
							year: "2021",
							value: 399.253131829885
						},
						{
							year: "2022",
							value: 395.7551058185346
						},
						{
							year: "2023",
							value: 391.1954549378434
						},
						{
							year: "2024",
							value: 386.6361478084485
						},
						{
							year: "2025",
							value: 382.07718443035
						},
						{
							year: "2026",
							value: 377.5185648035479
						},
						{
							year: "2027",
							value: 372.9602889280421
						},
						{
							year: "2028",
							value: 368.4023568038331
						},
						{
							year: "2029",
							value: 363.9218768598694
						},
						{
							year: "2030",
							value: 359.5961737005318
						},
						{
							year: "2031",
							value: 355.5051247246916
						},
						{
							year: "2032",
							value: 351.5699399614045
						},
						{
							year: "2033",
							value: 347.7139433325843
						},
						{
							year: "2034",
							value: 343.8602425847128
						},
						{
							year: "2035",
							value: 340.0088377177899
						},
						{
							year: "2036",
							value: 336.1597287318157
						},
						{
							year: "2037",
							value: 332.3129156267904
						},
						{
							year: "2038",
							value: 328.4683984027134
						},
						{
							year: "2039",
							value: 324.8017467235595
						},
						{
							year: "2040",
							value: 321.4884334468867
						},
						{
							year: "2041",
							value: 318.709154916684
						},
						{
							year: "2042",
							value: 316.2827278233524
						},
						{
							year: "2043",
							value: 314.033388890085
						},
						{
							year: "2044",
							value: 311.7854716464914
						},
						{
							year: "2045",
							value: 309.5389760925711
						},
						{
							year: "2046",
							value: 307.2939022283244
						},
						{
							year: "2047",
							value: 305.0502500537513
						},
						{
							year: "2048",
							value: 302.8080195688514
						},
						{
							year: "2049",
							value: 300.7063037958532
						},
						{
							year: "2050",
							value: 298.884173882657
						},
						{
							year: "2051",
							value: 297.4848940429026
						},
						{
							year: "2052",
							value: 296.3650900284525
						},
						{
							year: "2053",
							value: 295.3856250684228
						},
						{
							year: "2054",
							value: 294.4073842662577
						},
						{
							year: "2055",
							value: 293.4303676219566
						},
						{
							year: "2056",
							value: 292.4545751355201
						},
						{
							year: "2057",
							value: 291.4800068069478
						},
						{
							year: "2058",
							value: 290.5066626362399
						},
						{
							year: "2059",
							value: 289.5911094196921
						},
						{
							year: "2060",
							value: 288.7898180407368
						},
						{
							year: "2061",
							value: 288.1608776152848
						},
						{
							year: "2062",
							value: 287.6457165566562
						},
						{
							year: "2063",
							value: 287.1875762428276
						},
						{
							year: "2064",
							value: 286.7297939646401
						},
						{
							year: "2065",
							value: 286.2723697220931
						},
						{
							year: "2066",
							value: 285.8153035151865
						},
						{
							year: "2067",
							value: 285.358595343921
						},
						{
							year: "2068",
							value: 284.902245208296
						},
						{
							year: "2069",
							value: 284.1548341761909
						},
						{
							year: "2070",
							value: 282.825109446602
						},
						{
							year: "2071",
							value: 280.6131534729115
						},
						{
							year: "2072",
							value: 277.8197194915995
						},
						{
							year: "2073",
							value: 274.7365586970207
						},
						{
							year: "2074",
							value: 271.6552561524132
						},
						{
							year: "2075",
							value: 268.5758118577775
						},
						{
							year: "2076",
							value: 265.4982258131131
						},
						{
							year: "2077",
							value: 262.42249801842
						},
						{
							year: "2078",
							value: 259.3486284736987
						},
						{
							year: "2079",
							value: 256.1967770930293
						},
						{
							year: "2080",
							value: 252.886898011134
						},
						{
							year: "2081",
							value: 249.336320186832
						},
						{
							year: "2082",
							value: 245.6266795287694
						},
						{
							year: "2083",
							value: 241.8374045641467
						},
						{
							year: "2084",
							value: 238.0481295995241
						},
						{
							year: "2085",
							value: 234.2588546349016
						},
						{
							year: "2086",
							value: 230.469579670279
						},
						{
							year: "2087",
							value: 226.6803047056565
						},
						{
							year: "2088",
							value: 222.8910297410339
						},
						{
							year: "2089",
							value: 219.1657725343047
						},
						{
							year: "2090",
							value: 215.5685508433621
						},
						{
							year: "2091",
							value: 212.1653223581567
						},
						{
							year: "2092",
							value: 208.890129388738
						},
						{
							year: "2093",
							value: 205.6789541772129
						},
						{
							year: "2094",
							value: 202.4677789656875
						},
						{
							year: "2095",
							value: 199.25660375416209
						},
						{
							year: "2096",
							value: 196.0454285426368
						},
						{
							year: "2097",
							value: 192.8342533311115
						},
						{
							year: "2098",
							value: 189.6230781195862
						},
						{
							year: "2099",
							value: 186.411902908061
						},
						{
							year: "2100",
							value: 183.2007276965355
						}
					]
				},
				{
					name: "Global",
					data: [
						{
							year: "2020",
							value: 49017.00912423762
						},
						{
							year: "2021",
							value: 47764.238955468514
						},
						{
							year: "2022",
							value: 47345.75620114968
						},
						{
							year: "2023",
							value: 46800.267044381726
						},
						{
							year: "2024",
							value: 46254.81901194757
						},
						{
							year: "2025",
							value: 45709.41210384722
						},
						{
							year: "2026",
							value: 45164.04632008066
						},
						{
							year: "2027",
							value: 44618.72166064787
						},
						{
							year: "2028",
							value: 44073.438125548884
						},
						{
							year: "2029",
							value: 43537.420502599205
						},
						{
							year: "2030",
							value: 43019.919441540405
						},
						{
							year: "2031",
							value: 42530.490993064726
						},
						{
							year: "2032",
							value: 42059.70919980469
						},
						{
							year: "2033",
							value: 41598.400997796816
						},
						{
							year: "2034",
							value: 41137.36746114561
						},
						{
							year: "2035",
							value: 40676.60858986313
						},
						{
							year: "2036",
							value: 40216.12438394331
						},
						{
							year: "2037",
							value: 39755.914843386134
						},
						{
							year: "2038",
							value: 39295.979968191605
						},
						{
							year: "2039",
							value: 38857.32385504638
						},
						{
							year: "2040",
							value: 38460.939019300786
						},
						{
							year: "2041",
							value: 38128.44288274712
						},
						{
							year: "2042",
							value: 37838.159765961755
						},
						{
							year: "2043",
							value: 37569.06240958537
						},
						{
							year: "2044",
							value: 37300.13513559506
						},
						{
							year: "2045",
							value: 37031.37794399075
						},
						{
							year: "2046",
							value: 36762.79083477244
						},
						{
							year: "2047",
							value: 36494.37380794016
						},
						{
							year: "2048",
							value: 36226.126863493955
						},
						{
							year: "2049",
							value: 35974.69025249516
						},
						{
							year: "2050",
							value: 35756.70160909265
						},
						{
							year: "2051",
							value: 35589.30020055452
						},
						{
							year: "2052",
							value: 35455.33359574834
						},
						{
							year: "2053",
							value: 35338.15630978709
						},
						{
							year: "2054",
							value: 35221.125474696455
						},
						{
							year: "2055",
							value: 35104.24109047639
						},
						{
							year: "2056",
							value: 34987.50315712699
						},
						{
							year: "2057",
							value: 34870.91167464818
						},
						{
							year: "2058",
							value: 34754.46664304
						},
						{
							year: "2059",
							value: 34644.9353729628
						},
						{
							year: "2060",
							value: 34549.07370063988
						},
						{
							year: "2061",
							value: 34473.83105787748
						},
						{
							year: "2062",
							value: 34412.200292973765
						},
						{
							year: "2063",
							value: 34357.39114639415
						},
						{
							year: "2064",
							value: 34302.624833041235
						},
						{
							year: "2065",
							value: 34247.90135291498
						},
						{
							year: "2066",
							value: 34193.22070601539
						},
						{
							year: "2067",
							value: 34138.582892342514
						},
						{
							year: "2068",
							value: 34083.9879118963
						},
						{
							year: "2069",
							value: 33994.572159609525
						},
						{
							year: "2070",
							value: 33835.49190534081
						},
						{
							year: "2071",
							value: 33570.86682099375
						},
						{
							year: "2072",
							value: 33236.67721156435
						},
						{
							year: "2073",
							value: 32867.826431971414
						},
						{
							year: "2074",
							value: 32499.197962208018
						},
						{
							year: "2075",
							value: 32130.791802274096
						},
						{
							year: "2076",
							value: 31762.60795216976
						},
						{
							year: "2077",
							value: 31394.646411895
						},
						{
							year: "2078",
							value: 31026.907181449715
						},
						{
							year: "2079",
							value: 30649.83867403839
						},
						{
							year: "2080",
							value: 30253.864684662934
						},
						{
							year: "2081",
							value: 29829.094948098438
						},
						{
							year: "2082",
							value: 29385.295892550654
						},
						{
							year: "2083",
							value: 28931.969868409906
						},
						{
							year: "2084",
							value: 28478.643844269165
						},
						{
							year: "2085",
							value: 28025.317820128425
						},
						{
							year: "2086",
							value: 27571.991795987677
						},
						{
							year: "2087",
							value: 27118.665771846943
						},
						{
							year: "2088",
							value: 26665.339747706203
						},
						{
							year: "2089",
							value: 26219.67242237491
						},
						{
							year: "2090",
							value: 25789.32249466248
						},
						{
							year: "2091",
							value: 25382.18074516046
						},
						{
							year: "2092",
							value: 24990.356393277314
						},
						{
							year: "2093",
							value: 24606.190740203623
						},
						{
							year: "2094",
							value: 24222.02508712991
						},
						{
							year: "2095",
							value: 23837.859434056205
						},
						{
							year: "2096",
							value: 23453.693780982496
						},
						{
							year: "2097",
							value: 23069.5281279088
						},
						{
							year: "2098",
							value: 22685.362474835085
						},
						{
							year: "2099",
							value: 22301.196821761394
						},
						{
							year: "2100",
							value: 21917.031168687663
						}
					]
				},
				{
					name: "India",
					data: [
						{
							year: "2020",
							value: 3502.106733367986
						},
						{
							year: "2021",
							value: 3387.463518184042
						},
						{
							year: "2022",
							value: 3357.784513676804
						},
						{
							year: "2023",
							value: 3319.098152111635
						},
						{
							year: "2024",
							value: 3280.414707104616
						},
						{
							year: "2025",
							value: 3241.734178655749
						},
						{
							year: "2026",
							value: 3203.056566765032
						},
						{
							year: "2027",
							value: 3164.381871432467
						},
						{
							year: "2028",
							value: 3125.71009265805
						},
						{
							year: "2029",
							value: 3087.695456969233
						},
						{
							year: "2030",
							value: 3050.994025034086
						},
						{
							year: "2031",
							value: 3016.283516707585
						},
						{
							year: "2032",
							value: 2982.895438417873
						},
						{
							year: "2033",
							value: 2950.179231918747
						},
						{
							year: "2034",
							value: 2917.482504823379
						},
						{
							year: "2035",
							value: 2884.805257131764
						},
						{
							year: "2036",
							value: 2852.147488843907
						},
						{
							year: "2037",
							value: 2819.509199959805
						},
						{
							year: "2038",
							value: 2786.890390479458
						},
						{
							year: "2039",
							value: 2755.780681358094
						},
						{
							year: "2040",
							value: 2727.668872196815
						},
						{
							year: "2041",
							value: 2704.08808127156
						},
						{
							year: "2042",
							value: 2683.501058646284
						},
						{
							year: "2043",
							value: 2664.416540657528
						},
						{
							year: "2044",
							value: 2645.344084995952
						},
						{
							year: "2045",
							value: 2626.283691661555
						},
						{
							year: "2046",
							value: 2607.235360654336
						},
						{
							year: "2047",
							value: 2588.199091974297
						},
						{
							year: "2048",
							value: 2569.174885621434
						},
						{
							year: "2049",
							value: 2551.342876454735
						},
						{
							year: "2050",
							value: 2535.883013740436
						},
						{
							year: "2051",
							value: 2524.01082281441
						},
						{
							year: "2052",
							value: 2514.509844752998
						},
						{
							year: "2053",
							value: 2506.199573511709
						},
						{
							year: "2054",
							value: 2497.899688638807
						},
						{
							year: "2055",
							value: 2489.610190134286
						},
						{
							year: "2056",
							value: 2481.331077998153
						},
						{
							year: "2057",
							value: 2473.062352230404
						},
						{
							year: "2058",
							value: 2464.804012831041
						},
						{
							year: "2059",
							value: 2457.036000828738
						},
						{
							year: "2060",
							value: 2450.237443479404
						},
						{
							year: "2061",
							value: 2444.901197933714
						},
						{
							year: "2062",
							value: 2440.530313517368
						},
						{
							year: "2063",
							value: 2436.643221656144
						},
						{
							year: "2064",
							value: 2432.759167548606
						},
						{
							year: "2065",
							value: 2428.878151194748
						},
						{
							year: "2066",
							value: 2425.000172594571
						},
						{
							year: "2067",
							value: 2421.125231748077
						},
						{
							year: "2068",
							value: 2417.253328655263
						},
						{
							year: "2069",
							value: 2410.911919152123
						},
						{
							year: "2070",
							value: 2399.629868614246
						},
						{
							year: "2071",
							value: 2380.862526376093
						},
						{
							year: "2072",
							value: 2357.161633514514
						},
						{
							year: "2073",
							value: 2331.002553272705
						},
						{
							year: "2074",
							value: 2304.859239354276
						},
						{
							year: "2075",
							value: 2278.731691759224
						},
						{
							year: "2076",
							value: 2252.619910487549
						},
						{
							year: "2077",
							value: 2226.523895539251
						},
						{
							year: "2078",
							value: 2200.443646914332
						},
						{
							year: "2079",
							value: 2173.701761339582
						},
						{
							year: "2080",
							value: 2145.619089606658
						},
						{
							year: "2081",
							value: 2115.494209200156
						},
						{
							year: "2082",
							value: 2084.019760052657
						},
						{
							year: "2083",
							value: 2051.86965356709
						},
						{
							year: "2084",
							value: 2019.7195470815209
						},
						{
							year: "2085",
							value: 1987.569440595954
						},
						{
							year: "2086",
							value: 1955.419334110387
						},
						{
							year: "2087",
							value: 1923.26922762482
						},
						{
							year: "2088",
							value: 1891.119121139252
						},
						{
							year: "2089",
							value: 1859.512173372029
						},
						{
							year: "2090",
							value: 1828.991543041494
						},
						{
							year: "2091",
							value: 1800.116848221094
						},
						{
							year: "2092",
							value: 1772.32847083738
						},
						{
							year: "2093",
							value: 1745.08325217201
						},
						{
							year: "2094",
							value: 1717.83803350664
						},
						{
							year: "2095",
							value: 1690.592814841271
						},
						{
							year: "2096",
							value: 1663.347596175901
						},
						{
							year: "2097",
							value: 1636.102377510532
						},
						{
							year: "2098",
							value: 1608.857158845161
						},
						{
							year: "2099",
							value: 1581.611940179793
						},
						{
							year: "2100",
							value: 1554.366721514421
						}
					]
				},
				{
					name: "Netherlands",
					data: [
						{
							year: "2020",
							value: 163.9151815480303
						},
						{
							year: "2021",
							value: 163.8497096990177
						},
						{
							year: "2022",
							value: 162.4141529036271
						},
						{
							year: "2023",
							value: 160.542915301292
						},
						{
							year: "2024",
							value: 158.6718187712383
						},
						{
							year: "2025",
							value: 156.8008633134658
						},
						{
							year: "2026",
							value: 154.9300489279746
						},
						{
							year: "2027",
							value: 153.0593756147647
						},
						{
							year: "2028",
							value: 151.1888433738361
						},
						{
							year: "2029",
							value: 149.3500967752408
						},
						{
							year: "2030",
							value: 147.5748691053841
						},
						{
							year: "2031",
							value: 145.8959412933875
						},
						{
							year: "2032",
							value: 144.2809786802669
						},
						{
							year: "2033",
							value: 142.6985141286756
						},
						{
							year: "2034",
							value: 141.1169917849143
						},
						{
							year: "2035",
							value: 139.5364116489829
						},
						{
							year: "2036",
							value: 137.9567737208815
						},
						{
							year: "2037",
							value: 136.3780780006102
						},
						{
							year: "2038",
							value: 134.8003244881686
						},
						{
							year: "2039",
							value: 133.2955653133483
						},
						{
							year: "2040",
							value: 131.9358128775025
						},
						{
							year: "2041",
							value: 130.7952232514192
						},
						{
							year: "2042",
							value: 129.7994405182292
						},
						{
							year: "2043",
							value: 128.8763330912658
						},
						{
							year: "2044",
							value: 127.9538091123001
						},
						{
							year: "2045",
							value: 127.0318685813319
						},
						{
							year: "2046",
							value: 126.1105114983612
						},
						{
							year: "2047",
							value: 125.1897378633881
						},
						{
							year: "2048",
							value: 124.2695476764126
						},
						{
							year: "2049",
							value: 123.4070233984006
						},
						{
							year: "2050",
							value: 122.6592385133009
						},
						{
							year: "2051",
							value: 122.0849872995895
						},
						{
							year: "2052",
							value: 121.6254303216729
						},
						{
							year: "2053",
							value: 121.2234671645508
						},
						{
							year: "2054",
							value: 120.8220063902399
						},
						{
							year: "2055",
							value: 120.4210479987399
						},
						{
							year: "2056",
							value: 120.0205919900511
						},
						{
							year: "2057",
							value: 119.6206383641733
						},
						{
							year: "2058",
							value: 119.2211871211066
						},
						{
							year: "2059",
							value: 118.8454527391173
						},
						{
							year: "2060",
							value: 118.51661033474
						},
						{
							year: "2061",
							value: 118.2584991318147
						},
						{
							year: "2062",
							value: 118.0470819050601
						},
						{
							year: "2063",
							value: 117.8590654527454
						},
						{
							year: "2064",
							value: 117.6711959348718
						},
						{
							year: "2065",
							value: 117.4834733514394
						},
						{
							year: "2066",
							value: 117.295897702448
						},
						{
							year: "2067",
							value: 117.1084689878978
						},
						{
							year: "2068",
							value: 116.9211872077887
						},
						{
							year: "2069",
							value: 116.6144567882301
						},
						{
							year: "2070",
							value: 116.0687503339709
						},
						{
							year: "2071",
							value: 115.1609845200996
						},
						{
							year: "2072",
							value: 114.0145856307445
						},
						{
							year: "2073",
							value: 112.7492855970747
						},
						{
							year: "2074",
							value: 111.4847481716203
						},
						{
							year: "2075",
							value: 110.2209733543814
						},
						{
							year: "2076",
							value: 108.9579611453578
						},
						{
							year: "2077",
							value: 107.6957115445497
						},
						{
							year: "2078",
							value: 106.4342245519571
						},
						{
							year: "2079",
							value: 105.1407345513398
						},
						{
							year: "2080",
							value: 103.7823914765546
						},
						{
							year: "2081",
							value: 102.3252679140937
						},
						{
							year: "2082",
							value: 100.8028664688616
						},
						{
							year: "2083",
							value: 99.24778385729252
						},
						{
							year: "2084",
							value: 97.69270124572332
						},
						{
							year: "2085",
							value: 96.13761863415425
						},
						{
							year: "2086",
							value: 94.5825360225851
						},
						{
							year: "2087",
							value: 93.02745341101601
						},
						{
							year: "2088",
							value: 91.47237079944688
						},
						{
							year: "2089",
							value: 89.94356047032258
						},
						{
							year: "2090",
							value: 88.46729470608784
						},
						{
							year: "2091",
							value: 87.07064191895855
						},
						{
							year: "2092",
							value: 85.72653369671878
						},
						{
							year: "2093",
							value: 84.40869775692387
						},
						{
							year: "2094",
							value: 83.0908618171289
						},
						{
							year: "2095",
							value: 81.773025877334
						},
						{
							year: "2096",
							value: 80.45518993753903
						},
						{
							year: "2097",
							value: 79.13735399774413
						},
						{
							year: "2098",
							value: 77.81951805794916
						},
						{
							year: "2099",
							value: 76.5016821181543
						},
						{
							year: "2100",
							value: 75.18384617835925
						}
					]
				},
				{
					name: "Remaining Advanced Economies",
					data: [
						{
							year: "2020",
							value: 1474.438235613005
						},
						{
							year: "2021",
							value: 1426.171762661094
						},
						{
							year: "2022",
							value: 1413.676467008492
						},
						{
							year: "2023",
							value: 1397.388942089575
						},
						{
							year: "2024",
							value: 1381.102645084362
						},
						{
							year: "2025",
							value: 1364.817575992856
						},
						{
							year: "2026",
							value: 1348.533734815056
						},
						{
							year: "2027",
							value: 1332.25112155096
						},
						{
							year: "2028",
							value: 1315.969736200546
						},
						{
							year: "2029",
							value: 1299.9650177154
						},
						{
							year: "2030",
							value: 1284.51317724711
						},
						{
							year: "2031",
							value: 1269.899544782247
						},
						{
							year: "2032",
							value: 1255.842674734587
						},
						{
							year: "2033",
							value: 1242.068672552726
						},
						{
							year: "2034",
							value: 1228.302871485192
						},
						{
							year: "2035",
							value: 1214.545271531985
						},
						{
							year: "2036",
							value: 1200.795872693104
						},
						{
							year: "2037",
							value: 1187.05467496855
						},
						{
							year: "2038",
							value: 1173.321678358323
						},
						{
							year: "2039",
							value: 1160.224035105392
						},
						{
							year: "2040",
							value: 1148.388551650615
						},
						{
							year: "2041",
							value: 1138.460693246152
						},
						{
							year: "2042",
							value: 1129.793255150413
						},
						{
							year: "2043",
							value: 1121.758393516198
						},
						{
							year: "2044",
							value: 1113.728610298422
						},
						{
							year: "2045",
							value: 1105.703905497083
						},
						{
							year: "2046",
							value: 1097.684279112183
						},
						{
							year: "2047",
							value: 1089.66973114372
						},
						{
							year: "2048",
							value: 1081.660261591696
						},
						{
							year: "2049",
							value: 1074.152724519033
						},
						{
							year: "2050",
							value: 1067.64389585139
						},
						{
							year: "2051",
							value: 1062.645529560882
						},
						{
							year: "2052",
							value: 1058.64547862126
						},
						{
							year: "2053",
							value: 1055.146732695065
						},
						{
							year: "2054",
							value: 1051.652359582107
						},
						{
							year: "2055",
							value: 1048.162359282382
						},
						{
							year: "2056",
							value: 1044.676731795894
						},
						{
							year: "2057",
							value: 1041.195477122641
						},
						{
							year: "2058",
							value: 1037.718595262624
						},
						{
							year: "2059",
							value: 1034.448148419366
						},
						{
							year: "2060",
							value: 1031.585856186135
						},
						{
							year: "2061",
							value: 1029.339218643019
						},
						{
							year: "2062",
							value: 1027.499012276518
						},
						{
							year: "2063",
							value: 1025.862489662596
						},
						{
							year: "2064",
							value: 1024.227245987473
						},
						{
							year: "2065",
							value: 1022.593281251149
						},
						{
							year: "2066",
							value: 1020.960595453624
						},
						{
							year: "2067",
							value: 1019.329188594897
						},
						{
							year: "2068",
							value: 1017.699060674968
						},
						{
							year: "2069",
							value: 1015.029234381548
						},
						{
							year: "2070",
							value: 1010.279325839164
						},
						{
							year: "2071",
							value: 1002.377999842126
						},
						{
							year: "2072",
							value: 992.399576763149
						},
						{
							year: "2073",
							value: 981.3862207881591
						},
						{
							year: "2074",
							value: 970.3795026662658
						},
						{
							year: "2075",
							value: 959.3794223974692
						},
						{
							year: "2076",
							value: 948.385979981768
						},
						{
							year: "2077",
							value: 937.3991754191626
						},
						{
							year: "2078",
							value: 926.4190087096538
						},
						{
							year: "2079",
							value: 915.1602831522295
						},
						{
							year: "2080",
							value: 903.3370669816105
						},
						{
							year: "2081",
							value: 890.6540510439724
						},
						{
							year: "2082",
							value: 877.4028468971189
						},
						{
							year: "2083",
							value: 863.8671811135231
						},
						{
							year: "2084",
							value: 850.3315153299274
						},
						{
							year: "2085",
							value: 836.7958495463314
						},
						{
							year: "2086",
							value: 823.2601837627356
						},
						{
							year: "2087",
							value: 809.7245179791397
						},
						{
							year: "2088",
							value: 796.1888521955438
						},
						{
							year: "2089",
							value: 782.8818641888712
						},
						{
							year: "2090",
							value: 770.0322317360434
						},
						{
							year: "2091",
							value: 757.8755622435884
						},
						{
							year: "2092",
							value: 746.1762483049786
						},
						{
							year: "2093",
							value: 734.7056121432925
						},
						{
							year: "2094",
							value: 723.2349759816052
						},
						{
							year: "2095",
							value: 711.7643398199185
						},
						{
							year: "2096",
							value: 700.2937036582318
						},
						{
							year: "2097",
							value: 688.823067496545
						},
						{
							year: "2098",
							value: 677.352431334858
						},
						{
							year: "2099",
							value: 665.8817951731717
						},
						{
							year: "2100",
							value: 654.411159011484
						}
					]
				},
				{
					name: "Rest of Asia",
					data: [
						{
							year: "2020",
							value: 4437.550619530948
						},
						{
							year: "2021",
							value: 4292.285180954426
						},
						{
							year: "2022",
							value: 4254.678650124482
						},
						{
							year: "2023",
							value: 4205.658816977986
						},
						{
							year: "2024",
							value: 4156.64267942829
						},
						{
							year: "2025",
							value: 4107.630237475392
						},
						{
							year: "2026",
							value: 4058.621491119289
						},
						{
							year: "2027",
							value: 4009.616440359981
						},
						{
							year: "2028",
							value: 3960.615085197476
						},
						{
							year: "2029",
							value: 3912.446401888979
						},
						{
							year: "2030",
							value: 3865.941690747621
						},
						{
							year: "2031",
							value: 3821.959696635073
						},
						{
							year: "2032",
							value: 3779.653365395143
						},
						{
							year: "2033",
							value: 3738.198368882436
						},
						{
							year: "2034",
							value: 3696.768054889623
						},
						{
							year: "2035",
							value: 3655.362423428815
						},
						{
							year: "2036",
							value: 3613.981474493918
						},
						{
							year: "2037",
							value: 3572.625208084941
						},
						{
							year: "2038",
							value: 3531.29362420188
						},
						{
							year: "2039",
							value: 3491.874234818502
						},
						{
							year: "2040",
							value: 3456.253511163518
						},
						{
							year: "2041",
							value: 3426.374081053006
						},
						{
							year: "2042",
							value: 3400.288081407562
						},
						{
							year: "2043",
							value: 3376.105918763163
						},
						{
							year: "2044",
							value: 3351.939040400955
						},
						{
							year: "2045",
							value: 3327.787446320875
						},
						{
							year: "2046",
							value: 3303.651136522944
						},
						{
							year: "2047",
							value: 3279.53011100717
						},
						{
							year: "2048",
							value: 3255.424369773635
						},
						{
							year: "2049",
							value: 3232.829272208194
						},
						{
							year: "2050",
							value: 3213.239942530731
						},
						{
							year: "2051",
							value: 3198.196583715613
						},
						{
							year: "2052",
							value: 3186.157809831087
						},
						{
							year: "2053",
							value: 3175.627791158661
						},
						{
							year: "2054",
							value: 3165.110933146084
						},
						{
							year: "2055",
							value: 3154.607235793347
						},
						{
							year: "2056",
							value: 3144.116699100461
						},
						{
							year: "2057",
							value: 3133.639323067414
						},
						{
							year: "2058",
							value: 3123.175107694218
						},
						{
							year: "2059",
							value: 3113.332190531
						},
						{
							year: "2060",
							value: 3104.717677989177
						},
						{
							year: "2061",
							value: 3097.956073752051
						},
						{
							year: "2062",
							value: 3092.417687196088
						},
						{
							year: "2063",
							value: 3087.492318493711
						},
						{
							year: "2064",
							value: 3082.57079895607
						},
						{
							year: "2065",
							value: 3077.653128583162
						},
						{
							year: "2066",
							value: 3072.739307374989
						},
						{
							year: "2067",
							value: 3067.82933533155
						},
						{
							year: "2068",
							value: 3062.923212452842
						},
						{
							year: "2069",
							value: 3054.887956016708
						},
						{
							year: "2070",
							value: 3040.592369341168
						},
						{
							year: "2071",
							value: 3016.812102914045
						},
						{
							year: "2072",
							value: 2986.780490570801
						},
						{
							year: "2073",
							value: 2953.634087113939
						},
						{
							year: "2074",
							value: 2920.507661305789
						},
						{
							year: "2075",
							value: 2887.401213146301
						},
						{
							year: "2076",
							value: 2854.314742635577
						},
						{
							year: "2077",
							value: 2821.248249773634
						},
						{
							year: "2078",
							value: 2788.201734560334
						},
						{
							year: "2079",
							value: 2754.316853277649
						},
						{
							year: "2080",
							value: 2718.733049917528
						},
						{
							year: "2081",
							value: 2680.561545766256
						},
						{
							year: "2082",
							value: 2640.679991048666
						},
						{
							year: "2083",
							value: 2599.942304902937
						},
						{
							year: "2084",
							value: 2559.204618757209
						},
						{
							year: "2085",
							value: 2518.466932611481
						},
						{
							year: "2086",
							value: 2477.729246465742
						},
						{
							year: "2087",
							value: 2436.991560320014
						},
						{
							year: "2088",
							value: 2396.253874174286
						},
						{
							year: "2089",
							value: 2356.204429276069
						},
						{
							year: "2090",
							value: 2317.531466872872
						},
						{
							year: "2091",
							value: 2280.944084007583
						},
						{
							year: "2092",
							value: 2245.733183637315
						},
						{
							year: "2093",
							value: 2211.210524514559
						},
						{
							year: "2094",
							value: 2176.687865391802
						},
						{
							year: "2095",
							value: 2142.165206269045
						},
						{
							year: "2096",
							value: 2107.642547146288
						},
						{
							year: "2097",
							value: 2073.119888023532
						},
						{
							year: "2098",
							value: 2038.597228900774
						},
						{
							year: "2099",
							value: 2004.074569778019
						},
						{
							year: "2100",
							value: 1969.551910655259
						}
					]
				},
				{
					name: "Rest of the European Union",
					data: [
						{
							year: "2020",
							value: 2729.770648172649
						},
						{
							year: "2021",
							value: 2728.680303673126
						},
						{
							year: "2022",
							value: 2704.773178298401
						},
						{
							year: "2023",
							value: 2673.61041824003
						},
						{
							year: "2024",
							value: 2642.450007536784
						},
						{
							year: "2025",
							value: 2611.291946188666
						},
						{
							year: "2026",
							value: 2580.136234195672
						},
						{
							year: "2027",
							value: 2548.982871557804
						},
						{
							year: "2028",
							value: 2517.831858275062
						},
						{
							year: "2029",
							value: 2487.210188964512
						},
						{
							year: "2030",
							value: 2457.646335685961
						},
						{
							year: "2031",
							value: 2429.686217475807
						},
						{
							year: "2032",
							value: 2402.791347282351
						},
						{
							year: "2033",
							value: 2376.437685374007
						},
						{
							year: "2034",
							value: 2350.099714576455
						},
						{
							year: "2035",
							value: 2323.777434889689
						},
						{
							year: "2036",
							value: 2297.470846313713
						},
						{
							year: "2037",
							value: 2271.179948848525
						},
						{
							year: "2038",
							value: 2244.904742494125
						},
						{
							year: "2039",
							value: 2219.845151423465
						},
						{
							year: "2040",
							value: 2197.200438189735
						},
						{
							year: "2041",
							value: 2178.205565064941
						},
						{
							year: "2042",
							value: 2161.622241629187
						},
						{
							year: "2043",
							value: 2146.249220470001
						},
						{
							year: "2044",
							value: 2130.885915794673
						},
						{
							year: "2045",
							value: 2115.532327603201
						},
						{
							year: "2046",
							value: 2100.188455895585
						},
						{
							year: "2047",
							value: 2084.854300671826
						},
						{
							year: "2048",
							value: 2069.529861931924
						},
						{
							year: "2049",
							value: 2055.165765631878
						},
						{
							year: "2050",
							value: 2042.712488228431
						},
						{
							year: "2051",
							value: 2033.14916352622
						},
						{
							year: "2052",
							value: 2025.495905694027
						},
						{
							year: "2053",
							value: 2018.801789777338
						},
						{
							year: "2054",
							value: 2012.116040320888
						},
						{
							year: "2055",
							value: 2005.438657324676
						},
						{
							year: "2056",
							value: 1998.769640788705
						},
						{
							year: "2057",
							value: 1992.108990712973
						},
						{
							year: "2058",
							value: 1985.456707097481
						},
						{
							year: "2059",
							value: 1979.199393554296
						},
						{
							year: "2060",
							value: 1973.722998182675
						},
						{
							year: "2061",
							value: 1969.424528830038
						},
						{
							year: "2062",
							value: 1965.903680220896
						},
						{
							year: "2063",
							value: 1962.772537717561
						},
						{
							year: "2064",
							value: 1959.643842195158
						},
						{
							year: "2065",
							value: 1956.517593653688
						},
						{
							year: "2066",
							value: 1953.393792093151
						},
						{
							year: "2067",
							value: 1950.272437513546
						},
						{
							year: "2068",
							value: 1947.153529914872
						},
						{
							year: "2069",
							value: 1942.045377719032
						},
						{
							year: "2070",
							value: 1932.957424764707
						},
						{
							year: "2071",
							value: 1917.839896017122
						},
						{
							year: "2072",
							value: 1898.748278001549
						},
						{
							year: "2073",
							value: 1877.676533129654
						},
						{
							year: "2074",
							value: 1856.617488396323
						},
						{
							year: "2075",
							value: 1835.571143801558
						},
						{
							year: "2076",
							value: 1814.537499345356
						},
						{
							year: "2077",
							value: 1793.516555027717
						},
						{
							year: "2078",
							value: 1772.508310848643
						},
						{
							year: "2079",
							value: 1750.96710278568
						},
						{
							year: "2080",
							value: 1728.345860425193
						},
						{
							year: "2081",
							value: 1704.079571688954
						},
						{
							year: "2082",
							value: 1678.726174081359
						},
						{
							year: "2083",
							value: 1652.828518842494
						},
						{
							year: "2084",
							value: 1626.930863603629
						},
						{
							year: "2085",
							value: 1601.033208364765
						},
						{
							year: "2086",
							value: 1575.1355531259
						},
						{
							year: "2087",
							value: 1549.237897887035
						},
						{
							year: "2088",
							value: 1523.340242648171
						},
						{
							year: "2089",
							value: 1497.880114334264
						},
						{
							year: "2090",
							value: 1473.295039870269
						},
						{
							year: "2091",
							value: 1450.035804572809
						},
						{
							year: "2092",
							value: 1427.651623125262
						},
						{
							year: "2093",
							value: 1405.704968602672
						},
						{
							year: "2094",
							value: 1383.758314080083
						},
						{
							year: "2095",
							value: 1361.811659557493
						},
						{
							year: "2096",
							value: 1339.865005034902
						},
						{
							year: "2097",
							value: 1317.918350512313
						},
						{
							year: "2098",
							value: 1295.971695989723
						},
						{
							year: "2099",
							value: 1274.025041467134
						},
						{
							year: "2100",
							value: 1252.078386944543
						}
					]
				},
				{
					name: "Rest of the World",
					data: [
						{
							year: "2020",
							value: 15616.19790208732
						},
						{
							year: "2021",
							value: 15104.9938547103
						},
						{
							year: "2022",
							value: 14972.65259751615
						},
						{
							year: "2023",
							value: 14800.14675337462
						},
						{
							year: "2024",
							value: 14627.65391441904
						},
						{
							year: "2025",
							value: 14455.1740806494
						},
						{
							year: "2026",
							value: 14282.70725206571
						},
						{
							year: "2027",
							value: 14110.25342866796
						},
						{
							year: "2028",
							value: 13937.81261045617
						},
						{
							year: "2029",
							value: 13768.30205030216
						},
						{
							year: "2030",
							value: 13604.64717967003
						},
						{
							year: "2031",
							value: 13449.87001021826
						},
						{
							year: "2032",
							value: 13300.98967108562
						},
						{
							year: "2033",
							value: 13155.10526658474
						},
						{
							year: "2034",
							value: 13009.307722436
						},
						{
							year: "2035",
							value: 12863.59703863939
						},
						{
							year: "2036",
							value: 12717.97321519492
						},
						{
							year: "2037",
							value: 12572.43625210258
						},
						{
							year: "2038",
							value: 12426.98614936236
						},
						{
							year: "2039",
							value: 12288.26525611065
						},
						{
							year: "2040",
							value: 12162.91225899453
						},
						{
							year: "2041",
							value: 12057.76346547892
						},
						{
							year: "2042",
							value: 11965.96414466785
						},
						{
							year: "2043",
							value: 11880.86462244634
						},
						{
							year: "2044",
							value: 11795.81888718871
						},
						{
							year: "2045",
							value: 11710.82693889498
						},
						{
							year: "2046",
							value: 11625.88877756511
						},
						{
							year: "2047",
							value: 11541.00440319914
						},
						{
							year: "2048",
							value: 11456.17381579704
						},
						{
							year: "2049",
							value: 11376.65933912911
						},
						{
							year: "2050",
							value: 11307.72246939144
						},
						{
							year: "2051",
							value: 11254.78333956262
						},
						{
							year: "2052",
							value: 11212.41765371508
						},
						{
							year: "2053",
							value: 11175.36143293018
						},
						{
							year: "2054",
							value: 11138.35152586343
						},
						{
							year: "2055",
							value: 11101.38793251482
						},
						{
							year: "2056",
							value: 11064.47065288437
						},
						{
							year: "2057",
							value: 11027.59968697207
						},
						{
							year: "2058",
							value: 10990.77503477792
						},
						{
							year: "2059",
							value: 10956.136795007
						},
						{
							year: "2060",
							value: 10925.82143768098
						},
						{
							year: "2061",
							value: 10902.02665561405
						},
						{
							year: "2062",
							value: 10882.53650261486
						},
						{
							year: "2063",
							value: 10865.2036226115
						},
						{
							year: "2064",
							value: 10847.88428821548
						},
						{
							year: "2065",
							value: 10830.5784994268
						},
						{
							year: "2066",
							value: 10813.28625624545
						},
						{
							year: "2067",
							value: 10796.00755867145
						},
						{
							year: "2068",
							value: 10778.74240670479
						},
						{
							year: "2069",
							value: 10750.46551130475
						},
						{
							year: "2070",
							value: 10700.15786869005
						},
						{
							year: "2071",
							value: 10616.47266067089
						},
						{
							year: "2072",
							value: 10510.78832219653
						},
						{
							year: "2073",
							value: 10394.14271282649
						},
						{
							year: "2074",
							value: 10277.56740686088
						},
						{
							year: "2075",
							value: 10161.0624042997
						},
						{
							year: "2076",
							value: 10044.62770514297
						},
						{
							year: "2077",
							value: 9928.263309390662
						},
						{
							year: "2078",
							value: 9811.969217042795
						},
						{
							year: "2079",
							value: 9692.724828106468
						},
						{
							year: "2080",
							value: 9567.501757312464
						},
						{
							year: "2081",
							value: 9433.172300782098
						},
						{
							year: "2082",
							value: 9292.82500009494
						},
						{
							year: "2083",
							value: 9149.464884691213
						},
						{
							year: "2084",
							value: 9006.104769287487
						},
						{
							year: "2085",
							value: 8862.74465388376
						},
						{
							year: "2086",
							value: 8719.384538480032
						},
						{
							year: "2087",
							value: 8576.024423076311
						},
						{
							year: "2088",
							value: 8432.664307672581
						},
						{
							year: "2089",
							value: 8291.726184139377
						},
						{
							year: "2090",
							value: 8155.632044347204
						},
						{
							year: "2091",
							value: 8026.877273859635
						},
						{
							year: "2092",
							value: 7902.966487113098
						},
						{
							year: "2093",
							value: 7781.477692237087
						},
						{
							year: "2094",
							value: 7659.988897361068
						},
						{
							year: "2095",
							value: 7538.500102485055
						},
						{
							year: "2096",
							value: 7417.011307609034
						},
						{
							year: "2097",
							value: 7295.522512733021
						},
						{
							year: "2098",
							value: 7174.033717856999
						},
						{
							year: "2099",
							value: 7052.544922980986
						},
						{
							year: "2100",
							value: 6931.05612810496
						}
					]
				},
				{
					name: "United Kingdom",
					data: [
						{
							year: "2020",
							value: 405.7548833709021
						},
						{
							year: "2021",
							value: 405.5928138558187
						},
						{
							year: "2022",
							value: 402.0392432015777
						},
						{
							year: "2023",
							value: 397.4071902921277
						},
						{
							year: "2024",
							value: 392.7754865923506
						},
						{
							year: "2025",
							value: 388.1441321022464
						},
						{
							year: "2026",
							value: 383.5131268218149
						},
						{
							year: "2027",
							value: 378.8824707510562
						},
						{
							year: "2028",
							value: 374.2521638899706
						},
						{
							year: "2029",
							value: 369.7005390609609
						},
						{
							year: "2030",
							value: 365.3061486944796
						},
						{
							year: "2031",
							value: 361.1501385509198
						},
						{
							year: "2032",
							value: 357.1524675649242
						},
						{
							year: "2033",
							value: 353.2352421301888
						},
						{
							year: "2034",
							value: 349.3203490323596
						},
						{
							year: "2035",
							value: 345.4077882714365
						},
						{
							year: "2036",
							value: 341.4975598474196
						},
						{
							year: "2037",
							value: 337.589663760309
						},
						{
							year: "2038",
							value: 333.6841000101041
						},
						{
							year: "2039",
							value: 329.9592261057693
						},
						{
							year: "2040",
							value: 326.5933012126762
						},
						{
							year: "2041",
							value: 323.7698909256048
						},
						{
							year: "2042",
							value: 321.3049349517098
						},
						{
							year: "2043",
							value: 319.0198790948453
						},
						{
							year: "2044",
							value: 316.7362675024567
						},
						{
							year: "2045",
							value: 314.4541001745436
						},
						{
							year: "2046",
							value: 312.1733771111064
						},
						{
							year: "2047",
							value: 309.8940983121449
						},
						{
							year: "2048",
							value: 307.616263777659
						},
						{
							year: "2049",
							value: 305.4811751676122
						},
						{
							year: "2050",
							value: 303.6301119203011
						},
						{
							year: "2051",
							value: 302.2086131208391
						},
						{
							year: "2052",
							value: 301.0710279023932
						},
						{
							year: "2053",
							value: 300.076010161666
						},
						{
							year: "2054",
							value: 299.0822360170268
						},
						{
							year: "2055",
							value: 298.0897054684752
						},
						{
							year: "2056",
							value: 297.0984185160117
						},
						{
							year: "2057",
							value: 296.108375159636
						},
						{
							year: "2058",
							value: 295.1195753993481
						},
						{
							year: "2059",
							value: 294.1894842473203
						},
						{
							year: "2060",
							value: 293.3754692798751
						},
						{
							year: "2061",
							value: 292.7365420014901
						},
						{
							year: "2062",
							value: 292.2132007758359
						},
						{
							year: "2063",
							value: 291.7477857190401
						},
						{
							year: "2064",
							value: 291.2827343830808
						},
						{
							year: "2065",
							value: 290.8180467679574
						},
						{
							year: "2066",
							value: 290.3537228736706
						},
						{
							year: "2067",
							value: 289.88976270022
						},
						{
							year: "2068",
							value: 289.4261662476054
						},
						{
							year: "2069",
							value: 288.6668871851495
						},
						{
							year: "2070",
							value: 287.3160479512634
						},
						{
							year: "2071",
							value: 285.0689686525178
						},
						{
							year: "2072",
							value: 282.2311781420013
						},
						{
							year: "2073",
							value: 279.0990602885689
						},
						{
							year: "2074",
							value: 275.9688301919874
						},
						{
							year: "2075",
							value: 272.8404878522564
						},
						{
							year: "2076",
							value: 269.7140332693762
						},
						{
							year: "2077",
							value: 266.5894664433465
						},
						{
							year: "2078",
							value: 263.4667873741677
						},
						{
							year: "2079",
							value: 260.2648882068852
						},
						{
							year: "2080",
							value: 256.9024520396459
						},
						{
							year: "2081",
							value: 253.2954951099094
						},
						{
							year: "2082",
							value: 249.5269496109635
						},
						{
							year: "2083",
							value: 245.6775053039628
						},
						{
							year: "2084",
							value: 241.8280609969623
						},
						{
							year: "2085",
							value: 237.9786166899619
						},
						{
							year: "2086",
							value: 234.1291723829613
						},
						{
							year: "2087",
							value: 230.2797280759608
						},
						{
							year: "2088",
							value: 226.4302837689603
						},
						{
							year: "2089",
							value: 222.645873748458
						},
						{
							year: "2090",
							value: 218.9915323009517
						},
						{
							year: "2091",
							value: 215.5342644488943
						},
						{
							year: "2092",
							value: 212.2070651698331
						},
						{
							year: "2093",
							value: 208.9449001772702
						},
						{
							year: "2094",
							value: 205.6827351847072
						},
						{
							year: "2095",
							value: 202.4205701921442
						},
						{
							year: "2096",
							value: 199.1584051995811
						},
						{
							year: "2097",
							value: 195.8962402070181
						},
						{
							year: "2098",
							value: 192.6340752144551
						},
						{
							year: "2099",
							value: 189.3719102218921
						},
						{
							year: "2100",
							value: 186.1097452293289
						}
					]
				},
				{
					name: "United States of America",
					data: [
						{
							year: "2020",
							value: 5981.354372479605
						},
						{
							year: "2021",
							value: 5978.965257172755
						},
						{
							year: "2022",
							value: 5926.581006873465
						},
						{
							year: "2023",
							value: 5858.298526344032
						},
						{
							year: "2024",
							value: 5790.02119361907
						},
						{
							year: "2025",
							value: 5721.749008698585
						},
						{
							year: "2026",
							value: 5653.481971582574
						},
						{
							year: "2027",
							value: 5585.220082271031
						},
						{
							year: "2028",
							value: 5516.963340763968
						},
						{
							year: "2029",
							value: 5449.866474678938
						},
						{
							year: "2030",
							value: 5385.087448941602
						},
						{
							year: "2031",
							value: 5323.822457531684
						},
						{
							year: "2032",
							value: 5264.891591110145
						},
						{
							year: "2033",
							value: 5207.146596675598
						},
						{
							year: "2034",
							value: 5149.435983918571
						},
						{
							year: "2035",
							value: 5091.759752839061
						},
						{
							year: "2036",
							value: 5034.11790343707
						},
						{
							year: "2037",
							value: 4976.5104357126
						},
						{
							year: "2038",
							value: 4918.937349665646
						},
						{
							year: "2039",
							value: 4864.027866803641
						},
						{
							year: "2040",
							value: 4814.409758921653
						},
						{
							year: "2041",
							value: 4772.789021481404
						},
						{
							year: "2042",
							value: 4736.452366528692
						},
						{
							year: "2043",
							value: 4702.767673131366
						},
						{
							year: "2044",
							value: 4669.104270069641
						},
						{
							year: "2045",
							value: 4635.462157343507
						},
						{
							year: "2046",
							value: 4601.841334952969
						},
						{
							year: "2047",
							value: 4568.241802898029
						},
						{
							year: "2048",
							value: 4534.663561178683
						},
						{
							year: "2049",
							value: 4503.189579923696
						},
						{
							year: "2050",
							value: 4475.902501685578
						},
						{
							year: "2051",
							value: 4454.947761747514
						},
						{
							year: "2052",
							value: 4438.17827701848
						},
						{
							year: "2053",
							value: 4423.510422217204
						},
						{
							year: "2054",
							value: 4408.860899638666
						},
						{
							year: "2055",
							value: 4394.229709282855
						},
						{
							year: "2056",
							value: 4379.616851149784
						},
						{
							year: "2057",
							value: 4365.022325239446
						},
						{
							year: "2058",
							value: 4350.446131551846
						},
						{
							year: "2059",
							value: 4336.735379058201
						},
						{
							year: "2060",
							value: 4324.735740398667
						},
						{
							year: "2061",
							value: 4315.317121851145
						},
						{
							year: "2062",
							value: 4307.602391957176
						},
						{
							year: "2063",
							value: 4300.741569083385
						},
						{
							year: "2064",
							value: 4293.886107927481
						},
						{
							year: "2065",
							value: 4287.036008489456
						},
						{
							year: "2066",
							value: 4280.191270769311
						},
						{
							year: "2067",
							value: 4273.3518947670549
						},
						{
							year: "2068",
							value: 4266.517880482679
						},
						{
							year: "2069",
							value: 4255.325120207255
						},
						{
							year: "2070",
							value: 4235.411994107585
						},
						{
							year: "2071",
							value: 4202.287124538054
						},
						{
							year: "2072",
							value: 4160.454403913117
						},
						{
							year: "2073",
							value: 4114.282915693161
						},
						{
							year: "2074",
							value: 4068.13925546285
						},
						{
							year: "2075",
							value: 4022.023423222187
						},
						{
							year: "2076",
							value: 3975.935418971168
						},
						{
							year: "2077",
							value: 3929.875242709794
						},
						{
							year: "2078",
							value: 3883.842894438068
						},
						{
							year: "2079",
							value: 3836.64273895169
						},
						{
							year: "2080",
							value: 3787.076059423359
						},
						{
							year: "2081",
							value: 3733.904826032921
						},
						{
							year: "2082",
							value: 3678.351567102939
						},
						{
							year: "2083",
							value: 3621.605754591675
						},
						{
							year: "2084",
							value: 3564.859942080413
						},
						{
							year: "2085",
							value: 3508.114129569151
						},
						{
							year: "2086",
							value: 3451.368317057891
						},
						{
							year: "2087",
							value: 3394.622504546629
						},
						{
							year: "2088",
							value: 3337.876692035368
						},
						{
							year: "2089",
							value: 3282.089569437275
						},
						{
							year: "2090",
							value: 3228.21982666552
						},
						{
							year: "2091",
							value: 3177.255204842757
						},
						{
							year: "2092",
							value: 3128.207962846333
						},
						{
							year: "2093",
							value: 3080.119410763079
						},
						{
							year: "2094",
							value: 3032.030858679823
						},
						{
							year: "2095",
							value: 2983.942306596568
						},
						{
							year: "2096",
							value: 2935.853754513312
						},
						{
							year: "2097",
							value: 2887.765202430058
						},
						{
							year: "2098",
							value: 2839.676650346802
						},
						{
							year: "2099",
							value: 2791.588098263549
						},
						{
							year: "2100",
							value: 2743.499546180291
						}
					]
				}
			]
		},
		{
			scenario: "SSP2-1.9",
			groupByRegion: [
				{
					name: "Australia",
					data: [
						{
							year: "2020",
							value: 527.7370476376862
						},
						{
							year: "2021",
							value: 518.9530148206193
						},
						{
							year: "2022",
							value: 503.9554056999611
						},
						{
							year: "2023",
							value: 486.26096157844
						},
						{
							year: "2024",
							value: 468.5701745191393
						},
						{
							year: "2025",
							value: 450.8830445220588
						},
						{
							year: "2026",
							value: 433.1995715871982
						},
						{
							year: "2027",
							value: 415.5197557145569
						},
						{
							year: "2028",
							value: 397.8435969041361
						},
						{
							year: "2029",
							value: 381.1265558791355
						},
						{
							year: "2030",
							value: 366.3241470956177
						},
						{
							year: "2031",
							value: 354.4208920977957
						},
						{
							year: "2032",
							value: 344.4325396341439
						},
						{
							year: "2033",
							value: 335.4037364472338
						},
						{
							year: "2034",
							value: 326.3790755467537
						},
						{
							year: "2035",
							value: 317.358556932704
						},
						{
							year: "2036",
							value: 308.3421806050847
						},
						{
							year: "2037",
							value: 299.3299465638955
						},
						{
							year: "2038",
							value: 290.3218548091367
						},
						{
							year: "2039",
							value: 281.7808634425086
						},
						{
							year: "2040",
							value: 274.1698396445954
						},
						{
							year: "2041",
							value: 267.9655887082502
						},
						{
							year: "2042",
							value: 262.6908479798906
						},
						{
							year: "2043",
							value: 257.8824775155093
						},
						{
							year: "2044",
							value: 253.077428292327
						},
						{
							year: "2045",
							value: 248.2757003103438
						},
						{
							year: "2046",
							value: 243.4772935699953
						},
						{
							year: "2047",
							value: 238.6822080704013
						},
						{
							year: "2048",
							value: 233.8904438120064
						},
						{
							year: "2049",
							value: 229.1488074940424
						},
						{
							year: "2050",
							value: 224.504069931004
						},
						{
							year: "2051",
							value: 220.0043844374781
						},
						{
							year: "2052",
							value: 215.6014171877801
						},
						{
							year: "2053",
							value: 211.2482897132045
						},
						{
							year: "2054",
							value: 206.8981594297851
						},
						{
							year: "2055",
							value: 202.5510263375167
						},
						{
							year: "2056",
							value: 198.2068904364019
						},
						{
							year: "2057",
							value: 193.8657517264408
						},
						{
							year: "2058",
							value: 189.5276102076335
						},
						{
							year: "2059",
							value: 185.2172561669834
						},
						{
							year: "2060",
							value: 180.9594696829209
						},
						{
							year: "2061",
							value: 176.7797718461218
						},
						{
							year: "2062",
							value: 172.6525902136942
						},
						{
							year: "2063",
							value: 168.5531140814883
						},
						{
							year: "2064",
							value: 164.4565429539275
						},
						{
							year: "2065",
							value: 160.3628768310117
						},
						{
							year: "2066",
							value: 156.2721157127411
						},
						{
							year: "2067",
							value: 152.1842595991155
						},
						{
							year: "2068",
							value: 148.099308490135
						},
						{
							year: "2069",
							value: 144.2106273667243
						},
						{
							year: "2070",
							value: 140.7114886689537
						},
						{
							year: "2071",
							value: 137.8009318409151
						},
						{
							year: "2072",
							value: 135.2794519299748
						},
						{
							year: "2073",
							value: 132.9534988734986
						},
						{
							year: "2074",
							value: 130.6296151497071
						},
						{
							year: "2075",
							value: 128.3078007586003
						},
						{
							year: "2076",
							value: 125.988055700178
						},
						{
							year: "2077",
							value: 123.6703799744403
						},
						{
							year: "2078",
							value: 121.3547735813873
						},
						{
							year: "2079",
							value: 119.1496071177504
						},
						{
							year: "2080",
							value: 117.1630220259709
						},
						{
							year: "2081",
							value: 115.5062145516759
						},
						{
							year: "2082",
							value: 114.0668357337157
						},
						{
							year: "2083",
							value: 112.7360566667777
						},
						{
							year: "2084",
							value: 111.4052775998398
						},
						{
							year: "2085",
							value: 110.0744985329019
						},
						{
							year: "2086",
							value: 108.743719465964
						},
						{
							year: "2087",
							value: 107.412940399026
						},
						{
							year: "2088",
							value: 106.0821613320881
						},
						{
							year: "2089",
							value: 104.9177093580769
						},
						{
							year: "2090",
							value: 104.0859115699355
						},
						{
							year: "2091",
							value: 103.7581352755278
						},
						{
							year: "2092",
							value: 103.7630131669843
						},
						{
							year: "2093",
							value: 103.9342181513729
						},
						{
							year: "2094",
							value: 104.1054231357616
						},
						{
							year: "2095",
							value: 104.2766281201502
						},
						{
							year: "2096",
							value: 104.4478331045388
						},
						{
							year: "2097",
							value: 104.6190380889275
						},
						{
							year: "2098",
							value: 104.7902430733162
						},
						{
							year: "2099",
							value: 104.961448057705
						},
						{
							year: "2100",
							value: 105.1326530420937
						}
					]
				},
				{
					name: "Canada",
					data: [
						{
							year: "2020",
							value: 672.3540219599809
						},
						{
							year: "2021",
							value: 661.1628808035885
						},
						{
							year: "2022",
							value: 642.0554430044109
						},
						{
							year: "2023",
							value: 619.5121504220435
						},
						{
							year: "2024",
							value: 596.9735170549104
						},
						{
							year: "2025",
							value: 574.4395429030125
						},
						{
							year: "2026",
							value: 551.9102279663489
						},
						{
							year: "2027",
							value: 529.3855722449195
						},
						{
							year: "2028",
							value: 506.8655757387249
						},
						{
							year: "2029",
							value: 485.567526229511
						},
						{
							year: "2030",
							value: 466.7087799564487
						},
						{
							year: "2031",
							value: 451.5436491246721
						},
						{
							year: "2032",
							value: 438.8181658906414
						},
						{
							year: "2033",
							value: 427.3151793874616
						},
						{
							year: "2034",
							value: 415.8174702908119
						},
						{
							year: "2035",
							value: 404.3250386006929
						},
						{
							year: "2036",
							value: 392.8378843171041
						},
						{
							year: "2037",
							value: 381.3560074400455
						},
						{
							year: "2038",
							value: 369.8794079695172
						},
						{
							year: "2039",
							value: 358.9979094607739
						},
						{
							year: "2040",
							value: 349.3012196326357
						},
						{
							year: "2041",
							value: 341.3968038085553
						},
						{
							year: "2042",
							value: 334.6766139727105
						},
						{
							year: "2043",
							value: 328.5505948969763
						},
						{
							year: "2044",
							value: 322.4288071896635
						},
						{
							year: "2045",
							value: 316.311250850772
						},
						{
							year: "2046",
							value: 310.1979258803014
						},
						{
							year: "2047",
							value: 304.0888322782519
						},
						{
							year: "2048",
							value: 297.9839700446237
						},
						{
							year: "2049",
							value: 291.9429724240869
						},
						{
							year: "2050",
							value: 286.0254269429999
						},
						{
							year: "2051",
							value: 280.2926824774295
						},
						{
							year: "2052",
							value: 274.6831601743463
						},
						{
							year: "2053",
							value: 269.1371353524557
						},
						{
							year: "2054",
							value: 263.5949290487756
						},
						{
							year: "2055",
							value: 258.0565412633059
						},
						{
							year: "2056",
							value: 252.5219719960468
						},
						{
							year: "2057",
							value: 246.991221246998
						},
						{
							year: "2058",
							value: 241.46428901616
						},
						{
							year: "2059",
							value: 235.9727589288375
						},
						{
							year: "2060",
							value: 230.548201604285
						},
						{
							year: "2061",
							value: 225.2231317352595
						},
						{
							year: "2062",
							value: 219.9649692046239
						},
						{
							year: "2063",
							value: 214.74210437497
						},
						{
							year: "2064",
							value: 209.5229406149409
						},
						{
							year: "2065",
							value: 204.3074779245368
						},
						{
							year: "2066",
							value: 199.0957163037575
						},
						{
							year: "2067",
							value: 193.8876557526031
						},
						{
							year: "2068",
							value: 188.6832962710733
						},
						{
							year: "2069",
							value: 183.7289910822608
						},
						{
							year: "2070",
							value: 179.2709755092239
						},
						{
							year: "2071",
							value: 175.5628322241715
						},
						{
							year: "2072",
							value: 172.3503854819964
						},
						{
							year: "2073",
							value: 169.3870462595385
						},
						{
							year: "2074",
							value: 166.4263434336715
						},
						{
							year: "2075",
							value: 163.4682770043956
						},
						{
							year: "2076",
							value: 160.5128469717104
						},
						{
							year: "2077",
							value: 157.5600533356161
						},
						{
							year: "2078",
							value: 154.6098960961127
						},
						{
							year: "2079",
							value: 151.8004428891578
						},
						{
							year: "2080",
							value: 149.2694694007508
						},
						{
							year: "2081",
							value: 147.1586432346896
						},
						{
							year: "2082",
							value: 145.3248281904177
						},
						{
							year: "2083",
							value: 143.6293727320613
						},
						{
							year: "2084",
							value: 141.933917273705
						},
						{
							year: "2085",
							value: 140.2384618153487
						},
						{
							year: "2086",
							value: 138.5430063569925
						},
						{
							year: "2087",
							value: 136.8475508986361
						},
						{
							year: "2088",
							value: 135.1520954402799
						},
						{
							year: "2089",
							value: 133.6685460635493
						},
						{
							year: "2090",
							value: 132.6088088500702
						},
						{
							year: "2091",
							value: 132.1912112778811
						},
						{
							year: "2092",
							value: 132.1974258689434
						},
						{
							year: "2093",
							value: 132.4155465416314
						},
						{
							year: "2094",
							value: 132.6336672143194
						},
						{
							year: "2095",
							value: 132.8517878870073
						},
						{
							year: "2096",
							value: 133.0699085596952
						},
						{
							year: "2097",
							value: 133.2880292323833
						},
						{
							year: "2098",
							value: 133.5061499050713
						},
						{
							year: "2099",
							value: 133.7242705777595
						},
						{
							year: "2100",
							value: 133.9423912504476
						}
					]
				},
				{
					name: "China",
					data: [
						{
							year: "2020",
							value: 13106.41681037749
						},
						{
							year: "2021",
							value: 12384.95668163362
						},
						{
							year: "2022",
							value: 12027.03460779882
						},
						{
							year: "2023",
							value: 11604.75182363121
						},
						{
							year: "2024",
							value: 11182.55631626108
						},
						{
							year: "2025",
							value: 10760.44808568845
						},
						{
							year: "2026",
							value: 10338.4271319133
						},
						{
							year: "2027",
							value: 9916.493454935615
						},
						{
							year: "2028",
							value: 9494.64705475542
						},
						{
							year: "2029",
							value: 9095.690264782113
						},
						{
							year: "2030",
							value: 8742.426700775137
						},
						{
							year: "2031",
							value: 8458.352240644252
						},
						{
							year: "2032",
							value: 8219.977457088997
						},
						{
							year: "2033",
							value: 8004.502581400049
						},
						{
							year: "2034",
							value: 7789.126562518049
						},
						{
							year: "2035",
							value: 7573.849400443003
						},
						{
							year: "2036",
							value: 7358.671095174903
						},
						{
							year: "2037",
							value: 7143.59164671375
						},
						{
							year: "2038",
							value: 6928.611055059549
						},
						{
							year: "2039",
							value: 6724.777942864491
						},
						{
							year: "2040",
							value: 6543.138762923282
						},
						{
							year: "2041",
							value: 6395.07260492017
						},
						{
							year: "2042",
							value: 6269.189464130213
						},
						{
							year: "2043",
							value: 6154.436378186229
						},
						{
							year: "2044",
							value: 6039.762554578536
						},
						{
							year: "2045",
							value: 5925.167993307134
						},
						{
							year: "2046",
							value: 5810.652694372017
						},
						{
							year: "2047",
							value: 5696.216657773186
						},
						{
							year: "2048",
							value: 5581.859883510647
						},
						{
							year: "2049",
							value: 5468.699426357841
						},
						{
							year: "2050",
							value: 5357.851484689089
						},
						{
							year: "2051",
							value: 5250.465250624242
						},
						{
							year: "2052",
							value: 5145.38722409634
						},
						{
							year: "2053",
							value: 5041.498637533679
						},
						{
							year: "2054",
							value: 4937.681579763697
						},
						{
							year: "2055",
							value: 4833.936050786384
						},
						{
							year: "2056",
							value: 4730.262050601747
						},
						{
							year: "2057",
							value: 4626.659579209785
						},
						{
							year: "2058",
							value: 4523.128636610501
						},
						{
							year: "2059",
							value: 4420.260849833494
						},
						{
							year: "2060",
							value: 4318.647602277963
						},
						{
							year: "2061",
							value: 4218.897961804506
						},
						{
							year: "2062",
							value: 4120.40163501774
						},
						{
							year: "2063",
							value: 4022.566507627235
						},
						{
							year: "2064",
							value: 3924.800708972978
						},
						{
							year: "2065",
							value: 3827.10423905497
						},
						{
							year: "2066",
							value: 3729.477097873205
						},
						{
							year: "2067",
							value: 3631.919285427691
						},
						{
							year: "2068",
							value: 3534.430801718421
						},
						{
							year: "2069",
							value: 3441.626355291456
						},
						{
							year: "2070",
							value: 3358.118446179851
						},
						{
							year: "2071",
							value: 3288.657205556595
						},
						{
							year: "2072",
							value: 3228.481392759095
						},
						{
							year: "2073",
							value: 3172.971882215307
						},
						{
							year: "2074",
							value: 3117.51175686619
						},
						{
							year: "2075",
							value: 3062.101016711737
						},
						{
							year: "2076",
							value: 3006.739661751945
						},
						{
							year: "2077",
							value: 2951.427691986819
						},
						{
							year: "2078",
							value: 2896.16510741636
						},
						{
							year: "2079",
							value: 2843.538202190035
						},
						{
							year: "2080",
							value: 2796.127801627038
						},
						{
							year: "2081",
							value: 2756.58763476626
						},
						{
							year: "2082",
							value: 2722.236462695268
						},
						{
							year: "2083",
							value: 2690.47705360403
						},
						{
							year: "2084",
							value: 2658.717644512793
						},
						{
							year: "2085",
							value: 2626.958235421556
						},
						{
							year: "2086",
							value: 2595.198826330321
						},
						{
							year: "2087",
							value: 2563.439417239082
						},
						{
							year: "2088",
							value: 2531.680008147845
						},
						{
							year: "2089",
							value: 2503.890040956188
						},
						{
							year: "2090",
							value: 2484.038957563693
						},
						{
							year: "2091",
							value: 2476.21648598811
						},
						{
							year: "2092",
							value: 2476.332898211685
						},
						{
							year: "2093",
							value: 2480.418752334841
						},
						{
							year: "2094",
							value: 2484.504606457996
						},
						{
							year: "2095",
							value: 2488.590460581153
						},
						{
							year: "2096",
							value: 2492.676314704308
						},
						{
							year: "2097",
							value: 2496.762168827466
						},
						{
							year: "2098",
							value: 2500.848022950623
						},
						{
							year: "2099",
							value: 2504.933877073784
						},
						{
							year: "2100",
							value: 2509.019731196941
						}
					]
				},
				{
					name: "France",
					data: [
						{
							year: "2020",
							value: 399.412668092019
						},
						{
							year: "2021",
							value: 392.7645580156516
						},
						{
							year: "2022",
							value: 381.4137629545558
						},
						{
							year: "2023",
							value: 368.0218944688814
						},
						{
							year: "2024",
							value: 354.6327937952933
						},
						{
							year: "2025",
							value: 341.2464609337911
						},
						{
							year: "2026",
							value: 327.8628958843751
						},
						{
							year: "2027",
							value: 314.4820986470447
						},
						{
							year: "2028",
							value: 301.1040692218009
						},
						{
							year: "2029",
							value: 288.4519387937969
						},
						{
							year: "2030",
							value: 277.2488792153978
						},
						{
							year: "2031",
							value: 268.2400160723961
						},
						{
							year: "2032",
							value: 260.6804283473942
						},
						{
							year: "2033",
							value: 253.8470661896606
						},
						{
							year: "2034",
							value: 247.0168390812522
						},
						{
							year: "2035",
							value: 240.189747022169
						},
						{
							year: "2036",
							value: 233.3657900124112
						},
						{
							year: "2037",
							value: 226.5449680519785
						},
						{
							year: "2038",
							value: 219.7272811408711
						},
						{
							year: "2039",
							value: 213.2631146299805
						},
						{
							year: "2040",
							value: 207.502785057433
						},
						{
							year: "2041",
							value: 202.8071578864951
						},
						{
							year: "2042",
							value: 198.8150215048385
						},
						{
							year: "2043",
							value: 195.175852936041
						},
						{
							year: "2044",
							value: 191.5391980164454
						},
						{
							year: "2045",
							value: 187.9050567460519
						},
						{
							year: "2046",
							value: 184.2734291248604
						},
						{
							year: "2047",
							value: 180.6443151528708
						},
						{
							year: "2048",
							value: 177.0177148300833
						},
						{
							year: "2049",
							value: 173.4290533530204
						},
						{
							year: "2050",
							value: 169.91372875919
						},
						{
							year: "2051",
							value: 166.5081854178884
						},
						{
							year: "2052",
							value: 163.1758423417442
						},
						{
							year: "2053",
							value: 159.8812200162046
						},
						{
							year: "2054",
							value: 156.5888660857316
						},
						{
							year: "2055",
							value: 153.2987805503256
						},
						{
							year: "2056",
							value: 150.0109634099864
						},
						{
							year: "2057",
							value: 146.7254146647141
						},
						{
							year: "2058",
							value: 143.4421343145087
						},
						{
							year: "2059",
							value: 140.1798846477468
						},
						{
							year: "2060",
							value: 136.9574202265456
						},
						{
							year: "2061",
							value: 133.7940564409558
						},
						{
							year: "2062",
							value: 130.6704390354998
						},
						{
							year: "2063",
							value: 127.5677902692815
						},
						{
							year: "2064",
							value: 124.4673401276649
						},
						{
							year: "2065",
							value: 121.3690886106499
						},
						{
							year: "2066",
							value: 118.2730357182364
						},
						{
							year: "2067",
							value: 115.1791814504246
						},
						{
							year: "2068",
							value: 112.0875258072143
						},
						{
							year: "2069",
							value: 109.1444151997441
						},
						{
							year: "2070",
							value: 106.4961260005039
						},
						{
							year: "2071",
							value: 104.293299283066
						},
						{
							year: "2072",
							value: 102.3849416582313
						},
						{
							year: "2073",
							value: 100.624566637564
						},
						{
							year: "2074",
							value: 98.86575777127678
						},
						{
							year: "2075",
							value: 97.10851505936982
						},
						{
							year: "2076",
							value: 95.35283850184298
						},
						{
							year: "2077",
							value: 93.59872809869629
						},
						{
							year: "2078",
							value: 91.84618384992976
						},
						{
							year: "2079",
							value: 90.17722499102625
						},
						{
							year: "2080",
							value: 88.67369732426542
						},
						{
							year: "2081",
							value: 87.41975865010206
						},
						{
							year: "2082",
							value: 86.3303787465138
						},
						{
							year: "2083",
							value: 85.32319151161156
						},
						{
							year: "2084",
							value: 84.31600427670934
						},
						{
							year: "2085",
							value: 83.30881704180713
						},
						{
							year: "2086",
							value: 82.30162980690501
						},
						{
							year: "2087",
							value: 81.29444257200281
						},
						{
							year: "2088",
							value: 80.28725533710076
						},
						{
							year: "2089",
							value: 79.40595114994228
						},
						{
							year: "2090",
							value: 78.77641305827118
						},
						{
							year: "2091",
							value: 78.52833874764154
						},
						{
							year: "2092",
							value: 78.53203053249925
						},
						{
							year: "2093",
							value: 78.66160536510063
						},
						{
							year: "2094",
							value: 78.79118019770199
						},
						{
							year: "2095",
							value: 78.92075503030337
						},
						{
							year: "2096",
							value: 79.05032986290472
						},
						{
							year: "2097",
							value: 79.17990469550613
						},
						{
							year: "2098",
							value: 79.3094795281075
						},
						{
							year: "2099",
							value: 79.43905436070898
						},
						{
							year: "2100",
							value: 79.56862919331035
						}
					]
				},
				{
					name: "Global",
					data: [
						{
							year: "2020",
							value: 49017.00912423762
						},
						{
							year: "2021",
							value: 46736.623756762914
						},
						{
							year: "2022",
							value: 45385.947308789146
						},
						{
							year: "2023",
							value: 43792.39538043496
						},
						{
							year: "2024",
							value: 42199.1728051011
						},
						{
							year: "2025",
							value: 40606.279582780524
						},
						{
							year: "2026",
							value: 39013.715713476835
						},
						{
							year: "2027",
							value: 37421.48119718995
						},
						{
							year: "2028",
							value: 35829.576033919926
						},
						{
							year: "2029",
							value: 34324.04849212071
						},
						{
							year: "2030",
							value: 32990.9516794001
						},
						{
							year: "2031",
							value: 31918.951065805904
						},
						{
							year: "2032",
							value: 31019.40552370128
						},
						{
							year: "2033",
							value: 30206.276462939655
						},
						{
							year: "2034",
							value: 29393.520454220983
						},
						{
							year: "2035",
							value: 28581.137497545184
						},
						{
							year: "2036",
							value: 27769.127592931432
						},
						{
							year: "2037",
							value: 26957.490740341127
						},
						{
							year: "2038",
							value: 26146.226939793793
						},
						{
							year: "2039",
							value: 25377.02994389594
						},
						{
							year: "2040",
							value: 24691.58531694826
						},
						{
							year: "2041",
							value: 24132.83388199426
						},
						{
							year: "2042",
							value: 23657.793626330815
						},
						{
							year: "2043",
							value: 23224.75442072939
						},
						{
							year: "2044",
							value: 22792.014324298398
						},
						{
							year: "2045",
							value: 22359.57333704001
						},
						{
							year: "2046",
							value: 21927.431458929124
						},
						{
							year: "2047",
							value: 21495.588689977885
						},
						{
							year: "2048",
							value: 21064.045030186484
						},
						{
							year: "2049",
							value: 20637.015865207934
						},
						{
							year: "2050",
							value: 20218.713348931975
						},
						{
							year: "2051",
							value: 19813.474142463834
						},
						{
							year: "2052",
							value: 19416.94532793444
						},
						{
							year: "2053",
							value: 19024.905056205855
						},
						{
							year: "2054",
							value: 18633.134709867973
						},
						{
							year: "2055",
							value: 18241.634288920766
						},
						{
							year: "2056",
							value: 17850.403793364247
						},
						{
							year: "2057",
							value: 17459.443223198425
						},
						{
							year: "2058",
							value: 17068.752578423257
						},
						{
							year: "2059",
							value: 16680.564458691253
						},
						{
							year: "2060",
							value: 16297.110544252964
						},
						{
							year: "2061",
							value: 15920.68925053876
						},
						{
							year: "2062",
							value: 15548.997537373722
						},
						{
							year: "2063",
							value: 15179.80096637368
						},
						{
							year: "2064",
							value: 14810.86601859759
						},
						{
							year: "2065",
							value: 14442.192693825335
						},
						{
							year: "2066",
							value: 14073.780992177386
						},
						{
							year: "2067",
							value: 13705.630913653758
						},
						{
							year: "2068",
							value: 13337.742458254439
						},
						{
							year: "2069",
							value: 12987.52997005919
						},
						{
							year: "2070",
							value: 12672.399458969156
						},
						{
							year: "2071",
							value: 12410.276308103272
						},
						{
							year: "2072",
							value: 12183.193210898778
						},
						{
							year: "2073",
							value: 11973.719154918723
						},
						{
							year: "2074",
							value: 11764.431461904787
						},
						{
							year: "2075",
							value: 11555.330131856954
						},
						{
							year: "2076",
							value: 11346.415164490316
						},
						{
							year: "2077",
							value: 11137.686560379918
						},
						{
							year: "2078",
							value: 10929.144319235622
						},
						{
							year: "2079",
							value: 10730.54823753421
						},
						{
							year: "2080",
							value: 10551.637474242816
						},
						{
							year: "2081",
							value: 10402.42630222728
						},
						{
							year: "2082",
							value: 10272.796635694325
						},
						{
							year: "2083",
							value: 10152.947403150876
						},
						{
							year: "2084",
							value: 10033.098170604348
						},
						{
							year: "2085",
							value: 9913.24893805782
						},
						{
							year: "2086",
							value: 9793.399705511301
						},
						{
							year: "2087",
							value: 9673.550472964977
						},
						{
							year: "2088",
							value: 9553.70124041845
						},
						{
							year: "2089",
							value: 9448.831334593127
						},
						{
							year: "2090",
							value: 9373.920082215209
						},
						{
							year: "2091",
							value: 9344.400728997483
						},
						{
							year: "2092",
							value: 9344.840029234278
						},
						{
							year: "2093",
							value: 9360.258656185142
						},
						{
							year: "2094",
							value: 9375.677283136005
						},
						{
							year: "2095",
							value: 9391.095910086868
						},
						{
							year: "2096",
							value: 9406.514537037732
						},
						{
							year: "2097",
							value: 9421.933163982176
						},
						{
							year: "2098",
							value: 9437.351790933037
						},
						{
							year: "2099",
							value: 9452.770417883887
						},
						{
							year: "2100",
							value: 9468.189044834744
						}
					]
				},
				{
					name: "India",
					data: [
						{
							year: "2020",
							value: 3502.106733367986
						},
						{
							year: "2021",
							value: 3309.328614734528
						},
						{
							year: "2022",
							value: 3213.689865949626
						},
						{
							year: "2023",
							value: 3100.853581005002
						},
						{
							year: "2024",
							value: 2988.040616901196
						},
						{
							year: "2025",
							value: 2875.250973638211
						},
						{
							year: "2026",
							value: 2762.484651216044
						},
						{
							year: "2027",
							value: 2649.741649634693
						},
						{
							year: "2028",
							value: 2537.021968894161
						},
						{
							year: "2029",
							value: 2430.418518027093
						},
						{
							year: "2030",
							value: 2336.024548717126
						},
						{
							year: "2031",
							value: 2260.118288905937
						},
						{
							year: "2032",
							value: 2196.42323429017
						},
						{
							year: "2033",
							value: 2138.847161139163
						},
						{
							year: "2034",
							value: 2081.297503071261
						},
						{
							year: "2035",
							value: 2023.774260086464
						},
						{
							year: "2036",
							value: 1966.277432184772
						},
						{
							year: "2037",
							value: 1908.807019366184
						},
						{
							year: "2038",
							value: 1851.363021630701
						},
						{
							year: "2039",
							value: 1796.897691782771
						},
						{
							year: "2040",
							value: 1748.362702828973
						},
						{
							year: "2041",
							value: 1708.798610184212
						},
						{
							year: "2042",
							value: 1675.161941874613
						},
						{
							year: "2043",
							value: 1644.499285499989
						},
						{
							year: "2044",
							value: 1613.857808458028
						},
						{
							year: "2045",
							value: 1583.237510748728
						},
						{
							year: "2046",
							value: 1552.638392372088
						},
						{
							year: "2047",
							value: 1522.060453328108
						},
						{
							year: "2048",
							value: 1491.503693616791
						},
						{
							year: "2049",
							value: 1461.266596423907
						},
						{
							year: "2050",
							value: 1431.647416100667
						},
						{
							year: "2051",
							value: 1402.953223108746
						},
						{
							year: "2052",
							value: 1374.875795879283
						},
						{
							year: "2053",
							value: 1347.116193557381
						},
						{
							year: "2054",
							value: 1319.375704122707
						},
						{
							year: "2055",
							value: 1291.65432757526
						},
						{
							year: "2056",
							value: 1263.95206391504
						},
						{
							year: "2057",
							value: 1236.268913142048
						},
						{
							year: "2058",
							value: 1208.604875256285
						},
						{
							year: "2059",
							value: 1181.118036257454
						},
						{
							year: "2060",
							value: 1153.966417045875
						},
						{
							year: "2061",
							value: 1127.31276390732
						},
						{
							year: "2062",
							value: 1100.994003086375
						},
						{
							year: "2063",
							value: 1074.851918384558
						},
						{
							year: "2064",
							value: 1048.728358702778
						},
						{
							year: "2065",
							value: 1022.623324041036
						},
						{
							year: "2066",
							value: 996.536814399332
						},
						{
							year: "2067",
							value: 970.4688297776639
						},
						{
							year: "2068",
							value: 944.4193701760328
						},
						{
							year: "2069",
							value: 919.6215111257224
						},
						{
							year: "2070",
							value: 897.3077380311821
						},
						{
							year: "2071",
							value: 878.7473120952238
						},
						{
							year: "2072",
							value: 862.6680035982479
						},
						{
							year: "2073",
							value: 847.8355567553067
						},
						{
							year: "2074",
							value: 833.016305908287
						},
						{
							year: "2075",
							value: 818.2102510571879
						},
						{
							year: "2076",
							value: 803.4173922020087
						},
						{
							year: "2077",
							value: 788.6377293427505
						},
						{
							year: "2078",
							value: 773.8712624794132
						},
						{
							year: "2079",
							value: 759.809063648418
						},
						{
							year: "2080",
							value: 747.1407435846245
						},
						{
							year: "2081",
							value: 736.5753932981922
						},
						{
							year: "2082",
							value: 727.3965709892873
						},
						{
							year: "2083",
							value: 718.9102820183654
						},
						{
							year: "2084",
							value: 710.4239930474435
						},
						{
							year: "2085",
							value: 701.9377040765216
						},
						{
							year: "2086",
							value: 693.4514151056003
						},
						{
							year: "2087",
							value: 684.9651261346783
						},
						{
							year: "2088",
							value: 676.4788371637562
						},
						{
							year: "2089",
							value: 669.0532049234546
						},
						{
							year: "2090",
							value: 663.7488861443919
						},
						{
							year: "2091",
							value: 661.6586786702379
						},
						{
							year: "2092",
							value: 661.6897846573231
						},
						{
							year: "2093",
							value: 662.7815473750275
						},
						{
							year: "2094",
							value: 663.873310092732
						},
						{
							year: "2095",
							value: 664.9650728104363
						},
						{
							year: "2096",
							value: 666.0568355281412
						},
						{
							year: "2097",
							value: 667.148598245846
						},
						{
							year: "2098",
							value: 668.2403609635502
						},
						{
							year: "2099",
							value: 669.3321236812553
						},
						{
							year: "2100",
							value: 670.4238863989599
						}
					]
				},
				{
					name: "Netherlands",
					data: [
						{
							year: "2020",
							value: 163.9151815480303
						},
						{
							year: "2021",
							value: 161.1868603669072
						},
						{
							year: "2022",
							value: 156.5286014144959
						},
						{
							year: "2023",
							value: 151.0327052303846
						},
						{
							year: "2024",
							value: 145.5379449336168
						},
						{
							year: "2025",
							value: 140.0443205171874
						},
						{
							year: "2026",
							value: 134.5518319846639
						},
						{
							year: "2027",
							value: 129.0604793360463
						},
						{
							year: "2028",
							value: 123.5702625713345
						},
						{
							year: "2029",
							value: 118.3779478905138
						},
						{
							year: "2030",
							value: 113.7803181830078
						},
						{
							year: "2031",
							value: 110.0831659428303
						},
						{
							year: "2032",
							value: 106.9807826289024
						},
						{
							year: "2033",
							value: 104.1764354201168
						},
						{
							year: "2034",
							value: 101.373374805927
						},
						{
							year: "2035",
							value: 98.57160078633339
						},
						{
							year: "2036",
							value: 95.77111336133575
						},
						{
							year: "2037",
							value: 92.97191253093408
						},
						{
							year: "2038",
							value: 90.1739982951284
						},
						{
							year: "2039",
							value: 87.52116548845126
						},
						{
							year: "2040",
							value: 85.15718070532695
						},
						{
							year: "2041",
							value: 83.23013971930009
						},
						{
							year: "2042",
							value: 81.59180470052456
						},
						{
							year: "2043",
							value: 80.0983243342515
						},
						{
							year: "2044",
							value: 78.60587554584029
						},
						{
							year: "2045",
							value: 77.1144583352909
						},
						{
							year: "2046",
							value: 75.6240727026033
						},
						{
							year: "2047",
							value: 74.13471864777746
						},
						{
							year: "2048",
							value: 72.6463961708134
						},
						{
							year: "2049",
							value: 71.17364343736016
						},
						{
							year: "2050",
							value: 69.73098746726392
						},
						{
							year: "2051",
							value: 68.33338468504216
						},
						{
							year: "2052",
							value: 66.9658225994113
						},
						{
							year: "2053",
							value: 65.61374075311635
						},
						{
							year: "2054",
							value: 64.26258983470543
						},
						{
							year: "2055",
							value: 62.9123698441785
						},
						{
							year: "2056",
							value: 61.5630807815356
						},
						{
							year: "2057",
							value: 60.21472264677671
						},
						{
							year: "2058",
							value: 58.86729543990175
						},
						{
							year: "2059",
							value: 57.52849902663222
						},
						{
							year: "2060",
							value: 56.20603010190542
						},
						{
							year: "2061",
							value: 54.90781551913925
						},
						{
							year: "2062",
							value: 53.62591247491217
						},
						{
							year: "2063",
							value: 52.3526147619351
						},
						{
							year: "2064",
							value: 51.08021934370289
						},
						{
							year: "2065",
							value: 49.80872622021555
						},
						{
							year: "2066",
							value: 48.53813539147305
						},
						{
							year: "2067",
							value: 47.26844685747542
						},
						{
							year: "2068",
							value: 45.99966061822263
						},
						{
							year: "2069",
							value: 44.79183585689844
						},
						{
							year: "2070",
							value: 43.70500301348739
						},
						{
							year: "2071",
							value: 42.8009837600231
						},
						{
							year: "2072",
							value: 42.01781183747055
						},
						{
							year: "2073",
							value: 41.29537057624785
						},
						{
							year: "2074",
							value: 40.57357204997221
						},
						{
							year: "2075",
							value: 39.85241625864363
						},
						{
							year: "2076",
							value: 39.13190320226203
						},
						{
							year: "2077",
							value: 38.41203288082749
						},
						{
							year: "2078",
							value: 37.69280529434
						},
						{
							year: "2079",
							value: 37.00788036141408
						},
						{
							year: "2080",
							value: 36.39084682531784
						},
						{
							year: "2081",
							value: 35.87624225150658
						},
						{
							year: "2082",
							value: 35.42917104096386
						},
						{
							year: "2083",
							value: 35.01583092438216
						},
						{
							year: "2084",
							value: 34.60249080780046
						},
						{
							year: "2085",
							value: 34.18915069121877
						},
						{
							year: "2086",
							value: 33.77581057463711
						},
						{
							year: "2087",
							value: 33.36247045805538
						},
						{
							year: "2088",
							value: 32.94913034147369
						},
						{
							year: "2089",
							value: 32.58745143725348
						},
						{
							year: "2090",
							value: 32.32909495775618
						},
						{
							year: "2091",
							value: 32.22728760662696
						},
						{
							year: "2092",
							value: 32.2288026802206
						},
						{
							year: "2093",
							value: 32.28197896617574
						},
						{
							year: "2094",
							value: 32.33515525213086
						},
						{
							year: "2095",
							value: 32.3883315380814
						},
						{
							year: "2096",
							value: 32.44150782403651
						},
						{
							year: "2097",
							value: 32.49468410999163
						},
						{
							year: "2098",
							value: 32.54786039594675
						},
						{
							year: "2099",
							value: 32.60103668190192
						},
						{
							year: "2100",
							value: 32.65421296785703
						}
					]
				},
				{
					name: "Remaining Advanced Economies",
					data: [
						{
							year: "2020",
							value: 1474.438235613005
						},
						{
							year: "2021",
							value: 1393.275823744036
						},
						{
							year: "2022",
							value: 1353.010509534429
						},
						{
							year: "2023",
							value: 1305.504780682219
						},
						{
							year: "2024",
							value: 1258.008870245612
						},
						{
							year: "2025",
							value: 1210.52277822458
						},
						{
							year: "2026",
							value: 1163.046504619176
						},
						{
							year: "2027",
							value: 1115.580049429376
						},
						{
							year: "2028",
							value: 1068.123412655174
						},
						{
							year: "2029",
							value: 1023.241798251739
						},
						{
							year: "2030",
							value: 983.5005544353222
						},
						{
							year: "2031",
							value: 951.5429071364472
						},
						{
							year: "2032",
							value: 924.7263561015714
						},
						{
							year: "2033",
							value: 900.4859858977048
						},
						{
							year: "2034",
							value: 876.2567368307683
						},
						{
							year: "2035",
							value: 852.0386089007627
						},
						{
							year: "2036",
							value: 827.8316021076875
						},
						{
							year: "2037",
							value: 803.6357164515421
						},
						{
							year: "2038",
							value: 779.4509519323278
						},
						{
							year: "2039",
							value: 756.5202502270057
						},
						{
							year: "2040",
							value: 736.086308909143
						},
						{
							year: "2041",
							value: 719.4292463482119
						},
						{
							year: "2042",
							value: 705.2677162606896
						},
						{
							year: "2043",
							value: 692.3582887525538
						},
						{
							year: "2044",
							value: 679.4577780641755
						},
						{
							year: "2045",
							value: 666.5661841982621
						},
						{
							year: "2046",
							value: 653.6835071282236
						},
						{
							year: "2047",
							value: 640.8097468676228
						},
						{
							year: "2048",
							value: 627.9449034161762
						},
						{
							year: "2049",
							value: 615.2146425712396
						},
						{
							year: "2050",
							value: 602.7445337838466
						},
						{
							year: "2051",
							value: 590.6638582327829
						},
						{
							year: "2052",
							value: 578.842850072955
						},
						{
							year: "2053",
							value: 567.1556508560797
						},
						{
							year: "2054",
							value: 555.4764984447041
						},
						{
							year: "2055",
							value: 543.8053928388282
						},
						{
							year: "2056",
							value: 532.142334038452
						},
						{
							year: "2057",
							value: 520.4873220435759
						},
						{
							year: "2058",
							value: 508.8403568541996
						},
						{
							year: "2059",
							value: 497.2679949919383
						},
						{
							year: "2060",
							value: 485.8367655706109
						},
						{
							year: "2061",
							value: 474.6151871605331
						},
						{
							year: "2062",
							value: 463.5346033218734
						},
						{
							year: "2063",
							value: 452.5284027174247
						},
						{
							year: "2064",
							value: 441.5300014177777
						},
						{
							year: "2065",
							value: 430.5393994229319
						},
						{
							year: "2066",
							value: 419.5565967328874
						},
						{
							year: "2067",
							value: 408.5815933476441
						},
						{
							year: "2068",
							value: 397.6143892672019
						},
						{
							year: "2069",
							value: 387.1741273530635
						},
						{
							year: "2070",
							value: 377.7797020146899
						},
						{
							year: "2071",
							value: 369.9654908113666
						},
						{
							year: "2072",
							value: 363.1958663947555
						},
						{
							year: "2073",
							value: 356.9511889992727
						},
						{
							year: "2074",
							value: 350.7120673113758
						},
						{
							year: "2075",
							value: 344.4785013310644
						},
						{
							year: "2076",
							value: 338.2504910583384
						},
						{
							year: "2077",
							value: 332.0280364931978
						},
						{
							year: "2078",
							value: 325.8111376356427
						},
						{
							year: "2079",
							value: 319.8907459362455
						},
						{
							year: "2080",
							value: 314.557197616215
						},
						{
							year: "2081",
							value: 310.1090303780198
						},
						{
							year: "2082",
							value: 306.2446117290598
						},
						{
							year: "2083",
							value: 302.6717597600355
						},
						{
							year: "2084",
							value: 299.0989077910113
						},
						{
							year: "2085",
							value: 295.526055821987
						},
						{
							year: "2086",
							value: 291.953203852963
						},
						{
							year: "2087",
							value: 288.3803518839386
						},
						{
							year: "2088",
							value: 284.8074999149143
						},
						{
							year: "2089",
							value: 281.6811999666188
						},
						{
							year: "2090",
							value: 279.4480040597811
						},
						{
							year: "2091",
							value: 278.5679960945456
						},
						{
							year: "2092",
							value: 278.5810921707674
						},
						{
							year: "2093",
							value: 279.040740267718
						},
						{
							year: "2094",
							value: 279.5003883646687
						},
						{
							year: "2095",
							value: 279.9600364616193
						},
						{
							year: "2096",
							value: 280.4196845585699
						},
						{
							year: "2097",
							value: 280.8793326555207
						},
						{
							year: "2098",
							value: 281.3389807524715
						},
						{
							year: "2099",
							value: 281.7986288494224
						},
						{
							year: "2100",
							value: 282.258276946373
						}
					]
				},
				{
					name: "Rest of Asia",
					data: [
						{
							year: "2020",
							value: 4437.550619530948
						},
						{
							year: "2021",
							value: 4193.279749193664
						},
						{
							year: "2022",
							value: 4072.095039179567
						},
						{
							year: "2023",
							value: 3929.119177995562
						},
						{
							year: "2024",
							value: 3786.172866856574
						},
						{
							year: "2025",
							value: 3643.256105762604
						},
						{
							year: "2026",
							value: 3500.368894713648
						},
						{
							year: "2027",
							value: 3357.511233709699
						},
						{
							year: "2028",
							value: 3214.683122750776
						},
						{
							year: "2029",
							value: 3079.60494111456
						},
						{
							year: "2030",
							value: 2959.997502254868
						},
						{
							year: "2031",
							value: 2863.816004688423
						},
						{
							year: "2032",
							value: 2783.107433936405
						},
						{
							year: "2033",
							value: 2710.152279072903
						},
						{
							year: "2034",
							value: 2637.230594996363
						},
						{
							year: "2035",
							value: 2564.342381706674
						},
						{
							year: "2036",
							value: 2491.48763922303
						},
						{
							year: "2037",
							value: 2418.666367506831
						},
						{
							year: "2038",
							value: 2345.878566577599
						},
						{
							year: "2039",
							value: 2276.86506224645
						},
						{
							year: "2040",
							value: 2215.365945657469
						},
						{
							year: "2041",
							value: 2165.233931645607
						},
						{
							year: "2042",
							value: 2122.612609778074
						},
						{
							year: "2043",
							value: 2083.759684909668
						},
						{
							year: "2044",
							value: 2044.933596562228
						},
						{
							year: "2045",
							value: 2006.134344735754
						},
						{
							year: "2046",
							value: 1967.361929430243
						},
						{
							year: "2047",
							value: 1928.616350645697
						},
						{
							year: "2048",
							value: 1889.897608382116
						},
						{
							year: "2049",
							value: 1851.583913341457
						},
						{
							year: "2050",
							value: 1814.053186267365
						},
						{
							year: "2051",
							value: 1777.694518875543
						},
						{
							year: "2052",
							value: 1742.117360872125
						},
						{
							year: "2053",
							value: 1706.942921638533
						},
						{
							year: "2054",
							value: 1671.792700514502
						},
						{
							year: "2055",
							value: 1636.666697500029
						},
						{
							year: "2056",
							value: 1601.564912595117
						},
						{
							year: "2057",
							value: 1566.487345799763
						},
						{
							year: "2058",
							value: 1531.433997113976
						},
						{
							year: "2059",
							value: 1496.605178715339
						},
						{
							year: "2060",
							value: 1462.201120293444
						},
						{
							year: "2061",
							value: 1428.4280391159
						},
						{
							year: "2062",
							value: 1395.079302975346
						},
						{
							year: "2063",
							value: 1361.954434735708
						},
						{
							year: "2064",
							value: 1328.853039813514
						},
						{
							year: "2065",
							value: 1295.775117988646
						},
						{
							year: "2066",
							value: 1262.72066938159
						},
						{
							year: "2067",
							value: 1229.689693992344
						},
						{
							year: "2068",
							value: 1196.68219182091
						},
						{
							year: "2069",
							value: 1165.260603850662
						},
						{
							year: "2070",
							value: 1136.986623310013
						},
						{
							year: "2071",
							value: 1113.468542368882
						},
						{
							year: "2072",
							value: 1093.094307423299
						},
						{
							year: "2073",
							value: 1074.299981979964
						},
						{
							year: "2074",
							value: 1055.52237730054
						},
						{
							year: "2075",
							value: 1036.761493385028
						},
						{
							year: "2076",
							value: 1018.01732994853
						},
						{
							year: "2077",
							value: 999.2898875660801
						},
						{
							year: "2078",
							value: 980.5791659475362
						},
						{
							year: "2079",
							value: 962.7608286224019
						},
						{
							year: "2080",
							value: 946.7086874919622
						},
						{
							year: "2081",
							value: 933.3212380877546
						},
						{
							year: "2082",
							value: 921.6906706272083
						},
						{
							year: "2083",
							value: 910.9376183274229
						},
						{
							year: "2084",
							value: 900.1845660245595
						},
						{
							year: "2085",
							value: 889.4315137216963
						},
						{
							year: "2086",
							value: 878.6784614188335
						},
						{
							year: "2087",
							value: 867.925409116178
						},
						{
							year: "2088",
							value: 857.1723568133126
						},
						{
							year: "2089",
							value: 847.7632720961375
						},
						{
							year: "2090",
							value: 841.04212255035
						},
						{
							year: "2091",
							value: 838.3936020521157
						},
						{
							year: "2092",
							value: 838.4330167340718
						},
						{
							year: "2093",
							value: 839.8163989929299
						},
						{
							year: "2094",
							value: 841.1997812517884
						},
						{
							year: "2095",
							value: 842.5831635106466
						},
						{
							year: "2096",
							value: 843.9665457695047
						},
						{
							year: "2097",
							value: 845.3499280219367
						},
						{
							year: "2098",
							value: 846.7333102807849
						},
						{
							year: "2099",
							value: 848.1166925396191
						},
						{
							year: "2100",
							value: 849.5000747984669
						}
					]
				},
				{
					name: "Rest of the European Union",
					data: [
						{
							year: "2020",
							value: 2729.770648172649
						},
						{
							year: "2021",
							value: 2684.334393386387
						},
						{
							year: "2022",
							value: 2606.757817412426
						},
						{
							year: "2023",
							value: 2515.231602956055
						},
						{
							year: "2024",
							value: 2423.724305005832
						},
						{
							year: "2025",
							value: 2332.235923561759
						},
						{
							year: "2026",
							value: 2240.766458623837
						},
						{
							year: "2027",
							value: 2149.315910192062
						},
						{
							year: "2028",
							value: 2057.884278266437
						},
						{
							year: "2029",
							value: 1971.413775354745
						},
						{
							year: "2030",
							value: 1894.846891903273
						},
						{
							year: "2031",
							value: 1833.276160312193
						},
						{
							year: "2032",
							value: 1781.610446296228
						},
						{
							year: "2033",
							value: 1734.908093224599
						},
						{
							year: "2034",
							value: 1688.227166528026
						},
						{
							year: "2035",
							value: 1641.567666206509
						},
						{
							year: "2036",
							value: 1594.929592260048
						},
						{
							year: "2037",
							value: 1548.312944688642
						},
						{
							year: "2038",
							value: 1501.717723492291
						},
						{
							year: "2039",
							value: 1457.538623989457
						},
						{
							year: "2040",
							value: 1418.169871200378
						},
						{
							year: "2041",
							value: 1386.077786371875
						},
						{
							year: "2042",
							value: 1358.793682514563
						},
						{
							year: "2043",
							value: 1333.921923713545
						},
						{
							year: "2044",
							value: 1309.067344352137
						},
						{
							year: "2045",
							value: 1284.229944430341
						},
						{
							year: "2046",
							value: 1259.409723948155
						},
						{
							year: "2047",
							value: 1234.606682905581
						},
						{
							year: "2048",
							value: 1209.820821302618
						},
						{
							year: "2049",
							value: 1185.294251293934
						},
						{
							year: "2050",
							value: 1161.268899416945
						},
						{
							year: "2051",
							value: 1137.993843323775
						},
						{
							year: "2052",
							value: 1115.219071651275
						},
						{
							year: "2053",
							value: 1092.702101010271
						},
						{
							year: "2054",
							value: 1070.200633628846
						},
						{
							year: "2055",
							value: 1047.714669506997
						},
						{
							year: "2056",
							value: 1025.244208644727
						},
						{
							year: "2057",
							value: 1002.789251042034
						},
						{
							year: "2058",
							value: 980.3497966989189
						},
						{
							year: "2059",
							value: 958.0540757597778
						},
						{
							year: "2060",
							value: 936.0302655641804
						},
						{
							year: "2061",
							value: 914.4103764088227
						},
						{
							year: "2062",
							value: 893.0621323727307
						},
						{
							year: "2063",
							value: 871.8571977018548
						},
						{
							year: "2064",
							value: 850.6672894469723
						},
						{
							year: "2065",
							value: 829.4924076080832
						},
						{
							year: "2066",
							value: 808.3325521851875
						},
						{
							year: "2067",
							value: 787.1877231782853
						},
						{
							year: "2068",
							value: 766.0579205873762
						},
						{
							year: "2069",
							value: 745.9433433783563
						},
						{
							year: "2070",
							value: 727.8437118406464
						},
						{
							year: "2071",
							value: 712.7885766467696
						},
						{
							year: "2072",
							value: 699.7459792364895
						},
						{
							year: "2073",
							value: 687.7147632909624
						},
						{
							year: "2074",
							value: 675.6942511678209
						},
						{
							year: "2075",
							value: 663.6844428670643
						},
						{
							year: "2076",
							value: 651.6853383886919
						},
						{
							year: "2077",
							value: 639.6969377327046
						},
						{
							year: "2078",
							value: 627.7192408991014
						},
						{
							year: "2079",
							value: 616.3128052249258
						},
						{
							year: "2080",
							value: 606.0370027246063
						},
						{
							year: "2081",
							value: 597.4670066759302
						},
						{
							year: "2082",
							value: 590.0216812691778
						},
						{
							year: "2083",
							value: 583.1380985220806
						},
						{
							year: "2084",
							value: 576.2545157749835
						},
						{
							year: "2085",
							value: 569.3709330278866
						},
						{
							year: "2086",
							value: 562.4873502807899
						},
						{
							year: "2087",
							value: 555.6037675336927
						},
						{
							year: "2088",
							value: 548.7201847865955
						},
						{
							year: "2089",
							value: 542.6969449286581
						},
						{
							year: "2090",
							value: 538.3943908490402
						},
						{
							year: "2091",
							value: 536.6989364335423
						},
						{
							year: "2092",
							value: 536.7241677963634
						},
						{
							year: "2093",
							value: 537.6097420483438
						},
						{
							year: "2094",
							value: 538.4953163003243
						},
						{
							year: "2095",
							value: 539.3808905523044
						},
						{
							year: "2096",
							value: 540.2664648042854
						},
						{
							year: "2097",
							value: 541.1520390562658
						},
						{
							year: "2098",
							value: 542.0376133082466
						},
						{
							year: "2099",
							value: 542.9231875602275
						},
						{
							year: "2100",
							value: 543.808761812208
						}
					]
				},
				{
					name: "Rest of the World",
					data: [
						{
							year: "2020",
							value: 15616.19790208732
						},
						{
							year: "2021",
							value: 14756.58353822786
						},
						{
							year: "2022",
							value: 14330.12205608543
						},
						{
							year: "2023",
							value: 13826.97526748453
						},
						{
							year: "2024",
							value: 13323.9324685371
						},
						{
							year: "2025",
							value: 12820.99365924309
						},
						{
							year: "2026",
							value: 12318.15883960252
						},
						{
							year: "2027",
							value: 11815.42800961536
						},
						{
							year: "2028",
							value: 11312.80116928162
						},
						{
							year: "2029",
							value: 10837.44712883392
						},
						{
							year: "2030",
							value: 10416.53622641537
						},
						{
							year: "2031",
							value: 10078.06362535938
						},
						{
							year: "2032",
							value: 9794.041848185412
						},
						{
							year: "2033",
							value: 9537.305140481907
						},
						{
							year: "2034",
							value: 9280.686219926749
						},
						{
							year: "2035",
							value: 9024.185086519958
						},
						{
							year: "2036",
							value: 8767.801740261526
						},
						{
							year: "2037",
							value: 8511.536181151449
						},
						{
							year: "2038",
							value: 8255.388409189727
						},
						{
							year: "2039",
							value: 8012.522775882839
						},
						{
							year: "2040",
							value: 7796.101047368235
						},
						{
							year: "2041",
							value: 7619.681324156971
						},
						{
							year: "2042",
							value: 7469.692500548383
						},
						{
							year: "2043",
							value: 7332.965054297965
						},
						{
							year: "2044",
							value: 7196.332048530217
						},
						{
							year: "2045",
							value: 7059.793483244616
						},
						{
							year: "2046",
							value: 6923.3493584422199
						},
						{
							year: "2047",
							value: 6786.999674122493
						},
						{
							year: "2048",
							value: 6650.744430285442
						},
						{
							year: "2049",
							value: 6515.914589364347
						},
						{
							year: "2050",
							value: 6383.8400933993339
						},
						{
							year: "2051",
							value: 6255.890196232273
						},
						{
							year: "2052",
							value: 6130.690511134429
						},
						{
							year: "2053",
							value: 6006.908034886161
						},
						{
							year: "2054",
							value: 5883.210784661011
						},
						{
							year: "2055",
							value: 5759.598760458981
						},
						{
							year: "2056",
							value: 5636.071962280061
						},
						{
							year: "2057",
							value: 5512.630390124269
						},
						{
							year: "2058",
							value: 5389.27404399154
						},
						{
							year: "2059",
							value: 5266.707843084041
						},
						{
							year: "2060",
							value: 5145.636416296615
						},
						{
							year: "2061",
							value: 5026.785463451566
						},
						{
							year: "2062",
							value: 4909.42782451893
						},
						{
							year: "2063",
							value: 4792.857999736732
						},
						{
							year: "2064",
							value: 4676.370779626827
						},
						{
							year: "2065",
							value: 4559.966164189215
						},
						{
							year: "2066",
							value: 4443.644153423893
						},
						{
							year: "2067",
							value: 4327.404747330864
						},
						{
							year: "2068",
							value: 4211.247945910123
						},
						{
							year: "2069",
							value: 4100.672139984918
						},
						{
							year: "2070",
							value: 4001.173088951722
						},
						{
							year: "2071",
							value: 3918.410538683983
						},
						{
							year: "2072",
							value: 3846.711506434226
						},
						{
							year: "2073",
							value: 3780.572338525678
						},
						{
							year: "2074",
							value: 3714.492012708338
						},
						{
							year: "2075",
							value: 3648.470528982203
						},
						{
							year: "2076",
							value: 3582.507887347271
						},
						{
							year: "2077",
							value: 3516.604087803544
						},
						{
							year: "2078",
							value: 3450.759130351022
						},
						{
							year: "2079",
							value: 3388.054565184203
						},
						{
							year: "2080",
							value: 3331.565426427086
						},
						{
							year: "2081",
							value: 3284.453612442092
						},
						{
							year: "2082",
							value: 3243.524447057601
						},
						{
							year: "2083",
							value: 3205.683347938114
						},
						{
							year: "2084",
							value: 3167.842248818627
						},
						{
							year: "2085",
							value: 3130.001149699141
						},
						{
							year: "2086",
							value: 3092.160050579656
						},
						{
							year: "2087",
							value: 3054.31895146017
						},
						{
							year: "2088",
							value: 3016.477852340682
						},
						{
							year: "2089",
							value: 2983.366313646567
						},
						{
							year: "2090",
							value: 2959.713895803193
						},
						{
							year: "2091",
							value: 2950.393479248822
						},
						{
							year: "2092",
							value: 2950.53218354519
						},
						{
							year: "2093",
							value: 2955.400448266928
						},
						{
							year: "2094",
							value: 2960.268712988665
						},
						{
							year: "2095",
							value: 2965.136977710404
						},
						{
							year: "2096",
							value: 2970.005242432141
						},
						{
							year: "2097",
							value: 2974.873507153881
						},
						{
							year: "2098",
							value: 2979.741771875621
						},
						{
							year: "2099",
							value: 2984.610036597361
						},
						{
							year: "2100",
							value: 2989.4783013191
						}
					]
				},
				{
					name: "United Kingdom",
					data: [
						{
							year: "2020",
							value: 405.7548833709021
						},
						{
							year: "2021",
							value: 399.0012089279771
						},
						{
							year: "2022",
							value: 387.4701762539685
						},
						{
							year: "2023",
							value: 373.8656602493052
						},
						{
							year: "2024",
							value: 360.2639560064108
						},
						{
							year: "2025",
							value: 346.6650635252855
						},
						{
							year: "2026",
							value: 333.0689828059288
						},
						{
							year: "2027",
							value: 319.4757138483409
						},
						{
							year: "2028",
							value: 305.8852566525221
						},
						{
							year: "2029",
							value: 293.0322248978421
						},
						{
							year: "2030",
							value: 281.6512735766303
						},
						{
							year: "2031",
							value: 272.4993600147627
						},
						{
							year: "2032",
							value: 264.8197347030698
						},
						{
							year: "2033",
							value: 257.8778665881016
						},
						{
							year: "2034",
							value: 250.9391833034479
						},
						{
							year: "2035",
							value: 244.0036848491087
						},
						{
							year: "2036",
							value: 237.0713712250841
						},
						{
							year: "2037",
							value: 230.1422424313738
						},
						{
							year: "2038",
							value: 223.216298467978
						},
						{
							year: "2039",
							value: 216.6494884034755
						},
						{
							year: "2040",
							value: 210.7976914010116
						},
						{
							year: "2041",
							value: 206.0275030537102
						},
						{
							year: "2042",
							value: 201.9719761229354
						},
						{
							year: "2043",
							value: 198.275021729242
						},
						{
							year: "2044",
							value: 194.5806208986185
						},
						{
							year: "2045",
							value: 190.8887736310645
						},
						{
							year: "2046",
							value: 187.1994799265803
						},
						{
							year: "2047",
							value: 183.5127397851657
						},
						{
							year: "2048",
							value: 179.8285532068209
						},
						{
							year: "2049",
							value: 176.1829078995772
						},
						{
							year: "2050",
							value: 172.6117639811978
						},
						{
							year: "2051",
							value: 169.1521445157831
						},
						{
							year: "2052",
							value: 165.7668876518188
						},
						{
							year: "2053",
							value: 162.4199505007354
						},
						{
							year: "2054",
							value: 159.0753177642319
						},
						{
							year: "2055",
							value: 155.7329894423085
						},
						{
							year: "2056",
							value: 152.392965534965
						},
						{
							year: "2057",
							value: 149.0552460422016
						},
						{
							year: "2058",
							value: 145.7198309640182
						},
						{
							year: "2059",
							value: 142.4057805124217
						},
						{
							year: "2060",
							value: 139.1321470504751
						},
						{
							year: "2061",
							value: 135.91855267448
						},
						{
							year: "2062",
							value: 132.7453358055696
						},
						{
							year: "2063",
							value: 129.5934205338494
						},
						{
							year: "2064",
							value: 126.443738798369
						},
						{
							year: "2065",
							value: 123.2962905991283
						},
						{
							year: "2066",
							value: 120.1510759361274
						},
						{
							year: "2067",
							value: 117.0080948093663
						},
						{
							year: "2068",
							value: 113.8673472188448
						},
						{
							year: "2069",
							value: 110.8775033889375
						},
						{
							year: "2070",
							value: 108.1871623932367
						},
						{
							year: "2071",
							value: 105.9493573128671
						},
						{
							year: "2072",
							value: 104.0106971567091
						},
						{
							year: "2073",
							value: 102.2223693988239
						},
						{
							year: "2074",
							value: 100.4356326640551
						},
						{
							year: "2075",
							value: 98.65048695240253
						},
						{
							year: "2076",
							value: 96.86693226386619
						},
						{
							year: "2077",
							value: 95.0849685984461
						},
						{
							year: "2078",
							value: 93.30459595614227
						},
						{
							year: "2079",
							value: 91.60913594386942
						},
						{
							year: "2080",
							value: 90.08173398141861
						},
						{
							year: "2081",
							value: 88.80788419863653
						},
						{
							year: "2082",
							value: 87.70120619105464
						},
						{
							year: "2083",
							value: 86.67802597751103
						},
						{
							year: "2084",
							value: 85.65484576396745
						},
						{
							year: "2085",
							value: 84.63166555042386
						},
						{
							year: "2086",
							value: 83.60848533688034
						},
						{
							year: "2087",
							value: 82.58530512333681
						},
						{
							year: "2088",
							value: 81.56212490979321
						},
						{
							year: "2089",
							value: 80.66682662247796
						},
						{
							year: "2090",
							value: 80.02729218761947
						},
						{
							year: "2091",
							value: 79.77527874133176
						},
						{
							year: "2092",
							value: 79.77902914750065
						},
						{
							year: "2093",
							value: 79.91066147989794
						},
						{
							year: "2094",
							value: 80.04229381229522
						},
						{
							year: "2095",
							value: 80.17392614469248
						},
						{
							year: "2096",
							value: 80.30555847708976
						},
						{
							year: "2097",
							value: 80.43719080948705
						},
						{
							year: "2098",
							value: 80.56882314188437
						},
						{
							year: "2099",
							value: 80.70045547428171
						},
						{
							year: "2100",
							value: 80.83208780667902
						}
					]
				},
				{
					name: "United States of America",
					data: [
						{
							year: "2020",
							value: 5981.354372479605
						},
						{
							year: "2021",
							value: 5881.796432908072
						},
						{
							year: "2022",
							value: 5711.814023501455
						},
						{
							year: "2023",
							value: 5511.265774731326
						},
						{
							year: "2024",
							value: 5310.758974984338
						},
						{
							year: "2025",
							value: 5110.293624260496
						},
						{
							year: "2026",
							value: 4909.869722559797
						},
						{
							year: "2027",
							value: 4709.487269882237
						},
						{
							year: "2028",
							value: 4509.14626622782
						},
						{
							year: "2029",
							value: 4319.675872065738
						},
						{
							year: "2030",
							value: 4151.905856871905
						},
						{
							year: "2031",
							value: 4016.994755506815
						},
						{
							year: "2032",
							value: 3903.787096598345
						},
						{
							year: "2033",
							value: 3801.454937690756
						},
						{
							year: "2034",
							value: 3699.169727321574
						},
						{
							year: "2035",
							value: 3596.931465490808
						},
						{
							year: "2036",
							value: 3494.740152198448
						},
						{
							year: "2037",
							value: 3392.5957874445
						},
						{
							year: "2038",
							value: 3290.498371228964
						},
						{
							year: "2039",
							value: 3193.695055477738
						},
						{
							year: "2040",
							value: 3107.431961619777
						},
						{
							year: "2041",
							value: 3037.113185190902
						},
						{
							year: "2042",
							value: 2977.329446943383
						},
						{
							year: "2043",
							value: 2922.831533957423
						},
						{
							year: "2044",
							value: 2868.371263810182
						},
						{
							year: "2045",
							value: 2813.948636501653
						},
						{
							year: "2046",
							value: 2759.563652031837
						},
						{
							year: "2047",
							value: 2705.216310400734
						},
						{
							year: "2048",
							value: 2650.906611608347
						},
						{
							year: "2049",
							value: 2597.165061247119
						},
						{
							year: "2050",
							value: 2544.521758193074
						},
						{
							year: "2051",
							value: 2493.522470532849
						},
						{
							year: "2052",
							value: 2443.619384272936
						},
						{
							year: "2053",
							value: 2394.281180388034
						},
						{
							year: "2054",
							value: 2344.976946569274
						},
						{
							year: "2055",
							value: 2295.70668281665
						},
						{
							year: "2056",
							value: 2246.470389130165
						},
						{
							year: "2057",
							value: 2197.268065509818
						},
						{
							year: "2058",
							value: 2148.099711955612
						},
						{
							year: "2059",
							value: 2099.246300766584
						},
						{
							year: "2060",
							value: 2050.988688538143
						},
						{
							year: "2061",
							value: 2003.616130474157
						},
						{
							year: "2062",
							value: 1956.838789346426
						},
						{
							year: "2063",
							value: 1910.375461448643
						},
						{
							year: "2064",
							value: 1863.945058778138
						},
						{
							year: "2065",
							value: 1817.547581334909
						},
						{
							year: "2066",
							value: 1771.183029118956
						},
						{
							year: "2067",
							value: 1724.851402130282
						},
						{
							year: "2068",
							value: 1678.552700368883
						},
						{
							year: "2069",
							value: 1634.478516180446
						},
						{
							year: "2070",
							value: 1594.819393055644
						},
						{
							year: "2071",
							value: 1561.83123751941
						},
						{
							year: "2072",
							value: 1533.252866988284
						},
						{
							year: "2073",
							value: 1506.890591406562
						},
						{
							year: "2074",
							value: 1480.551769573552
						},
						{
							year: "2075",
							value: 1454.236401489256
						},
						{
							year: "2076",
							value: 1427.94448715367
						},
						{
							year: "2077",
							value: 1401.676026566796
						},
						{
							year: "2078",
							value: 1375.431019728635
						},
						{
							year: "2079",
							value: 1350.437735424765
						},
						{
							year: "2080",
							value: 1327.92184521356
						},
						{
							year: "2081",
							value: 1309.14364369242
						},
						{
							year: "2082",
							value: 1292.829771424058
						},
						{
							year: "2083",
							value: 1277.746765168483
						},
						{
							year: "2084",
							value: 1262.663758912907
						},
						{
							year: "2085",
							value: 1247.580752657332
						},
						{
							year: "2086",
							value: 1232.497746401758
						},
						{
							year: "2087",
							value: 1217.414740146181
						},
						{
							year: "2088",
							value: 1202.331733890609
						},
						{
							year: "2089",
							value: 1189.133873444205
						},
						{
							year: "2090",
							value: 1179.706304621108
						},
						{
							year: "2091",
							value: 1175.991298861099
						},
						{
							year: "2092",
							value: 1176.046584722728
						},
						{
							year: "2093",
							value: 1177.987016395175
						},
						{
							year: "2094",
							value: 1179.927448067622
						},
						{
							year: "2095",
							value: 1181.867879740069
						},
						{
							year: "2096",
							value: 1183.808311412517
						},
						{
							year: "2097",
							value: 1185.748743084964
						},
						{
							year: "2098",
							value: 1187.689174757412
						},
						{
							year: "2099",
							value: 1189.629606429861
						},
						{
							year: "2100",
							value: 1191.570038102309
						}
					]
				}
			]
		}
	]
}
