import React from 'react';
import { sanitizeHtml } from '../../common/htmlUtil';

import { StylePrivacy } from './PrivacyModalBody.style';

interface IOwnProps {
    content: string;
}
const PrivacyModalContent: React.FC<IOwnProps> = ({ content }) => {
    return (
        <StylePrivacy>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
        </StylePrivacy>
    );
};

export default React.memo(PrivacyModalContent);
