import { MotifToast } from '@ey-xd/motif-react';
import React, { useState } from 'react';

function ErrorFallbackUI({ errorMessage }) {
    const [showError, SetShowError] = useState(true);

    return (
        <div className="error-message mb-20">
            {showError && (
                <>
                    <MotifToast variant="error" onClose={() => SetShowError(false)}>
                        <h3>Something Went Wrong.</h3>
                        {/* <h3 className="error">{errorMessage}</h3> */}
                        <h3>Please try again or report an issue to support</h3>
                    </MotifToast>
                </>
            )}
        </div>
    );
}

class ErrorBoundary extends React.Component {
    state = { error: false, errorMessage: '' };

    static getDerivedStateFromError(error) {
        // Update state to render the fallback UI
        console.log('getDerivedStateFromError', error);
        return { error: true, errorMessage: error.toString() };
    }

    componentDidCatch(error, errorInfo) {
        // Log error to an error reporting service like Sentry
        console.log({ error, errorInfo });
    }

    render() {
        const { error, errorMessage } = this.state;
        const { children } = this.props;

        return <>{error ? <ErrorFallbackUI {...{ error, errorMessage }} /> : children}</>;
    }
}

export default ErrorBoundary;
