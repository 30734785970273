import { ellipsisStyle, getFormatedLabel } from '../../../../../table/TableContainer';
import ActionCellRenderer from '../CellRenderer/ActionCellRenderer';
import FullWidthCellRenderer from '../CellRenderer/FullCellRenderer';
import sassVars from '../../../../../../styles/variables.module.scss';
const { fontInterstate } = sassVars;

export const chartLibraryColumnDefs = [
    {
        headerName: 'Chart Name',
        field: 'name',
        cellRendererFramework: (params: any) => {
            if (
                params.value === 'Emissions' ||
                params.value === 'Key Indicators' ||
                params.value === 'Sectoral Impacts'
            ) {
                return FullWidthCellRenderer(params);
            }
            return getFormatedLabel(params.value, {
                fontSize: '1rem',
                fontFamily: fontInterstate,
                color: 'var(--body-color)',
                ...ellipsisStyle
            });
        },
        flex: 1,
        suppressMovable: true
    },
    {
        headerName: 'Chart Description',
        field: 'description',
        cellStyle: {
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            overflowY: 'auto',
            fontSize: '14px'
        },
        flex: 3,
        suppressMovable: true
    },
    {
        headerName: 'Action',
        field: 'value',
        cellRendererFramework: ActionCellRenderer,
        width: 103,
        suppressMovable: true
    }
];
