import { LoopReducer, loop, Cmd, Loop } from 'redux-loop';
import { AnyAction } from 'redux';
import * as RichTextEditorActions from '../actions/RichTextEditorActions';
import { IClimateScenarioData } from '../../models/IClimateScenarioData';
import {
    getPageDetails,
    savePageDetails
} from '../../components/editableArea/EditableTextArea.service';
import { TabNavPageNames } from '../../components/editableArea/EditableTextArea';

export type IPageDataEnum = {
    [key in TabNavPageNames]: string;
};
export type IPageData = {
    loading: boolean;
    error: string | null;
} & IPageDataEnum;

export type IRichTextEditorState = {
    climateScenariosData: IClimateScenarioData;
    pageData: IPageData;
};

let pageNames: IPageDataEnum = {
    [TabNavPageNames.overview]: '',
    [TabNavPageNames.theWorldToday]: '',
    [TabNavPageNames.modellingInputs]: '',
    [TabNavPageNames.emissions]: '',
    [TabNavPageNames.keyIndicators]: '',
    [TabNavPageNames.sectoralImpacts]: ''
};

export const initialState: IRichTextEditorState = {
    climateScenariosData: {
        coRemoval: [],
        effortCurbEmissions: [],
        regionalPolicyVariation: [],
        speedGreenTechnologyDevelopment: []
    },
    pageData: {
        ...pageNames,
        ...{
            loading: false,
            error: null
        }
    }
};

export const RichTextEditorReducer: LoopReducer<IRichTextEditorState> = (
    state: IRichTextEditorState = initialState,
    action: AnyAction
): IRichTextEditorState | Loop<IRichTextEditorState, AnyAction> => {
    switch (action.type) {
        case RichTextEditorActions.SET_CLIMATE_SCENARIOS_DATA:
            return {
                ...state,
                climateScenariosData: action.value
            };

        case RichTextEditorActions.FETCH_PAGE_DATA:
            state.pageData.loading = true;
            const cmd = Cmd.run(getPageDetails, {
                successActionCreator: RichTextEditorActions.storePageData,
                failActionCreator: RichTextEditorActions.storePageData,
                args: [action.value]
            });
            return loop(state, cmd);

        case RichTextEditorActions.SAVE_PAGE_DATA_TO_DB:
            state.pageData.loading = true;
            const cmdSave = Cmd.run(savePageDetails, {
                successActionCreator: RichTextEditorActions.storePageData,
                failActionCreator: RichTextEditorActions.storePageData,
                args: [action.value]
            });
            return loop(state, cmdSave);

        case RichTextEditorActions.STORE_PAGE_DATA:
            const { pageName, data, error } = action.value;
            state.pageData.loading = false;
            state.pageData.error = error;
            state.pageData[pageName] = data;
            return {
                ...state
            };
        default:
            return state;
    }
};
