import React from 'react';
import { MotifIcon } from '@ey-xd/motif-react';
import { actionIcLock24px } from '@ey-xd/motif-react/assets/icons';
import { StyleProgressSteps } from './ProgressSteps.style';
import { CLIENT_DEMO_DISCLAIMER, PRIVACY_NOTICE } from '../Policies.label.const';

interface IOwnProps {
    isClientDemo?: boolean;
    isExternalUser?: boolean;
}

const ProgressSteps: React.FC<IOwnProps> = ({ isClientDemo = false, isExternalUser = false }) => {
    let privacyClass = 'active';
    if (isExternalUser) {
        privacyClass = 'active single-active';
    }
    let clientDemoActiveFlag = '';
    if (isClientDemo) {
        clientDemoActiveFlag = 'active';
    }

    return (
        <StyleProgressSteps>
            <ul className="progressBar">
                <li className={privacyClass}>
                    <MotifIcon className="icons privacy-notice-icon" src={actionIcLock24px} />
                    {PRIVACY_NOTICE}
                </li>
                {!isExternalUser && (
                    <li className={clientDemoActiveFlag}>
                        <span className="motif-icon icons privacy-notice-icon">!</span>
                        {CLIENT_DEMO_DISCLAIMER}
                    </li>
                )}
            </ul>
        </StyleProgressSteps>
    );
};

export default React.memo(ProgressSteps);
