import styled from 'styled-components';
import sassVars from '../../../../styles/variables.module.scss';
const { netIncomeColor, gray100, white4 } = sassVars;

export const StyledPageLayout = styled.div`
    display: flex;
    height: 125px;
    z-index: 9999999;
    width: 90%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.20287);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    .warning {
        background: ${netIncomeColor};
        padding: 10px 13px;
        height: 100%;
    }
    p,
    span {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
    }
    .box {
        padding: 10px;
        background: ${gray100};
        line-height: 22px;
        width: 100%;
    }
    a {
        color: ${white4};
        cursor: pointer;
    }
    .details-p {
        margin-top: 1rem;
    }
`;
