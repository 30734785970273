import { MotifLabel } from '@ey-xd/motif-react';
import React from 'react';
import styled from 'styled-components';

export const StyledMotifLabel = styled(MotifLabel)`
    &.motif-label {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const HeaderWrapper = ({ children }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            fontSize: '16px',
            lineHeight: '40px',
            fontWeight: 400
        }}>
        {children}
    </div>
);

export function customizeStyles() {
    const selectDropdown = document.querySelector('.motif-select-wrapper-options') as HTMLElement;

    if (selectDropdown instanceof HTMLElement) {
        selectDropdown.setAttribute('data-popper-placement', 'bottom');
        selectDropdown.style.inset = '0px auto auto 0px';
        selectDropdown.style.transform = 'translate(0px, 64px)';
        selectDropdown.style.zIndex = '9999999';
        selectDropdown.style.overflowY = 'scroll';
    } else {
        console.error('Invalid HTML element provided.');
    }
    const modalOverlay = document.querySelector('.motif-modal-overlay') as HTMLElement;
    if (modalOverlay instanceof HTMLElement) {
        modalOverlay.style.zIndex = '999999999';
    }
}
