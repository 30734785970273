import React, { FC, useEffect, useState } from 'react';
import { RICH_TEXT_EDITOR } from '../../app.constant';
import { characterCountFromHTML } from '../../common/htmlUtil';

import StyledReadMore from './ReadMore.style';

function truncateString(text, length) {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    } else {
        return text;
    }
}

interface ReadMoreProps {
    text: string;
}

const ReadMore: FC<ReadMoreProps> = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReadmore, setShowReadmore] = useState(false);
    const [htmlContent, setHtmlContent] = useState(text);

    useEffect(() => {
        setHtmlContent(text);
        const readMoreStatus =
            characterCountFromHTML(htmlContent) > RICH_TEXT_EDITOR.READMORE_PREVIEW_CHARACTER_LIMIT;
        setShowReadmore(readMoreStatus);
        if (!readMoreStatus) {
            setIsExpanded(true);
        }
    }, [text]);

    const handleReadMoreClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <StyledReadMore.Container>
            <div
                className="motif-rich-text-editor ql-editor"
                dangerouslySetInnerHTML={{
                    __html: isExpanded
                        ? htmlContent
                        : truncateString(
                              htmlContent,
                              RICH_TEXT_EDITOR.READMORE_PREVIEW_CHARACTER_LIMIT
                          )
                }}
            />
            {showReadmore && (
                <StyledReadMore.Link onClick={handleReadMoreClick}>
                    {isExpanded ? 'read less' : 'read more'}
                </StyledReadMore.Link>
            )}
        </StyledReadMore.Container>
    );
};

export default ReadMore;
