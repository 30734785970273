import React, { useCallback, useEffect, useState } from 'react';
import { StyledLandingPage, StyledContent } from './LandingPage.style';
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer';
import { getUserTenant } from '../../../../services/userProfile.service';
import { IUserTenant } from '../../../../models/IUserTenant';
import {
    MotifButton,
    MotifCard,
    MotifCardBody,
    MotifCardFooter,
    MotifCardImage,
    MotifIcon
} from '@ey-xd/motif-react';
import { navigationIcArrowForward24px } from '@ey-xd/motif-react/assets/icons';
import { globalTenantImg } from '../../../../assets/icons';
import { TENANT_START_BTN_TEXT, appRoutes } from '../../../../app.labels';
import { useDispatch } from 'react-redux';
import { storeUserTenant } from '../../../../store/actions/userProfileActions';
import { useHistory, useLocation } from 'react-router';
import LoadIndicator from '../../../loadIndicator';

const LandingPage: React.FC = () => {
    const [tenantsData, setTenantsData] = useState<Array<IUserTenant>>([]);
    const [isLoding, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const unauthorizedCondition = location?.pathname.includes(`/${appRoutes.unauthorized}`);
    const tenant = location?.pathname?.split('/');

    const getTenants = useCallback(async () => {
        if (!unauthorizedCondition) {
            const tenantsDetails = await getUserTenant();
            if (tenant.length > 0) {
                const tenantDetails = tenantsDetails?.find(
                    (item) => item?.tenantName?.toLowerCase() === tenant[1]
                );
                if (tenantDetails) {
                    dispatch(storeUserTenant(tenantDetails));
                } else {
                    setTenantsData(tenantsDetails);
                }
            } else {
                setTenantsData(tenantsDetails);
            }
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getTenants();
    }, []);

    const onTenantSelect = useCallback(
        (tenant: IUserTenant) => () => {
            dispatch(storeUserTenant(tenant));
            let name = tenant?.tenantName?.toLowerCase();
            history.push(`/${name}/${appRoutes.overview}`);
        },
        []
    );

    if (isLoding) {
        return (
            <StyledContent>
                <div className="center-wrapper">
                    <LoadIndicator />
                </div>
            </StyledContent>
        );
    }

    return (
        <>
            <StyledLandingPage.Container>
                <div className="main-container">
                    <Header isSidebar={false} />
                    <div className="main-wrapper">
                        <div className="main-content">
                            <div className="flex-container mb-10">
                                {tenantsData?.map((tenant, index) => {
                                    return (
                                        <MotifCard key={index} className="flex-item flex-1">
                                            <MotifCardImage>
                                                <img
                                                    src={globalTenantImg}
                                                    alt="woman on glacier with EY in the center"
                                                />
                                            </MotifCardImage>
                                            <MotifCardBody>
                                                <p className="motif-body tenant-body">
                                                    {tenant?.tenantFriendlyName}
                                                </p>
                                            </MotifCardBody>
                                            <MotifCardFooter>
                                                <MotifButton
                                                    className="tenant-start-btn"
                                                    size="large"
                                                    onClick={onTenantSelect(tenant)}>
                                                    <MotifIcon src={navigationIcArrowForward24px} />
                                                    {TENANT_START_BTN_TEXT}
                                                </MotifButton>
                                            </MotifCardFooter>
                                        </MotifCard>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </StyledLandingPage.Container>
            <Footer />
        </>
    );
};

export default React.memo(LandingPage);
