import { MotifDropdownItem, MotifIcon } from "@ey-xd/motif-react";
import styled from "styled-components";


const StyledDropdown = {
    Wrapper: styled.div``,
    Item: styled(MotifDropdownItem)`
        cursor: pointer;
    `,
    Icon: styled(MotifIcon)`
        .motif-dropdown-item & svg {
            width: 20px;
            height: 20px;
        }
    `,
    ModalBody: styled.div`
        font-size: 14px;
        font-weight: var(--weight-bold);
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 20px 0 40px;
    `,
    ModalBodyIcon: styled(MotifIcon)`
        display: inline-block;
        height: 24px;
    `
};

export default StyledDropdown;