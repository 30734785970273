import styled from 'styled-components';
import sassVars from '../../../../../../styles/variables.module.scss';

const { fontInterstate, chartXaxisLabelColor, gray100 } = sassVars;
export const StyledIcon = styled.div`
    cursor: pointer;
    .motif-icon svg {
        fill: none;
    }
`;
export const StyledNoResultFound = styled.div`
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledDiv = styled.div`
    padding-top: 20px;
    .ag-center-cols-viewport {
        overflow-x: inherit;
    }
    .ag-theme-material
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border: none;
    }
    .ag-body-horizontal-scroll {
        display: none;
    }
    .ag-center-cols-viewport {
        ::-webkit-scrollbar {
            height: 10px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(145, 158, 171, 0.6);
            border-radius: 3px;
        }
    }
    .motif-table {
        padding: 20px 15px !important;
    }
    .motif-table .ag-header {
        background-color: ${gray100};
    }
    .motif-table .ag-header-cell-label {
        font-size: 14px;
    }
    .ag-header-row-column {
        color: var(--body-color);
    }
    .motif-label {
        padding-left: unset;
    }
    .motif-table .ag-react-container {
        align-items: center;
    }
    .ag-pinned-left-cols-container .ag-row {
        border-right: unset;
    }
    .motif-table .ag-cell:hover {
        background: unset;
    }
    .motif-table .ag-cell:focus {
        border: unset !important;
        background: unset;
    }
    .motif-tooltip-wrapper {
        width: 100%;
    }
    .motif-tooltip-trigger-wrap {
        display: inline;
    }
    .ag-overflow {
        position: initial;
    }
`;
