import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { convertValueAsperCIMStandard, formattDecimalPoints } from '../../utils/numberUtils';
import WidgetWrapper from '../widgetWrapper/WidgetWrapper';
import { useSelector } from 'react-redux';
import { IStore } from '../../store';
import { MotifTable } from '@ey-xd/motif-react';
import styled from 'styled-components';
import { FILTER_VIEWS } from '../../app.labels';
import { TABLE_PROPERTIES } from '../../app.constant';
import sassVars from '../../styles/variables.module.scss';
import { filterMappingFunction } from '../../common/filtersUtil';
import Tooltip, { TooltipPlacement } from '../tooltip';
import {
    ITableColumnDetails,
    ITableRow,
    ITableRowWithRegionsandSector,
    ITableColumnDetailsParams
} from '../../models/ITableDetails';
import LoadIndicator from '../loadIndicator';

interface IOwnProps {
    tableColHeaders: (string | number)[];
    tableData: Array<ITableRow | ITableRowWithRegionsandSector>;
    headerText: string;
    unit: string;
    selectedSector?: string;
    columnWidth?: number;
    columnDetails?: Array<ITableColumnDetails>;
    tableUnit?: string;
    useDefaultFormatter?: boolean;
}
const { gray100, fontEyInterstate } = sassVars;
export const ellipsisStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

export const labelStyle = {
    fontSize: '13px',
    fontFamily: fontEyInterstate,
    color: 'var(--body-color)'
};

export const getLabel = (label, style) => {
    return <div style={style}>{label}</div>;
};

export const getFormatedLabel = (label, style?, length?: number) => {
    let placement = TooltipPlacement.BOTTOM;
    if (length && length === 1) {
        placement = TooltipPlacement.LEFT;
    }
    return (
        <Tooltip
            hideCloseButton={true}
            content={getLabel(label, { minWidth: 'max-content', minHeight: 'max-content' })}
            placement={placement}
            children={<div style={style ? style : ellipsisStyle}>{label}</div>}></Tooltip>
    );
};
const TableContainer: React.FC<IOwnProps> = ({
    tableColHeaders,
    tableData = [],
    headerText,
    unit,
    selectedSector,
    columnWidth = TABLE_PROPERTIES.defaultColWidth,
    columnDetails,
    tableUnit = '',
    useDefaultFormatter = true
}: IOwnProps) => {
    const [rowData, setRowData] = useState(tableData);
    const filterViewState = useSelector((store: IStore) => store?.filter?.filterView);
    const [loading, setLoading] = useState(false);
    const selectedScenarioRegion = useSelector(
        (store: IStore) => store?.filter?.selectedScenarioRegion
    );
    const regionFilters = useSelector((store: IStore) => store?.filter?.regionFilters);
    const selectedRegion = filterMappingFunction(regionFilters, selectedScenarioRegion)?.name;

    const tableCellTitle =
        filterViewState === FILTER_VIEWS.regional
            ? TABLE_PROPERTIES.regionHeader
            : TABLE_PROPERTIES.scenarioHeader;

    const columnDefs =
        columnDetails ??
        [
            {
                headerName: tableCellTitle,
                field: 'name',
                pinned: 'left',
                cellRendererFramework: (params: ITableColumnDetailsParams) =>
                    getFormatedLabel(
                        params.value,
                        {
                            ...labelStyle,
                            ...ellipsisStyle
                        },
                        tableData?.length
                    ),
                suppressMovable: true,
                width:
                    filterViewState === FILTER_VIEWS.regional
                        ? TABLE_PROPERTIES.firstColWidthRegView
                        : TABLE_PROPERTIES.firstColWidthSceView
            },
            filterViewState === FILTER_VIEWS.scenario
                ? {
                      headerName: TABLE_PROPERTIES.regionHeader,
                      field: 'region',
                      suppressMovable: true,
                      cellRendererFramework: (params: ITableColumnDetailsParams) =>
                          getFormatedLabel(params.value, {
                            ...labelStyle,
                            ...ellipsisStyle
                        }, tableData?.length),
                      width: TABLE_PROPERTIES.defaultColWidth
                  }
                : null,
            !!selectedSector
                ? {
                      headerName: TABLE_PROPERTIES.sectorHeader,
                      field: 'sector',
                      suppressMovable: true,
                      cellRendererFramework: (params: ITableColumnDetailsParams) =>
                          getFormatedLabel(params.value, {
                            ...labelStyle,
                            ...ellipsisStyle
                        }, tableData?.length),
                      width: TABLE_PROPERTIES.firstColWidthRegView
                  }
                : null,
            ...tableColHeaders.map((category: string | number, index: number) => ({
                headerName: category as string,
                field: `data[${index}]`,
                suppressMovable: true,
                cellRendererFramework: (params: ITableColumnDetailsParams) =>
                    getLabel(params.value, labelStyle),
                width: columnWidth
            }))
        ].filter((item) => item !== null);

    const TablePortal: HTMLElement = document.getElementById('table-portal') || document.body;

    // Scroll to top on maximize view
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const stringifyTableData = JSON.stringify(tableData);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 20);
    }, [stringifyTableData]);

    useEffect(() => {
        if (tableData.length > 0) {
            let updatedRowData: Array<ITableRow | ITableRowWithRegionsandSector> = [];
            if (!columnDetails) {
                updatedRowData = tableData.map(({ name, data }: any) => ({
                    name,
                    ...(selectedRegion &&
                        filterViewState === FILTER_VIEWS.scenario && {
                            region: selectedRegion
                        }),
                    ...(!!selectedSector && { sector: selectedSector }),
                    ...data.reduce((obj: any, value: number, index: number) => {
                        obj[`data[${index}]`] = useDefaultFormatter
                            ? formattDecimalPoints(value)
                            : convertValueAsperCIMStandard(value);
                        return obj;
                    }, {})
                }));
            } else {
                updatedRowData = tableData.map(
                    ({
                        name,
                        data,
                        sector,
                        region,
                        regionTo,
                        year,
                        value,
                        inputSector,
                        scenarion,
                        variable,
                        ...rest
                    }: ITableRowWithRegionsandSector) => ({
                        name,
                        regionTo,
                        sector,
                        region,
                        year,
                        value,
                        inputSector,
                        scenarion,
                        variable,
                        ...rest,
                        ...data?.reduce((obj: any, value: number, index: number) => {
                            if (!useDefaultFormatter && variable && variable === 'Index') {
                                obj[`data[${index}]`] = formattDecimalPoints(value);
                                return obj;
                            } else {
                                obj[`data[${index}]`] = useDefaultFormatter
                                    ? formattDecimalPoints(value)
                                    : convertValueAsperCIMStandard(value);
                                return obj;
                            }
                        }, {})
                    })
                );
            }

            setRowData(updatedRowData);
        }
    }, [tableData, selectedSector, selectedRegion, filterViewState, columnDetails]);

    if (loading) {
        return createPortal(<LoadIndicator height={TABLE_PROPERTIES.tableHeight} />, TablePortal);
    }

    return createPortal(
        <StyledDiv
            data-testid={TABLE_PROPERTIES.dataTestId}
            tableHeight={TABLE_PROPERTIES.tableHeight}>
            <WidgetWrapper
                height={TABLE_PROPERTIES.tableHeight}
                headerText={headerText + ' ' + TABLE_PROPERTIES.detailsText}
                unit={tableUnit === '' ? unit : tableUnit}>
                <MotifTable columnDefs={columnDefs} rowData={rowData} children={null} />
            </WidgetWrapper>
        </StyledDiv>,
        TablePortal
    );
};

const StyledDiv = styled.div<{ tableHeight: string }>`
    padding-top: 20px;
    .motif-card {
        height: ${(props): string => props.tableHeight ?? 'auto'};
    }
    .ag-theme-material
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border: none;
    }
    .ag-body-horizontal-scroll {
        display: none;
    }
    .ag-center-cols-viewport {
        ::-webkit-scrollbar {
            height: 10px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(145, 158, 171, 0.6);
            border-radius: 3px;
        }
    }
    .motif-table {
        padding: 20px 15px !important;
    }
    .motif-table .ag-header {
        background-color: ${gray100};
    }
    .motif-table .ag-header-cell-label {
        font-size: 14px;
    }
    .ag-header-row-column {
        color: var(--body-color);
    }
    .motif-label {
        padding-left: unset;
    }
    .motif-table .ag-react-container {
        align-items: center;
    }
    .ag-pinned-left-cols-container .ag-row {
        border-right: unset;
    }
    .motif-table .ag-cell:hover {
        background: unset;
    }
    .motif-table .ag-cell:focus {
        border: unset !important;
        background: unset;
    }
    .motif-tooltip-wrapper {
        width: 100%;
    }
    .motif-tooltip-trigger-wrap {
        display: inline;
    }
`;
export default React.memo(TableContainer);
