import React, { useState } from 'react';
import {
    MotifHeader,
    MotifVerticalNavigationHeaderButton as VerticalNavHeaderWrapper
} from '@ey-xd/motif-react';
import { APP_HEADER_NAME } from '../../app.labels';
import HeaderLogo from './HeaderLogo';
import HeaderProfileCard from './HeaderProfileCard';
import '../../styles/Header.scss';
import { windowResize } from '../../common/commonUtil';

interface IOwnProps {
    handleSidebar?: Function;
    isSidebar?: boolean;
}

const Header: React.FC<IOwnProps> = ({
    handleSidebar,
    isSidebar = true
}: IOwnProps): JSX.Element => {
    const [visible, setVisibility] = useState<boolean>(true);

    const handleToggle = () => {
        setVisibility((visible) => !visible);
        handleSidebar && handleSidebar(visible);
        windowResize();
    };

    return (
        <>
            <MotifHeader
                fixed
                logo={<HeaderLogo />}
                appHeaderName={APP_HEADER_NAME}
                leftComponents={isSidebar && <VerticalNavHeaderWrapper onClick={handleToggle} />}
                menu={
                    <div className="motif-header-profile-card">
                        <HeaderProfileCard />
                    </div>
                }
            />
        </>
    );
};

export default React.memo(Header);
