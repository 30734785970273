import { GLOBAL, GLOBAL_COLOR_CODE } from "../app.labels";
import { IDataPoint, IChartData } from "../models/IChartModels";
import { IFilterModel } from "../models/IFilterModel";

interface IfilterData {
  color: string;
  name: string;
  data: IChartData[];
}

export const getColorbyFilterObject = (name, filterData): string => {
  const data = filterData?.find((item) => item?.name === name);
  return data?.color;
};

const filterByRegion = (data, filterData, propertyName): IfilterData[] => {
  const filteredData = (data[propertyName] as IDataPoint[])
    .filter(
      (countryData) =>
        filterData["selectedRegions"].includes(countryData.name) ||
        countryData.name === GLOBAL
    )
    .map((el) => {
      let colr = "";
      if (!el.hasOwnProperty("color")) {
        filterData["regionFilters"].forEach((country) => {
          if (el.name === GLOBAL) {
            colr = GLOBAL_COLOR_CODE;
            return;
          }
          if (country.name === el.name) {
            colr = country.color;
            return;
          }
        });
      }
      return { ...el, color: colr };
    });

  return filteredData;
};

export const filterByMultipleScenario = (data, selectedScenarios) => {
  return data?.filter((item) => selectedScenarios?.includes(item.scenario));
};

export const filterMappingFunction = (
  filterData,
  value,
  param = "id"
): IFilterModel => {
  const data = filterData?.find(
    (item) => item[param]?.toString() === value?.toString()
  );
  return data;
};

export const truncateTitle = (str, n, useWordBoundary?): string => {
  if (!str || str?.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
};

export { filterByRegion };
