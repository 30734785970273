import { MotifFooter, MotifTextLink } from '@ey-xd/motif-react';
import React, { useState } from 'react';
import '../../styles/Footer.scss';
import FooterModal from './FooterModal';
import { FOOTER, FOOTER_LEAGAL_SECTION } from './Footer.const';
import { ENGAGEMENT_MANAGRMENT_URL } from '../../app.constant';
import { useSelector } from 'react-redux';
import { IStore } from '../../store';
import { UserRoles } from '../../common/enum/userProfile';

const Footer: React.FC = () => {
    const [modalType, setModalType] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);

    const showPolicyModal = useSelector((store: IStore) => store?.userProfile?.showPolicyModal);
    const { isExternal } = useSelector((state: IStore) => state.userProfile?.userData);
    
    return (
        <>
            <FooterModal show={showModal} type={modalType} onClose={() => setShowModal(false)} />
            <MotifFooter className="cim-footer flex-container">
                <div className="flex-item legal-statement">{FOOTER_LEAGAL_SECTION}</div>
                <div className="flex-item ">
                    {!isExternal &&
                        <MotifTextLink
                            href={ENGAGEMENT_MANAGRMENT_URL}
                            className="cim-footer-links cim-footer-engagement"
                            target="_blank">
                            {FOOTER.engagement}
                        </MotifTextLink>
                    }
                    <MotifTextLink
                        className="cim-footer-links"
                        href="#"
                        onClick={() => {
                            if (showPolicyModal) return;
                            setModalType(FOOTER.privacyNotice);
                            setShowModal(true);
                        }}
                        disabled={showPolicyModal}>
                        {FOOTER.privacyNotice}
                    </MotifTextLink>
                    {!isExternal &&
                        <MotifTextLink
                            className="cim-footer-links cim-footer-disclaimer"
                            href="#"
                            onClick={() => {
                                if (showPolicyModal) return;
                                setModalType(FOOTER.demoDisclaimer);
                                setShowModal(true);
                            }}
                            disabled={showPolicyModal}>
                            {FOOTER.demoDisclaimer}
                        </MotifTextLink>
                    }
                </div>
            </MotifFooter>
        </>
    );
};

export default React.memo(Footer);
