import { LoopReducer, loop, Cmd, Loop } from 'redux-loop';
import { AnyAction } from 'redux';
import * as userProfileActions from '../actions/userProfileActions';
import { fetchLogout, getUserProfile } from '../../services/userProfile.service';
import { createBrowserHistory } from 'history';
import { IUserTenant } from '../../models/IUserTenant';

export type IUserProfileState = {
    loading: boolean;
    logout: boolean;
    userData: {
        displayName: string;
        email: string;
        isExternal: boolean;
        profileImage: string;
        sid: string;
    };
    userTenant: IUserTenant;
    showPolicyModal: boolean;
    policyTypeList: Array<{
        content: string;
        hasAccepted: boolean;
        policyType: string | number;
        version: string | number;
    }>;
};

export const initialState: IUserProfileState = {
    loading: false,
    logout: false,
    userData: {
        displayName: '',
        email: '',
        isExternal: false,
        profileImage: '',
        sid: ''
    },
    userTenant: {
        tenantId: '',
        tenantName: '',
        tenantFriendlyName: '',
        roles: []
    },
    showPolicyModal: false,
    policyTypeList: []
};

export const browserHistory = createBrowserHistory({
    forceRefresh: true
});

const setTenantData = (tenant) => {
    return {
        tenantId: tenant?.tenantId,
        tenantName: tenant?.tenantName?.toLowerCase(),
        tenantFriendlyName: tenant?.tenantFriendlyName,
        roles: []
    };
};

const updateUserRole = (roles, state) => {
    const userTenantRoles = {
        ...state.userTenant,
        roles: roles
    };
    return userTenantRoles;
};

export const userProfileReducer: LoopReducer<IUserProfileState> = (
    state: IUserProfileState = initialState,
    action: AnyAction
): IUserProfileState | Loop<IUserProfileState, AnyAction> => {
    switch (action.type) {
        case userProfileActions.FETCH_USER_PROFILE:
            state.loading = true;
            return loop(
                {
                    ...state
                },
                Cmd.list([
                    Cmd.run(getUserProfile, {
                        successActionCreator: userProfileActions.storeUserProfile,
                        failActionCreator: userProfileActions.storeUserProfile,
                        args: []
                    })
                ])
            );
        case userProfileActions.STORE_USER_PROFILE:
            const { claims = {}, sid } = action.value?.idToken;
            const { name, preferred_username, acct } = claims;
            return {
                ...state,
                loading: false,
                userData: {
                    displayName: name || initialState?.userData?.displayName,
                    email: preferred_username || initialState?.userData?.email,
                    isExternal: !!acct || initialState?.userData?.isExternal,
                    profileImage: action.value?.image
                        ? `data:image/png;base64, ${action.value?.image}`
                        : '',
                    sid: sid ? sid : ''
                }
            };
        case userProfileActions.USER_TENANT:
            const userTenant = setTenantData(action.value);
            return {
                ...state,
                loading: false,
                userTenant
            };
        case userProfileActions.UPDATE_USER_ROLE:
            const userRoles = updateUserRole(action.value, state);
            return {
                ...state,
                loading: false,
                userTenant: userRoles
            };

        case userProfileActions.FETCH_USER_LOGOUT:
            state.loading = true;
            return loop(
                {
                    ...state
                },
                Cmd.list([
                    Cmd.run(fetchLogout, {
                        successActionCreator: userProfileActions.userLogoutSuccess,
                        failActionCreator: userProfileActions.userLogoutFailure,
                        args: []
                    })
                ])
            );
        case userProfileActions.USER_LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                logout: true
            };
        case userProfileActions.USER_LOGOUT_FAILURE:
            return {
                ...state,
                loading: false
            };

        case userProfileActions.SHOW_POLICY_MODAL: {
            return {
                ...state,
                showPolicyModal: action.value,
                policyTypeList: action.policyTypeList
            };
        }
        default:
            return state;
    }
};
