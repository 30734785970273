import { Q_QUERY_KEY } from '../../../app.constant';
import { APPLICATION_TABNAV_HEADERS, FILTER_VIEWS } from '../../../app.labels';
import { IFilterModel, IScenarioFilterModel } from '../../../models/IFilterModel';
import { IGenericObject } from '../../../models/IGenericObject';
import { IQueryFilterParam } from '../../../models/IQueryFilterParam';
import { store } from '../../../store';
import { IFilterState } from '../../../store/reducers/FilterReducer';

export const buildFilterStateObjectfromQueryParams = (
    searchString,
    tabName: string,
    view: string
): any => {
    const URLParams: URLSearchParams = new URLSearchParams(searchString);
    const qQueryValue: string = URLParams.get(Q_QUERY_KEY) || '';

    let newObj: any = {};
    if (qQueryValue && qQueryValue !== '') {
        newObj = JSON.parse(qQueryValue);
    }
    return { ...newObj, pageName: tabName };
};
export const buildFilteredQueryParams = (
    filterState: IFilterState,
    searchString: string
): string => {
    const URLParams: URLSearchParams = new URLSearchParams(searchString);
    const qQueryValue: string = URLParams.get(Q_QUERY_KEY) || '';
    let updatedObje: IQueryFilterParam = {};
    let isRegionalView: boolean = false;

    if (qQueryValue !== '') {
        if (
            filterState.filterView === 'Sector View' ||
            filterState.filterView === 'Regional View'
        ) {
            isRegionalView = true;
        }

        if (
            filterState?.selectedScenarioRegView?.length > 0 ||
            filterState.selectedScenarioSceView.length > 0
        ) {
            updatedObje = {
                ...updatedObje,
                selectedScenario: isRegionalView
                    ? filterState.selectedScenarioRegView
                    : filterState.selectedScenarioSceView
            };
        }
        if (filterState.currentTab === 'sectoralimpacts') {
            if (filterState.selectedSectoralRegion !== '') {
                updatedObje = {
                    ...updatedObje,
                    selectedRegions: filterState.selectedSectoralRegion
                };
            }

            if (
                filterState.selctedSectors.length > 0 ||
                filterState.selectedScenarioSector !== ''
            ) {
                updatedObje = {
                    ...updatedObje,
                    selectedSectors: isRegionalView
                        ? filterState.selctedSectors
                        : filterState.selectedScenarioSector
                };
            }
        } else {
            updatedObje = {
                ...updatedObje,
                selectedRegions: isRegionalView
                    ? filterState.selectedRegions
                    : filterState.selectedScenarioRegion
            };
        }

        const jsonObj = qQueryValue ? JSON.parse(qQueryValue) : {};
        let returObject: any = {
            ...jsonObj,
            ...updatedObje
        };

        if (filterState.filterView === '') {
            returObject['view'] =
                filterState.currentTab === 'sectoralimpacts' ? 'Sector View' : 'Regional View';
        }
        let queryString = serializeQueryString(returObject);
        return queryString;
    }
    return '';
};

export const generateQueryFilterParams = (
    filterState: IFilterState,
    searchString: string,
    tabName: string = ''
): string => {
    const URLParams: URLSearchParams = new URLSearchParams(searchString);
    const qQueryValue: string = URLParams.get(Q_QUERY_KEY) || '';
    let updatedObje: IQueryFilterParam = {};

    let isRegionalView: boolean = false;

    if (qQueryValue !== '') {
        if (
            filterState.filterView === 'Sector View' ||
            filterState.filterView === 'Regional View'
        ) {
            isRegionalView = true;
        }

        if (
            filterState?.selectedScenarioRegView?.length > 0 ||
            filterState.selectedScenarioSceView.length > 0
        ) {
            updatedObje = {
                ...updatedObje,
                selectedScenario: isRegionalView
                    ? filterState.selectedScenarioRegView
                    : filterState.selectedScenarioSceView
            };
        }
        if (filterState.currentTab === 'sectoralimpacts') {
            if (filterState.selectedSectoralRegion !== '') {
                updatedObje = {
                    ...updatedObje,
                    selectedRegions: filterState.selectedSectoralRegion
                };
            }

            if (
                filterState.selctedSectors.length > 0 ||
                filterState.selectedScenarioSector !== ''
            ) {
                updatedObje = {
                    ...updatedObje,
                    selectedSectors: isRegionalView
                        ? filterState.selctedSectors
                        : filterState.selectedScenarioSector
                };
            }
        } else {
            updatedObje = {
                ...updatedObje,
                selectedRegions: isRegionalView
                    ? filterState.selectedRegions
                    : filterState.selectedScenarioRegion
            };
        }

        const jsonObj = qQueryValue ? JSON.parse(qQueryValue) : {};
        let returObject: any = {
            ...jsonObj,
            ...updatedObje
        };

        if (filterState.filterView === '') {
            returObject['view'] =
                filterState.currentTab === 'sectoralimpacts' ? 'Sector View' : 'Regional View';
        }
        return JSON.stringify(returObject);
    } else if (tabName !== '') {
        let view = tabName === 'sectoralimpacts' ? FILTER_VIEWS.sector : FILTER_VIEWS.regional;
        return JSON.stringify({ view: view });
    }

    return '';
};

export const getSelectedItems = (data: Array<IFilterModel>): Array<string> => {
    let updatedData: Array<string> = [];
    data?.map((values) => {
        if (values?.checked) updatedData?.push(values?.name);
    });
    return updatedData;
};

export const getSelectedIDs = (data: Array<IFilterModel>): Array<number> => {
    let updatedData: Array<number> = [];
    data?.map((values) => {
        if (values?.checked) updatedData?.push(values?.id as number);
    });
    return updatedData;
};

export const getSelectedScenarios = (scenarioFiltersState) => {
    const selectedScenariosArray: Array<string | number> = [];
    const finalObj = scenarioFiltersState;
    for (const key in finalObj) {
        // Adding selected baseline to array
        if (finalObj[key]?.checked) {
            selectedScenariosArray?.push(finalObj[key]?.id);
        }
        finalObj[key]?.data?.map((values) => {
            if (values?.checked) {
                // Adding selected policies to array
                selectedScenariosArray?.push(values?.id);
            }
        });
    }
    return selectedScenariosArray;
};

export const getSelectedScenarioItems = (
    data: Array<IScenarioFilterModel>
): Array<IGenericObject> => {
    let updatedData: Array<IGenericObject> = [];
    data?.map((values) => {
        if (values?.checked) {
            updatedData?.push({
                name: values?.name,
                isBaseLine: true
            });
            values?.data?.map((val) => {
                if (val?.checked)
                    updatedData?.push({
                        name: val?.name,
                        isBaseLine: false
                    });
            });
        }
    });
    return updatedData;
};

export const serializeQueryString = (obj: any): string => {
    return JSON.stringify(obj);
};

