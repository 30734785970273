import styled from 'styled-components';

const StyledCharacterCounter = {
    Container: styled.span`
        font-size:12px; 
        padding-top: 10px;
    `,
    Error: styled.div`
    color: var(--warning);
    `
};

export default StyledCharacterCounter;