import styled from 'styled-components';
import sassVars from '../../../../styles/variables.module.scss';
const { black3, gray } = sassVars;

export const StyledLandingPage = {
    Container: styled.div`
        .main-container {
            .main-wrapper {
                display: flex;
                margin: 0rem 0 0 0;
                padding: 0;
                min-height: 100vh;

                .main-content {
                    flex-grow: 1;
                    padding: 0px 86px;
                    width: 100%;
                    margin-bottom: 0px;
                    margin-top: 55px;

                    .motif-card {
                        background-color: ${black3};
                        border: 1px solid ${gray};
                    }

                    .header-menu-section {
                        display: flex;
                        .results-inputs {
                            display: flex;
                            flex-direction: row;
                            .input-icons-section,
                            .result-icons-section {
                                display: flex;
                            }

                            .motif-label {
                                width: 60px;
                                font-size: 0.75rem;
                            }

                            .motif-icon svg {
                                height: 0.75rem;
                                width: 0.75rem;
                            }
                        }
                    }

                    .tenant-start-btn {
                        margin: 1rem;
                        width: 100%;
                        .motif-icon {
                            margin: 0;
                            width: 21px;
                        }
                    }
                    .motif-card-footer {
                        background-color: transparent;
                    }
                    .tenant-body {
                        margin: 0;
                        font-size: 1.25rem;
                    }
                    .motif-card-body {
                        min-height: 8rem;
                    }
                }
            }
        }
    `
};

export const StyledContent = styled.div`
    .center-wrapper {
        position: absolute;
        text-align: center;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-transform: uppercase;
    }
`;
