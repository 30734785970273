import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Configuration } from "msal/lib-commonjs/Configuration";
import { AuthenticationParameters } from "msal";
import { IActiveDirectoryConfig } from "./models/IAppConfig";
export let authProvider: MsalAuthProvider;

const configureMsalAuthProvider = async (
  config: IActiveDirectoryConfig,
  searchObj
): Promise<MsalAuthProvider> => {
  const authConfig: Configuration = {
    auth: {
      authority: "https://login.microsoftonline.com/" + config.tenant,
      clientId: config.clientId,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };

  const options = {
    loginType: LoginType.Redirect,
  };

  const authParams: AuthenticationParameters = { scopes: ["openid"] };
  authProvider = new MsalAuthProvider(authConfig, authParams, options);
  
  if(searchObj && searchObj?.authInfo) {
    const request = {
      scopes: ["user.read"],
      // sid: searchObj?.authInfo
      loginHint: searchObj?.authInfo
    };
    try {
      const loginResponse = await authProvider.ssoSilent(request);
    } catch (err) {
      console.log('err', err);
    }
  }  

  return authProvider;
};

export default configureMsalAuthProvider;
