import { AnyAction, Dispatch } from "redux";
import { APP_HISTORY } from "../../app.constant";

export const redirectOnErrorMiddleware = () => (next: Dispatch) => (
  action: AnyAction
): void => {
  // if (action.type === FETCH_ENTITIES_FAIL) {
  //     APP_HISTORY.history.replace('/error');
  //     return;
  // }

  next(action);
};

export default redirectOnErrorMiddleware;
