import React from 'react';
import { StyledChartDownloads } from './index.style';
import { CHART_DOWNLOAD_LIBRARY } from '../../../../app.labels';
import ChartLibraryTable from './widgets/ChartLibraryTable';
import WidgetWrapper from '../../../widgetWrapper/WidgetWrapper';

const ChartDownloadsLandingPage: React.FC = () => {
    return (
        <StyledChartDownloads.Container>
            <StyledChartDownloads.Title>{CHART_DOWNLOAD_LIBRARY.title}</StyledChartDownloads.Title>
            <StyledChartDownloads.Table>
                <WidgetWrapper headerText="Downloads" height="100%">
                    <ChartLibraryTable />
                </WidgetWrapper>
            </StyledChartDownloads.Table>
        </StyledChartDownloads.Container>
    );
};

export default ChartDownloadsLandingPage;
