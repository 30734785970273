import React from "react";
import { MotifTooltip } from "@ey-xd/motif-react";

export enum TooltipPlacement {
  AUTO = "auto",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
}

export enum TooltipVariant {
  DEFAULT = "default",
  ALT = "alt",
}

type TooltipProps = {
  id?: string;
  allowClick?: boolean;
  allowHover?: boolean;
  children?: React.ReactChild;
  flip?: boolean;
  placement?: TooltipPlacement;
  content: React.ReactElement;
  variant?: TooltipVariant;
  hide?: boolean;
  contentClassName?: string;
  className?: string;
  hideCloseButton?: boolean;
  hideArrow?: boolean;
};

const Tooltip = ({
  id = "tooltip",
  allowClick = false,
  allowHover = true,
  children,
  flip = true,
  placement = TooltipPlacement.AUTO,
  content,
  variant = TooltipVariant.ALT,
  hide = false,
  contentClassName = "",
  hideCloseButton = false,
  hideArrow = false,
}: TooltipProps) => (
  <MotifTooltip
    id={id}
    allowClick={allowClick}
    allowHover={allowHover}
    flip={flip}
    placement={placement}
    trigger={children}
    variant={variant}
    hide={hide}
    contentClassName={contentClassName}
    hideCloseButton={hideCloseButton}
    hideArrow={hideArrow}
  >
    {content}
  </MotifTooltip>
);

export default Tooltip;
