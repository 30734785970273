import { Q_QUERY_KEY, TABLE_PROPERTIES } from '../app.constant';
import { getDataFromApi } from '../services/common.service';
import { ICIMChartSeriesData, IChartModel, ITableData } from '../models/IChartModels';
import { convertToDecimal } from '../utils/numberUtils';
import { store } from '../store';
import {
    ITableColumnDetails,
    ITableColumnDetailsParams,
    ITableRowData,
    ITableRowWithRegionsandSector
} from '../models/ITableDetails';
import { FILTER_VIEWS } from '../app.labels';
import {
    ellipsisStyle,
    getFormatedLabel,
    getLabel,
    labelStyle
} from '../components/table/TableContainer';
import { ErrorMessages } from './enum/error';

export enum ChartPropertyType {
    emissionsPerCapita = 'emissionsPerCapita',
    exchangeRates = 'exchangeRates',
    gdpPerCapita = 'gdPperCapita',
    emissions = 'emissions',
    marketEmissions = 'marketEmissions',
    emissionsIntensitybySector = 'emissionsIntensitybySector',
    gniPerCapita = 'gniPerCapita',
    gni = 'gni',
    gniDeviation = 'gniDeviation',
    carbonPrices = 'carbonPrices',
    gdpDeviation = 'gdpDeviations',
    gdp = 'gdp',
    wages = 'wages',
    totalCarbonTaxByGDP = 'totalCarbonTaxGDP',
    employment = 'employment',
    investment = 'investment',
    unemploymentRate = 'unEmployment',
    populationPathway = 'populationPathway'
}

export enum EmissionBySectorKeys {
    scenario = 'data',
    regional = 'emissionsByYear'
}

export enum GniDeviationKeys {
    scenario = 'gniDeviationByScenario',
    regional = 'groupByRegion'
}

export enum ExchangeRatesKeys {
    scenario = 'exchangeRatesScenario',
    regional = 'groupByRegion'
}

export enum GdpDeviationKeys {
    scenario = 'gdpDeviationsByScenario',
    regional = 'groupByRegion'
}

export enum PeopleEmploymentKeys {
    scenario = 'employmentScenario',
    regional = 'groupByRegion'
}

export const formattResponseToLineChart = (data: Array<IChartModel>): Array<any> => {
    return [];
};

export const handleWidgetOptions = (menuList: Array<any>, isMaximized: boolean) => {
    const toggleFunction = (item) => item.title !== `${isMaximized ? 'Maximize' : 'Minimize'}`;
    return menuList.filter(toggleFunction);
};

export const windowResize = () => {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 200);
};

export const createTableData = (dataSeries) => {
    const transformedData: ITableData[] = [];
    for (const countryData of dataSeries) {
        const name = countryData.name ? countryData.name : countryData.scenario;
        const { data } = countryData;
        const transformedCountryData: ITableData = {
            name,
            data: data.map((item) => convertToDecimal(item.value))
        };

        transformedData.push(transformedCountryData);
    }
    return transformedData;
};

export const buildChartData = <T>(
    data: T,
    selectedScenarioRegView: string,
    genericProperty: string = ''
): T | undefined => {
    if (data && Array.isArray(data)) {
        const result = data.find((chartDataObj: ICIMChartSeriesData) => {
            return chartDataObj.scenario === selectedScenarioRegView;
        });
        return result as T;
    }
    if (data && genericProperty) {
        const result = data[genericProperty].find((chartDataObj: ICIMChartSeriesData) => {
            return chartDataObj.scenario === selectedScenarioRegView;
        });
        return result as T;
    }
    return undefined;
};

interface IUpdateChartData<T> {
    url: string;
    genericProperty: string;
    isScenarioView: boolean;
    selectedScenarioRegView: string | undefined;
    setData: (data: T | undefined) => void;
    setLoading: (loading: boolean) => void;
    setError: (error: boolean) => void;
    selectedScenarioRegViewID?: string;
    selectedScenarioSecViewIDs?: (string | number)[];
    selectedScenarioRegionID?: string | number;
}

export const updateChartData = async <T>({
    url,
    genericProperty,
    isScenarioView,
    selectedScenarioRegView,
    setData,
    setLoading,
    setError,
    selectedScenarioRegViewID,
    selectedScenarioSecViewIDs,
    selectedScenarioRegionID
}: IUpdateChartData<T>): Promise<void> => {
    setLoading(true);
    let reqBody;
    if (!isScenarioView) {
        reqBody = { scenario: selectedScenarioRegViewID };
    } else {
        reqBody = {
            scenario: selectedScenarioSecViewIDs,
            region: selectedScenarioRegionID
        };
    }

    const cacheKey = JSON.stringify({ genericProperty, url, reqBody, isScenarioView });
    const response = await getDataFromApi(url, cacheKey);
    if (response.errorMessage) {
        if (response?.errorMessage === ErrorMessages.Error404) {
            setData(undefined);
        } else {
            setError(true);
        }
    } else if (response) {
        setError(false);

        if (!isScenarioView && selectedScenarioRegView) {
            const output = buildChartData<T>(response, selectedScenarioRegView, genericProperty);
            setData(output as T);
        } else {
            if (isScenarioView) {
                setData(response as T);
            }
        }
    }
    setLoading(false);
};

export const removeHashFromURL = () => {
    let searchUrl = window.location.search;
    let urlParameter = window.location;
    if (
        (urlParameter.hash !== '' || urlParameter.hash !== null) &&
        !urlParameter.hash.includes('id_token')
    ) {
        searchUrl = urlParameter.search + urlParameter.hash;
        return searchUrl;
    } else {
        return searchUrl;
    }
};

export const createNewUrl = (searchPath: string, qQueryValue: string): string => {
    const url: string = `${searchPath}?${Q_QUERY_KEY}=${qQueryValue}`;
    return `${url}`;
};

export const handleToggle = (menuList: Array<any>, isMaximized: boolean) => {
    const toggleFunction = (item) => item.item !== `${isMaximized ? 'Maximize' : 'Minimize'}`;
    return menuList.filter(toggleFunction);
};

export const getTenantName = () => {
    return store?.getState()?.userProfile?.userTenant?.tenantName;
};

export const getTableColumnDetails = (
    tableData: Array<ITableRowWithRegionsandSector>,
    filterViewState: string,
    tableColHeaders: Array<number>,
    columnWidth: number = TABLE_PROPERTIES.defaultColWidth
): Array<ITableColumnDetails> => {
    const tableCellTitle =
        filterViewState === FILTER_VIEWS.regional
            ? TABLE_PROPERTIES.regionHeader
            : TABLE_PROPERTIES.scenarioHeader;
    let columnDetails: Array<ITableColumnDetails> = [];
    tableData &&
        tableData.length > 0 &&
        Object.keys(tableData[0])?.forEach((key: string, index: number) => {
            if (index === 0 && key === 'name') {
                columnDetails.push({
                    headerName: tableCellTitle,
                    field: 'name',
                    pinned: 'left',
                    suppressMovable: true,
                    cellRendererFramework: (params: ITableColumnDetailsParams) =>
                        getFormatedLabel(
                            params.value,
                            {
                                ...labelStyle,
                                ...ellipsisStyle
                            },
                            tableData.length
                        ),
                    width:
                        filterViewState === FILTER_VIEWS.regional
                            ? TABLE_PROPERTIES.firstColWidthRegView
                            : TABLE_PROPERTIES.firstColWidthSceView
                });
            }

            if (key === 'region') {
                columnDetails.push({
                    headerName: TABLE_PROPERTIES.regionHeader,
                    field: 'region',
                    suppressMovable: true,
                    cellRendererFramework: (params: ITableColumnDetailsParams) =>
                        getFormatedLabel(params.value, {
                            ...labelStyle,
                            ...ellipsisStyle
                        }, tableData.length),
                    width: TABLE_PROPERTIES.defaultColWidth
                });
            }

            if (key === 'sector') {
                columnDetails.push({
                    headerName: TABLE_PROPERTIES.sectorHeader,
                    field: 'sector',
                    suppressMovable: true,
                    cellRendererFramework: (params: ITableColumnDetailsParams) =>
                        getFormatedLabel(params.value, {
                            ...labelStyle,
                            ...ellipsisStyle
                        }, tableData.length),
                    width: TABLE_PROPERTIES.firstColWidthRegView
                });
            }
            if (key === 'year') {
                columnDetails.push({
                    headerName: 'Year',
                    field: 'year',
                    suppressMovable: true,
                    cellRendererFramework: (params: ITableColumnDetailsParams) =>
                        getFormatedLabel(params.value, null, tableData.length),
                    width: TABLE_PROPERTIES.firstColWidthRegView
                });
            }
            if (key === 'value') {
                columnDetails.push({
                    headerName: 'Value',
                    field: 'value',
                    suppressMovable: true,
                    cellRendererFramework: (params: ITableColumnDetailsParams) =>
                        getFormatedLabel(params.value, null, tableData.length),
                    width: TABLE_PROPERTIES.firstColWidthRegView
                });
            }
        });
    tableColHeaders?.forEach((tableCol: number, tableIndex: number) => {
        columnDetails.push({
            headerName: tableCol.toString(),
            field: `data[${tableIndex}]`,
            suppressMovable: true,
            cellRendererFramework: (params: ITableColumnDetailsParams) =>
                getLabel(params.value, labelStyle),
            width: columnWidth
        });
    });

    return columnDetails ?? [];
};

export const generateTableData = <T>(
    seriesData: Array<ITableRowData>,
    setYearList: (data: Number[] | undefined) => void,
    setTableRowData: (data: T | undefined) => void
) => {
    let tableDataList: Array<ITableRowWithRegionsandSector> = [];
    let tableDataLoop: ITableRowWithRegionsandSector;
    const filteredSeriesData = seriesData?.map((item: ITableRowData) => {
        return {
            ...item,
            data: item?.data.filter((year) => year.year % 10 === 0)
        };
    });
    filteredSeriesData?.forEach((item) => {
        tableDataLoop = {
            name: item.name,
            data: item.data.map((splineData) => Math.round(splineData.value * 100) / 100),
            scenarion: item.scenarion
        };
        tableDataList.push(tableDataLoop);
    });
    if (tableDataList) {
        const categories =
            filteredSeriesData.length > 0
                ? filteredSeriesData[0].data.map((item) => Number(item.year))
                : [];
        setYearList(categories);
        setTableRowData(tableDataList as T);
    }
};
