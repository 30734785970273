const PRIVACY_NOTICE = 'Privacy Notice';
const CLIENT_DEMO_DISCLAIMER = 'Client demo disclaimer';

const CLIENT_DEMO_DISCLAIMER_CONTENT = `<div class="term-modal term-modal-se">
<p class="motif-h6 text-color">
    <span>EY Climate Economics Model is a proprietary EY cloud-based dashboarding tool used to assist in visualizing the impact of climate-related effects on a set of macro-economic parameters such as gross domestic product, emissions, emissions intensity, carbon pricing, gross national income, wages and employment. By progressing with this demo, you confirm you understand and accept the following Terms of Use.
    </span>
</p>
<div class="motif-h4 text-color">
    <span>
    <strong>Data content</strong>
    </span>
</div>

<p class="motif-h6 text-color"><span>All the underlying data in the tool used in the calculations carried out by an EY developed computational model relies on licensed data from third-party sources. No EY or client proprietary data is used in the core database. To the extent that the computational model uses publicly available information or other third-party sources, EY does not verify the accuracy, reliability or completeness of such information.</span></p>

<div class="motif-h4 text-color">
    <span><strong>Intellectual property</strong>
    </span>
</div>

<p class="motif-h6 text-color"><span>The tool is confidential and proprietary to or licensed by EY. The scoring components, indicators and calculations displayed in the tool are exclusive to EY and based on a standard and objective set of algorithms using third-party public data.</span></p>

<div class="motif-h4 text-color">
    <span><strong>No warranties</strong>
    </span>
</div>

<p class="motif-h6 text-color"><span>Demonstration of the tool is for informational purposes only. You acknowledge that you shall be solely responsible for any decision made as a result of the data provided, and for the results of such decisions. EY accepts no responsibility or liability to any persons and, accordingly, if such persons choose to rely upon any of the contents of this demo, they do so at their own risk. No responsibility or liability is or will be accepted by EY in connection with the adequacy, accuracy or completeness of the tool or the output of the tool or any conclusions or decisions that you reach through your use of the tool.</span></p>

</div>`;

const I_AGREE_TO_SATEMENT = `I agree to the privacy statement`;
const BUTTONS = {
    CONTINUE: 'Continue',
    CONTINUE_TO_CIM: 'Continue to Climate Economics Model Journey'
};

export {
    PRIVACY_NOTICE,
    CLIENT_DEMO_DISCLAIMER,
    I_AGREE_TO_SATEMENT,
    BUTTONS,
    CLIENT_DEMO_DISCLAIMER_CONTENT,
};
