import React, { FunctionComponent, useEffect, useState } from 'react';
import { MotifRichTextEditor } from '@ey-xd/motif-react';
import { RICH_TEXT_EDITOR } from '../../app.constant';
import richTextEditorSpec from './RichTextEditor.spec';
import StyledRichTextEditor from './RichTextEditor.style';
import CharacterCounter from './CharacterCounter';
import { characterCountFromHTML } from '../../common/htmlUtil';

interface RichTextEditorProps {
    value: string;
    onChange: (content: string) => void;
    height?: number;
    placeholder?: string;
    maxCharacterLimit: number;
}

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
    value,
    onChange,
    height = RICH_TEXT_EDITOR.DEFAULT_HEIGHT,
    placeholder = RICH_TEXT_EDITOR.DEFAULT_PLACE_HOLDER,
    maxCharacterLimit = RICH_TEXT_EDITOR.EDITOR_MAX_CHARACTER_LIMIT
}) => {
    const [editorHtml, setEditorHtml] = useState('');
    const [characterCount, setCharacterCount] = useState(characterCountFromHTML(value));

    useEffect(() => {
        setEditorHtml(value);
        setCharacterCount(characterCountFromHTML(value));
    }, [value]);

    const handleEditorChange = (content, delta, source, editor) => {
        let textLength = editor.getLength() - 1;
        let htmlContent = content.replaceAll(/(&lt;).*?(&gt;)/gi, '');
        if (maxCharacterLimit < textLength) {
            htmlContent = editor.getText(0, maxCharacterLimit).trim();
            textLength = htmlContent.length;
        }
        setEditorHtml(htmlContent);
        setCharacterCount(textLength);
        onChange(htmlContent);
    };

    const handleCharacterCountLimit = (event: KeyboardEvent) => {
        if (characterCount >= maxCharacterLimit && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    return (
        <StyledRichTextEditor height={height}>
            <MotifRichTextEditor
                onChange={handleEditorChange}
                value={editorHtml}
                modules={richTextEditorSpec.modules}
                formats={richTextEditorSpec.formats}
                onKeyDown={handleCharacterCountLimit}
                theme={richTextEditorSpec.theme}
                placeholder={placeholder}
            />
            <CharacterCounter
                characterCount={characterCount}
                maxCharacterLimit={maxCharacterLimit}
            />
        </StyledRichTextEditor>
    );
};

export default RichTextEditor;
