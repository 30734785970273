import { IRouterManagerProps, QueryKeyEnum } from "../models/IRouterManager";
import { matchPath, match } from 'react-router-dom';
import qs from 'query-string';
import { IGenericObject } from "../models/IGenericObject";
import { CIMTabNames } from "../models/CIMTabNames";

export const sanitizeQueryString = (query: string): string => {
    if (!query) return '';
    return query.match(/^\?[a-z]+=/)
        ? query
        : `?${QueryKeyEnum.Query}=${query}`;
};


const allocatePathParams = (
    params: IGenericObject<string>
): IGenericObject<string | undefined> => {
    const subRoutes = Object.keys(CIMTabNames);
    const paramsMap: IGenericObject<string | undefined> = {
        subpage: undefined,
        expanded: undefined
    };

    for (const paramKey in params) {
        if (!params[paramKey]) break;

        if (subRoutes.includes(params[paramKey])) {
            paramsMap.subpage = params[paramKey];
        } else if (params[paramKey].startsWith('expanded')) {
            paramsMap.expanded = params[paramKey];
        }
    }

    return paramsMap;
};

const extractQueryString = (
    search: string,
    queryKey: QueryKeyEnum
): Array<string> | string => {
    const queryStringObj = qs.parse(search);

    return queryStringObj[queryKey] || '';
};

export default function staticUriAnalyzer(
    currentLocation: Location
): IRouterManagerProps {
    let tempRouteObject = {};
    const pathMask = '/:main/:level_1?/:level_2?';
    const query = extractQueryString(
        currentLocation.search,
        QueryKeyEnum.Query
    );

    const pathParams = matchPath(currentLocation.pathname, {
        path: pathMask,
        exact: true,
        strict: false
    }) as match;

    if (pathParams && pathParams.params) {
        tempRouteObject = {
            routeParams: allocatePathParams(pathParams.params)
        };
    }

    tempRouteObject = {
        ...tempRouteObject,
        q: query,
        location: currentLocation
    };

    return tempRouteObject as IRouterManagerProps;
}