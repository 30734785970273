import React from 'react';
import ReactDOM from 'react-dom';
import { MotifTheme } from '@ey-xd/motif-react';
import { Provider } from 'react-redux';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { store } from './store';
import { initApi } from './services/api';
import { initializeSession } from './services/session.service';
import { initConfig } from './appConfig';
import { IAppConfig } from './models/IAppConfig';
import { TENANT, CLIENT_ID, BASE_API_URL } from './app.constant';
import { IAuthProvider } from 'react-aad-msal/dist/typings/interfaces';
import configureMsalAuthProvider from './msalConfig';
import Intro from './components/layout/pages/introduction/Intro';
import LoadIndicator from './components/loadIndicator';
import App from './App';

import './styles/index.scss';

interface IRoot {
    logout: () => void;
}

const Root: React.FC<IRoot> = ({ logout }) => {
    return (
        <React.StrictMode>
            <MotifTheme variant="dark" />
            <Provider store={store}>
                <App logout={logout} />
            </Provider>
        </React.StrictMode>
    );
};

window.addEventListener('DOMContentLoaded', () => {
    const rootEl = document.getElementById('root');
    const appConfig = {
        activeDirectory: {
            tenant: TENANT,
            clientId: CLIENT_ID
        },
        baseApiUrl: BASE_API_URL
    } as IAppConfig;

    initializeSession();
    initConfig(appConfig);
    initApi(appConfig);

    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('q');
    const searchObj = param ? JSON.parse(param) : '';

    configureMsalAuthProvider(appConfig.activeDirectory, searchObj).then((result) => {
        ReactDOM.render(
            <AzureAD provider={result}>
                {({ login, logout, authenticationState }: IAuthProvider): JSX.Element => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            return <Root logout={logout} />;

                        case AuthenticationState.Unauthenticated:
                            if (searchObj && searchObj?.authInfo) {
                                login();
                                return <></>;
                            }
                            return <Intro onClickHandler={login} />;

                        case AuthenticationState.InProgress:
                            if (searchObj && searchObj?.authInfo) {
                                return (
                                    <div className="global-loder">
                                        <div className="center-wrapper">
                                            <LoadIndicator />
                                        </div>
                                    </div>
                                );
                            }
                            return <Intro onClickHandler={(): void => {}} />;

                        default:
                            return <Root logout={logout} />;
                    }
                }}
            </AzureAD>,
            rootEl
        );
    });
});
