import { ErrorMessages } from '../../../../common/enum/error';
import { getDataFromApi } from '../../../../services/common.service';
import { reqURLMapper } from './util';

export const getChartDownload = async ({ identifier, name }, scenario, scenario_name) => {
    const reqBody = { scenario: scenario };
    const url = reqURLMapper(identifier).url;
    const cacheKey = JSON.stringify({ url, reqBody });
    const response = await getDataFromApi(url, cacheKey);

    if (response.errorMessage || !response) {
        if (response?.errorMessage === ErrorMessages.Error404) {
            return {
                name,
                data: '',
                error: ErrorMessages.Error404
            };
        }
        return {
            name,
            data: '',
            error: ErrorMessages.Error50x
        };
    }

    const blob = new Blob([response], { type: 'text/csv' });
    const fileName = name + '_' + scenario_name + '.csv';
    const blobURL = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.download = fileName;

    a.click();

    window.URL.revokeObjectURL(blobURL);

    return {
        response,
        status: 200
    };
};
