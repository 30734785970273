import { Guid as guid } from 'guid-typescript';

const sessionIdKey = 'session_id';

export const initializeSession = (): void => {
    const sessionId = sessionStorage.getItem(sessionIdKey);
    if (sessionId === null) {
        sessionStorage.setItem(sessionIdKey, guid.create().toString());
    }
};

export const getCurrentSessionId = (): string | null => {
    const sessionId = sessionStorage.getItem(sessionIdKey);
    return sessionId;
};
