import { IGenericObject } from "../models/IGenericObject";

export const colorPalettePool: IGenericObject<string> = {
  // Marrons
  // "marron-1": "#C981B2",
  "marron-2": "#C981B2",
  "marron-3": "#b54599",
  "marron-4": "#a44688",
  "marron-5": "#a05488",
  "marron-6": "#a97097",
  "marron-7": "#87647c",
  'marron-8': '#5a4353',
  // Reds
  "red-1": "#FF9A91",
  "red-2": "#FF736A",
  "red-3": "#F95D54",
  "red-4": "#FF4236",
  "red-5": "#E0362C",
  "red-6": "#c44b45",
  "red-7": "#a65d59",
  // Oranges
  "orange-1": "#FFB46A",
  "orange-2": "#FF9831",
  "orange-3": "#FF810A",
  "orange-4": "#FF6D00",
  "orange-5": "#F76900",
  "orange-6": "#EB4F00",
  "orange-7": "#cb3706",
  // Greens
  "green-1": "#8DE8AD",
  "green-2": "#57E188",
  "green-3": "#34C768",
  "green-4": "#2DB757",
  "green-5": "#189D3E",
  "green-6": "#168736",
  "green-7": "#3e7e50",
  // Teals
  "teal-1": "#93F0E6",
  "teal-2": "#60E6E1",
  "teal-3": "#42C9C2",
  "teal-4": "#27ACAA",
  "teal-5": "#109090",
  "teal-6": "#0f7b7b",
  "teal-7": "#2b7878",
  // Blues
  "blue-1": "#87D3F2",
  "blue-2": "#4EBEEB",
  "blue-3": "#35A4E8",
  "blue-4": "#188CE5",
  "blue-5": "#1777CF",
  "blue-6": "#4575b0",
  "blue-7": "#316bc9",
  // Purples
  "purple-1": "#9C82D4",
  "purple-2": "#7f5cc7",
  "purple-3": "#8e72ca",
  "purple-4": "#8869bf",
  "purple-5": "#7861cc",
  "purple-6": "#6664b9",
  "purple-7": "#4a4ea1",
};

export const seIndicatorColorPaletteBars = [
  "#28482F",
  "#003F5C",
  "#4A2D44",
  "#4A2D44",
];

export const seIndicatorColorPaletteLines = [
  "#7f5cc7",
  "#4575b0",
  "#FF00F5",
  "#CBB781",
  "#8CE8AD",
  "#188CE5",
  "#35A4E8",
  "#168736",
  "#6DCBBC",
  "#FFA600",
  "#95B809",
  "#9F0131",
  "#D37EAF",
  "#F95D54",
  "#C62B97",
  "#7f5cc7",
  "#4575b0",
  "#FF00F5",
  "#CBB781",
  "#8CE8AD",
  "#188CE5",
  "#35A4E8",
  "#168736",
  "#6DCBBC",
  "#FFA600",
  "#95B809",
  "#9F0131",
  "#D37EAF",
  "#F95D54",
  "#C62B97",
  "#7f5cc7",
  "#4575b0",
  "#FF00F5",
  "#CBB781",
  "#8CE8AD",
  "#188CE5",
  "#35A4E8",
  "#168736",
  "#6DCBBC",
  "#FFA600",
  "#95B809",
  "#9F0131",
  "#D37EAF",
  "#F95D54",
  "#C62B97",
];

export const colorPalettePoolForPieChart = [
  "#7f5cc7",
  "#B14891",
  "#C981B2",
  "#FF736A",
  "#FF9831",
  "#189D3E",
  "#34C768",
  "#8CE8AD",
  "#35A4E8",
  "#87D3F2",
  "#F6F6FA",
];

export const ColorPaletteForBarChart = ["#7f5cc7"];

const colorPaletteKeys = Object.keys(colorPalettePool);

export const getColorPaletteKey = (dataIdx: number): string => {
  const key =
    colorPaletteKeys[
      ((dataIdx * 7) % colorPaletteKeys.length) +
        Math.trunc((dataIdx % colorPaletteKeys.length) / 7)
    ];
  return key;
};

export const getColorPaletteColor = (dataIdx: number): string => {
  const key = getColorPaletteKey(dataIdx);
  return colorPalettePool[key];
};

export const getColorPaletteColorCompanyType = (dataIdx: string): string => {
  return colorPalettePoolForPieChart[dataIdx];
};

export const ECOSYSTEM_COLORS = {
  root: "#ED1248",
  subsidiary: "#0CCAAC",
  investment: "#FCBD0F",
  "co-Investor": "#AD2EC5",
  jv_alliances: "#1577CF",
};

export const colorPalettePoolForTop10Assignees = {
  "0": "#7f5cc7",
  "1": "#B14891",
  "2": "#C981B2",
  "3": "#FF736A",
  "4": "#FF9831",
  "5": "#189D3E",
  "6": "#34C768",
  "7": "#8CE8AD",
  "8": "#35A4E8",
  "9": "#87D3F2",
};

export const getPatentInsightAssigneeColorCodes = (itemIdx: number): string => {
  return colorPalettePoolForTop10Assignees[itemIdx];
};

export const distressSelectedCompanyColorPaletteLines = [
  "#f95d54",
  "#95B809",
  "#35a4e8",
];
