import React, { useCallback, useState } from 'react';
import {
    MotifButton,
    MotifCheckbox,
    MotifInlineMessage,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader
} from '@ey-xd/motif-react';
import PrivacyModalContent from '../../../../footer/PrivacyModalBody';
import ProgressSteps from './components/ProgressSteps';
import {
    BUTTONS,
    CLIENT_DEMO_DISCLAIMER,
    CLIENT_DEMO_DISCLAIMER_CONTENT,
    I_AGREE_TO_SATEMENT,
    PRIVACY_NOTICE
} from './Policies.label.const';
import Header from '../../../../header/Header';
import Footer from '../../../../footer/Footer';
import { IStore } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { showPolicyModal } from '../../../../../store/actions/userProfileActions';
import { acceptPrivacyPolicy } from '../../../../../services/userProfile.service';
import { useHistory, useLocation } from 'react-router';
import LoadIndicator from '../../../../loadIndicator';
import { GLOBAL_ERROR_BOUNDARY_MESSAGE } from '../../../../../app.labels';
import { LoaderStyle, StylePrivacy } from './Policies.style';

const Policies: React.FC = () => {
    const [isClientDemo, setIsClientDemo] = useState(false);
    const [checkboxAgree, setCheckboxAgree] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const policyTypeList = useSelector((store: IStore) => store?.userProfile?.policyTypeList);
    const { isExternal } = useSelector((state: IStore) => state.userProfile?.userData);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let redirectUrl = '/';
    if (location?.pathname) {
        redirectUrl = location?.pathname;
        if (location?.search) {
            redirectUrl = location?.pathname + location?.search;
        }
    }

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxAgree(event.target.checked);
    };

    const getModalHeaderTitle = useCallback((): string => {
        if (isClientDemo) {
            return CLIENT_DEMO_DISCLAIMER;
        }
        return PRIVACY_NOTICE;
    }, [isClientDemo]);

    const getButtonTitle = useCallback((): string => {
        if (isClientDemo) {
            return BUTTONS.CONTINUE_TO_CIM;
        }
        return BUTTONS.CONTINUE;
    }, [isClientDemo]);

    const getContent = useCallback(() => {
        if (isClientDemo) {
            return CLIENT_DEMO_DISCLAIMER_CONTENT;
        }
        return policyTypeList[0]?.content;
    }, [isClientDemo]);

    const acceptPrivacy = async () => {
        let hasAccepted = await acceptPrivacyPolicy();
        if (hasAccepted.hasAccepted) {
            dispatch(showPolicyModal(false, []));
            history.push(redirectUrl);
        } else {
            setIsError(true);
        }
        setLoading(false);
    };

    const handleActionButton = () => {
        if (isClientDemo || isExternal) {
            setLoading(true);
            acceptPrivacy();
        } else {
            window.scrollTo(0, 0);
            document.getElementsByClassName('motif-modal-body')[0]?.scrollTo(0, 0);
            setIsClientDemo(true);
        }
    };

    if (loading) {
        return (
            <LoaderStyle>
                <div className="loadIndicator">
                    <LoadIndicator />
                </div>
            </LoaderStyle>
        );
    }

    return (
        <>
            <StylePrivacy>
                <div className="main-container">
                    <Header isSidebar={false} />
                    <div className="main-wrapper">
                        <div className="main-content">
                            <div className="wrapper-progressBar">
                                <ProgressSteps
                                    isClientDemo={isClientDemo}
                                    isExternalUser={isExternal}
                                />
                                <div className="policy-modal">
                                    <MotifModalHeader>{getModalHeaderTitle()}</MotifModalHeader>
                                    <MotifModalBody>
                                        <PrivacyModalContent content={getContent()} />
                                    </MotifModalBody>
                                    <MotifModalFooter>
                                        <div className="footer-wrapper">
                                            {!isClientDemo && (
                                                <MotifCheckbox
                                                    id="checkbox-agree-to-privacy"
                                                    value="checkbox-agree-to-privacy"
                                                    name="checkbox-agree-to-privacy"
                                                    checked={checkboxAgree}
                                                    // @ts-ignore
                                                    onChange={handleCheckbox}>
                                                    {I_AGREE_TO_SATEMENT}
                                                </MotifCheckbox>
                                            )}
                                            <MotifButton
                                                onClick={handleActionButton}
                                                type="button"
                                                disabled={!checkboxAgree}>
                                                {getButtonTitle()}
                                            </MotifButton>
                                            {isError && (
                                                <div className="policy-error">
                                                    <MotifInlineMessage variant="error">
                                                        {GLOBAL_ERROR_BOUNDARY_MESSAGE.title +
                                                            ' ' +
                                                            GLOBAL_ERROR_BOUNDARY_MESSAGE.description}
                                                    </MotifInlineMessage>
                                                </div>
                                            )}
                                        </div>
                                    </MotifModalFooter>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StylePrivacy>
            <Footer />
        </>
    );
};

export default React.memo(Policies);
