import React, { useCallback, useEffect, useState } from 'react';
import { MotifTabNavigation as MotifTabNav, MotifTabControl, MotifIcon } from '@ey-xd/motif-react';
import { ITabNav } from '../models/ITabNav';
import { useHistory } from 'react-router';
import { Q_QUERY_KEY } from '../app.constant';
import { FILTER_VIEWS } from '../app.labels';
import { serializeQueryString } from './layout/filters/filterUtils';
import { IGenericObject } from '../models/IGenericObject';
import { useSelector } from 'react-redux';
import { IStore } from '../store';

interface ITabNavProps {
    tabControlList: Array<ITabNav>;
    defaultActiveKeyIndex: number;
    onTabClick?: Function;
    isRouteTab?: boolean;
    routeonPage?: string;
}

const TabNav: React.FC<ITabNavProps> = ({
    tabControlList,
    onTabClick,
    isRouteTab = false,
    defaultActiveKeyIndex,
    routeonPage = 'main'
}: ITabNavProps): JSX.Element => {
    const [tabIndex, setTabIndex] = useState<number>(defaultActiveKeyIndex);
    const tenantName = useSelector((store: IStore) => store?.userProfile?.userTenant?.tenantName);
    let history = useHistory();

    const onTabButtonClick = useCallback(
        (tabNavObj: ITabNav, index) => {
            onTabClick && onTabClick(tabNavObj.tabName);
            if (isRouteTab) {
                let pageName = `${tenantName}/${tabNavObj.pageName ?? tabNavObj.tabName}`;
                if (index > 1) {
                    // let obj: IGenericObject = {
                    //     view: index === 5 ? FILTER_VIEWS.sector : FILTER_VIEWS.regional
                    // };
                    //let queryString = serializeQueryString(obj);
                    history.push(`/${pageName}`);
                } else {
                    history.push(`/${pageName}`);
                }
            }
        },
        [isRouteTab, onTabClick, routeonPage]
    );

    useEffect(() => {
        setTabIndex(defaultActiveKeyIndex);
    }, [defaultActiveKeyIndex]);

    return (
        <div className="nav-bar-wrapper">
            <MotifTabNav defaultActiveKey={tabIndex} variant="alt">
                {tabControlList?.map((controlObj: ITabNav, index: number) => {
                    return (
                        <MotifTabControl
                            onClick={() => onTabButtonClick(controlObj, index)}
                            key={index}>
                            {controlObj?.tabIcon && controlObj.tabIcon !== '' && (
                                <div className="dot">
                                    <MotifIcon src={controlObj.tabIcon} />
                                </div>
                            )}
                            <div className="main-nav-item">{controlObj.tabName}</div>
                        </MotifTabControl>
                    );
                })}
            </MotifTabNav>
        </div>
    );
};

export default TabNav;
