import { SIDEBAR, appRoutes } from '../../app.labels';

export const getCurrentSideBar = (tenantName: string, location: string) => {
    switch (location) {
        case `/${tenantName}/${appRoutes.overview}`:
            return SIDEBAR.dashboard;
        case `/${tenantName}/${appRoutes.download}`:
            return SIDEBAR.download;
        default:
            return SIDEBAR.dashboard;
    }
};
