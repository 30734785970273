export const debounce = <T extends Function>(fn: T, delay: number) => {
    let timer: ReturnType<typeof setTimeout>;
    return function(this: ThisParameterType<T>, ...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
};

export const reqURLMapper = (chartIdentifier: string) => {
    const downloadKeyIndicatorsChart = 'keyindicators/download';
    const downloadEmissionsChart = 'emissions/download';
    const downloadSectoralImpactChart = 'sectoral-impacts/download';
    switch (chartIdentifier) {
        case 'ES_Emissions':
            return {
                url: `${downloadEmissionsChart}/emissions`,
                reqBody: 'Emissions'
            };
        case 'ES_Emissions_Intensity':
            return {
                url: `${downloadEmissionsChart}/emissions-intensity`,
                reqBody: 'emissionsIntensity'
            };
        case 'ES_Emissions_per_Capita':
            return {
                url: `${downloadEmissionsChart}/emissions-per-capita`,
                reqBody: 'emissionsPerCapita'
            };
        case 'ES_Emissions_by_Sector':
            return {
                url: `${downloadEmissionsChart}/emissions-by-sector`,
                reqBody: 'emissionsBySector'
            };
        case 'ES_Emissions_by_Country':
            return {
                url: `${downloadEmissionsChart}/emissions-by-country`,
                reqBody: 'emissionsByCountry'
            };
        case 'ES_Emissions_Intensity_by_Sector':
            return {
                url: `${downloadEmissionsChart}/emissions-intensity-sector`,
                reqBody: 'emissionsByCountry'
            };
        case 'KI_GNI_per_Capita':
            return {
                url: `${downloadKeyIndicatorsChart}/gni-per-capita`,
                reqBody: 'GniDeviation'
            };
        case 'KI_Carbon_Prices':
            return {
                url: `${downloadKeyIndicatorsChart}/carbon-prices`,
                reqBody: 'carbonPrices'
            };
        case 'KI_Fossil_Fuel_as_a_%_of_Total_Fuel':
            return {
                url: `${downloadKeyIndicatorsChart}/fossilfuel-percenttotalfuel`,
                reqBody: 'fossilfuelPercenttotalfuel'
            };
        case 'KI_Employment_(People)':
            return {
                url: `${downloadKeyIndicatorsChart}/employment`,
                reqBody: 'employment'
            };
        case 'KI_Unemployment_Rate':
            return {
                url: `${downloadKeyIndicatorsChart}/unemployment-rate`,
                reqBody: 'unemploymentRate'
            };
        case 'KI_Investment':
            return {
                url: `${downloadKeyIndicatorsChart}/investment`,
                reqBody: 'investment'
            };
        case 'KI_Return_on_Capital':
            return {
                url: `${downloadKeyIndicatorsChart}/return-on-capital`,
                reqBody: 'returnOnCapital'
            };
        case 'KI_Exchange_Rates':
            return {
                url: `${downloadKeyIndicatorsChart}/exchange-rates`,
                reqBody: 'exchangeRates'
            };
        case 'KI_GDP':
            return {
                url: `${downloadKeyIndicatorsChart}/gdp`,
                reqBody: 'GDP'
            };
        case 'KI_GDP_per_Capita':
            return {
                url: `${downloadKeyIndicatorsChart}/gdp-per-capita`,
                reqBody: 'GDPPerCapita'
            };
        case 'KI_GDP_Deviation':
            return {
                url: `${downloadKeyIndicatorsChart}/gdp-deviation`,
                reqBody: 'GDPDeviation'
            };
        case 'KI_GNI':
            return {
                url: `${downloadKeyIndicatorsChart}/gni`,
                reqBody: 'GNI'
            };
        case 'KI_GNI_Deviation':
            return {
                url: `${downloadKeyIndicatorsChart}/gnideviation`,
                reqBody: 'gnideviation'
            };
        case 'KI_Wages_(Index)':
            return {
                url: `${downloadKeyIndicatorsChart}/wages`,
                reqBody: 'wages'
            };

        case 'KI_Total_value_of_Carbon_Tax_/GDP':
            return {
                url: `${downloadKeyIndicatorsChart}/carbon-tax-gdp`,
                reqBody: 'CarbonTaxByGDP'
            };

        case 'SI_Total_value_of_Carbon_Tax':
            return {
                url: `${downloadSectoralImpactChart}/prices-deviation`,
                reqBody: 'pricesDeviation'
            };
        case 'SI_GVA':
            return {
                url: `${downloadSectoralImpactChart}/gva`,
                reqBody: 'gva'
            };
        case 'SI_GVA_Deviation':
            return {
                url: `${downloadSectoralImpactChart}/gva-deviation`,
                reqBody: 'gvaDeviation'
            };
        case 'SI_Prices':
            return {
                url: `${downloadSectoralImpactChart}/prices`,
                reqBody: 'prices'
            };
        case 'SI_Output':
            return {
                url: `${downloadSectoralImpactChart}/output`,
                reqBody: 'output'
            };
        case 'SI_Output_Deviation':
            return {
                url: `${downloadSectoralImpactChart}/output-deviation`,
                reqBody: 'outputDeviation'
            };
        case 'SI_Prices_Deviation':
            return {
                url: `${downloadSectoralImpactChart}/prices-deviation`,
                reqBody: 'pricesDeviation'
            };
        case 'SI_Share_of_Employment':
            return {
                url: `${downloadSectoralImpactChart}/share-employment`,
                reqBody: 'shareEmployment'
            };
        case 'SI_Share_of_Employment_Deviation':
            return {
                url: `${downloadSectoralImpactChart}/share-employment-deviation`,
                reqBody: 'shareEmploymentDeviation'
            };

        default:
            return {
                url: '',
                reqBody: ''
            };
    }
};
