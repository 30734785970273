import styled from 'styled-components';

export const StyledChartDownloads = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    `,
    Title: styled.div`
        font-family: var(--primary-font);
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 2rem;
    `,
    Table: styled.div``
};
