import { AnyAction } from 'redux';
import { IUserTenant } from '../../models/IUserTenant';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const STORE_USER_PROFILE = 'STORE_USER_PROFILE';
export const USER_TENANT = 'USER_TENANT';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const FETCH_USER_LOGOUT = 'FETCH_USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const SHOW_POLICY_MODAL = 'SHOW_POLICY_MODAL';

export function storeUserProfile(token): AnyAction {
    return {
        type: STORE_USER_PROFILE,
        value: token
    };
}

export function fetchUserProfile(): AnyAction {
    return {
        type: FETCH_USER_PROFILE
    };
}

export function storeUserTenant(tenant: IUserTenant): AnyAction {
    return {
        type: USER_TENANT,
        value: tenant
    };
}

export function updateUserRole(roles: Array<string>): AnyAction {
    return {
        type: UPDATE_USER_ROLE,
        value: roles
    };
}

export function userLogout(): AnyAction {
    return {
        type: FETCH_USER_LOGOUT
    };
}

export function userLogoutSuccess(value: any): AnyAction {
    return {
        type: USER_LOGOUT_SUCCESS,
        value
    };
}
export function userLogoutFailure(value: any): AnyAction {
    return {
        type: USER_LOGOUT_FAILURE,
        value
    };
}

export function showPolicyModal(value: boolean, policyTypeList: any): AnyAction {
    return {
        type: SHOW_POLICY_MODAL,
        value,
        policyTypeList
    };
}
