import { AnyAction } from 'redux';
import { IGenericObject } from '../../models/IGenericObject';
import { IFormStateActionObject } from '../../models/IQueryFilterParam';
import { IFilterModel, IScenarioFilterModel } from '../../models/IFilterModel';
import { ISetFiltersModel } from '../../models/IFilterModel';

// Region filter actions
export const CREATE_FILTER_REGIONS = 'CREATE_FILTER_REGIONS';
export const UPDATE_FILTER_REGIONS = 'UPDATE_FILTER_REGIONS';
export const SELECTED_FILTER_REGIONS = 'SELECTED_FILTER_REGIONS';
export const UPDATE_SELECT_ALL_REGIONS = 'UPDATE_SELECT_ALL_REGIONS';
export const SELECT_ALL_REGIONS = 'SELECT_ALL_REGIONS';

// Sectoral Region filter actions
export const UPDATED_SECTORAL_REGIONS = 'UPDATED_SECTORAL_REGIONS';
export const SELECTED_SECTORAL_REGIONS = 'SELECTED_SECTORAL_REGIONS';

// Scenario Region filter actions
export const UPDATED_SCENARIO_REGIONS = 'UPDATED_SCENARIO_REGIONS';
export const SELECTED_SCENARIO_REGIONS = 'SELECTED_SCENARIO_REGIONS';

// Scenario Sector filter actions
export const UPDATED_SCENARIO_SECTOR = 'UPDATED_SCENARIO_SECTOR';
export const SELECTED_SCENARIO_SECTOR = 'SELECTED_SCENARIO_SECTOR';

// Sectors filter actions
export const CREATE_FILTER_SECTORS = 'CREATE_FILTER_SECTORS';
export const UPDATE_FILTER_SECTORS = 'UPDATE_FILTER_SECTORS';
export const SELECTED_FILTER_SECTORS = 'SELECTED_FILTER_SECTORS';
export const UPDATE_SELECT_ALL_SECTORS = 'UPDATE_SELECT_ALL_SECTORS';
export const SELECT_ALL_SECTORS = 'SELECT_ALL_SECTORS';

// Scenario filter actions
export const SELECTED_SCENARIOS = 'SELECTED_SCENARIOS';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
// For Scenario view - Scenario filter
export const CREATE_SCENARIOS_FILTER = 'CREATE_SCENARIOS_FILTER';
export const UPDATE_BASELINE_SCENARIO = 'UPDATE_BASELINE_SCENARIO';
export const UPDATE_POLICIES_SCENARIO = 'UPDATE_POLICIES_SCENARIO';

// Current tab actions
export const CURRENT_TAB = 'CURRENT_TAB';

//Current Menu
export const CURRENT_MENU = 'CURRENT_MENU';
// DropDown (Open/Close) actions
export const SET_DD_STATE = 'SET_DD_STATE';
export const RESET_DD_STATE = 'RESET_DD_STATE';

// Filter view (Regional/Scenario) actions
export const SET_FILTER_VIEW = 'SET_FILTER_VIEW';

// Reset Filters
export const RESET_FILTER_STATE = 'RESET_FILTER_STATE';

// Reset Filters
export const UPDATE_FILTER_FOR_QUERY_PARAMS = 'UPDATE_FILTER_FOR_QUERY_PARAMS';

export const SELECTED_REGIONS_ON_IDS = 'SELECTED_REGIONS_ON_IDS';

export const UPDATE_PRE_SELECTED_FILTERS = 'UPDATE_PRE_SELECTED_FILTERS';

export const RESET_PRE_SELECTED_FILTERS = 'RESET_PRE_SELECTED_FILTERS';

// Set Filters
export const SET_FILTER_STATE = 'SET_FILTER_STATE';

export const RESET_BUTTON_STATE = 'RESET_BUTTON_STATE';

export const RESET_FILTERS_LOADING = 'RESET_FILTERS_LOADING';

export const FILTERS_LOADING = 'FILTERS_LOADING';

export const SET_DEFAULT_FILTER_STATE = 'SET_DEFAULT_FILTER_STATE'; 

// Regions
export function createFilterRegions(value: Array<{}>): AnyAction {
    return {
        type: CREATE_FILTER_REGIONS,
        value
    };
}
export function selectedFilterRegions(): AnyAction {
    return {
        type: SELECTED_FILTER_REGIONS
    };
}
export function updateFilterRegions(value: string): AnyAction {
    return {
        type: UPDATE_FILTER_REGIONS,
        value
    };
}
export function selectAllRegions(value: boolean): AnyAction {
    return {
        type: SELECT_ALL_REGIONS,
        value
    };
}
export function updateSelectAllRegions(): AnyAction {
    return {
        type: UPDATE_SELECT_ALL_REGIONS
    };
}

// Sectoral Region
export function setSectoralRegion(): AnyAction {
    return {
        type: SELECTED_SECTORAL_REGIONS
    };
}
// Sectoral Region
export function setUpdateSectoralRegion(value: string): AnyAction {
    return {
        type: UPDATED_SECTORAL_REGIONS,
        value
    };
}

// Scenario Region
export function setUpdateScenarioRegion(value: string): AnyAction {
    return {
        type: UPDATED_SCENARIO_REGIONS,
        value
    };
}

export function setScenarioRegion(): AnyAction {
    return {
        type: SELECTED_SCENARIO_REGIONS
    };
}

// Scenario Sector
export function setUpdateScenarioSector(value: string): AnyAction {
    return {
        type: UPDATED_SCENARIO_SECTOR,
        value
    };
}

export function setScenarioSector(): AnyAction {
    return {
        type: SELECTED_SCENARIO_SECTOR
    };
}

// Selector
export function createFilterSectors(value: Array<{}>): AnyAction {
    return {
        type: CREATE_FILTER_SECTORS,
        value
    };
}
export function selectedFilterSectors(): AnyAction {
    return {
        type: SELECTED_FILTER_SECTORS
    };
}
export function updateFilterSectors(value: IGenericObject<string>): AnyAction {
    return {
        type: UPDATE_FILTER_SECTORS,
        value
    };
}
export function selectAllsectors(value: boolean): AnyAction {
    return {
        type: SELECT_ALL_SECTORS,
        value
    };
}
export function updateSelectAllsectors(): AnyAction {
    return {
        type: UPDATE_SELECT_ALL_SECTORS
    };
}

// Scenarios
export function selectedScenarios(view: string, value?: Array<string>): AnyAction {
    return {
        type: SELECTED_SCENARIOS,
        view
    };
}
// Scenarios - Scenario view
export function createScenariosFilter(value: any): AnyAction {
    return {
        type: CREATE_SCENARIOS_FILTER,
        value
    };
}
export function updateBaseLineScenarios(value: string): AnyAction {
    return {
        type: UPDATE_BASELINE_SCENARIO,
        value
    };
}
export function updateScenarios(value: string): AnyAction {
    return {
        type: UPDATE_SCENARIO,
        value
    };
}
export function updatePoliciesScenarios(value: string, baseLine: string): AnyAction {
    return {
        type: UPDATE_POLICIES_SCENARIO,
        value,
        baseLine
    };
}

// DropDown
export function setDDState(value: string): AnyAction {
    return {
        type: SET_DD_STATE,
        value
    };
}
export function resetDDState(): AnyAction {
    return {
        type: RESET_DD_STATE
    };
}

// Filter view
export function setFilterView(value: string): AnyAction {
    return {
        type: SET_FILTER_VIEW,
        value
    };
}

// Current tab
export function setCurrentTab(value: string): AnyAction {
    return {
        type: CURRENT_TAB,
        value
    };
}

export function setCurrentMenu(value: string): AnyAction {
    return {
        type: CURRENT_MENU,
        value
    };
}
// Reset Filters
export function resetFiltersState(value: IFormStateActionObject = {}): AnyAction {
    return {
        type: RESET_FILTER_STATE,
        value
    };
}

export function resetFilterLoading(value: boolean): AnyAction {
    return {
        type: RESET_FILTERS_LOADING,
        value
    };
}

export function setSelectedRegions(value: any): AnyAction {
    if (value) {
        return {
            type: SELECTED_REGIONS_ON_IDS,
            value
        };
    }
    return {
        type: ''
    };
}

// Set Filters
export function setFiltersState(value: ISetFiltersModel): AnyAction {
    return {
        type: SET_FILTER_STATE,
        value
    };
}

export function resetButtonState(): AnyAction {
    return {
        type: RESET_BUTTON_STATE
    };
}

// loading filters
export function setFiltersLoading(value: boolean): AnyAction {
    return {
        type: FILTERS_LOADING,
        value
    };
}

// Set Default filters when there is not data from API.
export function setDefaultFilters(): AnyAction {
    return {
        type: SET_DEFAULT_FILTER_STATE,
    };
}

// Update Filter for API response.
export function updatePreSelectedFilters(value: any): AnyAction {
    return {
        type: UPDATE_PRE_SELECTED_FILTERS,
        value
    };
}

export function resetPreSelectedFilters(): AnyAction {
    return {
        type: RESET_PRE_SELECTED_FILTERS,
    };
}