import React from 'react';
import { StyledPageLayout } from './Errors.style';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../store';

interface IOwnProps {
    isUnauthorized?: boolean;
    isTenantError?: boolean;
    isBlockedError?: boolean;
}

const ErrorPage: React.FC<IOwnProps> = ({
    isUnauthorized = false,
    isTenantError,
    isBlockedError = false
}) => {
    const tenantName = useSelector((store: IStore) => store?.userProfile?.userTenant?.tenantName);
    if (isUnauthorized) {
        return (
            <StyledPageLayout>
                <div className="warning">
                    <i className="fas fa-exclamation-triangle"> </i>
                </div>
                <div className="box">
                    <h2>Unauthorised access!</h2>
                    <p className="details-p">
                        Looks like you do not have the credentials to access CEM.
                    </p>

                    <p>
                        Please contact the admin
                        {!isTenantError && (
                            <>
                                or <a href="/">Try again</a>
                            </>
                        )}
                    </p>
                </div>
            </StyledPageLayout>
        );
    }
    if (isBlockedError) {
        return (
            <StyledPageLayout>
                <div className="warning">
                    <i className="fas fa-exclamation-triangle"> </i>
                </div>
                <div className="box">
                    <h2>Oops!</h2>
                    <p className="details-p">Something went wrong.</p>
                    <p>
                        Please <a href={`/`}>Try again</a>, or report an issue to support
                    </p>
                </div>
            </StyledPageLayout>
        );
    }

    return (
        <StyledPageLayout>
            <div className="warning">
                <i className="fas fa-exclamation-triangle"> </i>
            </div>
            <div className="box">
                <h2>Oops!</h2>
                <p className="details-p">Something went wrong.</p>
                <p>
                    Please <a href={`/${tenantName}`}>Try again</a>, if issue persists then contact
                    admin
                </p>
            </div>
        </StyledPageLayout>
    );
};

export default ErrorPage;
