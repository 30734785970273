import { AxiosError } from 'axios';
import { get, apiRoutes, post, browserHistory, errorStatus } from './api';
import { store } from '../store';
import { appRoutes } from '../app.labels';
import { ApiErrorStatusCode, ErrorMessages } from '../common/enum/error';
import { ISectorialAreaChart } from '../models/ISectorialImpacts';
import { totalEmissions } from '../mockData/TotalEmissionsBaselinePolicy';
import { outputBySector } from '../mockData/OutputBySector';
import { IConfigurationResponse } from '../models/IConfigurationResponse';
import { HTTP_METHOD } from '../common/enum/api';

let cache: any = {};
export const getOutPutData = async (url: string): Promise<Array<ISectorialAreaChart>> => {
    let returnData: any = [];
    if (url === 'baseline') {
        returnData = totalEmissions.totalEmissions;
    } else if (url === 'outputBySector') {
        returnData = outputBySector.theWorldTodayOutPut;
    }
    return Promise.resolve(returnData);
};

export const fetchConfiguration = (): Promise<IConfigurationResponse> => {
    return get(apiRoutes.configuration)
        .then((config: IConfigurationResponse) => {
            return config;
        })
        .catch((error: AxiosError) => {
            if (
                error.response?.status !== errorStatus.sessionTimeOut &&
                error.response?.status !== errorStatus.unauthorized &&
                error.response?.status != errorStatus.unavailable
            ) {
                const tenantName = store?.getState()?.userProfile?.userTenant?.tenantName;
                browserHistory.push(`/${tenantName}/${appRoutes.error}`);
            }
            return {
                gemRegions: [],
                gemSectors: [],
                scenarios: [],
                climateScenariosData: {
                    coRemoval: [],
                    effortCurbEmissions: [],
                    regionalPolicyVariation: [],
                    speedGreenTechnologyDevelopment: []
                },
                countryByRegion: []
            };
        });
};
export interface IAPIError {
    errorMessage: string | AxiosError;
}

export type IHttpMethod = HTTP_METHOD.GET | HTTP_METHOD.POST;

// TODO: have to remove 'any' if all the api's are implemented
export const getDataFromApi = <T = any>(
    url: string,
    cacheKey: string,
    httpMethod: IHttpMethod = HTTP_METHOD.POST,
    isCaching = true
): Promise<T> => {
    const { reqBody } = JSON.parse(cacheKey);
    let cacheData = cache[cacheKey];
    if (cacheData) {
        return new Promise((resolve) => {
            setTimeout(() => {
                return resolve(cacheData);
            }, 0);
        });
    } else if (httpMethod === HTTP_METHOD.POST) {
        return post<any>(url, reqBody)
            .then((response) => {
                if (isCaching) {
                    cache[cacheKey] = response;
                }
                return response;
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === ApiErrorStatusCode.Error404) {
                    return { errorMessage: ErrorMessages.Error404 };
                }
                return { errorMessage: error };
            });
    } else {
        return get<any>(url)
            .then((response) => {
                cache[cacheKey] = response;
                return response;
            })
            .catch((error: AxiosError) => {
                return { errorMessage: error };
            });
    }
};

/**
 *  this function call get call API
 * @param url
 * @param cacheKey
 * @returns
 */

let fetchCache = {};
export const fetchDataFromApi = (url) => {
    let cacheData = fetchCache[url];
    if (cacheData) {
        return new Promise((resolve) => {
            setTimeout(() => {
                return resolve(cacheData);
            }, 0);
        });
    } else {
        return get<any>(url)
            .then((response) => {
                fetchCache[url] = response;
                return response;
            })
            .catch((error: AxiosError) => {
                return { errorMessage: error };
            });
    }
};



/**
 *  this function will fetch filters without caching.
 * @param url
 * @returns
 */

export const fetchFiltersData = (url) => {
    return get<any>(url)
        .then((response) => {
            return response;
        })
        .catch((error: AxiosError) => {
            return { errorMessage: error };
        });
};