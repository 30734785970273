import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    MotifVerticalNavigation as MotifVerticalNav,
    MotifVerticalNavigationContent as MotifVerticalNavContent,
    MotifVerticalNavigationMenu as MotifVerticalNavMenu,
    MotifVerticalNavigationMenuItem as MotifVerticalNavMenuItem,
    MotifIcon as IconWrapper
} from '@ey-xd/motif-react';
import { sideBarMenuList } from '../../app.constant';
import { SIDEBAR } from '../../app.labels';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import { getCurrentSideBar } from './sideBarView.utils';
import { setCurrentMenu } from '../../store/actions/FilterActions';

interface IOwnProps {
    isExpanded: boolean;
    getActiveMenu: (tab: string) => void;
}

const SideBarView: React.FC<IOwnProps> = ({
    isExpanded,
    getActiveMenu
}: IOwnProps): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const tenantName = useSelector((store: IStore) => store?.userProfile?.userTenant?.tenantName);
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState<string>('');

    useEffect(() => {
        dispatch(setCurrentMenu(getCurrentSideBar(tenantName, location.pathname)));
        setSelectedMenu(getCurrentSideBar(tenantName, location.pathname));
    }, [location.pathname]);

    const handleRoute = (item): void => {
        setSelectedMenu(item.keyName);
        getActiveMenu(item.keyName);
        if (item.keyName === SIDEBAR.dashboard) {
            history.push(`/${tenantName}/overview`);
        }
        if (item.keyName === SIDEBAR.download) {
            history.push(`/${tenantName}/download`);
        }
    };

    return (
        <StyleSidbarWrapper isExpanded={isExpanded}>
            <StyleSidbar>
                <div className="sidebar-wrapper">
                    <MotifVerticalNav className="example-root-class" collapse={!isExpanded}>
                        <MotifVerticalNavContent>
                            <MotifVerticalNavMenu>
                                {sideBarMenuList?.map((route, index) => {
                                    const disabled = selectedMenu !== route.keyName;
                                    return (
                                        <MotifVerticalNavMenuItem
                                            icon={<IconWrapper src={route.icon} />}
                                            key={route.id}
                                            label={route.keyName}
                                            className={
                                                index === 1
                                                    ? `icon ${disabled ? 'disabled' : null}`
                                                    : ''
                                            }
                                            onClick={() => handleRoute(route)}
                                            selected={selectedMenu === route.keyName}>
                                            {!isExpanded ? '' : route.item}
                                        </MotifVerticalNavMenuItem>
                                    );
                                })}
                            </MotifVerticalNavMenu>
                        </MotifVerticalNavContent>
                    </MotifVerticalNav>
                </div>
            </StyleSidbar>
        </StyleSidbarWrapper>
    );
};
const StyleSidbarWrapper = styled.div<{ isExpanded: boolean }>`
    width: ${(props): string => (props.isExpanded ? '282px' : '55px')};
    transition: width 0.5s;
`;

const StyleSidbar = styled.div`
    position: fixed;
    .sidebar-wrapper {
        display: flex;
        overflow: hidden;
        margin: 0;
        padding: 0;
        min-height: 100vh;
    }
    .disabled {
        path {
            stroke: var(--disabled-text);
        }
    }
    .icon {
        .motif-icon svg {
            fill: none;
        }
    }
`;
export default React.memo(SideBarView);
