import numeral from "numeral";
import { ONE_MILLION } from "../app.labels";

    export interface IFormatOptions {
        precision?: number;
        ltKFormat?: string;
        gtKFormat?: string;
    }

    const _formatOpt: IFormatOptions = {
        ltKFormat: "0.[00]",
        gtKFormat: "0.0a",
    };
    export const formatNumber = (
        value: number,
        formatOpts: IFormatOptions = _formatOpt
    ): string => {
        return formatOpts.precision
        ? formatDecimal(value, formatOpts.precision)
        : formatNumberWithNotation(value, formatOpts);
    };

    const formatNumberWithNotation = (
        value: number | undefined,
        formatOpts: IFormatOptions
    ): string => {
        
        if  (value && value > -1000 && value < 1000) {
            return numeral(value).format(formatOpts.ltKFormat);
        } else {
            return numeral(value)
            .format(formatOpts.gtKFormat);
        }
    
    };

    const formatDecimal = (
        value: number | undefined,
        precision: number
    ): string => {
        return numeral(value).format(`0.${"0".repeat(precision)}`);
    };


    export const getThousandSeparator = (value: number, separator=","): string => {
        if(value < 0){
            const number = numeral(value as number).format(`0${separator}0`);
            return `(${number.toString().replace("-",'')})`
        }
        return numeral(value as number).format(`0${separator}0`);
    }

    //this  method is used to formatt  Decima Points with Thousand Separator 
    export const formattDecimalPoints = (value:number|string):string =>
    {
        if(typeof value === "number"){
            return numeral(value).format('0,0.00')
        }else {
            return value;
        }
    
    }

    export const billionFormatter = (value:number,postText=""):string =>{
        return `${(value*1/1000000000)}${postText}`;
    } 

    export const convertToDecimal = (value:number) => {
        if (typeof value === 'number' && value.toExponential().includes('e-')) {
            return Number(value.toFixed(2)); // Convert to decimal
          }
          return value; // Return the value as-is
    }



    const _formatOptCIM: IFormatOptions = {
        ltKFormat: "0.[00]",
        gtKFormat: "0.00a",
    };
    export const formatNumberCIMStandard = (
        value: number,
    ): string => {
        return formatNumberWithNotation(value, _formatOptCIM);
    };

    /**
     *  this method convert the given value in million and formatt it 
     * @param value 
     * @returns 
     */
    export const convertValueAsperCIMStandard=(value:number):string =>{

        return formatNumberCIMStandard(value * ONE_MILLION);
    }