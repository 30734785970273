import { AnyAction } from 'redux';
import { IClimateScenarioData } from '../../models/IClimateScenarioData';

export const SET_CLIMATE_SCENARIOS_DATA = 'SET_CLIMATE_SCENARIOS_DATA';
export const FETCH_PAGE_DATA = 'FETCH_PAGE_DATA';
export const STORE_PAGE_DATA = 'STORE_PAGE_DATA';
export const SAVE_PAGE_DATA_TO_DB = 'SAVE_PAGE_DATA_TO_DB';

export function setClimateScenariosData(value: IClimateScenarioData): AnyAction {
    return {
        type: SET_CLIMATE_SCENARIOS_DATA,
        value
    };
}

export function fetchPageData(value: string): AnyAction {
    return {
        type: FETCH_PAGE_DATA,
        value
    };
}

export function storePageData(value): AnyAction {
    return {
        type: STORE_PAGE_DATA,
        value,
    };
}

interface ISavePageDataToDBPayload {
    pageName: string;
    content: string;
}

export function savePageDataToDB(value: ISavePageDataToDBPayload): AnyAction {
    return {
        type: SAVE_PAGE_DATA_TO_DB,
        value,
    };
}
