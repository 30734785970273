export const FOOTER = {
    privacyNotice: 'Privacy Notice',
    engagement: 'Engagement Management',
    termsOfUse: 'Terms Of Use',
    demoDisclaimer: 'Demo disclaimer'
};
export const FOOTER_LEAGAL_SECTION = `EY refers to the global organization, and may refer to one or more, of the
member firms of Ernst & Young Global Limited, each of which is a separate legal
entity. Ernst & Young Global Limited, a UK company limited by guarantee, does
not provide services to clients`;
