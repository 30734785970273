import styled from 'styled-components';

const StyledReadMore = {
    Container: styled.div`
        // Verify this import when Motif updates the RTE
        .ql-editor {
            padding: 0px;
        }
    `,
    Link: styled.div`
        font-weight: var(--weight-bold);
        text-decoration: underline;
        cursor: pointer;
    `
};

export default StyledReadMore;
