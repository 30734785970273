import React, { FunctionComponent } from 'react';
import { MotifIcon } from '@ey-xd/motif-react';

type IconProps = {
    src: string;
    suppressEvent?: boolean;
    disabled?: boolean;
    className?: string;
    testId?: string;
    onClick?: (evt?: React.MouseEvent) => void;
};

const Icon: FunctionComponent<IconProps> = ({
    src,
    suppressEvent = false,
    disabled = false,
    className = '',
    testId = '',
    onClick,
    ...props
}) => {
    const handleClick = (event?: React.MouseEvent) => {
        if (suppressEvent) {
            event?.preventDefault();
        }
        if (!disabled) onClick?.(event);
    };

    return (
        <MotifIcon
            {...props}
            src={src}
            className={className}
            data-test-id={testId}
            onClick={handleClick}
        />
    );
};

export default Icon;
