import React, { useState } from "react";
import {
  MotifDropdown,
  MotifDropdownItem,
  MotifIcon,
} from "@ey-xd/motif-react";
import { navigationIcMoreHoriz24px } from "@ey-xd/motif-react/assets/icons";
import styled from "styled-components";
import { WIDGET_OPTIONS } from "../../app.labels";

interface IOwnProps {
  options: Array<{
    title: string;
    icon: string;
  }>;
  updateMaxMinView?: Function;
}

const OptionsModal: React.FC<IOwnProps> = ({
  options,
  updateMaxMinView,
}: IOwnProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const triggerId = "dropdown-trigger";

  const handleMenu = (): void => {
    setIsOpen(!isOpen);
  };

  const setClickOutside2 = (): void => {
    setIsOpen(false);
  };

  const handleClickDropdownItem = (item): void => {
    if (updateMaxMinView && item !== WIDGET_OPTIONS.download)
      updateMaxMinView(item === WIDGET_OPTIONS.maximize, item);
    setIsOpen(false);
  };

  return (
    <>
      <StyledDiv>
        <MotifDropdown
          open={isOpen}
          aria-labelledby={triggerId}
          handleClickOutside={setClickOutside2}
          trigger={
            <MotifIcon
              title="Options Dropdown Icon"
              src={navigationIcMoreHoriz24px}
              onClick={handleMenu}
            />
          }
        >
          {options?.map((item, index) => {
            return (
              <MotifDropdownItem
                key={index}
                onClick={() => {
                  handleClickDropdownItem(item.title);
                }}
              >
                <img src={item.icon} alt={item.title} /> {item.title}
              </MotifDropdownItem>
            );
          })}
        </MotifDropdown>
      </StyledDiv>
    </>
  );
};

const StyledDiv = styled.div`
  .motif-dropdown-item img {
    margin-right: 10px;
  }
`;

export default React.memo(OptionsModal);
