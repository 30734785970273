import styled from 'styled-components';

const StyledRichTextEditor = styled.div<{ height: number }>`
    --rich-text-editor-bg-color:#23232F;
    --rich-text-editor-toolbar-bg-color:#1a1a24;
    --rich-text-editor-active: #3A3A4A;

    width: 100%;
    margin-bottom: 10px;

    .ql-snow .ql-editor {
        min-height: ${(props) => props.height}px;
    }

    .ql-snow .ql-picker-label.ql-active .ql-stroke {
        stroke: var(--rich-text-editor-icon-color);
    }

    .quill.motif-rich-text-editor {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-top: 1px solid var(--rich-text-editor-border-color);
    }

    .ql-snow .ql-icon-picker .ql-picker-item {
        width: 30px !important;
    }

    .ql-snow .ql-color-picker .ql-picker-item {
        margin: 2px !important;
    }

    .ql-snow .ql-color-picker {
        border: none;
    }
    
    .ql-snow {
        .ql-picker {
            &.ql-size {
                .ql-picker-label,
                .ql-picker-item {
                    &::before {
                        content: attr(data-value) !important;
                    }
                }
            }
        }
    }

    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
        z-index: 2;
    }
`;

export default StyledRichTextEditor;