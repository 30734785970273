import React, { FunctionComponent } from 'react';
import StyledCharacterCounter from './CharacterCounter.style';

interface CharacterCounterProps {
    characterCount: number;
    maxCharacterLimit: number;
}

const CharacterCounter: FunctionComponent<CharacterCounterProps> = ({
    characterCount,
    maxCharacterLimit
}) => {
    const remainder = maxCharacterLimit - characterCount;
    return (
        <StyledCharacterCounter.Container>
            <div>{remainder} Characters remaining.</div>
            {remainder <= 0 && (
                <StyledCharacterCounter.Error>
                    Exceeded the maximum characters limit.
                </StyledCharacterCounter.Error>
            )}
        </StyledCharacterCounter.Container>
    );
};

export default CharacterCounter;
