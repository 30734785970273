import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import SidebarView from '../sideBarView/SideBarView';
import TabNav from '../TabNav';
import Header from '../header/Header';
import {
    resetFiltersState,
    setCurrentTab,
    setFilterView,
    setSelectedRegions
} from '../../store/actions/FilterActions';
import { IStore } from '../../store';
import { ITabNav } from '../../models/ITabNav';
import { removeHashFromURL } from '../../common/commonUtil';
import {
    buildFilterStateObjectfromQueryParams,
    serializeQueryString
} from '../layout/filters/filterUtils';

import { APPLICATION_TABNAV_HEADERS, FILTER_VIEWS, SIDEBAR, appRoutes } from '../../app.labels';

import styled from 'styled-components';

export interface LayoutProps {
    children: React.ReactNode;
}

const PageLayout: React.FC<LayoutProps> = ({ children }: LayoutProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [defaultKeyIndex, setDefaultKeyIndex] = useState<number>(0);
    const [showTab, setShowTab] = useState<boolean>(false);

    const [selectedMenu, setSelectedMenu] = useState<string>(SIDEBAR.dashboard);

    const tenantName = useSelector((store: IStore) => store?.userProfile?.userTenant?.tenantName);
    const currentMenu = useSelector((store: IStore) => store?.filter?.currentMenu);

    const queryObj = qs.parse(removeHashFromURL());
    const searchString = queryObj['q'] as string;

    const handleSidebar = (flag): void => {
        setIsExpanded(flag);
    };

    const initialTabClick = useCallback(
        (value: string, index: number = 0) => {
            // Reset filterview
            if (location.search !== '') {
                let searchOject = JSON.parse(searchString);
                let view = '';
                if (searchOject['view'] !== '') {
                    if (index > 1) {
                        view =
                            index === 5 && searchOject['view'] === FILTER_VIEWS.sector
                                ? FILTER_VIEWS.regional
                                : searchOject['view'];

                        //dispatch(setFilterView(view));
                    }
                } else {
                    if (index > 1) {
                        view = index === 5 ? FILTER_VIEWS.regional : searchOject['view'];
                        // dispatch(setFilterView(view));
                    }
                }

                let updateObject = buildFilterStateObjectfromQueryParams(
                    history.location.search,
                    APPLICATION_TABNAV_HEADERS[index]?.pageName ?? value,
                    view
                );
                if (Object.keys(updateObject).length > 2) {
                    dispatch(setSelectedRegions(updateObject));
                }
            } else {
                if (index > 1) {
                    // const qQueryObject = {
                    //     view: index === 5 ? FILTER_VIEWS.sector : FILTER_VIEWS.regional
                    // };
                    //let queryString = serializeQueryString(qQueryObject);
                    // dispatch(setFilterView(FILTER_VIEWS.regional));
                    //let createdNewURl = createNewUrl(history.location.pathname, queryString);
                    //history.push(createdNewURl);
                }
            }
        },
        [location, history, dispatch, searchString]
    );

    const onTabClick = useCallback(
        (value: string, index: number = 0) => {
            // Reset filterview
            dispatch(setFilterView(FILTER_VIEWS.regional));
            // Set Current Tab
            dispatch(setCurrentTab(value));
            // Reset Filters
            // dispatch(resetFiltersState());
        },
        [location, history, dispatch, searchString]
    );

    const getActiveMenu = useCallback((sidebarMenu) => {
        dispatch(setCurrentTab(sidebarMenu));
        setSelectedMenu(sidebarMenu);
    }, []);

    const showTabNav = useCallback(() => {
        if (
            showTab &&
            currentMenu === SIDEBAR.dashboard &&
            location.pathname !== `/${tenantName}/${appRoutes.download}`
        ) {
            return true;
        }
        return false;
    }, [showTab, currentMenu, selectedMenu, location.pathname, tenantName, getActiveMenu]);

    useEffect(() => {
        APPLICATION_TABNAV_HEADERS.forEach((tabObj: ITabNav, index) => {
            let pathNameSplit = location?.pathname?.split('/');
            let tabNameExists = false;
            if (pathNameSplit.length > 1 && pathNameSplit[2]) {
                tabNameExists = pathNameSplit[2] === tabObj.pageName;
            } else {
                tabNameExists = location?.pathname?.includes(tabObj.pageName ?? '');
            }
            if (location.pathname !== '/' && tabNameExists) {
                setDefaultKeyIndex(index);
                initialTabClick(tabObj.tabName ?? tabObj.pageName, index);
                dispatch(setCurrentTab(tabObj.pageName ?? appRoutes.overview ?? ''));
            }
        });
        if (location.pathname !== '/') {
            setShowTab(true);
        }
        if (location.pathname === '/download') {
            setShowTab(false);
        }
    }, [location, initialTabClick, dispatch]);

    return (
        <StyledPageLayout>
            <div className="main-container">
                <Header handleSidebar={handleSidebar} />
                <div className="main-wrapper">
                    <SidebarView isExpanded={isExpanded} getActiveMenu={getActiveMenu} />
                    <div className="main-content">
                        <div className="header-menu-section mb-20 mt-10">
                            {showTabNav() && (
                                <TabNav
                                    isRouteTab={true}
                                    onTabClick={onTabClick}
                                    defaultActiveKeyIndex={defaultKeyIndex}
                                    tabControlList={APPLICATION_TABNAV_HEADERS}
                                />
                            )}
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </StyledPageLayout>
    );
};

const StyledPageLayout = styled.div`
    .main-container {
        .main-wrapper {
            display: flex;
            margin: 0rem 0 0 0;
            padding: 0;
            min-height: 100vh;

            .main-content {
                flex-grow: 1;
                padding: 10px 20px;
                width: 75%;
                margin-bottom: 0px;

                .header-menu-section {
                    display: flex;
                    .results-inputs {
                        display: flex;
                        flex-direction: row;
                        /* justify-content: end;
            margin-top: -28px; */

                        .input-icons-section,
                        .result-icons-section {
                            display: flex;
                        }

                        .motif-label {
                            width: 60px;
                            font-size: 0.75rem;
                        }

                        .motif-icon svg {
                            height: 0.75rem;
                            width: 0.75rem;
                        }
                    }
                }
            }
        }
    }
`;

export default React.memo(PageLayout);
