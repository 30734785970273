import { createStore, compose, AnyAction, StoreEnhancer, applyMiddleware } from 'redux';
import { install, StoreCreator, combineReducers } from 'redux-loop';
import { composeWithDevTools } from 'redux-devtools-extension';
import { PRODUCTION } from '../app.constant';
import redirectOnErrorMiddleware from './middlewares/redirectOnErrorMiddleware';
import {
    FilterReducer,
    IFilterState,
    initialState as filterInitialState
} from './reducers/FilterReducer';
import {
    userProfileReducer,
    IUserProfileState,
    initialState as userProfileInitialState
} from './reducers/userProfileReducer';
import {
    RichTextEditorReducer,
    IRichTextEditorState,
    initialState as richTextEditorInitialState
} from './reducers/RichTextEditorReducer';
import {
    CommonReducer,
    ICommonState,
    initialState as commonInitialState
} from './reducers/CommonReducer';

export type IStore = {
    filter: IFilterState;
    userProfile: IUserProfileState;
    richTextEditor: IRichTextEditorState;
    common: ICommonState;
};

export const rootReducer = combineReducers<IStore, AnyAction>({
    filter: FilterReducer,
    userProfile: userProfileReducer,
    richTextEditor: RichTextEditorReducer,
    common: CommonReducer
});

export const initialState: IStore = {
    filter: filterInitialState,
    userProfile: userProfileInitialState,
    richTextEditor: richTextEditorInitialState,
    common: commonInitialState
};

export const enhancedCreateStore = createStore as StoreCreator;

export let enhancer: StoreEnhancer<any, AnyAction> = compose(
    install<IStore>(),
    applyMiddleware(redirectOnErrorMiddleware)
);

if (process.env.NODE_ENV !== PRODUCTION) {
    // @ts-ignore
    if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        const composeEnhancers = composeWithDevTools({
            trace: true,
            traceLimit: 25
        });
        // @ts-ignore
        enhancer = compose(
            install<IStore>(),
            // @ts-ignore
            composeEnhancers(applyMiddleware(redirectOnErrorMiddleware))
        );
    }
}

export const store = enhancedCreateStore(rootReducer, initialState, enhancer);
