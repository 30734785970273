import React, { useContext, useState } from 'react';
import { MotifCard, MotifCardBody, MotifCardHeader } from '@ey-xd/motif-react';
import styled from 'styled-components';
import OptionsModal from '../optionsModal/OptionsModal';
import { DFAULT_WIDGET_HIEGHT } from '../../app.constant';
import { MotifIcon } from '@ey-xd/motif-react';
import { actionIcInfoOutline24px } from '@ey-xd/motif-react/assets/icons';
import { IRoutingOptions } from '../../models/IRoutingOptions';
import { WIDGET_OPTIONS } from '../../app.labels';
import { removeHashFromURL } from '../../common/commonUtil';
import qs from 'query-string';
import { IRouteManagerContext, RouteManagerContext } from '../routerManager/Context';

interface IOwnProps {
    children: React.ReactNode;
    headerText: string;
    showInfoIcon?: boolean;
    infoIconToolTip?: string;
    options?: Array<{
        title: string;
        icon: string;
    }>;
    height?: string;
    updateMaxMinView?: Function;
    routingOptions?: IRoutingOptions;
    unit?: string;
    queryString?: string;
}

const WidgetWrapper: React.FC<IOwnProps> = ({
    children,
    headerText,
    height,
    options,
    updateMaxMinView,
    showInfoIcon,
    infoIconToolTip = '',
    routingOptions,
    queryString,
    unit
}: IOwnProps): JSX.Element => {
    const [maximized, setMaximized] = useState<boolean>(false);
    const routeManagerContext = useContext(RouteManagerContext) as IRouteManagerContext;

    const handleMaxMinDropDwon = (e, item) => {
        // check if clicked on maximize or minimize dropdown
        if (
            routingOptions &&
            (item === WIDGET_OPTIONS.minimize || item === WIDGET_OPTIONS.maximize)
        ) {
            setMaximized(item === WIDGET_OPTIONS.maximize);
            routeManagerContext.routeTo({
                ...routingOptions,
                expanded: item === WIDGET_OPTIONS.maximize ? routingOptions!.expanded : undefined
            });
            updateMaxMinView && updateMaxMinView(e, item);
        }
    };

    return (
        <StyledDiv wrapperHeight={height ? height : DFAULT_WIDGET_HIEGHT} maximized={maximized}>
            <section>
                <article>
                    <MotifCard>
                        <MotifCardHeader>
                            <div className="wrapper-tooltip">
                                <span className="motif-h5">{headerText}</span>
                                {unit && (
                                    <span className="motif-label table-unit">
                                        {'(' + unit + ')'}
                                    </span>
                                )}
                                {showInfoIcon && false && (
                                    <MotifIcon src={actionIcInfoOutline24px} />
                                )}
                            </div>
                            {options && options?.length > 0 && (
                                <OptionsModal
                                    options={options}
                                    updateMaxMinView={handleMaxMinDropDwon}
                                />
                            )}
                        </MotifCardHeader>
                        <MotifCardBody>{children}</MotifCardBody>
                    </MotifCard>
                </article>
            </section>
        </StyledDiv>
    );
};

const StyledDiv = styled.div<{ wrapperHeight: string; maximized: boolean }>`
    width: 100%;
    .motif-card {
        height: ${(props): string => (props.maximized ? '650px' : props.wrapperHeight)};
        width: 100%;
        overflow: hidden;

        .motif-card-header {
            padding: 0.5rem;
        }
        .motif-card-body {
            overflow: hidden;
            padding: 0;

            & > div {
                padding-top: 10px;
            }
        }
        .wrapper-tooltip {
            display: flex !important;
            flex-direction: row !important;
            .motif-h5,
            .table-unit {
                font-size: 16px !important;
            }
        }
        .motif-tooltip-wrapper {
            margin-top: 5px;
        }
        .motif-h5 {
            font-weight: 400;
        }
        .motif-label {
            width: unset;
        }
        .table-unit {
            padding-left: 10px;
        }
    }
`;

export default React.memo(WidgetWrapper);
