// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue3": "#188ce5",
	"black": "#000",
	"colorForegroundLight": "#f4f6f8",
	"black1": "#3a3a3a",
	"white4": "#f5f6f7",
	"yellow1": "#f5dc00",
	"white": "#fff",
	"gray": "#747480",
	"gray2": "#f6f6fa",
	"alternategray": "#c4c4cd",
	"gray6": "#191a24",
	"gray15": "#24242d",
	"gray30": "#6e6e75",
	"gray50": "#2e2e38",
	"gray40": "#404854",
	"gray60": "#c4cdd5",
	"gray70": "#35374b",
	"gray90": "#eaeaf2",
	"gray100": "#2e2e3c",
	"darkgray": "#959595",
	"lightblack": "#282833",
	"black3": "#2e2e3c",
	"lightbrown": "#333",
	"alternateblack": "#23232f",
	"formControlBlack": "#0c0c11",
	"lightgray": "#f6f6fa",
	"loaderOverlay": "#2625257a",
	"red2": "#ff9a91",
	"teal2": "#93f0e6",
	"orange7": "#cb3706",
	"yellow": "#ffe600",
	"yellow2": "#ffe601",
	"green4": "#34c768",
	"darkwhite": "#fff",
	"lightwhite": "#d8d8d8",
	"darkblack": "#1a1a24",
	"headerGray": "#4a4a57",
	"tooltipDetails": "#dfe3e8",
	"bgTile": "#24242d",
	"networkNodeUnchecked": "#3c3c42",
	"networkNodeNa": "#b0b0b8",
	"chartLineColor": "#464646",
	"tableCellPadding": "12",
	"fontInterstate": "\"EYInterstate\",\"Segoe UI\",sans-serif",
	"fontEyInterstate": "\"ey_interstate\"",
	"modalwidth": "800",
	"lsmodalwidth": "1000",
	"logoutModalWidth": "400",
	"limitRestrictionModalWidth": "620",
	"modalDeletePortfolio": "586",
	"downloadModalWidth": "420",
	"discardModalWidth": "500",
	"avancedSearchClosingModalwidth": "500",
	"saveReportModalWidth": "520",
	"deleteReportModalWidth": "568",
	"EYItalic": "\"NotoSansItalic\",\"Segoe UI\",sans-serif",
	"green2": "#2db757",
	"threatvaluecolors-value0": "#4a4a57",
	"threatvaluecolors-value1": "#189d3e",
	"threatvaluecolors-value2": "#57e188",
	"threatvaluecolors-value3": "#ffb46a",
	"threatvaluecolors-value4": "#ff9731",
	"threatvaluecolors-value5": "#f95d54",
	"heatMapvalue1": "#c7c7c7",
	"heatMapvalue2": "#1d9d31",
	"heatMapvalue3": "#21b218",
	"heatMapvalue4": "#f46b00",
	"heatMapvalue5": "#fd8500",
	"heatMapvalue6": "#af0d00",
	"revenueColor": "#57e188",
	"ebitdaColor": "#60e6e1",
	"ebitColor": "#cb3706",
	"netIncomeColor": "#ff9831",
	"cashColor": "#3e7e50",
	"otherCurrentAssets": "#0f7b7b",
	"netPPColor": "#1777cf",
	"totalIntangiblesColor": "#7f5cc7",
	"otherLongTermAssetsColor": "#8869bf",
	"currentBorrowingsColor": "#e0362c",
	"otherCurrentLiabilitiesColor": "#ff9831",
	"LTDebtCapitalLeasesColor": "#35a4e8",
	"otherLongTermLiabilities": "#189d3e",
	"creditRatingCreditRatingColor": "#60e6e1",
	"quickRatioColor": "#ff736a",
	"quickRatioCreditRatingColor": "#cb3706",
	"solvencyCreditRatingColor": "#ff736a",
	"ebitdaCapexCreditRatingColor": "#57e188",
	"currentAssetsColor": "#7f5cc7",
	"currentLiabilitiesColor": "#b14891",
	"accountsReceivableColor": "#3e7e50",
	"inventoryColor": "#168736",
	"listMinimizedHeight": "354",
	"stockPriceColor": "#b14891",
	"fileUploadBackgroundColor": "#2a2a32",
	"fileUploadErrorColor": "#ff4136",
	"fileUploadSuccessColor": "#34c768",
	"fileUploadInProgressColor": "#ff810a",
	"chartAxisLabelColor": "#c4c4cd",
	"totalEquity": "#b14891",
	"filterIconColor": "#909090",
	"gray28": "#282836",
	"gray2e": "#2e2e37",
	"gray3A": "#3a3a4a",
	"skyBlue": "#188ce5",
	"lightBlue": "#178be4",
	"darkBlue": "#8e72ca",
	"white2": "#ccc",
	"brown2": "#464653",
	"blue2": "#a0a0aa",
	"estimatedPatentsStillActive": "#57e188",
	"estimatedPatentsInActive": "#747480",
	"patentFilingsAlternateRowColor": "#23232f",
	"tableCellBorderColor": "#2e2e38",
	"chartSolidLineColor": "#d8d8da",
	"mapMaxColor": "#316bc9",
	"nonCountryJurisdictionLabelColor": "#3e4652",
	"gray80": "#383843",
	"black4": "#2a2b39",
	"white3": "#4c4e5a",
	"gray5": "#919eab",
	"keyword": "#747480",
	"tableCellBackgroundHover": "#1e1f31",
	"valueOfTransactionsColor": "#35a4e8",
	"numberOfTransactionsColor": "#b14891",
	"valueOfFundingColor": "#ff810a",
	"numberOfFundingColor": "#7f5cc7",
	"networkCountIcon": "#c4c4c4",
	"tooltipColor": "#494956",
	"dnsHealthColor": "#35a4e8",
	"networkSecurityColor": "#922b73",
	"patchingCadenceColor": "#60e6e1",
	"applicationSecurityColor": "#7f5cc7",
	"endpointSecurityColor": "#ff736a",
	"cybersecurityColor": "#ffe600",
	"violetColor": "#714ac3",
	"lightGreen": "#27acaa",
	"orange": "#ff810a",
	"red": "#df362c",
	"green": "#34c668",
	"OthersPieChartColor": "#c4c4cd",
	"acquisitionNumberColor": "#57e188",
	"acquisitionValueColor": "#bf5b14",
	"lightGrayColor": "#e1e1e6",
	"announcedBankruptcy": "#34c868",
	"consolidatedBankruptcy": "#1777cf",
	"dismissedBankruptcy": "#932c73",
	"emergedBankruptcy": "#ff9831",
	"liquidatedBankruptcy": "#e1362c",
	"shadowColor": "#000000b3",
	"volumeOfInvestments": "#168736",
	"valueOfInvestments": "#35a4e8",
	"volumeOfExits": "#c44b45",
	"valueOfExits": "#35a4e8",
	"noOfCompanies": "#57e287",
	"avgEntryValue": "#35a4e8",
	"unleveredFreeCashFlowMarginColor": "#178be4",
	"unleveredFreeCashFlowColor": "#57e188",
	"grossProfitColor": "#35a4e8",
	"valueOfDivestmentsColor": "#ffe600",
	"numberOfDivestmentsColor": "#35a4e8",
	"publicOffering": "#57e287",
	"spinSplit": "#ff9831",
	"mergerAcquisition": "#e1362c",
	"lineChart": "#188ce5",
	"totalRevenueColor": "#003f5c",
	"grossProfitMarginColor": "#35a4e8",
	"EBITDAMarginColor": "#ff736a",
	"valueOfInvestmentColor": "#35a4e8",
	"volumeOfInvestmentColor": "#168736",
	"volumeOfExitsColor": "#c44b45",
	"valueOfExitsColor": "#35a4e8",
	"corpGov": "#34c868",
	"engageManagement": "#1777cf",
	"environment": "#932c73",
	"financialMatters": "#ff9831",
	"financingBankruptcyReorganization": "#e1362c",
	"legalMatters": "#b14891",
	"maMatters": "#8ce8ad",
	"meetingMatters": "#9c82d4",
	"social": "#87d3f2",
	"strategicMatters": "#cb3706",
	"downloadBoxOptionBgColor": "#2e2e3c",
	"alertTriangle": "#ff9831",
	"volumenOfInvestments": "#168736",
	"segmentColor": "#109090",
	"industryColor": "#ff736a",
	"smartMoneyRed": "#f95d54",
	"betaTextColor": "#f95d54",
	"lightGreenDistress": "#95b809",
	"noOfPortfolioCompanies": "#57e287",
	"averageInvestmentValue": "#35a4e8",
	"mergeracquisition": "#a05488",
	"undefinedinvestment": "#e0362c",
	"ipo": "#189d3e",
	"pipe": "#7f5cc7",
	"unattributedfundinground": "#c4c4cd",
	"postipoequity": "#ff9831",
	"angelseed": "#b14891",
	"privateequity": "#1777cf",
	"serieselate": "#66c61c",
	"growthequity": "#ff9a91",
	"seriesa": "#feee95",
	"buyoutlbo": "#9c82d4",
	"seriesb": "#4ebeeb",
	"seriesc": "#a6ef67",
	"secondarytransaction": "#ffb46a",
	"seriesd": "#2ed3b7",
	"peNumberOfTransactions": "#34c668",
	"peValueOfTransactions": "#35a4e8",
	"chartYaxisLabelColor": "#fff",
	"chartXaxisLabelColor": "#fff",
	"chartAxisGridColor": "#464646",
	"combinedColoumnChartColor": "#3a3a4a"
};
export default ___CSS_LOADER_EXPORT___;
