export const outputBySector = {
  theWorldTodayOutPut: [
    {
      scenario: "SSP2-Baseline (7.0)",
      startYear: "2020",
      outPutBySector: [
        {
          name: "Accommodation, food and service activities",
          data: [
            {
              name: "Australia",
              value: 94770.78783180994,
            },
            {
              name: "Canada",
              value: 103059.0702181427,
            },
            {
              name: "China",
              value: 357216.2851113291,
            },
            {
              name: "France",
              value: 196758.3047525149,
            },
            {
              name: "India",
              value: 135905.3506499553,
            },
            {
              name: "Netherlands",
              value: 55635.70234224455,
            },
            {
              name: "Remaining Advanced Economies",
              value: 400414.8971987745,
            },
            {
              name: "Rest of Asia",
              value: 370371.4626557516,
            },
            {
              name: "Rest of the European Union",
              value: 822512.2119010333,
            },
            {
              name: "Rest of the World",
              value: 741705.5155773277,
            },
            {
              name: "United Kingdom",
              value: 233782.4684602199,
            },
            {
              name: "United States of America",
              value: 966636.9038708345,
            },
          ],
        },
        {
          name: "Beverages and Tobacco",
          data: [
            {
              name: "Australia",
              value: 20358.34602275669,
            },
            {
              name: "Canada",
              value: 14196.95912365866,
            },
            {
              name: "China",
              value: 369062.3045067164,
            },
            {
              name: "France",
              value: 49388.17453762332,
            },
            {
              name: "India",
              value: 31182.38161237507,
            },
            {
              name: "Netherlands",
              value: 17258.8385675832,
            },
            {
              name: "Remaining Advanced Economies",
              value: 78517.0258983902,
            },
            {
              name: "Rest of Asia",
              value: 88863.87944771386,
            },
            {
              name: "Rest of the European Union",
              value: 205621.2946004301,
            },
            {
              name: "Rest of the World",
              value: 331416.6895728142,
            },
            {
              name: "United Kingdom",
              value: 52056.33036774163,
            },
            {
              name: "United States of America",
              value: 183781.4363432967,
            },
          ],
        },
        {
          name: "Chemical, rubber, and pharmaceutical manufacturing",
          data: [
            {
              name: "Australia",
              value: 45961.22292976156,
            },
            {
              name: "Canada",
              value: 105272.1547884766,
            },
            {
              name: "China",
              value: 2998607.617652348,
            },
            {
              name: "France",
              value: 210398.3048523332,
            },
            {
              name: "India",
              value: 261753.6983016709,
            },
            {
              name: "Netherlands",
              value: 120212.5464555031,
            },
            {
              name: "Remaining Advanced Economies",
              value: 631324.7567498381,
            },
            {
              name: "Rest of Asia",
              value: 955074.2027297821,
            },
            {
              name: "Rest of the European Union",
              value: 1269109.82134292,
            },
            {
              name: "Rest of the World",
              value: 1120644.237911935,
            },
            {
              name: "United Kingdom",
              value: 211935.9902335559,
            },
            {
              name: "United States of America",
              value: 1237347.353348992,
            },
          ],
        },
        {
          name: "Coal",
          data: [
            {
              name: "Australia",
              value: 67624.89997896661,
            },
            {
              name: "Canada",
              value: 8360.051159243974,
            },
            {
              name: "China",
              value: 292059.3476938701,
            },
            {
              name: "France",
              value: 144.5638796557831,
            },
            {
              name: "India",
              value: 54101.88081430292,
            },
            {
              name: "Netherlands",
              value: 8.29572587590382,
            },
            {
              name: "Remaining Advanced Economies",
              value: 833.0987072797451,
            },
            {
              name: "Rest of Asia",
              value: 66202.01352216881,
            },
            {
              name: "Rest of the European Union",
              value: 27043.10601441241,
            },
            {
              name: "Rest of the World",
              value: 90172.27321971359,
            },
            {
              name: "United Kingdom",
              value: 1505.239191130341,
            },
            {
              name: "United States of America",
              value: 74321.55555577784,
            },
          ],
        },
        {
          name: "Communications",
          data: [
            {
              name: "Australia",
              value: 138651.1619867871,
            },
            {
              name: "Canada",
              value: 156475.6902220768,
            },
            {
              name: "China",
              value: 761194.7092946534,
            },
            {
              name: "France",
              value: 393261.1383931311,
            },
            {
              name: "India",
              value: 98801.7576471784,
            },
            {
              name: "Netherlands",
              value: 128138.7634097747,
            },
            {
              name: "Remaining Advanced Economies",
              value: 641718.7583968082,
            },
            {
              name: "Rest of Asia",
              value: 374253.2709036743,
            },
            {
              name: "Rest of the European Union",
              value: 1413729.963930683,
            },
            {
              name: "Rest of the World",
              value: 964679.8132561989,
            },
            {
              name: "United Kingdom",
              value: 381762.0499750967,
            },
            {
              name: "United States of America",
              value: 1162979.295703239,
            },
          ],
        },
        {
          name: "Construction",
          data: [
            {
              name: "Australia",
              value: 379864.8223148827,
            },
            {
              name: "Canada",
              value: 339484.5634236343,
            },
            {
              name: "China",
              value: 3448853.806491232,
            },
            {
              name: "France",
              value: 475029.6014954414,
            },
            {
              name: "India",
              value: 617961.3188029954,
            },
            {
              name: "Netherlands",
              value: 156427.8676857622,
            },
            {
              name: "Remaining Advanced Economies",
              value: 931936.2152344157,
            },
            {
              name: "Rest of Asia",
              value: 985124.3331205392,
            },
            {
              name: "Rest of the European Union",
              value: 1807716.704882091,
            },
            {
              name: "Rest of the World",
              value: 2283087.926849075,
            },
            {
              name: "United Kingdom",
              value: 540292.2923027884,
            },
            {
              name: "United States of America",
              value: 2618840.376529992,
            },
          ],
        },
        {
          name: "Crops",
          data: [
            {
              name: "Australia",
              value: 25319.55067283759,
            },
            {
              name: "Canada",
              value: 29996.37086387739,
            },
            {
              name: "China",
              value: 1202597.784643596,
            },
            {
              name: "France",
              value: 38424.30615095619,
            },
            {
              name: "India",
              value: 366171.8318054491,
            },
            {
              name: "Netherlands",
              value: 22920.02995571126,
            },
            {
              name: "Remaining Advanced Economies",
              value: 64734.84926937206,
            },
            {
              name: "Rest of Asia",
              value: 425997.4425284542,
            },
            {
              name: "Rest of the European Union",
              value: 176721.3693291511,
            },
            {
              name: "Rest of the World",
              value: 1116700.033087136,
            },
            {
              name: "United Kingdom",
              value: 15913.04508367176,
            },
            {
              name: "United States of America",
              value: 184417.6616879927,
            },
          ],
        },
        {
          name: "Electricity",
          data: [
            {
              name: "Australia",
              value: 44521.18845582596,
            },
            {
              name: "Canada",
              value: 55339.24487986172,
            },
            {
              name: "China",
              value: 693543.5893741844,
            },
            {
              name: "France",
              value: 71181.26599417554,
            },
            {
              name: "India",
              value: 302423.5413883617,
            },
            {
              name: "Netherlands",
              value: 18900.93282825002,
            },
            {
              name: "Remaining Advanced Economies",
              value: 262099.7896374494,
            },
            {
              name: "Rest of Asia",
              value: 281501.9708087565,
            },
            {
              name: "Rest of the European Union",
              value: 412612.0348491626,
            },
            {
              name: "Rest of the World",
              value: 751887.3672574674,
            },
            {
              name: "United Kingdom",
              value: 74945.50908396454,
            },
            {
              name: "United States of America",
              value: 474566.5587960951,
            },
          ],
        },
        {
          name: "Electronics, electricals and machinery",
          data: [
            {
              name: "Australia",
              value: 16775.04987570901,
            },
            {
              name: "Canada",
              value: 21085.05485007319,
            },
            {
              name: "China",
              value: 2349493.549181312,
            },
            {
              name: "France",
              value: 59212.58062093038,
            },
            {
              name: "India",
              value: 67465.46516472553,
            },
            {
              name: "Netherlands",
              value: 44769.63766499305,
            },
            {
              name: "Remaining Advanced Economies",
              value: 403026.6059415822,
            },
            {
              name: "Rest of Asia",
              value: 1095492.224189435,
            },
            {
              name: "Rest of the European Union",
              value: 497037.831696015,
            },
            {
              name: "Rest of the World",
              value: 334801.8957434632,
            },
            {
              name: "United Kingdom",
              value: 74984.67433364183,
            },
            {
              name: "United States of America",
              value: 760396.3856575539,
            },
          ],
        },
        {
          name: "Financial services",
          data: [
            {
              name: "Australia",
              value: 193056.3013192229,
            },
            {
              name: "Canada",
              value: 207935.9473066873,
            },
            {
              name: "China",
              value: 1432592.533699087,
            },
            {
              name: "France",
              value: 293967.9319614987,
            },
            {
              name: "India",
              value: 191837.4695969208,
            },
            {
              name: "Netherlands",
              value: 102064.5522640876,
            },
            {
              name: "Remaining Advanced Economies",
              value: 546109.4797977005,
            },
            {
              name: "Rest of Asia",
              value: 552950.9500120612,
            },
            {
              name: "Rest of the European Union",
              value: 1138618.274856412,
            },
            {
              name: "Rest of the World",
              value: 1003842.018133605,
            },
            {
              name: "United Kingdom",
              value: 414556.7404081902,
            },
            {
              name: "United States of America",
              value: 2753540.671973873,
            },
          ],
        },
        {
          name: "Forestry and fishing",
          data: [
            {
              name: "Australia",
              value: 10183.92773837764,
            },
            {
              name: "Canada",
              value: 19633.42336034819,
            },
            {
              name: "China",
              value: 303102.3374353821,
            },
            {
              name: "France",
              value: 11590.74392296415,
            },
            {
              name: "India",
              value: 76893.00385827264,
            },
            {
              name: "Netherlands",
              value: 1856.894260534982,
            },
            {
              name: "Remaining Advanced Economies",
              value: 38353.80119940716,
            },
            {
              name: "Rest of Asia",
              value: 142117.1066247725,
            },
            {
              name: "Rest of the European Union",
              value: 64953.68303307112,
            },
            {
              name: "Rest of the World",
              value: 168338.9819252444,
            },
            {
              name: "United Kingdom",
              value: 6320.442355841297,
            },
            {
              name: "United States of America",
              value: 39289.10618735092,
            },
          ],
        },
        {
          name: "Gas",
          data: [
            {
              name: "Australia",
              value: 22083.04095438586,
            },
            {
              name: "Canada",
              value: 64858.09455158952,
            },
            {
              name: "China",
              value: 13154.71915805268,
            },
            {
              name: "France",
              value: 222.1427951546426,
            },
            {
              name: "India",
              value: 19883.40299395949,
            },
            {
              name: "Netherlands",
              value: 22060.78317773195,
            },
            {
              name: "Remaining Advanced Economies",
              value: 39468.23942120434,
            },
            {
              name: "Rest of Asia",
              value: 122501.9018829756,
            },
            {
              name: "Rest of the European Union",
              value: 14360.55868946697,
            },
            {
              name: "Rest of the World",
              value: 520490.4518625521,
            },
            {
              name: "United Kingdom",
              value: 11847.04115464086,
            },
            {
              name: "United States of America",
              value: 143606.042253441,
            },
          ],
        },
        {
          name: "Health and human technology",
          data: [
            {
              name: "Australia",
              value: 169281.4899577358,
            },
            {
              name: "Canada",
              value: 250223.0507196862,
            },
            {
              name: "China",
              value: 1088409.423701791,
            },
            {
              name: "France",
              value: 329212.7104110342,
            },
            {
              name: "India",
              value: 156518.5047582651,
            },
            {
              name: "Netherlands",
              value: 115322.0905369876,
            },
            {
              name: "Remaining Advanced Economies",
              value: 835140.3010576122,
            },
            {
              name: "Rest of Asia",
              value: 406884.4828770738,
            },
            {
              name: "Rest of the European Union",
              value: 1248765.150314185,
            },
            {
              name: "Rest of the World",
              value: 1373621.974460109,
            },
            {
              name: "United Kingdom",
              value: 352152.6730172675,
            },
            {
              name: "United States of America",
              value: 2571828.89905683,
            },
          ],
        },
        {
          name: "Livestock",
          data: [
            {
              name: "Australia",
              value: 28160.3140264083,
            },
            {
              name: "Canada",
              value: 25706.87278670618,
            },
            {
              name: "China",
              value: 498727.1962013793,
            },
            {
              name: "France",
              value: 36001.80555456424,
            },
            {
              name: "India",
              value: 142740.9316597516,
            },
            {
              name: "Netherlands",
              value: 15095.69854897834,
            },
            {
              name: "Remaining Advanced Economies",
              value: 63370.76945068074,
            },
            {
              name: "Rest of Asia",
              value: 152926.0398300987,
            },
            {
              name: "Rest of the European Union",
              value: 166562.6795850817,
            },
            {
              name: "Rest of the World",
              value: 462575.5034036283,
            },
            {
              name: "United Kingdom",
              value: 26588.27142947367,
            },
            {
              name: "United States of America",
              value: 211083.9103956343,
            },
          ],
        },
        {
          name: "Non ferrous metals",
          data: [
            {
              name: "Australia",
              value: 53882.59548457453,
            },
            {
              name: "Canada",
              value: 51528.31537536663,
            },
            {
              name: "China",
              value: 1149182.085617718,
            },
            {
              name: "France",
              value: 21029.40736201745,
            },
            {
              name: "India",
              value: 48897.98675178311,
            },
            {
              name: "Netherlands",
              value: 8539.775497522634,
            },
            {
              name: "Remaining Advanced Economies",
              value: 214180.437169939,
            },
            {
              name: "Rest of Asia",
              value: 157117.262434531,
            },
            {
              name: "Rest of the European Union",
              value: 214704.3571507673,
            },
            {
              name: "Rest of the World",
              value: 367019.4500095823,
            },
            {
              name: "United Kingdom",
              value: 70694.50508333749,
            },
            {
              name: "United States of America",
              value: 212233.8949429383,
            },
          ],
        },
        {
          name: "Oil",
          data: [
            {
              name: "Australia",
              value: 14091.55514252938,
            },
            {
              name: "Canada",
              value: 110557.7128452489,
            },
            {
              name: "China",
              value: 190384.5318952296,
            },
            {
              name: "France",
              value: 659.5029966509413,
            },
            {
              name: "India",
              value: 37548.02639110118,
            },
            {
              name: "Netherlands",
              value: 1193.336658202277,
            },
            {
              name: "Remaining Advanced Economies",
              value: 51511.12350229,
            },
            {
              name: "Rest of Asia",
              value: 98932.79193858651,
            },
            {
              name: "Rest of the European Union",
              value: 21304.18079281823,
            },
            {
              name: "Rest of the World",
              value: 1939942.937465365,
            },
            {
              name: "United Kingdom",
              value: 28895.54309965876,
            },
            {
              name: "United States of America",
              value: 303171.0495995755,
            },
          ],
        },
        {
          name: "Other business services",
          data: [
            {
              name: "Australia",
              value: 247322.3977087825,
            },
            {
              name: "Canada",
              value: 237893.5471188136,
            },
            {
              name: "China",
              value: 1201790.868730857,
            },
            {
              name: "France",
              value: 503856.9201981912,
            },
            {
              name: "India",
              value: 200488.234380914,
            },
            {
              name: "Netherlands",
              value: 192615.144182069,
            },
            {
              name: "Remaining Advanced Economies",
              value: 1025473.265439289,
            },
            {
              name: "Rest of Asia",
              value: 527473.3261118347,
            },
            {
              name: "Rest of the European Union",
              value: 2132199.62453938,
            },
            {
              name: "Rest of the World",
              value: 956277.1040111767,
            },
            {
              name: "United Kingdom",
              value: 549369.1279606253,
            },
            {
              name: "United States of America",
              value: 1889824.706879131,
            },
          ],
        },
        {
          name: "Other government services",
          data: [
            {
              name: "Australia",
              value: 220214.1982798001,
            },
            {
              name: "Canada",
              value: 320993.3190210984,
            },
            {
              name: "China",
              value: 1502464.659001472,
            },
            {
              name: "France",
              value: 455060.783612759,
            },
            {
              name: "India",
              value: 199738.8764074694,
            },
            {
              name: "Netherlands",
              value: 160809.1696064037,
            },
            {
              name: "Remaining Advanced Economies",
              value: 1071017.689500574,
            },
            {
              name: "Rest of Asia",
              value: 682671.3110859608,
            },
            {
              name: "Rest of the European Union",
              value: 1749656.165758049,
            },
            {
              name: "Rest of the World",
              value: 1844202.62989907,
            },
            {
              name: "United Kingdom",
              value: 476754.9942422602,
            },
            {
              name: "United States of America",
              value: 3178072.229298583,
            },
          ],
        },
        {
          name: "Other manufacturing",
          data: [
            {
              name: "Australia",
              value: 204175.8054162405,
            },
            {
              name: "Canada",
              value: 376565.7181966425,
            },
            {
              name: "China",
              value: 11438447.41430954,
            },
            {
              name: "France",
              value: 676407.439936129,
            },
            {
              name: "India",
              value: 1108067.883985471,
            },
            {
              name: "Netherlands",
              value: 237870.2852556481,
            },
            {
              name: "Remaining Advanced Economies",
              value: 2249724.608914338,
            },
            {
              name: "Rest of Asia",
              value: 2747221.472158701,
            },
            {
              name: "Rest of the European Union",
              value: 4737625.000261695,
            },
            {
              name: "Rest of the World",
              value: 3564193.601166569,
            },
            {
              name: "United Kingdom",
              value: 648454.8348617016,
            },
            {
              name: "United States of America",
              value: 4780426.546352582,
            },
          ],
        },
        {
          name: "Other mining",
          data: [
            {
              name: "Australia",
              value: 138388.2686310726,
            },
            {
              name: "Canada",
              value: 40135.07749327723,
            },
            {
              name: "China",
              value: 476489.170019734,
            },
            {
              name: "France",
              value: 8604.603792848227,
            },
            {
              name: "India",
              value: 16718.89385943925,
            },
            {
              name: "Netherlands",
              value: 3107.807138720806,
            },
            {
              name: "Remaining Advanced Economies",
              value: 55909.51821979287,
            },
            {
              name: "Rest of Asia",
              value: 90865.55078555104,
            },
            {
              name: "Rest of the European Union",
              value: 95830.23610910386,
            },
            {
              name: "Rest of the World",
              value: 357321.1090411462,
            },
            {
              name: "United Kingdom",
              value: 13020.91047047654,
            },
            {
              name: "United States of America",
              value: 61365.26236206804,
            },
          ],
        },
        {
          name: "Petroleum and coal products",
          data: [
            {
              name: "Australia",
              value: 33864.62884822104,
            },
            {
              name: "Canada",
              value: 96982.4631705076,
            },
            {
              name: "China",
              value: 830441.2212979553,
            },
            {
              name: "France",
              value: 60197.9004293296,
            },
            {
              name: "India",
              value: 343398.2556371395,
            },
            {
              name: "Netherlands",
              value: 67740.33158992573,
            },
            {
              name: "Remaining Advanced Economies",
              value: 267190.2389414682,
            },
            {
              name: "Rest of Asia",
              value: 488019.0200253475,
            },
            {
              name: "Rest of the European Union",
              value: 501526.828652567,
            },
            {
              name: "Rest of the World",
              value: 1143192.880919792,
            },
            {
              name: "United Kingdom",
              value: 67667.02362698017,
            },
            {
              name: "United States of America",
              value: 728890.5885706216,
            },
          ],
        },
        {
          name: "Real estate activities",
          data: [
            {
              name: "Australia",
              value: 233431.763814127,
            },
            {
              name: "Canada",
              value: 232510.0769988678,
            },
            {
              name: "China",
              value: 1040933.77283465,
            },
            {
              name: "France",
              value: 428759.7131892343,
            },
            {
              name: "India",
              value: 206319.6116597807,
            },
            {
              name: "Netherlands",
              value: 118501.1903589113,
            },
            {
              name: "Remaining Advanced Economies",
              value: 897292.9469563201,
            },
            {
              name: "Rest of Asia",
              value: 554221.012507961,
            },
            {
              name: "Rest of the European Union",
              value: 1556894.567078783,
            },
            {
              name: "Rest of the World",
              value: 1293767.160430427,
            },
            {
              name: "United Kingdom",
              value: 539448.0535376825,
            },
            {
              name: "United States of America",
              value: 2348224.651650968,
            },
          ],
        },
        {
          name: "Recreational and other services",
          data: [
            {
              name: "Australia",
              value: 86648.2851544164,
            },
            {
              name: "Canada",
              value: 61769.01191474636,
            },
            {
              name: "China",
              value: 637611.7534052753,
            },
            {
              name: "France",
              value: 146321.0601377708,
            },
            {
              name: "India",
              value: 12580.35885077611,
            },
            {
              name: "Netherlands",
              value: 39143.7663236406,
            },
            {
              name: "Remaining Advanced Economies",
              value: 392488.8451704039,
            },
            {
              name: "Rest of Asia",
              value: 416197.952311287,
            },
            {
              name: "Rest of the European Union",
              value: 727875.41842768,
            },
            {
              name: "Rest of the World",
              value: 637020.8845798438,
            },
            {
              name: "United Kingdom",
              value: 182355.3214652278,
            },
            {
              name: "United States of America",
              value: 1700883.606149057,
            },
          ],
        },
        {
          name: "Rest of food manufacturing",
          data: [
            {
              name: "Australia",
              value: 90231.7350512455,
            },
            {
              name: "Canada",
              value: 106585.5401820198,
            },
            {
              name: "China",
              value: 1449027.305038065,
            },
            {
              name: "France",
              value: 213934.537721796,
            },
            {
              name: "India",
              value: 275314.7477701044,
            },
            {
              name: "Netherlands",
              value: 93688.95171770086,
            },
            {
              name: "Remaining Advanced Economies",
              value: 389378.9400260705,
            },
            {
              name: "Rest of Asia",
              value: 711308.9050372475,
            },
            {
              name: "Rest of the European Union",
              value: 880563.1921713404,
            },
            {
              name: "Rest of the World",
              value: 1716479.471801399,
            },
            {
              name: "United Kingdom",
              value: 131009.5032298064,
            },
            {
              name: "United States of America",
              value: 909126.5030860647,
            },
          ],
        },
        {
          name: "Trade",
          data: [
            {
              name: "Australia",
              value: 258225.3630017453,
            },
            {
              name: "Canada",
              value: 279222.2469947122,
            },
            {
              name: "China",
              value: 1593178.621836995,
            },
            {
              name: "France",
              value: 484101.1168504381,
            },
            {
              name: "India",
              value: 495933.3790485013,
            },
            {
              name: "Netherlands",
              value: 139400.9639626381,
            },
            {
              name: "Remaining Advanced Economies",
              value: 1344458.090237798,
            },
            {
              name: "Rest of Asia",
              value: 1213708.482997802,
            },
            {
              name: "Rest of the European Union",
              value: 2201099.833215382,
            },
            {
              name: "Rest of the World",
              value: 2418059.528695383,
            },
            {
              name: "United Kingdom",
              value: 521456.9964318539,
            },
            {
              name: "United States of America",
              value: 2926321.98343224,
            },
          ],
        },
        {
          name: "Transport",
          data: [
            {
              name: "Australia",
              value: 190442.8790108889,
            },
            {
              name: "Canada",
              value: 128280.8442622409,
            },
            {
              name: "China",
              value: 1489984.547946416,
            },
            {
              name: "France",
              value: 269345.2877565362,
            },
            {
              name: "India",
              value: 439162.3329251981,
            },
            {
              name: "Netherlands",
              value: 117557.2318585374,
            },
            {
              name: "Remaining Advanced Economies",
              value: 855407.618431374,
            },
            {
              name: "Rest of Asia",
              value: 896892.6813216815,
            },
            {
              name: "Rest of the European Union",
              value: 1614578.276273399,
            },
            {
              name: "Rest of the World",
              value: 1641039.704537397,
            },
            {
              name: "United Kingdom",
              value: 316795.1796158255,
            },
            {
              name: "United States of America",
              value: 1261398.248126141,
            },
          ],
        },
        {
          name: "Water",
          data: [
            {
              name: "Australia",
              value: 27757.97813122087,
            },
            {
              name: "Canada",
              value: 57546.31454045553,
            },
            {
              name: "China",
              value: 235631.323973854,
            },
            {
              name: "France",
              value: 40670.59519832842,
            },
            {
              name: "India",
              value: 47895.08937315639,
            },
            {
              name: "Netherlands",
              value: 15351.53036627646,
            },
            {
              name: "Remaining Advanced Economies",
              value: 132535.348519979,
            },
            {
              name: "Rest of Asia",
              value: 92517.85396549767,
            },
            {
              name: "Rest of the European Union",
              value: 220963.5814627254,
            },
            {
              name: "Rest of the World",
              value: 285717.0711305355,
            },
            {
              name: "United Kingdom",
              value: 93343.41090437568,
            },
            {
              name: "United States of America",
              value: 391010.3344481595,
            },
          ],
        },
      ],
    },
  ],
};
