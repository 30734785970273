import { MotifButton } from "@ey-xd/motif-react";
import React from "react";
import styled from "styled-components";
import { INTRO_PAGE } from "../../../../app.labels";

interface IOwnProps {
  onClickHandler: () => void;
}

const Intro: React.FC<IOwnProps> = ({ onClickHandler }: IOwnProps) => {
  return (
    <StyledIntro>
      <MotifButton
        onClick={onClickHandler}
        size="large"
        className="handler-btn"
      >
        {INTRO_PAGE.buttonLabel}
      </MotifButton>
    </StyledIntro>
  );
};

const StyledIntro = styled.div`
  position: relative;
  height: 100vh;
  cursor: pointer;
  /* The below fixes unwanted y-scrollbar in Edge */
  overflow: hidden;
  background: #1a1a24;

  .handler-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default Intro;
