import React from 'react';
import styled from 'styled-components';

interface IOwnProps {
    color?: string;
    height?: string | number;
}

const LoadIndicator: React.FC<IOwnProps> = ({ color = '#fff', height = 'auto' }: IOwnProps) => {
    return (
        <StyledLoader.Wrapper height={height} className="loadingIndicator">
            <StyledLoader.Indicator color={color}>
                <StyledLoader.IndicatorChild />
                <StyledLoader.IndicatorChild />
                <StyledLoader.IndicatorChild />
            </StyledLoader.Indicator>
        </StyledLoader.Wrapper>
    );
};

const StyledLoader = {
    Wrapper: styled.div<{ height: number | string }>`
        height: ${(props) =>
            typeof props.height === 'string' ? props.height : props.height + 'px'};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    Indicator: styled.div<{ color: string }>`
        color: ${(props) => props.color};
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    `,
    IndicatorChild: styled.div`
        display: inline-block;
        position: absolute;
        left: 6px;
        width: 13px;
        background: currentColor;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

        &:nth-child(1) {
            left: 6px;
            animation-delay: -0.24s;
        }
        &:nth-child(2) {
            left: 26px;
            animation-delay: -0.12s;
        }
        &:nth-child(3) {
            left: 45px;
            animation-delay: 0;
        }

        @keyframes lds-facebook {
            0% {
                top: 6px;
                height: 51px;
            }
            50%,
            100% {
                top: 19px;
                height: 26px;
            }
        }
    `
};

export default LoadIndicator;
