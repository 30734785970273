import { LoopReducer, Loop } from 'redux-loop';
import { AnyAction } from 'redux';
import { IMapCountryIsoCode } from '../../models/IMapGdp';
import { SET_COUNTRY_LIST_BY_REGION } from '../actions/CommonAction';

export type ICommonState = {
    countryListByRegion:Array<IMapCountryIsoCode>
};

export const initialState: ICommonState = {
    countryListByRegion:[]
};

export const CommonReducer: LoopReducer<ICommonState> = (
    state: ICommonState = initialState,
    action: AnyAction
): ICommonState | Loop<ICommonState, AnyAction> => {
    switch (action.type) {
        case SET_COUNTRY_LIST_BY_REGION:
            return {
                ...state,
                countryListByRegion: action.value
            };

        default:
            return state;
    }
};