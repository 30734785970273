import React from 'react';
import { IGenericObject } from '../../models/IGenericObject';
import { QueryKeyEnum } from '../../models/IRouterManager';

export interface IRouteManagerProps {
    routeParams: IGenericObject<string | undefined>;
    [QueryKeyEnum.Query]: string;
    location: Location;
}

export interface IRouteManagerContext {
    routeTo: (
        paramsForReplace: {
            [key: string]: string | boolean | number | undefined;
        },
        queryString?: string
    ) => void;
}

export const RouteManagerContext = React.createContext<IRouteManagerContext | null>(null);
