import { AxiosError } from 'axios';
import { IUserTenant } from '../models/IUserTenant';
import { apiRoutes, browserHistory, errorStatus, get, getAuthToken, post } from './api';
import { store } from '../store';
import { appRoutes } from '../app.labels';
import { IGenericObject } from '../models/IGenericObject';

let cache: any = {};

const fomatted = (response) => {
    return response.reduce(
        (accumulator, current) =>
            // Check to see if the response is fulfilled
            current?.status === 'fulfilled'
                ? {
                      // If it is, then spread out the response
                      ...accumulator,
                      ...(typeof current?.value === 'string'
                          ? { image: current.value }
                          : current.value)
                  }
                : { ...accumulator },
        {}
    );
};

export const getUserProfile = async (): Promise<any> => {
    try {
        const response = await Promise.allSettled([
            await getAuthToken()
            //   await getProfilePicture(),
        ]);
        return fomatted(response);
    } catch (err) {
        throw err;
    }
};

export const getUserTenant = (): Promise<Array<IUserTenant>> => {
    return get(apiRoutes.tenantDetails)
        .then((tenants) => {
            return tenants?.tenantDetails;
        })
        .catch((error: AxiosError) => {
            if (
                error.response?.status !== errorStatus.sessionTimeOut &&
                error.response?.status !== errorStatus.unavailable
            ) {
                if (error.response?.status === errorStatus.blocked) {
                    browserHistory.push(`/${appRoutes.errorPage}`);
                } else {
                    browserHistory.push(`/${appRoutes.unauthorizedaccess}`);
                }
            }
        });
};

export const getUserRole = (): Promise<Array<IUserTenant>> => {
    return get(apiRoutes.userRoles)
        .then((tenantRoles) => {
            return tenantRoles?.tenantRoles;
        })
        .catch((error: AxiosError) => {
            if (
                error.response?.status != errorStatus.sessionTimeOut &&
                error.response?.status != errorStatus.unauthorized &&
                error.response?.status != errorStatus.unavailable
            ) {
                const tenantName = store?.getState()?.userProfile?.userTenant?.tenantName;
                browserHistory.push(`/${tenantName}/${appRoutes.error}`);
            }
        });
};

export const fetchLogout = async (): Promise<any> => {
    return post(apiRoutes.logout, {})
        .then((response) => {
            return response;
        })
        .catch((error: AxiosError) => {
            return error;
        });
};

export const getPrivacyPolicyContent = (policyType): Promise<IGenericObject> => {
    let queryParams = { PolicyType: policyType };
    const cacheKey = JSON.stringify({ queryParams, url: apiRoutes.getPrivacyPolicy });
    let cacheData = cache[cacheKey];
    if (cacheData) {
        return new Promise((resolve) => {
            setTimeout(() => {
                return resolve(cacheData);
            }, 0);
        });
    }
    return get(apiRoutes.getPrivacyPolicy, queryParams)
        .then((response) => {
            cache[cacheKey] = response;
            return response;
        })
        .catch((error: AxiosError) => {
            const { response } = error;
            return response;
        });
};

export const acceptPrivacyPolicy = (): Promise<IGenericObject> => {
    return post(apiRoutes.acceptPrivacy, {})
        .then((response) => {
            return response;
        })
        .catch((error: AxiosError) => {
            const { response } = error;
            return response;
        });
};
