import { TABNAV_INPUT_ICON_SMALL, TABNAV_RESULT_ICON_SMALL } from './image.constant';
import { ITabNav } from './models/ITabNav';
import { IWidgetProps } from './models/IWidgetProps';
import { ChartUnit } from './common/enum/chart';
import { Chart } from 'highcharts';

export const APP_HEADER_NAME = 'EY Climate Economics Model';
export const EY_LOGO_HOME = 'EY Logo - Home';
export const NOT_AVAILABLE = 'N/A';
export const REGION = 'Region';
export const SCENARIO = 'Scenario';
export const FLEX_1 = 'flex-1';
export const FLEX_2 = 'flex-2';
export const FLEX_3 = 'flex-3';
export const FLEX_4 = 'flex-4';
export const FLEX_ITEM = 'flex-item';
export const GLOBAL_COLOR_CODE = '#9F1AB1';
export const REST_OF_THE_WORLD_COLOR_CODE = '#EEAAFD';
export const REST_OF_THE_WORLD_TEXT = 'rest of the world';
export const COMMON_WIDGET_HEIGHT = '510px'; //this is the default height given in figma
export const DEFAULT_CHART_HEIGHT = 350;
export const CHART_HEIGHT_370 = 370;
export const CHART_HEIGHT_490 = 490;
export const CHART_HEIGHT_510 = 510;
export const WIDGET_HEIGHT_570PX = '570px';
export const SPLINE_CHART_HEIGHT = DEFAULT_CHART_HEIGHT;
export const WIDGET_HEIGHT_530PX = '530px';
export const WIDGET_HEIGHT_600PX = '600px';
export const WIDGET_HEIGHT_650PX = '650px';
export const WIDGET_HEIGHT_700PX = '700px';
export const TENANT_START_BTN_TEXT = 'Start';
export const SEARCH_BAR_PLACEHOLDER = 'Search';
export const SSP2_CGE = 'SSP2 - CGE';
export const GLOBAL = 'Global';

export const SELECT_SCENARIO = 'Select Scenario';
export const SELECT_BASELINE = 'Select Baseline';
export const INITIAL_BASELINE = 'SSP2-Baseline (7.0)';
export const FILTER_LABELS = {
    SELECT_ALL: 'Select All',
    ALL: 'All'
};

export const CHART_DOWNLOAD_LIBRARY = {
    title: 'Chart Library'
};

export const PROFILE_CARD_MENU = {
    help: 'Help',
    contactUs: 'Contact us',
    logOut: 'Logout',
    modalHeaderText: 'Logout',
    modalActionButtonText: 'Logout',
    modalCancelButtonText: 'Close'
};
export const SIDEBAR = {
    dashboard: 'Dashboard',
    download: 'Download'
};
export const WIDGET_OPTIONS = {
    download: 'Download',
    maximize: 'Maximize',
    minimize: 'Minimize',
    noDataAvailableScenario: 'No Data Available for Scenario View',
    noDataAvailable: 'No Data Available'
};
export const EMISSION_INTENSITY_BY_COUNTRY = {
    headerText: 'Emissions intensity',
    height: '445px',
    flexType: 'flex-item flex-3',
    dataTestId: 'emissions-intensity-by-country-chart',
    yAxisTitle: ChartUnit.tCo2eq_Mgdp,
    chartHeight: 285,
    unit: ChartUnit.tCo2eq_Mgdp
};
export const TOTAL_EMISSIONS_BY_COUNTRY = {
    headerText: 'Emissions',
    height: '445px',
    flexType: 'flex-item flex-3',
    dataTestId: 'total-emissions-by-country-chart',
    chartHeight: 285,
    unit: ChartUnit.mtCo2eq
};
export const EMISSIONS_BY_SCTORS = {
    headerText: 'Emissions by sector',
    height: WIDGET_HEIGHT_700PX,
    flexType: 'flex-item flex-1',
    dataTestId: 'emissions-by-sectors-chart',
    sectorsLabel: 'Select Sector'
};
export const EMISSION_PER_CAPITA = {
    headerText: 'Emissions per capita',
    yAxisTitle: ChartUnit.tCo2eq_pop,
    legends: ['Start Year', 'Mid Point', 'End Point'],
    flexType: 'flex-item flex-3',
    dataTestId: 'emissions-per-capita-chart',
    regionPropertyName: 'emissionsByCountry',
    scenarioPropertyName: 'emissionsPerCapitaScenario',
    height: '643px',
    chartHeight: 590,
    hoverUnit: ChartUnit.tCo2eq_pop
};
export const EMISSION_INTENSITY_BY_SECTOR = {
    chart_text: '',
    select_sector: 'Select Sector',
    flexType: 'flex-item flex-1',
    chartHeight: 400,
    headerText: 'Emissions intensity by sector',
    yAxisTitle: ChartUnit.tCo2eq_million_output,
    emissionIntensityPropertyName: 'emissionByCountry',
    unit: ChartUnit.tCo2eq_million_output
};
export const EMISSION_LANDING_PAGE = {
    apply: 'Apply',
    scenario: 'Scenario',
    baselineOptions: 'Baseline Options',
    reset: 'Reset'
};
export const MODELLING_INPUT = {
    pageDescription: `Emissions intensity by sector is each ton of carbon dioxide equivalent emitted. Carbon prices act as the main climate policy mechanism in the model and represent the cost of all policy action (tariffs, subsidies and taxes). Under each scenario, the model determines the carbon price that best fits the individual region's economy and emissions pathway.`
};
export const MODELLING_INPUT_BASELINE_GDP_PATHWAY = {
    headerText: 'Baseline GDP pathway',
    yAxisTitle: ChartUnit.gdp,
    chartHeight: DEFAULT_CHART_HEIGHT,
    height: COMMON_WIDGET_HEIGHT,
    propertyName: 'groupByRegion',
    dataTestId: 'modelling_input_baseline_gdp_pathway',
    emptyChartHeight: 530,
    flexType: 'flex-item flex-2',
    unit: ChartUnit.gdp,
    tableUnit: ChartUnit.gdp
};
export const MODELLING_INPUT_BASELINE_POPULATION_PATHWAY = {
    headerText: 'Baseline population pathway',
    yAxisTitle: ChartUnit.people,
    chartHeight: 370,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-2',
    propertyName: 'groupByRegion',
    dataTestId: 'modelling_input_baseline_population_pathway',
    unit: ChartUnit.million,
    tableUnit: ChartUnit.people
};
export const CHANGE_IN_EMISSIONS = {
    headerText: 'Change in emissions over the modeling  period',
    yAxisTitle: ChartUnit.percentage,
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 390,
    genericPropertyName: 'changeInEmissionsByStartDate',
    propertyName: 'groupByRegion',
    dataTestId: 'change_in_emissions_from_start_Date',
    unit: ChartUnit.percentage,
    tableUnit: ChartUnit.mtCo2eq,
    select_policy: 'Select Policy'
};
export const MODELLING_INPUT_BASELINE_SHARE_GDP = {
    headerText: 'Baseline share of GDP',
    yAxisTitle: ChartUnit.million,
    chartHeight: 395,
    height: COMMON_WIDGET_HEIGHT,
    propertyName: 'groupByRegion',
    dataTestId: 'modelling_input_baseline_share_gdp',
    genericPropertyName: 'gdpShare',
    regionalPropertyName: 'groupByYear',
    scenarioPropertyName: 'groupByYear',
    select_year: 'Select Year',
    tableUnit: ChartUnit.gdp,
    flexType: 'flex-item flex-1'
};
export const MODELLING_INPUT_BASELINE_TOTAL_EMISSIONS = {
    headerText: 'Baseline Total Emissions',
    yAxisTitle: ChartUnit.mtCo2eq,
    chartHeight: 473,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-4',
    dataTestId: 'modelling_input_baseline_total_emissions'
};
export const MODELLING_INPUT_POLICY_TOTAL_EMISSIONS = {
    headerText: 'Policy Total Emissions',
    yAxisTitle: ChartUnit.mtCo2eq,
    chartHeight: 473,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-4',
    dataTestId: 'modelling_input_policy_total_emissions'
};

export const MODELLING_INPUT_SHARE_OF_POPULATION = {
    headerText: 'Baseline share of population',
    chartHeight: 370,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    dataTestId: 'share-of-population-id',
    select_year: 'Select Year',
    genericPropertyName: 'populationShare',
    regionalPropertyName: 'groupByYear'
};

export enum appRoutes {
    overview = 'overview',
    download = 'download',
    theworldtoday = 'theworldtoday',
    modellinginputs = 'modellinginputs',
    emissions = 'emissions',
    keyindicators = 'keyindicators',
    sectoralimpacts = 'sectoralimpacts',
    unauthorized = 'unauthorized',
    error = 'error',
    unauthorizedaccess = 'unauthorizedaccess',
    errorPage = 'error-page'
}

export const APPLICATION_TABNAV_HEADERS: Array<ITabNav> = [
    { tabName: 'Overview', tabIcon: '', pageName: appRoutes.overview },
    {
        tabName: 'The World Today',
        tabIcon: TABNAV_INPUT_ICON_SMALL,
        pageName: appRoutes.theworldtoday
    },
    {
        tabName: 'Modeling Inputs',
        tabIcon: TABNAV_INPUT_ICON_SMALL,
        pageName: appRoutes.modellinginputs
    },
    {
        tabName: 'Emissions',
        tabIcon: TABNAV_RESULT_ICON_SMALL,
        pageName: appRoutes.emissions
    },
    {
        tabName: 'Key Indicators',
        tabIcon: TABNAV_RESULT_ICON_SMALL,
        pageName: appRoutes.keyindicators
    },
    {
        tabName: 'Sectoral Impacts',
        tabIcon: TABNAV_RESULT_ICON_SMALL,
        pageName: appRoutes.sectoralimpacts
    }
];

/* Key Indictors */
export const GDP_PER_CAPITA = {
    headerText: 'GDP per capita',
    yAxisTitle: ChartUnit.gdp_pop,
    legends: ['Start Year', 'Mid Point', 'End Year'],
    flexType: 'flex-item flex-4',
    dataTestId: 'gdp-per-capita-chart',
    regionPropertyName: 'groupByRegion',
    scenarioPropertyName: 'gdpPerCapitaScenario',
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 370,
    hoverUnit: ChartUnit.gdp_pop
};

export const CARBON_PRICES = {
    headerText: 'Carbon prices',
    yAxisTitle: ChartUnit.tCo2eq_dollar,
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 370,
    flexType: 'flex-item flex-3',
    dataTestId: 'carbon-prices-chart',
    scenarioPropertyName: 'carbonPricesByScenario',
    regionPropertyName: 'groupByRegion',
    unit: ChartUnit.tCo2eq_dollar
};

export const TOTAL_EMISSIONS_BY_COUNTRY_TABLE_VIEW = {
    headerText: 'Table View',
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-3',
    dataTestId: 'total-emissions-by-country-chart-table-view'
};

export const RETURN_ON_CAPITAL = {
    headerText: 'Return on capital',
    yAxisTitle: ChartUnit.return_on_capital_index + ' ' + ChartUnit.start_year_100,
    unit: ChartUnit.index,
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 370,
    flexType: 'flex-item flex-1',
    dataTestId: 'return-on-capital-chart'
};

export const TOTAL_CARBON_TAX_BY_GDP = {
    headerText: 'Total value of carbon tax / GDP',
    yAxisTitle: ChartUnit.percentage,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-4',
    chartHeight: 370,
    dataTestId: 'total-carbon-tax-by-gdp-chart',
    regionPropertyName: 'groupByRegion',
    scenarioPropertyName: 'totalCarbonTaxGDPScenario',
    unit: ChartUnit.percentage
};

export const EXCHANGE_RATES = {
    headerText: 'Exchange rates',
    yAxisTitle: ChartUnit.exchange_rate_index + ' ' + ChartUnit.start_year_100,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    chartHeight: 370,
    dataTestId: 'exchange-rates-chart',
    unit: ChartUnit.index
};

export const GNI_DEVIATION = {
    headerText: 'GNI deviation',
    yAxisTitle: ChartUnit.percentage,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-4',
    chartHeight: 370,
    dataTestId: 'gni-deviation-chart',
    unit: ChartUnit.percentage
};

export const GDP_DEVIATION = {
    headerText: 'GDP deviation',
    yAxisTitle: ChartUnit.percentage,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-4',
    chartHeight: 370,
    dataTestId: 'gdp-deviation-chart',
    unit: ChartUnit.percentage
};

export const PEOPLE_EMPLOYMENT = {
    headerText: 'Employment (People)',
    yAxisTitle: 'People',
    unit: ChartUnit.million,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    chartHeight: CHART_HEIGHT_370,
    xAxisTitle: 'Year',
    dataTestId: 'people-employment-chart',
    propertyName: 'employmentByCountry'
};

export const GDP = {
    headerText: 'GDP',
    flexType: 'flex-item flex-4',
    yAxisTitle: ChartUnit.gdp,
    xAxisTitle: ChartUnit.gdp,
    chartHeight: CHART_HEIGHT_370,
    height: WIDGET_HEIGHT_530PX,
    regionPropertyName: 'groupByRegion',
    scenarioPropertyName: 'gdpScenario',
    genericProperty: 'groupByRegion',
    dataTestId: 'gdp-chart',
    unit: ChartUnit.gdp,
    tableUnit: ChartUnit.gdp
};

export const TRADES = {
    headerText: 'Trade',
    flexType: 'flex-3',
    yAxisTitle: '',
    xAxisTitle: '',
    chartHeight: 580,
    height: '700px',
    genericProperty: 'exportsCountryByCountry',
    dataTestId: 'trades-chart',
    tooltipText: ChartUnit.million_dollar,
    tableUnit: ChartUnit.dollarSymbol
};

export const GNI = {
    headerText: 'GNI',
    flexType: 'flex-item flex-4',
    yAxisTitle: ChartUnit.gni_dollar,
    chartHeight: CHART_HEIGHT_370,
    height: WIDGET_HEIGHT_530PX,
    genericProperty: 'gniByRegion',
    regionPropertyName: 'gniByRegion',
    scenarioPropertyName: 'gniByScenario',
    dataTestId: 'gni-chart',
    unit: ChartUnit.gni_dollar,
    tableUnit: ChartUnit.gni_dollar
};

/* Sectorial Impacts */
export const SECTORIAL_IMPACT_OUTPUT = {
    chart_text: '',
    headerText: 'Output',
    yAxisTitle: ChartUnit.output_dollar,
    flexType: 'flex-item flex-1',
    propertyName: 'sectoralImpactsOutPutBySectors',
    dataTestId: 'sectorial-impact-output-chart-view',
    height: WIDGET_HEIGHT_530PX
};

export const SECTORIAL_IMPACT_GVA = {
    chart_text: '',
    headerText: 'GVA',
    yAxisTitle: ChartUnit.gva_dollar,
    flexType: 'flex-item flex-1',
    propertyName: 'sectoralImpactsGVABySectors',
    dataTestId: 'sectorial-impact-gva-chart-view',
    url: 'gva',
    height: WIDGET_HEIGHT_530PX
};

export const POLICY_TOTAL_EMISSIONS = {
    select_policy: 'Select Policy',
    headerText: 'Policy total emissions',
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 294,
    emptyChartHeight: 390,
    flexType: 'flex-item flex-4',
    dataTestId: 'policy-total-emissions',
    propertyName: 'groupByRegion',
    yAxisTitle: ChartUnit.mtCo2eq
};

export const BASE_TOTAL_EMISSIONS = {
    headerText: 'Baseline total emissions',
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-4',
    dataTestId: 'baseline-total-emissions',
    propertyName: 'groupByRegion',
    yAxisTitle: ChartUnit.mtCo2eq,
    chartHeight: 355,
    hoverUnit: ChartUnit.mtCo2eq
};

export const WAGES = {
    headerText: 'Wages',
    yAxisTitle: ChartUnit.wages_index + ' ' + ChartUnit.start_year_100,
    unit: ChartUnit.index,
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 370,
    flexType: 'flex-item flex-1',
    dataTestId: 'wages-chart',
    scenarioPropertyName: 'wagesScenario',
    regionPropertyName: 'groupByRegion'
};

export const UNEMPLOYMENT_RATE = {
    headerText: 'Unemployment rate',
    yAxisTitle: ChartUnit.unemployment_index + ' ' + ChartUnit.start_year_100,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    chartHeight: 370,
    dataTestId: 'unemployment-rate-chart',
    regionPropertyName: 'groupByRegion',
    scenarioPropertyName: 'unEmploymentScenario',
    unit: ChartUnit.percentage
};

export const FILTER_VIEWS = {
    regional: 'Regional View',
    scenario: 'Scenario View',
    sector: 'Sector View'
};

export const FILTER_DROPDOWN_TITLE = {
    regions: 'Regions',
    sectors: 'Sectors',
    scenario: 'Scenario'
};

export const BUTTONS = {
    clearAll: 'Clear All',
    close: 'Close'
};

export const CARBON_TAX: IWidgetProps = {
    headerText: 'Carbon Tax as % of all Taxes',
    yAxisTitle: ChartUnit.percentage,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    chartHeight: 420,
    xAxisTitle: ChartUnit.regions,
    dataTestId: 'carbon-tax-chart',
    propertyName: 'carbonTaxPercentageByYear',
    url: 'carbonTaxPerPercentage',
    scenarioUrl: 'carbonTaxByYearScenario',
    scenarioPropertyName: 'carbonTaxByYearScenario',
    unit: ChartUnit.percentage
};

export const FOSSIL_FUEL_TAX: IWidgetProps = {
    headerText: 'Fossil fuel as % of total fuel',
    yAxisTitle: ChartUnit.fuel_percentage,
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    chartHeight: 420,
    xAxisTitle: ChartUnit.regions,
    dataTestId: 'fossil-fuel-chart',
    propertyName: 'fossilFuelPercentageByYear',
    url: 'fossilFuelPercentageByScenario',
    scenarioUrl: 'fossilFuelsByYearScenario',
    scenarioPropertyName: 'fossilFuelsByYearScenario',
    unit: ChartUnit.percentage
};
export const GNI_PER_CAPITA = {
    headerText: 'GNI Per Capita',
    yAxisTitle: ChartUnit.gni_dollar,
    legends: ['Start Year', 'Mid Point', 'End Year'],
    flexType: 'flex-item flex-1',
    dataTestId: 'gni-per-capita-chart',
    regionPropertyName: 'gniPerCapitaByCountry',
    scenarioPropertyName: 'gniPerCapitaByScenario',
    height: WIDGET_HEIGHT_530PX,
    chartHeight: 470
};
export const INVESTMENT = {
    headerText: 'Investment',
    height: WIDGET_HEIGHT_530PX,
    flexType: 'flex-item flex-1',
    chartHeight: 260,
    dataTestId: 'investment-id',
    select_year: 'Select Year',
    genericPropertyName: 'investment',
    regionalPropertyName: 'groupByYear',
    tableUnit: ChartUnit.dollarSymbol
};

export const INTRO_PAGE = {
    buttonLabel: 'Begin Your Climate Economics Model Journey'
};

export const GLOBAL_ERROR_BOUNDARY_MESSAGE = {
    title: 'Something Went Wrong.',
    description: 'Please try again or report an issue to support'
};

export const EDIT_TEXT_AREA = {
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    save_warning_message: "Content can't be empty"
};

export const MODAL = {
    DEFAULT_ACTION_BUTTON_TEXT: 'Confirm',
    DEFAULT_CANCEL_BUTTON_TEXT: 'Cancel'
};

export const OVERVIEW_BUTTONS = {
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit'
};

export const SECTORAL_IMPACT_TRADES = {
    headerText: 'Trade',
    flexType: 'flex-3',
    yAxisTitle: '',
    xAxisTitle: '',
    chartHeight: 580,
    height: '700px',
    genericProperty: 'exportsByRegion',
    dataTestId: 'sectoral-trades-chart',
    tooltipText: ChartUnit.million_dollar,
    tableUnit: ChartUnit.dollarSymbol
};

export const ONE_MILLION = 1000000;

export const DOWNLOAD_CHART = {
    NO_RESULT_FOUND: 'No Result Found',
    DOWNLOAD: 'Download',
    DOWNLOAD_REPORT_DESC: 'Please fill in below details to intiate the download',
    DOWNLOAD_REPORT: 'Download Report',
    CANCEL: 'Cancel',
    SELECT_SCENARIO: 'Select Scenario',
    CHART_NAME: 'Chart Name'
};
