import React, { useEffect, useState } from 'react';
import ModalComponent, { ModalSize } from '../modal';
import { MotifButton, MotifInlineMessage } from '@ey-xd/motif-react';
import PrivacyModalContent from './PrivacyModalBody';
import { FOOTER } from './Footer.const';
import { getPrivacyPolicyContent } from '../../services/userProfile.service';
import LoadIndicator from '../loadIndicator';
import { BUTTONS, GLOBAL_ERROR_BOUNDARY_MESSAGE } from '../../app.labels';
import { IGenericObject } from '../../models/IGenericObject';
import '../../styles/Footer.scss';
import { CLIENT_DEMO_DISCLAIMER_CONTENT } from '../layout/pages/landingPage/policyLandingPage/Policies.label.const';

interface IOwnProps {
    show: boolean;
    type: string;
    onClose: () => void;
}

enum PolicyType {
    PRIVACY = 1
}

const FooterModal: React.FC<IOwnProps> = ({ show, type, onClose }) => {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    const loadData = async () => {
        let data: IGenericObject = {};
        if (type === FOOTER.privacyNotice) {
            data = await getPrivacyPolicyContent(PolicyType.PRIVACY);
        }
        if (data?.policyTypeList && data?.policyTypeList[0]?.content) {
            setData(data?.policyTypeList[0]?.content);
        } else if (type === FOOTER.demoDisclaimer) {
            setData(CLIENT_DEMO_DISCLAIMER_CONTENT)
        } else {
            setData('');
        }
        setLoading(false);
    };

    useEffect(() => {
        if (show) {
            setLoading(true);
            loadData();
        }
    }, [show]);

    const GetModalBody: React.FC = () => {
        if (loading) {
            return (
                <div className="loadIndicator">
                    <LoadIndicator />
                </div>
            );
        } else if (data) {
            return <PrivacyModalContent content={data} />;
        }
        return (
            <div className="loadIndicator">
                <MotifInlineMessage variant="error">
                    {GLOBAL_ERROR_BOUNDARY_MESSAGE.title +
                        ' ' +
                        GLOBAL_ERROR_BOUNDARY_MESSAGE.description}
                </MotifInlineMessage>
            </div>
        );
    };

    return (
        <ModalComponent
            modalHeader={type}
            isModalVisible={show}
            size={ModalSize.EXTRA_LARGE}
            modalBody={<GetModalBody />}
            modalFooter={
                <MotifButton type="button" onClick={onClose}>
                    {BUTTONS.close}
                </MotifButton>
            }
            onClose={onClose}
        />
    );
};

export default React.memo(FooterModal);
